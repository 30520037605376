@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.signOut {
  display: grid;
  place-items: center;
}

.signoutButton {
  font-size: 20px;
  background-color: white;
  border: 0px;
  cursor: pointer;
  font-size: 1.0625em;
  font-family: "Manrope-Regular";
}

.main_card{
  height: 438px !important;
  width: 265px !important;
  border-radius: 10px !important;
}

.nav_bg_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_profile {
  height: 38px;
  width: 38px;
  border: 3px solid #2896FF;
  border-radius: 50%;
  padding-top: 0px !important;
}

.user_menu {
  display: flex;
  align-items: center;
}

.user_name{
  font-family: "Manrope-Regular" !important;
  font-size: 2vh !important;
  text-align: center;
}

.all_profile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #D1E8FF !important;
  padding: 15px 30px 50px 30px !important;
  height: 252px;
}

.edit_profile{
  height: 82.25px;
  width: 82.25px;
  border-radius: 50%;
  align-items: center;

}

.recruiter_name{
  font-family: "Arial" !important;
  font-size: 14px bold !important;
  text-align: center;
  padding-top: 19px !important;
}

.user_roll{
  font-family: "Arial" !important;
  font-size: 10px !important;
  font-weight: bold !important;
  text-align: center;
  padding-top: 10px !important;
}

.edit_logo{
  height: 38px;
  width: 146px;
  align-items: center;
  padding-top: 17px !important;
}

.cardAction{
  width:100%;
  padding:0 !important;
  margin: 0 !important;
  background-color: #1885e6;
  color: white;
  height: 186px;
  margin-top: -40px !important;
}

.card_text{
  font-size: 12px !important;
  font-family: 'Segoe UI'!important;
  width: 100%;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  color: #ffffff;
}

.dialog{
  position: absolute;
  left: 10;
  top:50;
  border: 5px solid black;
}
.menulist{
  height: 100%;
  width: 100%;
}

.arrow{
  cursor: pointer;
}

.menu_title{
  font-size: 12px ;
  font-family: 'Segoe UI';
 
  color: #ffffff;
}


.navlink{
  text-decoration: none;
}

.person{
  color: #ffffff;
}

.logo_main_div{
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit_logo_div{
  height: 40px;
}

.edit_logos {
  max-width: 100%;
  max-height: 100%;
}

.menu-icon{
  height: 17px;
  width: 17px;
}

.account-text{
  margin-left: 4px;
}

.profile_dropdown{
  position: relative;
}

.header_profile_pic{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 }

 .header_nav{
  margin-top: 17px;
  padding-top: 0px;
  display: flex;
  width: -webkit-max-content;
  width: max-content;
  margin-right: 20px;
 }

 .user_menu{
  width: 80%;
}

.profile_name{
  width: 80%;
  flex-grow: 1;
}

.profileImg{
  width: 38px;;
  height:38px;
  border-radius: 50%;
  border: 3px solid #2896FF;
  margin-top: 8px;
}

.profile_drop{
  width: 1%;
}

.profile_drop_menu_headers{
  position: absolute;
  top: 66px !important;
  right: 0px;
  }

  .notification_profile_mainContainer_header{
    display: flex;
    flex-direction: row;
  }

  .notification_container_header{
    margin-top: 16px;
    margin-right: 45px;
    display: flex;
    flex-direction: column;
  }

  .bellIcon_Img{
    height: 30px;
    width: 29px;
  }

  .notification_contain_header{
    position: absolute;
    top: 83px;
    right: 300px;
    width: 328px;
    box-shadow: 0px 3px 8px #0000003d;
    background-color: #ffffff;
  }

  .notification_contain_header_recruiter{
    position: absolute;
    top: 75px;
    right: 241px;
    width: 328px;
    box-shadow: 0px 3px 8px #0000003d;
    background-color: #ffffff;
  }

  .notification_titleContainer_header{
    background-color: #31B3F8;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .notification_text_header{
    color: #ffffff;
    font-family: "Segoe-regular";
    font-size: 25px;
    font-weight: 400;
  }

  .markAllRead_container_header{
    padding-top: 5px;
  }

  .markAllRead_text_header{
    color: #ffffff;
    font-family: "Segoe-regular";
    font-size: 15px;
    font-weight: 400;
    border-bottom: 1px solid #ffffff;
  }
 

  .notification_sms_container_header{
    background-color: #ffffff;
    height: 217px;
    overflow: auto;
    padding-top: 14px;
  }



  .notification_mainDiv_header{
    border: 1px solid #31B3F8;
    display: flex;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7px;
    padding: 10px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    cursor: pointer;
  }
  .notification_mainDiv_header_Unread{
    border: 1px solid #31B3F8;
    background-color: aliceblue;
    display: flex;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7px;
    padding: 10px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    cursor: pointer;
  }

  .iconColor_header{
    color: rgb(49, 179, 248) !important;
  }

  .requitionNumber_notificationcontainer_header{
    display: flex;
    flex-direction: column;
  }

  .viewallContainer_notifacation_header{
    display: flex;
    justify-content: flex-end;
    padding: 13px 10px 13px 10px;
  }

  .viewAllText_header{
    color: #31B3F8;
    font-family: "Segoe-regular";
    font-size: 17px;
    border-bottom: 1px solid #31B3F8;
    margin-right: 7px;
    cursor: pointer;
  }

  .viewAllText_header:hover{
    opacity: 0.5;
  }

  .requitionno_text_header{
    font-family: "Segoe-Semibold";
    font-size: 13px;
    color: #393939;
    padding-bottom: 2px;
  }

  .submittedBy_notification_header{
    font-family: "Segoe-regular";
    font-size: 14px;
    color:  #9B9B9B;
  }

  .notificationIcon_header{
    cursor: pointer;
  }

  .NoRecentText{
    font-family: "Segoe-Semibold";
    font-size: 13px;
    color: #393939;
    text-align: center;
  }

  .headerUserTitle{
    max-width: 200px ;
  }

 
.home-contact-us .dropdown_width_outer {
  display: flex;
  justify-content: space-between;
}
.mt_12 {
  margin-top: 12px;
}
.home-contact-us .dropdown_width {
  width: 48.5%;
}

input[type=text], select {
  width: 100%;
  /* padding: 14px 20px!important; */
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
}
.home-contact-us .text_box_cleartalent {
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}


.home-contact-us .btn-green, .home-contact-us .btn-green:hover {
  color: #fff;
  background-color: #2ad156;
  font-family:'Manrope-Bold';
  border: none;
}
.home-contact-us .btn-big {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 9px 35px 11px;
  font-size: 18px;
  border-radius: 4px;
  font-family:'Manrope-Bold';
}
.cp {
  cursor: pointer;
}
.contact_header, .MuiTypography-h6 {
  font-family: 'Manrope-Light' !important;
  color: #01313c!important;
  font-size: 28px !important;
}

.home-contact-us .text_area_cleartalent_support_home {
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Manrope-Regular' !important;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid #a5a4a4;
  border-radius: 4px;
}
.home-contact-us .MuiDialog-paperWidthSm {
  max-width: 750px !important;
}
.home-contact-us legend {
  font-family: 'Manrope-bold' !important;
  font-size: 16px;
  margin-bottom: 17px;
  font-style: normal;
  font-weight: 600;
  color: #01313c;
}
.home-contact-us .MuiTypography-h6 {
  font-family: 'Manrope-Light' !important;
}
.text_area_cleartalent_support_home {
  width: 100%;
}

.support_dialog_title {
  position: relative;
}

.support_grid_message {
  margin-top: 20px !important;
}

.support_message {
  height: 160px;
  width: 100%;
  border: 1px solid #707070;
  opacity: 0.6;
}

.support_line {
  border-bottom: 1px solid #6398F1;
  width: 268px;
  font-family: 'Manrope-Medium';
  font-size: 36px;
  color: #1885E6;
  height: 58px;
}

.blue {
  position: absolute;
  top: 0;
  right: 4px;
}

.support_con {
  padding-top: 0px !important;
  padding-right: 28px !important;
}

.support_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
}

.support_grid {
  padding-right: 20px;
}

.support_form_leb {
  font-family: 'Manrope-Medium';
  font-size: 16px;
  color: #666666;
}

.Support_textbox{
  height: 40px;
  width: 100%;
  padding-left: 0px !important;
}

.support_grid_message{
  margin-top: 20px !important;
  padding-bottom: 0px;
}

#mes_lable{
  font-size: 16px;
  font-family: 'Manrope-Medium';
  width: 100%;
 height: 15px;
 color:#666666;
}

.support_message{
  height: 140px;
  width: 100%;
  border: 1.4px solid #707070;
  opacity: 0.6;
  border-radius: 3px;
}

.support_mail{
  padding-Right: 0px;
}
.footer_recruiter_signup {
  width: 100%;
  background-color: #C2C2C2;
}

.container_rec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:50px 0px;
  
}

.footer-left img {
  height: 40px;
  width: 350px;
}

.footer-links {
  display: flex;
  color: #000;
  font-size: 1em;
  font-family: 'Manrope-Medium';
}

.footerTerms {
  font-size: 1em;
  font-family: 'Manrope-Medium';
  color: #2896ff;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}
.footerTerms:hover{
  opacity: 0.7;
}
@media (max-width: 768px) {
  .container_rec {
      flex-direction: column;
      align-items: flex-start;
  }

  .footer-links {
      flex-direction: column;
      align-items: flex-start;
  }
}

@media (min-width:769px) and (max-width: 880px) {
  .footer-left img {
      height: 25px;
      width: 250px;
  }
  .footer-links .footerTerms{
      font-size: 0.5em;
  }
}

.pending_resume_container {
  margin: 120px 90px 100px;
  background-color: #ffffff;
}

.pendingResumeArrow {
  font-size: 20px;
  color: #04093f;
  font-family: "Segoe-Semibold";
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 34vw;
  margin-bottom: 15px;
}

.pending_resume_container__title {
  font-size: 30px;
  color: #04093f;
  font-family: "Segoe-Semibold";
  text-decoration: none;
  margin: 10px 0px 20px;
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  color: #2896ff;
  border-bottom: 1px solid #2896ff;
  padding-bottom: 5px;
}


.pending_resume_container__table {
  height: 80%;
  overflow-y: auto;
}

.scrollable {
  scrollbar-width: thin;
  scrollbar-color: #1A8FFF #dddddd;
  scrollbar-track-color: #dddddd;
  scrollbar-face-color: #1A8FFF
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #1A8FFF;
  height: 5px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #dddddd;
}

.resume_table_body {
  background-color: #e4f0f5;
}


.resume_table {
  border-collapse: collapse;
  border: 1px solid rgb(200, 200, 200);
  letter-spacing: 1px;
  font-family: sans-serif;
  font-size: 0.8rem;
  width: 98%;
}

.resume_table_td,
.resume_table_th {
  padding: 5px 0px 5px 15px;
  height: 37px;
  text-align: left;
}

.error .resume_table_td span {
  color: rgba(178, 32, 32, 1);
}

.success .resume_table_td {
  font-family: "Segoe-Regular";
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgb(190, 190, 190);
}

.pending-validations-actions {
  margin-top: 40px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  grid-gap: 15px;
  gap: 15px;
}

.pending-validations-actions__cancel {
  color: black !important;
  width: 135px; 
}

.pending-validations-actions__submit {
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff ; 
    padding: 5px 30px ;
    width: 135px;    
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
}


.pending-validations-actions__submit:hover{
  opacity: 0.9;
}
.pending-validations-actions__submit_disable{
  /* height: 40px; */
  display: flex ;
  background-color: #1885e6 !important;  
  color: #ffffff ; 
  padding: 5px 30px ;
  width: 135px;    
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  border: none;
  font-family: Arial;
  font-size: 15px;
  border-radius: 4px;
  grid-gap: 10px;
  gap: 10px;
}
.pending-validations-actions .MuiCircularProgress-root{
  color: #FFFFFF !important;
  height: 13px !important;
  width: 13px !important;
}


.status_icons .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.status_icons .success {
  color: rgba(75, 255, 34, 1);
}

.status_icons .error {
  color: rgba(178, 32, 32, 1);
}



.status_icons {
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.custom_kendo_grid .k-table-thead,
.k-table-header {
  background-color: #1A8FFF !important;
  height: 48px !important;
  color: #ffffff !important;
  font-family: "Segoe-Semibold" !important;
  font-weight: bold !important;
  font-size: 18px !important;
  letter-spacing: 0px !important;
}

.custom_kendo_grid .k-pager-numbers .k-button .k-button-text {
  color: black !important;
}

.kendoStylePendingResume {
 min-height: 567px;
}

.pending-validations-actions__viewResume {
  color: white !important;
  background-color: #31B3F8 !important;
}

.pending-validations-actions__viewResume_blank{
  color: white !important;
  background-color: #31B3F8 !important;
  opacity: 0.5;
}

.footer_container_pending_resume{
  margin-top: auto;
}
.inputLabel_right {
    font-size: 14px;
    font-family: 'Manrope-Regular';
    margin-bottom: 8px;
    margin-top: 5px;
}

.pending-validations-actions__upload {
    background-color: #31B3F8 !important;
    margin-left: 15px !important;
    color: white !important;
    padding: 10px !important;
}

.textbox_phone_input {
    max-width: 100%;
    max-height: 45px !important;
}

.textbox_phone_input .PhoneInputInput {
    padding: 10px;
    border: none;
    font-size: 16px;
}

.upload_field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.upload_field .MuiFormControl-root {
    width: 100% !important;
}

.upload_field .upload_resume_textbox .input[type="text"] {
    padding: 24px !important;
}

.form-error {
    color: red;
    font-size: 13px;
    margin-top: 8px;
}

.autocomplete-container .MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-inputRoot {
    padding-top: 5px;
    align-items: center;
    justify-content: center;
}

.add-button {
    background-color: #788189;

    color: #000000;
    cursor: pointer;
    border: none;
    padding: 6px 12px;
    margin-left: 8px;
    border-radius: 4px;
    font-size: 14px;
}

.add-button:hover {
    background-color: #0056b3;
}


.phoneNumber_pendingResume .PhoneInputInput {
    border: none;
    outline: none;

}

.textbox_phone_input_pendingResume {
    height: 46px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}

.admin_textbox_error{
    height: 46px;
    border: 2px solid red;
}

.admin_textbox_primarySkill_error{
    height: 43px;
    border: 2px solid red;
}

.dialogbox_addidtional_skill_candidate_resume .MuiAutocomplete-endAdornment{
    display: none !important;
}

.admin_textbox_currency{
    height: 46px;
    width: 140px;
}

@media screen and (max-width: 1800px) {
    .admin_textbox_currency{
        width: 100px;
    }
}

@media screen and (max-width: 1345px) {
    .admin_textbox_currency{
        width: 80px;
    }
}

@media screen and (max-width: 820px) {
    .admin_textbox_currency{
        width: 50px;
        
    }
}
/*--------------- Header CSS -------------*/
.nav_bg {
  width: 100%;
  background-color:  #FFFFFF !important;
}
.header_fixed {
  position: fixed;
  top: 0px;
  z-index: 999;
}

.nav_bg_left {
  width: 42%;
  /* background-color: #04093f; */
}

.nav_bg_right {
  /* width: 58%; */
  background-color: #04093f;
}
.hamberger_icon {
  display: none;
}

.nav_logo {
  margin-top: 22px;
  margin-bottom: 16px;
  width: 100%;
}

.navbar {
  width: 100%;
  overflow: auto;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.navbar li {
  list-style-type: none;
}

.navbar li img {
  vertical-align: middle;
}

.LinkForHeader {
  float: left;
  padding: 7px 0px 0px 38px;
  color: #d1e8ff;
  text-decoration: none;
  font-size: 16px;
  font-family: "Segoe-Regular";
}

.nav_menu_icon {
  padding-right: 5px;
}

.notify-badge {
  position: absolute;
  top: 5px;
  margin-left: 9px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  background: #2ad156;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: 8px;
}
/* .contact_header{
  font-weight: normal!important;
  font-family: 'Segoe-Light'!important;
  font-size: 68px!important;
  color: #01313C!important;
} */
.MuiTypography-h6 {
  font-family: "Segoe-Light" !important;
  font-size: 35px !important;
  color: #01313c !important;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 6px 24px;
}
.contact_details_heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Segoe-Semibold" !important;
  color: #01313c !important;
}
.nav_menu_adjust {
  display: flex;
  justify-content: space-between;
}
.mobilemenuleft_adjust {
  margin-left: 0px;
}
.fadeInHmbMenu {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
  position: absolute;
  width: 100%;
  left: 0px;
  height: 350px;
  z-index: 99;
  background-color: #04093f;
  top: 61px;
  /* display: block; */
}
.fadeOutHmbMenu {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  width: 0;
  height: 0;
  /* display: none; */
}
.support_textbackgroundone {
  background-color: #d1e8ff;
  width: 44.5%;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
}
.support_textbackgroundtwo {
  background-color: #d1e8ff;
  width: 42%;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
}
.support_section {
  display: flex;
  justify-content: space-between;
}
.text_area_cleartalent_support {
  width: 94%;
  /* height: 45px; */
  /* padding: 0px 0px; */
  /* font-size: 14px; */
  /* line-height: 1.42857143; */
  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}
.add_email_box {
  width: 100%;
  position: relative;
  display: flex;
}
.add_email_box .search-input {
  width: 100%;
  padding: 10px;
  height: 45px;
  border: 1px solid #a5a4a4;
  border-radius: 4px 0 0 4px;
  border-right: none;
  outline: none;
  font-size: 16px;
  color: #000;
  background: none;
  font-family: "Segoe-Regular";
}

.add_email_box .search-input:focus {
  box-shadow: none;
  border: 1px solid #a5a4a4;
}

.add_email_box .search-input::placeholder {
  font-size: 16px;
  color: #01313c;

  font-family: "Segoe-Regular";
}

.text_area_cleartalent_support {
  width: 94%;

  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid #a5a4a4;
  border-radius: 4px;
}

.text_area_cleartalent_support::placeholder {
  font-size: 16px;
  color: #01313c;

  font-family: "Segoe-Regular";
}

.add_email_box .search-button {
  text-align: center;
  height: 45px;
  padding: 0px 20px;
  outline: none;
  cursor: pointer;
  border: 1px solid #2ad156;
  border-radius: 0 4px 4px 0;
  border-left: none;
  background: none;
  font-size: 16px;
  border-left: 1px solid #a5a4a4;
  font-family: "Segoe-Semibold";
  color: #fff;
  background-color: #2ad156;
}
.mail_bg {
  background-color: #d1e8ff;
}

.mail_text {
  font-size: 18px;
  font-family: "Segoe-Regular";
  color: #01313c;
}

.mail_close_icon {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .text_area_cleartalent_support {
    width: 77%;
    /* height: 45px; */
    /* padding: 0px 0px; */
    /* font-size: 14px; */
    /* line-height: 1.42857143; */
    padding: 0px 30px;
    font-size: 16px;
    font-family: "Segoe-Regular";
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
  }
  .responsive_Support {
    padding: 0px 18px !important ;
  }
  .support_textbackgroundone {
    background-color: #d1e8ff;
    width: 94%;
    padding: 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .support_textbackgroundtwo {
    background-color: #d1e8ff;
    width: 94%;
    padding: 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .support_section {
    display: block;
    justify-content: unset;
  }

  .MuiTypography-h6 {
    font-family: "Segoe-Light" !important;
    font-size: 30px !important;
    color: #01313c !important;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  .hamberger_icon {
    float: right;
    display: contents;
  }
  .nav_logo {
    margin-top: 22px;
    margin-bottom: 16px;

    width: 80%;
  }

  .nav_bg_left {
    width: 100% !important;
    /* background-color: #04093f; */
  }

  .nav_bg_right {
    width: 100% !important;
    background-color: #04093f;
    display: none !important;
  }

  .LinkForHeader {
    float: left;
    padding: 7px 4px 0px 4px !important;
    color: #d1e8ff;
    text-decoration: none;
    font-size: 16px;
    font-family: "Segoe-Regular";
  }
  .nav_menu_adjust {
    display: flex;
    padding-bottom: 0px;
    padding-right: 20px;
  }
  .hamberger_nav {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 350px;
    z-index: 99;
    background-color: #04093f;
    top: 61px;
  }
  .fadeInHmbMenu {
    opacity: 1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    position: absolute;
    width: 100%;
    left: 0px;
    height: 350px;
    z-index: 99;
    background-color: #04093f;
    top: 61px;
    /* display: block; */
  }
  .fadeOutHmbMenu {
    opacity: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    width: 0;
    height: 0;
    /* display: none; */
  }
  .navbar_mobile {
    width: 100%;
    overflow: auto;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
  }
  .mobilemenuleft_adjust {
    margin-left: 24px;
  }

  .navbar_mobile li {
    list-style-type: none;

    /* display: block; */
    width: 100%;
    /* margin: 10px 0px; */
    border-bottom: 1px solid #192288;
    padding: 20px 0px !important;
  }
  .navbar_mobile li:last-child {
    list-style-type: none;

    /* display: block; */
    width: 100%;
    /* margin: 10px 0px; */
    border-bottom: 0px solid #192288;

    padding: 20px 0px !important;
  }

  .navbar_mobile li img {
    vertical-align: middle;
  }
}

.support-mailbody-textarea {
  width: 100%;
  height: 100px !important;
}
.mail-validation-error {
  color: red;
}
.remove-snackbar-alert .MuiAlert-message {
  font-family: "Segoe-Regular";
}
.text_area_send_mail {
  width: 100%;
  padding-right: 0px;
}
.fle{
  display: flex;
}
.mail_more{
  display: inline;
}
.dropdown {
  position: relative;
  display: inline-block;
  /* z-index: 10; */
}

.dropdown-content {
  /* display: none; */
  position: absolute;
  /* background-color: #04093f; */
  background-color: white;
  min-width: 207px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 150px;
  left: 0px;
}
.recruiter_nav {
 
  padding: 5px 0px 0px 38px;
 
}


.dropdown-content li {
  /* color: white; */
  padding: 14px 16px 14px 37px;
  text-decoration: none;
  display: block;
  /* border-bottom: 1px solid #2f3575f0; */
  border-bottom: 1px solid #1A8FFF;
  /* font-family: 'Segoe-Regular'; */
  font-family: 'Manrope-Regular';
  font-size: 16px;
}
/* .responsive_li_adminpages{
    display: none;
} */
.hamberger_nav{
  display: none;
}
.LinkForHeader_admin{
  float: left;
  padding: 3px 0px 0px 10px;
  /* color: #d1e8ff; */
  text-decoration: none;
  font-size: 17px;
  /* font-family: "Segoe-Regular"; */
  font-family: 'Manrope-Regular';
}
#menu-list-grow::-webkit-scrollbar-thumb
{
  /* background-color: rgb(195, 195, 240); */
  /* color: red; */
  background-color: #1A8FFF;
}
#menu-list-grow
{
  padding-bottom: 30px;
  padding-top: 0px;
}
@media only screen and (max-width: 768px){
  .responsive_li_adminpages{
      margin-left: 24px;
      width: 70% !important;
    }
    .dropdown-content li {
      
      padding: 14px 16px 14px 37px !important;
      
  }
  .dropdown-content li:last-child {
      
      padding: 14px 16px 14px 37px !important;
      
  }
  .dropdown-content {
      /* display: none; */
      width: 100%;
    }
    .hamberger_nav {
      display: block;
  }
    
} 


/* .show {display: block;} */
.navbar .LinkForHeader_admin:first-child {
  margin-top: 2px;
}



/* Drop down css admin menu */
.menuBorder
{
  border-bottom:1px solid #1A8FFF;
}
.navbar.admin-header-navbar {
  display: flex;
}
.admin-header-navbar .MuiButton-label {
  /* color: #d1e8ff; */
  font-size: 18px;
  /* font-family: 'Segoe-Regular'; */
  font-family: 'Manrope-Regular';
  padding: 0;
}
.admin-header-navbar .MuiList-root {
  /* background-color: #04093f; */
  /* background-color: white; */
  /* color: #d1e8ff; */
  min-width: 220px;
  height: 300px;
  overflow-y: scroll;
}

.admin-header-navbar .MuiList-root::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5; */
  
}

.admin-header-navbar .MuiList-root::-webkit-scrollbar {
  width: 6px;
	background-color: transparent;
}

.admin-header-navbar .MuiList-root::-webkit-scrollbar-thumb {
  background-color: white;
	border-radius: 10px;

}




#style-4 .MuiList-root::-webkit-scrollbar
{
	
}

#style-4 .MuiList-root::-webkit-scrollbar-thumb
{

}
.admin-header-navbar .MuiList-root li.MuiListItem-root {
  /* border-bottom: 1px solid #2f3575f0; */
  border-bottom: 1px solid #1A8FFF;
  /* font-family: 'Segoe-Regular'; */
  font-family: 'Manrope-Regular';
  font-size: 18px;
  padding: 14px 16px 14px 37px;
}
.admin-header-navbar .MuiButton-text {
  padding: 2px 8px;
}
.admin-header-navbar .MuiPaper-root {
  top: 43px;
  position: relative;
  right: -25px;
  transition: none !important;
}
.listStyle
{
  list-style-type: none;
}
.itemlist
{
  display: block !important;
}

.scroll_hide {
  overflow: unset !important;
}

.add_question_button {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
}

.mr_10 {
  margin-right: 10px !important;
}

.question_header {
  padding: 16px 0px !important;
}

.pb_15 {
  padding-bottom: 15px;
}
.height_adjust textarea {
  font-size: 16px;
  font-family: "Segoe-Regular";
  padding: 0.5rem;
  width:100%;
  margin-right: 20px;
}

.mySelect__value-container{
    height: 44px;
}

.choice_textarea textarea {
  width: 98% !important;
}

.choice_order_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.choice_order_section_width {
  width: 16%;
}
.choice_order_section_width_edit {
  width: 17%;
  margin-left: 30px;
}

.mt_30 {
  margin-top: 30px;
}

@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
.widthFix{
  width: 100%;
}
.ql-editor{
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}
.requ_header {
    /* border: 1px solid black; */
    height: 5vh;
    width: 89vw;
    /* background-color: #8dc4fc; */
    color: white;

}

.searchrec_subheader {
    height: 46px;
    /* margin-bottom: 0px; */
    padding: 15px;
    padding-left: 10px;
    background-color: #D1E8FF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


}

/* style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: '' }} */
.searchrec_color {
    color: #ffff;
}

.searchreq_leftmaincon {
    background-color: #ffff;
    /* width: 100%; */
    height: 60vh;
    /* box-shadow: 0px 0px 10px 0px #00000040; */
    /* margin-top: -4px; */
    /* padding-top: 1vh; */
    padding-left: 2vh;

}

.searchreq_autocom {
    width: 95%;

    /* height: 1em !important; */
    /* padding: ; */
}

.ListIco {
    height: 40px !important;
    width: 40px !important;
}

.searchreq_autoco {
    width: 23vh;
}

.searchreq_calender {
    padding-top: 10px;
}

.seareq_button {
    background-color: #009EFD;
    width: 172px;
    height: 39px;
    border-radius: 8px;
    color: #ffff;
    font-size: 16px;
    font-family: "Segoe-Semibold";
    box-shadow: none;
    border: none;

}

.seareq_image {
    height: 18px;
    width: 18px;
    /* display: flex; */
    justify-content: center;
    text-align: center;
    align-items: center;
}

.searec_leftcont_requisitionSearch {
    width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}


.searec_employementContainer {
    display: flex;
    width: 95%;
    grid-gap: 10px;
    gap: 10px;
}

.searec_employmentItems {
    margin-top: 15px;
    width: 100%;
}

.seareq-date-picker .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 10px;
    top: 8px;
}

.divider_input_searchreq {
    margin-top: 15px;
    border-top: 1px solid #2699FB;
    opacity: 0.4;
}

.seareq_date_border {
    /* border:solid 2px #7a6bdc; */
    border: 2px solid #177bd3ce !important;
    padding: 12px 10px !important;
    font-family: "Segoe UI" !important;
    font-size: 14px !important;
}

.sea_top_contain {
    display: flex;
    justify-content: space-between;


}

.addReq_button {
    display: flex;
    justify-content: center;
    align-items: center;

}

.searec_main_con {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */

}

.datePicker_SearchReequisition .input[type="text"] {
    border: 1px solid #2699FB !important;
}

.seareq-date-picker {
    display: flex;
    flex-direction: row;
    width: 95%;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
}

.seareq_datePicker {
    margin: 3px;
}

.seareq_card_border {
    margin-left: 20px;
    height: 800px;
    width: 80%;
}
.secondCard {
  width: calc(100% - 450px);
  margin-bottom: 10px;
}
@media screen and (min-width: 1290px) {
  .secondCard {
    width: calc(100% - 630px);
    margin-bottom: 10px;
  }
}
.secondCard_searchRequisition {
  width: calc(100% - 420px);
  margin-bottom: 10px;
  height: 790px;
}

.cardBlock {
  padding: 10px 0px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #0000003d;
}

.secCard_header {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.sec_req_cards {
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  font-family: 'Segoe-Semibold';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #075482;
}


.secCard_header_components {
  margin-right: 10px;
  margin-top: 5px;
}

.searchBG {
  background-color: #e6e6e6 !important;
  border-radius: 10px !important;
}

.filterNav {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.filterBtnStyle_selected {
  box-shadow: 0px 3px 8px #0000003d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #009EFD;
  color: white;
  border-radius: 8px;
  border: 0px;
  padding: 8px 1px;
  width: 160px;
  cursor: pointer;
}

.filterBtnStyle {
  box-shadow: 0px 3px 8px #0000003d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #74c1f1;
  color: white;
  border-radius: 8px;
  border: 0px;
  padding: 8px 1px;
  width: 160px;
  cursor: pointer;
}

.filterBtnStyle:hover {
  background-color: #31B3F8;
  color: white;
  cursor: pointer;
}

.filterTexts {
  font-family: 'Segoe-Regular';
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin-left: 20px;
  letter-spacing: 0.05em;
}

.filterIcon {
  margin-right: 10px;
}

.sec_card_content {
  /* padding: 0px 20px 20px 20px; */
  padding-left: 20px;
}
.sec_card_content_searchRequisition{
  padding-left: 20px;
  height: 780px;
}

.sec_card_req_container {
  height: 66vh;
  overflow-y: auto;
}
.sec_card_req_container_searchRequisition {
  height: 780px;
  overflow-y: auto;
}

.sec_card_req_container::-webkit-scrollbar {
  width: 10px;
}

.sec_card_req_container::-webkit-scrollbar-thumb {
  background-color: #4ab6fa;
  ;
  border: 1px solid #c8c8c8;
  border-radius: 20px;
  box-shadow: 0 0 6px #c8c8c8;
}

.sec_card_req_container::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #c8c8c8 !important;
}
.sec_card_req_container_searchRequisition::-webkit-scrollbar {
  width: 10px;
}

.sec_card_req_container_searchRequisition::-webkit-scrollbar-thumb {
  background-color: #4ab6fa;
  ;
  border: 1px solid #c8c8c8;
  border-radius: 20px;
  box-shadow: 0 0 6px #c8c8c8;
}

.sec_card_req_container_searchRequisition::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #c8c8c8 !important;
}


.req_card {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.req_card:hover {
  background-color: #e9e6e6;
  cursor: pointer;
}

.greenCard {
  border: 2px solid #11BB55;
}

.redCard {
  border: 2px solid #F13A3A;
}

.blueCard {
  border: 2px solid #009EFD;
}

.reqCardContainer {
  /* width: 35%; */
}

.card_left_text {
  margin-bottom: 5px;
  display: flex;
}

.secCard_label {
  font-family: 'Segoe-Semibold';
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  min-width: 120px;

}

.valuesFields {
  font-family: 'Segoe-semibold';
  font-size: 15px;
  text-align: left;
  color: #6E6D6D;
  word-break: break-word;
}

.card_right_part {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.priorityDiv {
  border-radius: 8px;
  padding: 8px 8px;
  color: white;
  width: 120px;
  font-family: 'Segoe-Regular';
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.greenPriorityDiv {
  background-color: #00bf63;
}

.redPriorityDiv {
  background-color: #F13A3A;
}

.bluePriorityDiv {
  background-color: #009EFD;
}

.personName {
  display: flex;
  font-family: 'Segoe-Regular';
  font-size: 15px;
  text-align: left;
}

.contact_label {
  font-family: 'Segoe-Semibold';
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  min-width: 60px;
}

.searchbar_requisition {
  width: 300px;
}

.secCard_header .MuiInputBase-root {
  background-color: #ECEEEF !important;
  font-family: 'Segoe-Regular' !important;
  opacity: 0.6;
  border: 1px solid #ECEEEF !important;
  outline-offset: 0ch;
}

.secCard_header .MuiOutlinedInput-adornedEnd {
  padding-right: 10px !important;
}

.secCard_header .Mui-focused {
  opacity: 1 !important;
}

.secCard_header .searchIcon_searchBar {
  color: #075482 !important;
}

.skeletonStyle {
  margin-bottom: 15px;
  border-radius: 8px;
}

.loadingDiv {
  text-align: center;
}

.noRecStyle {
  text-align: center;
  padding: 25px 0px;
  font-family: 'Segoe-Regular';
  font-size: 15px;
  font-weight: 600;
}

.noDataFound {
  font-family: 'Segoe-Regular';
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #6E6D6D;
  height: 55vh;
}
.searchrec_subheader {
    height: 46px;
    /* margin-bottom: 0px; */
    padding: 15px;
    padding-left: 10px;
    background-color: #D1E8FF;
}

.searchrec_color {
    color: #ffff;


}

.activity_autocom {
    width: 96%;
    padding:2%;
}

.ListIco {
    height: 40px !important;
    width: 40px !important;
}

.searchreq_autoco {
    width: 23vh;
}

.searchreq_calender {
    padding-top: 10px;
}

/* .talentCommunityMainContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 2px 2px !important;
} */

.seareq_button {
    background-color: #009EFD;
    width: 172px;
    height: 39px;
    border-radius: 8px;
    color: #ffff;
    font-size: 16px;
    font-family: "Segoe-Semibold";
    box-shadow: none;
    border: none;

}

.seareq_image {
    height: 18px;
    width: 18px;
    /* display: flex; */
    justify-content: center;
    text-align: center;
    align-items: center;
}

.searec_leftcont {
    width: 400px;
    height: 800px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}


.searec_employementContainer {
    display: flex;
    width: 95%;
    grid-gap: 10px;
    gap: 10px;
}

.searec_employmentItems {
    margin-top: 15px;
    width: 100%;
}


.activity_date .react-datepicker__input-container .react-datepicker__calendar-icon {
    margin-top: 12px;
    margin-left: 90%;
}

.activity_date {
    width: 95%;
    /* height: 6vh; */
}

.activity_date_border {
    /* border:solid .5px; */
    border: 1px solid rgb(192, 192, 192);
    /* width: 10vh; */
    height: 40px;
    /* box-shadow: none; */
}

.mainDiv_advanceSea {
    /* margin-top: 120px; */
    margin-left: 5%;
    margin-right: 5%;
}

.interview_mainCont {
    box-shadow: 0px 0px 10px 0px #00000040;
    border-radius: 8px;

}

.activity_dialog {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    font-family: "Segoe-Semibold";
    color: #ffff;


}


.activity_date .react-datepicker-wrapper {
    display: inline;
}


.activity_topCon {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 4vh;
    padding-top: 10px;
    padding-bottom: 20px;
    /* padding-right: ; */
}

.activity_topCon .pt_20 {
    padding-top: 20px;
}

.activity_rightCon {
    margin-left: 20px;
    width: 300px;
}

.activity_footer {
    margin-top: 4vh;
}

.activity_support_line {
    border-bottom: 1px solid #6398F1;
    width: 200px;
    font-family: 'Manrope-Medium';
    font-size: 36px;
    color: #1885E6;
    height: 58px;
}

.btn_activity_cancel {
    width: 107px;
    height: 39px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #ffff;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.btn_activity_delete {
    width: 107px;
    height: 39px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #da1e28;
    border: 1px solid #da1e28;
    background-color: #ffff;
    /* background-color: #da1e28; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /* margin-right: 40px; */

}

.btn_activity_save {
    width: 107px;
    height: 39px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #ffff;
    background-color: #31B3F8;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

}

.activity_textarea {
    font-size: 14px;
    font-family: Segoe UI;
}

.activit_dialog_header {
    display: flex;
    align-items: center;

}

.pd_top_10 {
    padding-top: 10px;
}

.KendoContainer_Activity {
    width: calc(100% - 320px);
        /* min-height: 500px; */
}

.kendo_Activity {
    min-height: 695px;
    /* max-height: 695px ; */
    /* height:695px */
}

.KendoContainer_Activity .k-grid-container {
    overflow-y: scroll;
}

.activity_na {
    text-align: center;
}
.editButtonAcitivity{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 107px;
    height: 39px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    grid-gap:5%;
    gap:5%;
    color: #ffff;
    background-color: #31B3F8;
}
.export_recSelect{
   display: flex;
   grid-gap:29%;
   gap:29%;
   margin-left: -120px;
}
.selectrec{
    background-color: #31B3F8;
    padding: 12px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Segoe-semibold';
    font-size: 14px;
    width: 170px;
    font-weight:bolder;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.recentActivities_Container{
    margin-top: 20px;
    padding: 8px 10px;
    background-color: #B0DAF130;
    border-radius: 5px;
}
.activities_cont
{  
    /* max-width: 86%; */
    width: 96%;
}

.recentActivities_heading{
    font-size: 20px;
    font-family: 'Segoe-Semibold', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0px 0px 5px 0px;
    color: #009EFD;
}

.recentActivities_status{
    display: flex;
    background: #c8e9fd;
    padding: 12px;
    border-radius: 10px;
    font-size: 15px;
    font-family: 'Segoe-Semibold', Tahoma, Geneva, Verdana, sans-serif;
    justify-content: space-between;
    margin-bottom: 7px;
    align-items: center;
    cursor: pointer;
}

.recentActivities_status:hover{
    background: #a6d3eb;
    box-shadow: 0px 0px 3px 0px #009EFD;

}

.recentActivities_status_bottom{
    display: flex;
    flex-direction: row-reverse;
}

.recentActivities_ViewAllCandidates{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color: #009EFD;
    text-decoration: underline;
    font-family: 'Segoe-Semibold', Tahoma, Geneva, Verdana, sans-serif;
    cursor: pointer;
}

.recentActivities_statusValue{
    font-size: 22px;
}

.recentActivities_BtnGroup{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.header {
    /* width: 947px; */
    height: 33px;
   
    border-radius: 8px, 8px, 0px, 0px;
    color: #FFFFFF !important;
    background: linear-gradient(90deg, rgba(49,179,248,1) 0%, rgba(29,105,146,1) 100%);
    padding: 17px 33px !important;
    display: flex;
    align-items: center;

}
.add_btn_assign {
    margin-right: 10px;
    margin-top: 36px;
}


.pad-adjust {
    padding: 10px 12px !important;
}

.input-text-3 .MuiInputBase-input {
    height: 13px;
}

.input-text input[type=text] {
    height: 50px;
}

.input-text-other input[type=text] {
    height: 50px;
}

.input-text .MuiInputBase-input {
    height: 11px;
}


.MuiDialog-paperWidthSm {
    max-width: 814px!important;
}

.input-text {
    width: 105px;
}



.modal-width {
    max-width: 800px !important;
}


/* .input-text {
    width: 150px;
    height: 50px !important;
    border-radius: 8px;
    border: 1px solid #999999;
    background-color: white !important;
} */

.save-btn {
    width: 107px;
    height: 39px;
  
    border-radius: 8px;
    background: #009EFD;
    color: #FFFFFF;
    border: none;
    font-family: "Segoe-Semibold";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;

}

.cancel-btn {
    width: 107px;
    height: 39px;
  
    border-radius: 8px;
    background: #5E5E5F;
    color: #FFFFFF;
    border: none;
    font-family: "Segoe-Semibold";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.header_top {
    display: flex;
    align-items: center;
    color: white;
    font-size: 22px;
    font-family: "Segoe-Semibold";

}

.header_top img {
    width: 40px;
    padding-right: 10px;
}

.close_icon_right {
    margin-top: 30px;
}
.activityCapsule{
    background: white;
    display:flex;
    height:35px;
    border:2px solid #31b3f8;
    padding: 12px;
    border-radius: 10px;
    font-size: 15px;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    font-family: 'Segoe-Semibold', Tahoma, Geneva, Verdana, sans-serif;
}

.activityCapsule:hover{
    background-color: #c8e9fd;
}
.activityTabsTitleAction{
    
    display: inline;
}
.activityActionsTabs{
    /* background: yellow; */
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    /* gap:8%; */
}
.activityActionsTabs span{
    
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    grid-gap:10%;
    gap:10%;
    margin-right: 8%;
    align-items: center;
    cursor: pointer;
}

.activityActionContainer{
    width: 90%!important;
    margin:auto;
}
.deleteIconeActivityTabs{
    color: red;
}
.editIconeActivityTabs{
 color:#18aefe;
}
/* DeleteActivity.css */

.deleteActivityDialog .deleteActivitydialogTitle {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    font-family: '"Segoe-Semibold"', sans-serif; 
    background: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    text-align: start;
    padding: 15px;
}

.deleteActivityDialog .deleteActivitydialogContent {
    font-size: 16px;
    color: #555;
    text-align: center;
    margin: 10px 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.deleteActivityDialog .deleteActivitydialogActions {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    grid-gap: 10px;
    gap: 10px;
}

.deleteActivityDialog .deleteActivityButtonCancel {
    background-color: #f0f0f0;
    color: #333;
    border-radius: 8px;
    font-weight: bold;
}

.deleteActivityDialog .deleteActivityButtonConfirm {
    background-color: #e53935;
    color: white;
    border-radius: 8px;
    font-weight: bold;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.deleteActivityDialog .deleteActivityButtonConfirm:hover {
    background-color: #d32f2f;
}

.edit_activity_cancel{
 /* background-color: yellow; */
 float:right;
 margin: 2%;
}
.actionFieldheightWidth{
    height: 40px;
    width: 100%!important;
    border:0.5px solid rgba(0, 0, 0, 0.342);
}
.loaderForEdit{
  display: grid;
  place-items: center;
  padding: 120px 0 120px 0; 
  color: rgb(45, 143, 230);
  width: 100%;
  height: 100%;
}
.updateActivityformWrapper{
    padding: 5px;
}
.spaceLeft{
  margin-left: 8%;
}
.spaceOut{
    margin-top: 15px;
}
span.spaceOut{
    display: inline-block;
    margin-bottom: 8px!important;
} 
/* .spaceOut span {
    
} */
.actionFieldheight{
    height: 40px;
    border:0.5px solid black;
}

.spaceOut .resumeLinkContainer{
    display: flex;
    align-items: center;
    margin: 5px;
    grid-gap: 15px;
    gap: 15px;
}

.ActivityDynamicFields .react-datepicker__input-container .react-datepicker__calendar-icon {
    margin-top: 6px;
    margin-left: 95%;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


body {
  margin: 0px;
  padding: 0px;
}

.mainbg {
  background-color: #fff;
}

p {
  margin: 0px;
}

 @font-face {
  font-family: 'Manrope-Light';
  font-style: normal;
  font-weight: normal;
  src: local('fonts/Manrope-Light'), url(/static/media/Manrope-Light.d03f265a.ttf) format('woff');
}

@font-face {
  font-family: 'Manrope-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('fonts/Manrope-Medium'), url(/static/media/Manrope-Medium.4a88d55f.ttf) format('woff');
}

@font-face {
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('fonts/Manrope-Regular'), url(/static/media/Manrope-Regular.82b079af.ttf) format('woff');
}

@font-face {
  font-family: 'Manrope-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('fonts/Manrope-SemiBold'), url(/static/media/Manrope-SemiBold.26ad6bf1.ttf) format('woff');
}

@font-face {
  font-family: 'Manrope-Bold';
  font-style: normal;
  font-weight: 600;
  src: local('fonts/Manrope-Bold'), url(/static/media/Manrope-Bold.d109d6f8.ttf) format('woff');
}

/*------------- BUTTON CSS COMPONENT ---------------*/

.btn-home {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 10px 14px 10px 14px;
  font-size: 0.8125em;
  border-radius: 8px;
   cursor: pointer;
  font-family: 'Manrope-Regular';
}

.btn1-home {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 19px 10px 19px 10px;
  font-size: 0.875em;
  border-radius: 11px;
  cursor: pointer;
  font-family: 'Manrope-Medium';
}

.btn2-home {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 23px 32px 23px 32px;
  font-size: 1.75em;
  border-radius: 11px;
   cursor: pointer;
  font-family: 'Manrope-SemiBold';
}

.btn-start {
  color: #fff;
  background: #009EFD 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.btn-start:hover {
  color: #fff;
  background-color: #26EDA1;
}

.btn-start:focus {
  outline: none;
}


.btn-start_big {
  color: #fff;
  background: #009EFD 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  transition: all .2s ease-in-out; 
}

.btn-start_big:hover {
  color: #fff;
  background-color: #26EDA1;
  transform: scale(1.3) !important;
  
}

.btn-start_big:focus {
  outline: none;
}


.btn-date {
  color: #01313C;
  background-color: #fff;
  border: 1px solid #A3BCD5;
  padding: 7px 24px 9px 8px;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  font-family: 'Segoe-Semibold';
}

.btn-date:hover {
  color: #01313C;
  background-color: #fff;
  border: 1px solid #A3BCD5;
}

.btn-date:focus {
  outline: none;
}

.btn-record {
  color: #01313C;
  background-color: #D1E8FF;
  border: 1px solid #A3BCD5;
  font-weight: 600;
  padding: 7px 24px 9px 23px;
  font-size: 16px;
  font-family: 'Segoe-Semibold';
}

.btn-record:hover {
  color: #01313C;
  background-color: #D1E8FF;
  border: 1px solid #A3BCD5;
}

.btn-record:focus {
  outline: none;
}

.preference-btn {
  color: #01313C;
  background-color: #D1E8FF;
  width: 100%;
  font-weight: 600;
  padding: 7px 24px 9px 18px;
  font-size: 12px;
  text-align: center;
  font-family: 'Segoe-Semibold';
}

.preference-btn:focus {
  outline: none;
}


/*------------- BUTTON CSS COMPONENT---------------*/


/*-------------------------------------------------------- Hints------------------------------------------------------------
mr= margin-right, ml= margin-left, mt = margin-top, mb= margin-bottom, pt= padding-top, pb= padding-bottom, pl= padding-left
pr=padding-right, fs= font-size, mw=min-width, fsi=font-style, pr=position-relative
-------------------------------------------------------- Hints------------------------------------------------------------*/


/*------------- COMMON CSS COMPONENT ---------------*/

.row {
  width: 100%;
}

.fl {
  float: left;
}

.card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
  transition: all .2s ease-in-out;
  padding: 24px 20px 23px 20px;
  border-radius: 4px;
  background-color: #FFFFFF;
  /*float: right;*/
}

.text-uppercase {
  text-transform: uppercase;
}

.f-color {
  color: #01313C;
}

.fs-semi-bold {
  font-weight: 600;
  font-family: 'Segoe-Semibold';
}

.fs-bold-italic {
  font-weight: 600;
  font-family: 'Segoe-bold-italic';
}

.text_italic {
  font-style: italic;
}

.fs-regular {
  font-weight: normal;
  font-family: 'Segoe-Regular';
}

.fs-light {
  font-weight: normal;
  font-family: 'Segoe-Light';
}

.fs-semi-light {
  font-weight: normal;
  font-family: 'Segoe-semi-Light';
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.pt_13 {
  padding-top: 13px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-25 {
  font-size: 25px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-35 {
  font-size: 35px;
}

.fs-44 {
  font-size: 44px;
}

.fsi {
  font-style: italic;
}

.w100 {
  width: 100%;
}

.fs-12 {
  font-size: 12px;
}

.mr_14 {
  margin-right: 14px;
}

.pr {
  position: relative;
}

.mt_17 {
  margin-top: 17px;
}

.mt_18 {
  margin-top: 18px;
}

.mt_19 {
  margin-top: 19px;
}

.mt_23 {
  margin-top: 23px;
}

.mt_22 {
  margin-top: 22px;
}

.text-center {
  text-align: center;
}

.mt_9 {
  margin-top: 9px;
}

.mt_10 {
  margin-top: 10px;
}

.green_text {
  color: #2AD156;
}

.mt_15 {
  margin-top: 15px;
}

.mt_41 {
  margin-top: 41px;
}

.pull-right {
  float: right;
}

.mt_6 {
  margin-top: 6px;
}

hr.new1 {
  border-top: 1px solid #A3BCD5;
}

.df {
  display: flex;
}

.ml_25 {
  margin-left: 25px;
}

.ml_57 {
  margin-left: 57px;
}

.ml_41 {
  margin-left: 41px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_21 {
  margin-left: 21px;
}

.pr_23 {
  padding-right: 23px;
}

.pr_18 {
  padding-right: 18px;
}

.pr_26 {
  padding-right: 26px;
}

.ml_12 {
  margin-left: 12px;
}

.ml_22 {
  margin-left: 22px;
}

.ml_25 {
  margin-left: 25px;
}

.mw {
  width: 50%;
}

.skill_video_sec_left {
  width: 37%;
}

.skill_video_sec_right {
  width: 63%;
}

.skill_video_width {
  width: 750px;
}

.mt_40 {
  margin-top: 40px;
}

.mw_36 {
  width: 36%;
}

.mw_50 {
  width: 50%;
}

.primary_background {
  background-color: #F5F9FC
}

.date_icon {
  vertical-align: middle;
  padding-right: 12px;
}

.profile_icon {
  vertical-align: middle;
}

.pt_6 {
  padding-top: 6px;
}

.pt_23 {
  padding-top: 23px;
}

.pl_8 {
  padding-left: 8px;
}

.pt-24 {
  padding-top: 24px;
}

.mb_3 {
  margin-bottom: 3px;
}

.w1200 {
  max-width: 1200px;
}

.main-left {
  max-width: 25%;
}



/*------------- COMMON CSS COMPONENT ---------------*/


/*------------- VIDEO INTERVIEW TOP ---------------*/

.playButton {
  width: 65px;
  height: 65px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.flex-video-top {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}


/*------------- VIDEO INTERVIEW TOP ---------------*/


/*--------------- NAV CSS -------------*/

.nav_bg_home_section {
  display: flex;
  justify-content: space-between;
}

.home-specific-header.nav_bg_home {
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 99;
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.34);
}

.home-specific-header .nav_bg_left {
  width: 25%;
  background-color: transparent;
}

.home-specific-header .nav_bg_right {
  background-color: transparent;
}

.w-100 {
  width: 100%
}

.nav_logo_home {
  margin-top: 23px;
  margin-bottom: 25px;
  width: 100%
}

.navbar_home {
  width: 100%;
  /*overflow: auto;*/
}

.navbar_home li {
  list-style-type: none;
  display: inline;
}

.navbar_home li a {
  padding: 10px 0px 0px 0px;
  color: #000000;
  text-decoration: none;
  font-size: 1.0625em;
  font-family: 'Manrope-Regular';
  margin-left: 20px;
}

.navbar_home .special_hover a:hover {
  padding: 5px 0px 5px 0px;

  
  border-bottom:2px solid #474747;
}


/*.navbar_home li a:hover {
  
  padding: 7px 24px 9px 23px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Segoe-Regular';
  background-color: #03A2F8;
  border-radius:8px;
}*/

.banner_text_section {
  position: absolute;
  bottom: 30%;
  width: 100%;
}
.f_bold {
  font-weight: bold;
}
.banner_text_section1 {
  position: absolute;
  top: 60%;
  width: 100%;
}

.banner_button {}

.banner_text_top {
  font-family: 'Manrope-Bold';
  font-size: 3.25em;
  color: #EFEFEF;
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .banner_bottom_section-2 {
  width: 100%;
  position: absolute;
  top: 63%;
}
.banner_text_top {
  font-family: 'Manrope-Bold';
  font-size: 3.5em;
  color: #EFEFEF;
}

.banner_text_bottom {
  font-family: 'Manrope-Regular';
  font-size: 2.5625em;
  color: #fff;
}
.banner_text_section {
  position: absolute;
  top: 45%;
  width: 100%;
}


}
.banner_text_top_other_color {
  color: #009EFD
}

.banner_text_bottom {
  font-family: 'Manrope-Regular';
  font-size: 1.75em;
  color: #fff;
}

.banner_section {
  width: 100%;
  position: relative;
  overflow: hidden;
  /* top:60px; */

}
/* .banner_section-empoyer {
  background-image: url('../images/Banner-2.png');
  width: 100%;
  height: 100%;
  position: absolute;
  z-index:9;
} */

.banner_bottom_section {
  width: 100%;
  background-color: #fff;
}

.banner_bottom_section-1 {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
  position: absolute;
  top: 40%;
}

.banner_bottom_section-2 {
  width: 100%;
  position: absolute;
  top: 63%;
}

.start_button_section {
  width: 100%;
  position: absolute;
  top: 60%;
}

.banner_bottom_section_employers_left {
  font-size: 1.5625em;
  color: #474747;
  padding: 20px 0px 0px 0px;
  font-family: 'Manrope-SemiBold';
  text-align: left;
  /* text-shadow: 0px 3px 6px #00000029; */
}


.banner_bottom_section_employers_right {
  font-size: 1.5625em;
  color: #474747;
  padding: 20px 0px 20px 0px;
  font-family: 'Manrope-SemiBold';
  float: right;
  text-align: right;
  /* text-shadow: 0px 3px 6px #00000029; */
}

.banner_bottom_section_employers_right_other_color {
 
  color: #009EFD;
 
  font-family: 'Manrope-Bold';
 
}

.banner_bottom_section_employers-1 {
  font-size: 1.875em;
  color: #474747;
  padding: 50px 0px 50px 0px;
  width:80%;
  font-family: 'Manrope-Medium';
  /* text-shadow: 0px 3px 6px #00000029; */
}

.banner_bottom_section_employers-1-other_color{
  color: #009EFD;
  font-family: 'Manrope-Bold';
  /* text-shadow: 0px 3px 6px #00000029; */
}

.banner_bottom_section_register {
  font-size: 1.3125em;
  color: #474747;
  padding: 50px 0px 50px 0px;
  width: 78%;
  font-family: 'Manrope-Medium';
  /* text-shadow: 0px 3px 6px #00000029; */
}

.banner_bottom_section_register_other_color {
  
  color: #009efd;
 
  font-family: 'Manrope-Bold';
  
}

.banner_bottom_section_text_left {
  font-size: 1.75em;
  color: #474747;
  padding: 35px 0px 70px 0px;
  font-family: 'Manrope-Medium';
  /* text-shadow: 0px 3px 5px #00000029; */
}

.employer_banner_bottom_section_text_left {
  font-size: 1.875em;
  color: #474747;
  padding: 61px 0px 0px 0px;
  font-family: 'Manrope-Bold';
  /* text-shadow: 0px 3px 5px #00000029; */

}

.employer_banner_bottom_section_text_left_bottom {
  font-size: 1.5625em;
  color: #474747;
  padding: 0px 0px 60px 0px;
  font-family: 'Segoe-Regular';
  /* text-shadow: 0px 3px 5px #00000029; */
}

.employer_banner_bottom_section_text_left_bottom_top {
  font-size: 1.8625em;
  color: #474747;
  padding: 50px 0px 50px 0px;
  font-family: 'Manrope-Medium';
  /* text-shadow: 0px 3px 5px #00000029; */
  line-height: 2.1;
}

.banner_left_text_section_employers {
  width: 100%;
}

.banner_left_text_section {
  width: 50%;
}


.banner_bottom_section_text_right {
  color: #474747;
  font-size: 1.875em;
  padding: 117px 0px 46px 0px;
  font-family: 'Manrope-SemiBold';
}

.banner_bottom_section_text_right_other_color {
  color: #009EFD;
  
  font-family: 'Manrope-Bold';
}

.banner_bottom_section_text_other_color {
  color: #009EFD;
  font-family: 'Manrope-SemiBold';
}

.banner_bottom_section_wrapper {
  display: flex;
  justify-content: space-between;
}

.home_step_section {
  width: 100%;
  position: absolute;
  top: 5%;
}

/* .full_body {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
} */

.proile-fwd-icon {
  vertical-align: super;
}

.profile-text {
  display: inline-block;
  width: 82px;
  margin-left: 5px;
}

.profile_card {
  position: relative;
}

/* .profile_top {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border-bottom: 1px solid #A3BCD5;
  padding: 8px 0px 8px 0px;
} */

.parallax {
  /* The image used */
  background-image: url("/static/media/Mask Group 2-768.1e453dca.png");
  /* Set a specific height */
  min-height: 1200px;
  background-attachment: fixed;
  background-position: 10% 66%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.parallax_employers {
  /* The image used */
  background-image: url("/static/media/Mask Group 22.6b566f30.jpg");
  /* Set a specific height */
  min-height: 1200px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: 10% -60%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.paralox_inner {
  position: absolute;
  top: 5%;
  left: 20px;
  height: 300px;
  border: 1px solid;
}


/*--------------- FOOTER CSS -------------*/

.footer_bg_employerpage {
  width: 100%;
  background-color: #C2C2C2;
  position: absolute;
  top: 80%
}

.footer_bg_candidates {
  width: 100%;
  background-color: #C2C2C2;
  position: absolute;
  top: 77%
}

.footer_adjust {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-top: 25px;
}

.footer_bg_sec {
  width: 25%;
}

.footer_bg_candidates .footer_links {
  margin-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
}
.footer_bg_candidates .f_height {
  height: initial;
}

.footer_bg_candidates .footer_links li {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px;
}

.footer_bg_candidates .footer_links li:last-child {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px 0px 0px;
}

.footer_bg_candidates .footer_links li a {
  color: #000;
  text-decoration: none;
  font-size: 1.0625em;
  font-family: 'Manrope-Medium';
}

.footer_bg_candidates .footer_links li a:hover::after {
  opacity: 1;
}

.footer-text-color {
  color: #D1E8FF;
}

.footer-copyright {
  opacity: .4;
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
}

.events li {
  display: flex;
  color: #000000;
  height:100px;
  font-size: 1.4375em;
  font-family: 'Manrope-SemiBold';
}

.events time {
  position: relative;
  padding: 0 .5em;
}

.events time::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  border-radius: 50%;
  background: #009EFD;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #009EFD;
  opacity: 1;
  width: 33px;
  height: 32px;
}

.events span {
  padding: 0px 34px;
  position: relative;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left:-1px;
  height: 100%;
  border-left: 3px #009EFD solid;
  top: 32px;
}

.last_border_none::before {
  border-left: none !important;
}

.events strong {
  display: block;
  font-weight: bolder;
}

.events {
  /*margin: 1em;*/
  padding-left: 0px;
  width: 100%;
}

.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  font-family: arial;
}

@keyframes zoominoutsinglefeatured {
  0% {
      transform: scale(1, 1);
      /* animation-duration: 200ms; */
  }
  50% {
      transform: scale(1.2, 1.2);
      /* animation-duration: 200ms; */
  }
  100% {
      transform: scale(1.2, 1.2);
      /* animation-duration: 200ms; */
  }
}

.cardcontainer img {
  animation: zoominoutsinglefeatured 20s;
}

.request_demo_sec {
  
  width: 100%
}

.request_button{
  width: 30%;
  display: flex;
  
  flex-direction: row;
  align-items: center;
}
/* .carousel-indicators [data-bs-target] {
  width: 11px;
  height: 9px;
  background: #fff 0% 0% no-repeat padding-box;

  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 50%;
} */
/* .carousel-indicators .active {
  background: #008FE4 0% 0% no-repeat padding-box;
}
.carousel-indicators {
  top: 200%;
} */
/* .btn1.btn-start_big.aos-init.aos-animate:hover {
  transform: scale(1.3) !important;
} */

.home-specific-header .nav_bg_right {
  display: flex;
  align-items: center;
}
.navbar_home {
  margin: 0;
}

.buller-wrapper {
  display: flex;
}
.circle-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bullet-circle {
  background: #009efd;
  width: 33px;
  height: 33px;
  border-radius: 50%;
}
.bullet-line {
  width: 3px;
  background: #009efd;
  height: 66px;
  /* transform-origin: 100% 0%; */
}
[data-aos="reveal-top"] {
  transform: scaleY(0);
  transform-origin: 100% 0%;
  transition-property: transform;
  transition-delay: 1500ms;
}
[data-aos="reveal-top"].aos-animate {
  transform: scaleY(1);
}

.bullet-text {
  font-size: 1.4375em;
  font-family: 'Manrope-SemiBold';
  color: #000;
  margin-left: 20px;
}
.buller-wrapper:last-child .bullet-line{
  display: none;
}
.request-demo {
 font-size: 1.7em;
 padding: 25px 20px;
}
/* .bullet-line {
  animation: mymove 5s;
} */
/* @keyframes mymove {
  from {height: 0px;}
  to {height: 66px;}
}
 */

 /* .cardcontainer img {
  animation: zoominoutsinglefeatured 5s infinite;
} */
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  transform: scale(1.3);
  transition: all .2s ease-in-out;
}

@media (min-width: 1200px) {
    /* .container {
      max-width: 90% !important;
    } */
}
/* .carousel-caption {
  position: initial;
  left: 0;
  bottom: 0;
  padding: 0;
  text-align: left;
} */
.carousel-indicators li {
 background: #fff;
  width: 10px;
  height: 10px;
  margin: 0rem 0.5rem;
  cursor: pointer;
  border-radius: 50%;
}
.carousel-indicators li.active {
  background: #009efd;
 }
 .carousel-control-prev, .carousel-control-next {
   display: none;
 }
@media(min-width: 1200px) {
  .carousel-indicators {
    top: 10em;
  }
}
.text_box_calender.homepage-text_box_calendar {
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}

.container-padding-left {
  padding-left: 17px;
}



[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
	transition-duration: 50ms
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
	transition-delay: 50ms
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
	transition-duration: .1s
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
	transition-delay: .1s
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
	transition-duration: .15s
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
	transition-delay: .15s
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
	transition-duration: .2s
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
	transition-delay: .2s
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
	transition-duration: .25s
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
	transition-delay: .25s
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
	transition-duration: .3s
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
	transition-delay: .3s
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
	transition-duration: .35s
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
	transition-delay: .35s
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
	transition-duration: .4s
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
	transition-delay: .4s
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
	transition-duration: .45s
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
	transition-delay: .45s
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
	transition-duration: .5s
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
	transition-delay: .5s
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
	transition-duration: .55s
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
	transition-delay: .55s
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
	transition-duration: .6s
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
	transition-delay: .6s
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
	transition-duration: .65s
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
	transition-delay: .65s
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
	transition-duration: .7s
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
	transition-delay: .7s
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
	transition-duration: .75s
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
	transition-delay: .75s
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
	transition-duration: .8s
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
	transition-delay: .8s
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
	transition-duration: .85s
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
	transition-delay: .85s
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
	transition-duration: .9s
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
	transition-delay: .9s
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
	transition-duration: .95s
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
	transition-delay: .95s
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
	transition-duration: 1s
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
	transition-delay: 1s
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
	transition-duration: 1.05s
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
	transition-delay: 1.05s
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
	transition-duration: 1.1s
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
	transition-delay: 1.1s
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
	transition-duration: 1.15s
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
	transition-delay: 1.15s
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
	transition-duration: 1.2s
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
	transition-delay: 1.2s
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
	transition-duration: 1.25s
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
	transition-delay: 1.25s
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
	transition-duration: 1.3s
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
	transition-delay: 1.3s
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
	transition-duration: 1.35s
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
	transition-delay: 1.35s
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
	transition-duration: 1.4s
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
	transition-delay: 1.4s
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
	transition-duration: 1.45s
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
	transition-delay: 1.45s
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
	transition-duration: 1.5s
}



[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
	transition-delay: 1.5s
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
	transition-duration: 1.55s
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
	transition-delay: 1.55s
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
	transition-duration: 1.6s
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
	transition-delay: 1.6s
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
	transition-duration: 1.65s
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
	transition-delay: 1.65s
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
	transition-duration: 1.7s
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
	transition-delay: 1.7s
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
	transition-duration: 1.75s
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
	transition-delay: 1.75s
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
	transition-duration: 1.8s
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
	transition-delay: 1.8s
}
[data-aos][data-aos][data-aos-delay="5000"].aos-animate,
body[data-aos-delay="5000"] [data-aos].aos-animate {
	transition-delay: 5s
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
	transition-duration: 1.85s
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
	transition-delay: 1.85s
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
	transition-duration: 1.9s
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
	transition-delay: 1.9s
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
	transition-duration: 1.95s
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
	transition-delay: 1.95s
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
	transition-duration: 2s
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
	transition-delay: 2s
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
	transition-duration: 2.05s
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
	transition-delay: 2.05s
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
	transition-duration: 2.1s
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
	transition-delay: 2.1s
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
	transition-duration: 2.15s
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
	transition-delay: 2.15s
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
	transition-duration: 2.2s
}

/* [data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
	transition-delay: 0
} */

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
	transition-delay: 2.2s
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
	transition-duration: 2.25s
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
	transition-delay: 2.25s
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
	transition-duration: 2.3s
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
	transition-delay: 2.3s
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
	transition-duration: 2.35s
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
	transition-delay: 2.35s
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
	transition-duration: 2.4s
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
	transition-delay: 2.4s
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
	transition-duration: 2.45s
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
	transition-delay: 2.45s
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
	transition-duration: 2.5s
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
	transition-delay: 2.5s
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
	transition-duration: 2.55s
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
	transition-delay: 2.55s
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
	transition-duration: 2.6s
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
	transition-delay: 2.6s
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
	transition-duration: 2.65s
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
	transition-delay: 2.65s
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
	transition-duration: 2.7s
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
	transition-delay: 2.7s
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
	transition-duration: 2.75s
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
	transition-delay: 2.75s
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
	transition-duration: 2.8s
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
	transition-delay: 2.8s
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
	transition-duration: 2.85s
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
	transition-delay: 2.85s
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
	transition-duration: 2.9s
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
	transition-delay: 2.9s
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
	transition-duration: 2.95s
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
	transition-delay: 2.95s
}
[data-aos][data-aos][data-aos-delay="3200"].aos-animate,
body[data-aos-delay="3200"] [data-aos].aos-animate {
	transition-delay: 3.2s
}
[data-aos][data-aos][data-aos-delay="4200"].aos-animate,
body[data-aos-delay="4200"] [data-aos].aos-animate {
	transition-delay: 4.2s
}
[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
	transition-delay: 2.2s
}
[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
	transition-duration: 3s
}
[data-aos][data-aos][data-aos-duration="3200"],
body[data-aos-duration="3200"] [data-aos] {
	transition-duration: 3.2s
}
[data-aos][data-aos][data-aos-duration="4200"],
body[data-aos-duration="4200"] [data-aos] {
	transition-duration: 4.2s
}
/* [data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
	transition-duration: 4.2s
} */

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
	transition-delay: 0
}
[data-aos][data-aos][data-aos-delay="3200"],
body[data-aos-delay="3200"] [data-aos] {
	transition-delay: 0
}
[data-aos][data-aos][data-aos-delay="4200"],
body[data-aos-delay="4200"] [data-aos] {
	transition-delay: 0
}
[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
	transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
	transition-delay: 3s
}

[data-aos][data-aos][data-aos-delay="3500"].aos-animate,
body[data-aos-delay="3500"] [data-aos].aos-animate {
	transition-delay: 3.5s
}

[data-aos][data-aos][data-aos-delay="4000"].aos-animate,
body[data-aos-delay="4000"] [data-aos].aos-animate {
	transition-delay: 4s
}

[data-aos][data-aos][data-aos-delay="4500"].aos-animate,
body[data-aos-delay="4500"] [data-aos].aos-animate {
	transition-delay: 4.5s
}

[data-aos][data-aos][data-aos-delay="5500"].aos-animate,
body[data-aos-delay="5500"] [data-aos].aos-animate {
	transition-delay: 5.5s
}

[data-aos][data-aos][data-aos-delay="6000"].aos-animate,
body[data-aos-delay="6000"] [data-aos].aos-animate {
	transition-delay: 6s
}

[data-aos][data-aos][data-aos-delay="6500"].aos-animate,
body[data-aos-delay="6500"] [data-aos].aos-animate {
	transition-delay: 6.5s
}

[data-aos][data-aos][data-aos-delay="7500"].aos-animate,
body[data-aos-delay="7500"] [data-aos].aos-animate {
	transition-delay: 7.5s
}



[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
	transition-timing-function: cubic-bezier(.25, .25, .75, .75)
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
	transition-timing-function: ease
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
	transition-timing-function: ease-in
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
	transition-timing-function: ease-out
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
	transition-timing-function: ease-in-out
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
	transition-timing-function: cubic-bezier(.6, -.28, .735, .045)
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
	transition-timing-function: cubic-bezier(.175, .885, .32, 1.275)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
	transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
	transition-timing-function: cubic-bezier(.47, 0, .745, .715)
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
	transition-timing-function: cubic-bezier(.39, .575, .565, 1)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
	transition-timing-function: cubic-bezier(.445, .05, .55, .95)
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
	transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
	transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
	transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
	transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
	transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
	transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
	transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
	transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
	transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos^=fade][data-aos^=fade] {
	opacity: 1;
	transition-property: opacity, transform
}

[data-aos^=fade][data-aos^=fade].aos-animate {
	 opacity: 1 !important; 
	transform: translateZ(0)
}


[data-aos=fade-up] {
	transform: translate3d(0, 100px, 0)
}

[data-aos=fade-down] {
	transform: translate3d(0, -100px, 0)
}

[data-aos=fade-right] {
	transform: translate3d(-100px, 0, 0)
}

[data-aos=fade-left] {
	transform: translate3d(100px, 0, 0)
}

[data-aos=fade-up-right] {
	transform: translate3d(-100px, 100px, 0)
}

[data-aos=fade-up-left] {
	transform: translate3d(100px, 100px, 0)
}

[data-aos=fade-down-right] {
	transform: translate3d(-100px, -100px, 0)
}

[data-aos=fade-down-left] {
	transform: translate3d(100px, -100px, 0)
}

[data-aos^=zoom][data-aos^=zoom] {
	opacity: 0;
	transition-property: opacity, transform
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
	opacity: 1;
	transform: translateZ(0) scale(1)
}

[data-aos=zoom-in] {
	transform: scale(.6)
}

[data-aos=zoom-in-up] {
	transform: translate3d(0, 100px, 0) scale(.6)
}

[data-aos=zoom-in-down] {
	transform: translate3d(0, -100px, 0) scale(.6)
}

[data-aos=zoom-in-right] {
	transform: translate3d(-100px, 0, 0) scale(.6)
}

[data-aos=zoom-in-left] {
	transform: translate3d(100px, 0, 0) scale(.6)
}

[data-aos=zoom-out] {
	transform: scale(1.2)
}

[data-aos=zoom-out-up] {
	transform: translate3d(0, 100px, 0) scale(1.2)
}

[data-aos=zoom-out-down] {
	transform: translate3d(0, -100px, 0) scale(1.2)
}

[data-aos=zoom-out-right] {
	transform: translate3d(-100px, 0, 0) scale(1.2)
}

[data-aos=zoom-out-left] {
	transform: translate3d(100px, 0, 0) scale(1.2)
}

[data-aos^=slide][data-aos^=slide] {
	transition-property: transform
}

[data-aos^=slide][data-aos^=slide].aos-animate {
	transform: translateZ(0)
}

[data-aos=slide-up] {
	transform: translate3d(0, 100%, 0)
}

[data-aos=slide-down] {
	transform: translate3d(0, -100%, 0)
}

[data-aos=slide-right] {
	transform: translate3d(-100%, 0, 0)
}

[data-aos=slide-left] {
	transform: translate3d(100%, 0, 0)
}

[data-aos^=flip][data-aos^=flip] {
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transition-property: transform
}

[data-aos=flip-left] {
	transform: perspective(2500px) rotateY(-100deg)
}

[data-aos=flip-left].aos-animate {
	transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-right] {
	transform: perspective(2500px) rotateY(100deg)
}

[data-aos=flip-right].aos-animate {
	transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-up] {
	transform: perspective(2500px) rotateX(-100deg)
}

[data-aos=flip-up].aos-animate {
	transform: perspective(2500px) rotateX(0)
}

[data-aos=flip-down] {
	transform: perspective(2500px) rotateX(100deg)
}

[data-aos=flip-down].aos-animate {
	transform: perspective(2500px) rotateX(0)
}


@media only screen and (max-width: 768px) {
  .footer_bg_candidates .footer_links {
    display: initial;
  }
  .home-contact-us .MuiDialog-container {
    height: 75%;
    overflow: hidden;
    position: relative;
    top:6em;
  }
  .homepage-requestdemo .MuiDialog-container {
    height: 75%;
    overflow: hidden;
    position: relative;
    top:6em;
  }
  .homepage-requestdemo .MuiDialog-paperFullWidth {
    width: calc(100% - 10px);
    margin: 10px;
    }

  .home-contact-us .MuiDialog-paperFullWidth {
    width: calc(100% - 37px);
    margin: 20px;
  }
  .dropdown_width {
    width: 100% !important;
  }
  .dropdown_width_outer {
  display: inline !important;
  }
  .dropdown_width_outer .dropdown_width:last-child {
    margin-top: 1rem;
  }
  .responsive-margin {
    margin-top: 1rem;
  }
  .banner_bottom_section.banner_bottom-responsive-employer {
    margin-top: 50px;
  }
  .banner-section-image-responsive img {
    height: 100%;
    transform: translate(-11%, 0%);
    width: auto !important;
  }
  .banner-section-image-responsive {
    width: auto !important;
    height: 100vh;
  }
  .responsive-home-myVideo {
    transform: translate(-27%, -20%);
    height: 100%;
    width: auto !important;
  }
  .carousel-indicators {
    top: 9em;
  }
	.nav_logo_home {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 70%
}
	.nav_bg_home {
		display: none;
	}

	.nav_bg_home_section {
		display: block;
		justify-content: space-between;
	}

	.home-specific-header .nav_bg_left {
		width: 100%;
	}

	.navbar_home li a {
		padding: 10px 0px 0px 0px;
		color: #000000;
		text-decoration: none;
		font-size: 1.0625em;
		font-family: 'Manrope-Regular';
		margin-left: 6px;
	}

	ol, ul {
		padding-left: 0rem;
	}

	.btn-home {
		display: inline-block;
		font-weight: normal;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		border: 1px solid transparent;
		padding: 10px 8px;
		font-size: 1em;
		border-radius: 8px;
		cursor: pointer;
		font-family: 'Manrope-Regular';
	}

	.banner_section {
		width: 100%;
		position: relative;
		overflow: hidden;
		top: 70px;
	}

	.banner_text_top {
		font-family: 'Manrope-Bold';
		font-size: 1.66em;
		color: #EFEFEF;
	}

	.banner_text_bottom {
		font-family: 'Manrope-Regular';
		font-size: 1.2em;
		color: #fff;
	}

	.banner_text_section {
		position: absolute;
		top: 30em;
		width: 100%;
	}

	.banner_bottom_section_wrapper {
		display: block;
		justify-content: space-between;
	}
	.banner_left_text_section {
		width: 100%;
	}

	.banner_bottom_section {
		width: 100%;
		background-color: #fff;
		box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		margin-top: -150px;
	}

	.banner_bottom_section_text_left {
		font-size: 1.75em;
		color: #474747;
		padding: 35px 0px 10px 0px;
		font-family: 'Manrope-Medium';
	}

	.banner_bottom_section_text_right {
		color: #474747;
		font-size: 1.875em;
		padding:10px 0px 46px 0px;
		font-family: 'Manrope-SemiBold';
	}

	.bullet-line {
		width: 3px;
		background: #009efd;
		/* height: 200px; */
	}
	.bullet-line.hr-first-l {
		height: 105px;
	}
	.bullet-line.hr-second-l {
		height:120px;
	}
	.bullet-line.hr-third-l {
		height:105px;
	}
	.bullet-line.er-first-l {
		height: 130px;
	}
	.bullet-line.er-second-l {
		height: 70px;
	}
	.bullet-line.er-third-l {
		height: 200px;
	}
	.banner_bottom_section-1 {
		width: 100%;
		background-color: #fff;
		box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		position: absolute;
		top: 56%;
	}

	.parallax_employers .banner_bottom_section-1-employers {
		width: 100%;
		background-color: #fff;
		box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
		position: absolute;
		top: 52%;
	}

	.start_button_section {
		width: 100%;
		position: absolute;
		top: 70%;
	}
	.banner_bottom_section-2 {
		width: 100%;
		position: absolute;
		top: 78%;
	}
	.footer_bg_employerpage {
		width: 100%;
		background-color: #C2C2C2;
		position: absolute;
		top: 84% !important;
	}

	.banner_bottom_section_employers_right {
		font-size: 1.5625em;
		color: #474747;
		padding: 20px 0px 20px 0px;
		width: 100%;
		font-family: 'Manrope-SemiBold';
		float: right;
		text-align: right;
	}

	.request-demo {
		font-size: 1em;
		padding: 15px 20px;
	}
	.banner_bottom_section_employers_left {
		font-size: 1.5625em;
		color: #474747;
		padding: 20px 0px 0px 0px;
		width: 100%;
		font-family: 'Manrope-SemiBold';
		text-align: left;
	}
	
	.footer_bg_candidates {
		width: 100%;
		background-color: #C2C2C2;
		position: absolute;
		top: 77%;
	}
	.btn1-home {
		display: inline-block;
		font-weight: normal;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		border: 1px solid transparent;
		padding: 10px 10px 10px 10px;
		font-size: 0.875em;
		border-radius: 11px;
		cursor: pointer;
		font-family: 'Manrope-Medium';
	}
	.banner_bottom_section_register {
		font-size: 1.3125em;
		color: #474747;
		padding: 10px 0px 10px 0px;
		width: 100%;
		font-family: 'Manrope-Medium';
	}

	.footer_left_logo {
		width: 100% !important;
	}
	.footer_adjust {
		display: block;
		justify-content: space-between;
		padding-bottom: 70px;
		padding-top: 25px;
	}

	.parallax_employers {
		/* The image used */
		background-image: url("/static/media/Mask Group 22.6b566f30.jpg");
		/* Set a specific height */
		min-height: 1600px;
		/* Create the parallax scrolling effect */
		background-attachment: fixed;
		background-position: 51% 45%;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.parallax {
		/* The image used */
		background-image: url("/static/media/Mask Group 2-mobile.1a61ac46.png");
		/* Set a specific height */
		min-height: 1200px;
		/* Create the parallax scrolling effect */
		background-attachment: fixed;
		background-position: 86% 53%;
		background-repeat: no-repeat;
		
		background-size: cover;
	   
		position: relative;
	
	}
	.employer_banner_bottom_section_text_left_bottom_top {
		font-size: 1.8625em;
		color: #474747;
		padding: 50px 0px 50px 0px;
		font-family: 'Manrope-Medium';
		line-height: normal;
	}

}

@media (min-width: 1367px) and (max-width: 1920px){

  .default_video {
    width: 437px !important;
  }
  .test_landing_main .form-one-left_recruiter {
    background: #fff;
    padding: 0px 26% !important;
}

.form-one-left-section {
  margin-top: 0px !important;
}

.test_landing_main .test_left_adjust {
  margin-top: 10% !important;
}
  .parallax {
    background-image: url("/static/media/Mask Group 2.247b7ecc.png");
    min-height: 1200px;
    background-attachment: fixed;
    background-position: 10% 58%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


}

.bullet_text_break {
  margin-top:-1.25%;
}
.footer_left_logo {
  width: 25%;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  transform: scale(1.3);
  transition: all .2s ease-in-out;
}
#myBtn.show-button{
  display: block;
}

.home-top-nav {
	overflow: hidden;
	position: fixed;
	width: 100%;
	z-index: 9999;
	background: #fff;
	box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 26%);
}

.home-top-nav #myLinks {
  display: none;
}

.home-top-nav a {
  color: #009efd;
	padding: 22px 16px;
  text-decoration: none;
  font-size: 25px;
  display: block;
}

.home-top-nav a.icon {
 
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
#myLinks {
	background:#fff;
}
.navbar_home-responsive {
	padding: 14px 10px;
  margin-top: 0;
}
.navbar_home-responsive a {
	color: #000;
	font-size: 1.1em;
	padding: 7px 10px;
  font-family: 'Manrope-Medium';
}
@media (min-width: 769px) {
	.nav_bg_home_responsive {
		display: none;
	}
}
.banner_bottom_section-1-employers {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
  position: absolute;
  top: 40%;

}
#myVideo.responsive-home-myVideo {
 width: 100%;
}
.banner-section-image-responsive img {
  width: 100%;
}
.footer_bg_candidates .footer_links li:last-child {
  padding-left: 3px;
}
.carousel {
  position: relative;
}

@media (min-width: 1200px) {
.carousel-indicators {
    top: 10em;
}
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
}
/* .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
} */
.carousel-caption {
  position: static;
  left: 0;
  bottom: 0;
  padding: 0;
  text-align: left;
  right: 15%;
  color: #fff;
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -4em;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

@media (min-width: 1367px) {
  .w1200 {
    max-width: 90%;
  }
  .bullet-text.bullet_text_break {
    margin-top:0%;
}
}
.homepage-requestdemo legend {
  font-family: 'Manrope-Bold' !important;
  font-size: 16px;
  margin-bottom: 17px;
  font-style: normal;
  font-weight: 600;
  color: #01313c;
}
.homepage-requestdemo .MuiTypography-h6 {
  font-family: 'Manrope-Light' !important;
}

.homepage-requestdemo .text_box_cleartalent {
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}
.homepage-requestdemo .btn-big {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 9px 35px 11px;
  font-size: 18px;
  border-radius: 4px;
  font-family:'Manrope-Bold';
}

.footer_bg_candidates .footer_links li:last-child a {
  text-decoration: underline;
  color: #1a8fff;
}

li.active-link {
  font-weight: bold;
}

@media (min-width: 768px) {
  .homepage-footer-left-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .homepage-footer-left-logo img {
    margin-top: 0px;
  }
  .footer_adjust.footer-adjust-home {
    padding-top: 46px;
    padding-bottom: 46px;
  }
 
}
.home-footer-terms {
  font-size: 1.0625em;
  font-family: 'Manrope-Medium';
  color: #2896ff;
  text-decoration: underline;
}
.home-footer-terms-concat {
  font-size: 1.0625em;
  font-family: 'Manrope-Medium';
  color: #2896ff;
}


.test_landing_header {
  text-align: center;
  background-color: white;
  padding: 102px 0px 15px;

}

.test_landing_header1 {
  text-align: center;
  background-color: white;
  padding: 52px 0px 15px;
}

.test_landing_header_text_1 {
  font-size: 25px;
  font-family: 'Manrope-Bold';
  color: #04093F;
}
.test_landing_header_text_2 {
  font-size: 22px;
  font-family: 'Manrope-Bold';
  color: #04093F;
  padding-top: 22px;
}
.test_landing_header_text_21 {
  font-size: 22px;
  font-family: 'Manrope-Bold';
  color: #04093F;
  padding-top: 10px;
}

.test_landing_header_text_3 {
  font-size: 14px;
  font-family: 'Manrope-Regular';
  color: #01313C;
  padding-top: 5px;
}
.test_landing_header_text_3_3 {
  color: #1A8FFF;
}

.test_dres_right_header {
  text-align: center;
  padding-bottom: 25px;
}
.test_dres_right_bottom {
  text-align: center;
  padding: 25px 0px;
  display: flex;
  justify-content: center;
}

.test_dres_right_bottom1 {
  text-align: center;
  padding: 0px 0px 25px;
}
.test_dres_right_header_text {
  font-size: 35px;
  font-family: 'Manrope-Bold';
  color: #04093F;
  
}

.cleartalent_sign_top_errormsg {
  margin-top: 120px;
    display: flex;
    justify-content: center;
}

.cleartalent_sign_top_errormsg_text {
font-family: 'Manrope-Regular';
    font-size: 16px;
    color: #ff0000;
    font-weight: 600;
    font-style: italic;
    padding-bottom: 10px;
  }

.test_dres_right_bottom_text {
  font-size: 14px;
  font-family: 'Manrope-Bold';
  color: #01313C;
  
}

.test_dres_right_bottom_text_1 {
  font-size: 14px;
  font-family: 'Manrope-Bold';
  color: #01313C;
  text-decoration: underline;
  width: auto;
  cursor:pointer;
}

.test_dres_right_bottom_text_2 {
  font-size: 14px;
  font-family: 'Manrope-Bold';
  color: #01313C;
  
  
}


.test_dres_right_header_text_1 {
  font-size: 17px !important;
  font-family: 'Manrope-Regular';
  color: #707070;
  padding-top: 24px;
  
}

.test_dres_right_header_text_1_1 {
  
  color: #2699FB;
 
  
}
.test_dres_right_header_text_1:first-child {
  padding-top: 0px;
}

.form_text_container_other .test_screen {
  padding: 0px 0% 0% 5%;
}


.btn-testlanding {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 15px 120px 16px 120px;
    font-size: 20px;
  border-radius: 8px;
   cursor: pointer;
  font-family: 'Manrope-Regular';
}


.test_landing_button_wrapper {
  display: flex;
  justify-content: center;
}
.btn-test-landing {
  color: #fff;
  background: #009EFD 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.btn-test-landing:hover {
  color: #fff;
  background-color: #26EDA1;
}

.btn-test-landing:focus {
  outline: none;
}
.test_landing {
  /* height: 272px !important; */
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}
.test_landing_left_bg {
  background-color: white;
  
}

.test_landing_video_bottom_section {
  height: 33px;
  background-color: #D1E8FF;
  border-radius: 0px 0px 8px 8px;
  display: flex;
    justify-content: center;
    align-items: center;
}

.test_landing_video_bottom_icon {
  justify-content: center;
  width: 20px;
  height: 20px;
  /* border: 1px solid red; */
  margin: 7px;
  display: flex;
  align-items: center;

}


.bubble_icon_section {
  position:absolute;
}

.test_landing_video_bottom_icon_adjust {
  width: 23px;
  cursor: pointer;
}



.test_recorder_bottom_text_section {
  text-align: center;
}
.test_recorder_bottom_text_section_text {
  font-size: 14px;
  font-family: 'Manrope-Regular';
  color: #01313C;
  padding-top: 26px;
}

.test_landing_main  .test_left_adjust {
  margin-top: 19%;
}
.default_video {
  width: 425px;
}




/* #root {
  
  background: white;
} */
.test_landing_main {
  margin-bottom: 20px;
  
}

.test_landing_main .form-one-left_recruiter {
  background: #fff;
  padding: 0px 17%;
}

.pid {
  /* height: 10%; */
    background: #2699FB;
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    border-radius: 30px;
    
}
.bar {
  width: 30px;
    background: #e4e4e4;
    margin-left: 10px;
    border-radius: 30px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.social_links{
  display: flex;
  justify-content: flex-end;
}
.iconColor{
  color: #000;
  margin-left: 5px;
}
@media (max-width: 768px){
  .social_links{
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}



@font-face {
  font-family: "Manrope-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: local("fonts/Manrope-SemiBold"),
    url(/static/media/Manrope-SemiBold.26ad6bf1.ttf) format("woff");
}

@font-face {
  font-family: "Manrope-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Manrope-Medium"),
    url(/static/media/Manrope-Medium.4a88d55f.ttf) format("woff");
}

@font-face {
  font-family: "Manrope-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Manrope-Regular"),
    url(/static/media/Manrope-Regular.82b079af.ttf) format("woff");
}

.mb_8{
  margin-bottom: 8px;
}

.mainDiv {
  min-height: 100vh;
  margin: 0px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.orText{
  color: #1a8fff;
}

.mandatoryText{
  text-align: center;
  margin-bottom: 16px;
  font-family: "Manrope-Regular";
  font-size: 2ch;
}

.mainHeader {
  min-height: 50px;
}

.mainFooter {
  min-height: 50px;
}

.relativeBody {
  position: relative;
}

.banner_section_referral {
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 85px;
  background-color: white;
  padding: 35px 0px;
  margin-bottom: 86px;
  padding-bottom: 0px;
}

.why_refer_text {
  margin-bottom: 20px;
  font-size: 21px;
  display: flex;
  font-family: "Manrope-SemiBold";
}

.smallTickStyle{
  margin-right: 10px;
  color: #1a8fff;
}

.blueReferralText{
  color: black;
}

.emailInputTextPadding {
  padding-left: 0px !important
}

.tickStyle{
  height: 3vh;
}

.text_body {
  margin-left: 45px;
}

.file_button {
  padding: 0px !important;
}

.text_body_heading {
  font-family: "Manrope-SemiBold";
  font-size: 20px;
  margin-bottom: 10px;
}

.why_refer_body_desc {
  font-family: "Manrope-Medium";
  font-size: 17px;
}

.bubble_blue {
  position: absolute;
  margin-left: -65px;
  margin-top: 33px;
}

.rightSide_border {
  border-right: 2px solid rgb(216, 210, 210);
}

.style {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 40px;
}

.companies {
  text-align: center;
  font-size: 24px;
  font-family: "Manrope-SemiBold";
  font-size: 27px;
}

.body_text {
  text-align: center;
  font-size: large;
  font-size: 22px;
  font-family: "Manrope-SemiBold";
}

.blue {
  color: #1a8fff;
}

.recruitmentPartners {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  /* border-bottom: 2px solid rgb(216, 210, 210); */
}

.underline1 {
  color: rgb(216, 210, 210);
}

.underline_box {
  text-align: right;
}

.underline_boxnew {
  text-align: center;
}

.cancelText {
  font-family: "Manrope-SemiBold" !important;
  color: #1a8fff !important;
  text-decoration: underline !important;
  text-align: center !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  font-size: 12px;
}

.resendOTP {
  font-family: "Manrope-Regular" !important;
  color: #1a8fff !important;
  font-size: 12px;
  background-color: #fff !important;
  border: none;
}

.resendOTP:disabled {
  color: #98a0a1 !important;
}

.cancelText:hover {
  color: blue !important;
}

.resend_otp_section_referral {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
}

.dialogHeading {
  padding: 20px !important;
}

.dialog1 {
  padding: 20px 0px !important;
}

.phoneDetails {
  font-size: 12px;
}

.dialogBody {
  text-align: center;
  font-family: "Segoe-Regular";
  font-size: 17px;
  margin-bottom: 10%;
  margin-top: 10%;
}

.dialogBody1 {
  text-align: center;
  font-family: "Segoe-Regular";
  font-size: 17px;
  margin-bottom: 13%;
  margin-top: 11%;
}

.inputTagMargin {
  margin-top: 2%;
}

.underline2 {
  color: rgb(216, 210, 210);
  width: 72%;
  border-bottom: 2px solid rgb(216, 210, 210);
}

.underline3 {
  width: 28%;
}

/* .logos {
  margin-right: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  height: 100px;
} */

.labels {
  width: 27%;
  font-family: "Segoe-Regular";
  font-size: 16px;
  margin-bottom: 12px;
}

.upload_btn {
  flex-shrink: 2.5;
  margin-top: 34px;
}

.docbutton {
  padding: 0px 8px !important;
}

.upload-btn-resume-testimonial {
  color: #01313c;
  width: 100%;
  background-color: white;
  padding: 1px 6px !important;
}

.upload_resume_button_text {
  color: #1a8fff;
  font-size: 10px;
  font-family: "Manrope-SemiBold";
}

.upload_resume_button_text_highlighted {
  color: red;
  font-size: 10px;
  font-family: "Manrope-SemiBold";
}

.dotted_border{
  border: 2px dotted red;
}

.scrollbox {
  /* height: 41vh; */
  padding: 0px 50px 0px 0px;
  width: 100%;
  overflow-y: scroll;
}

.referralSidebarFixed {
  width: 13vw;
}


@media only screen and (max-width: 600px) {
  .referralSidebarFixed {
    display: flex;
    position: unset;
    width: 100%;
  }

  .referralSidebarFixed .bubble_blue {
    margin-top: 0;
  }

  .referralSidebarFixed .style {
    margin-top: 0px;
    margin-bottom: 0px;
  }

}

.upload_btn_style {
  margin-top: 25px;
}

.fileType_icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload_textFile {
  font-size: 10px;
  width: -webkit-max-content;
  width: max-content;
  padding: 0px;
  color: #1a8fff;
}

.MuiButton-label {
  flex-direction: column;
}

.replace_remove {
  border: 0px;
  background-color: white;
  font-size: 10px;
  font-family: "Manrope-SemiBold";
}

.f_menu.MuiMenuItem-root {
  border-bottom: 1px solid black;
  padding-left: 40px;
  padding-right: 40px;
}

.s_menu.MuiMenuItem-root {
  padding-left: 40px;
  padding-right: 40px;
}

.button_box {
  width: 100%;
  margin-right: 10px;
}

.button_boxnew {
  width: 100%;
}

.emailInputNew {
  width: 70% !important;
}

.btn-invite_next {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 9px 35px 11px 35px;
  font-size: 18px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn-invite_next_blue {
  color: #fff;
  background-color: #1a8fff;
  border: 1px solid #1a8fff;
  margin-top: 13px;
}

.invite_referral_label_wrapper {
  margin-right: 10px;
  width: 100%;
}

.btn_style {
  padding: 20px 150px;
  border-radius: 5px;
}

.add_btn_text {
  margin-bottom: 4px;
  font-family: "Manrope-Medium";
  margin-right: 10px;
  font-size: 18px;
}

.add_btn {
  margin-right: 10px;
}

.add_btn_style {
  align-items: center;
  justify-content: space-evenly;
  margin-top: -25px;
}

.autocomplete_border {
  border: 1px solid black;
  border-radius: 5px;
  height: 47px;
}

.autocomplete_border_highlighted{
  border: 2px solid red;
  border-radius: 5px;
  height: 47px;
}

.text_box_cleartalent_form_referral {
  height: 47px;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid#292929;
  border-radius: 4px;
}

.highlightStyle{
  height: 47px;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 2px solid red;
  border-radius: 4px;
}

#phoneNumber::placeholder{
  opacity: 0.6 ;
}

.mtb_24 {
  margin: 24px 0px;
}

.input_rows {
  margin-bottom: 28px;
}

.invite_candidate_add_refer_icon_wrap {
  float: right;
  margin-top: 0px;
  margin-right: -3px;
}

.invite_candidate_add_refer_icon_wrap .MuiSvgIcon-colorPrimary {
  color: #1a8fff;
  width: 1.8em;
  height: 1.8em;
}

.input_row_each {
  display: flex;
  flex-direction: row;
}

.flexInputs {
  display: flex;
  flex-direction: row;
}

.pdFix {
  padding: 0px !important;
}

.emailVerifyCode {
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 35vh;
}

.emailVerifyInput {
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 20vh;
}

.dialogAction {
  display: flex;
  flex-direction: column;
}

.dialogWidth {
  width: 600px;
}

.emptyDiv {
  height: 79px;
}

.otpSection {
  margin-right: 8%;
}

.emailInput {
  width: 25% !important;
}

.numericContainer .PhoneInput {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.numericContainer .PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: 0px;
  height: 30px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  width: 80%;
}

.numericContainer .PhoneInputInput:focus {
  flex: 1 1;
  min-width: 0;
  border: 0px;
  outline: none;
  height: 30px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  width: 80%;
}

.numericContainer .PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 160%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
  margin-left: -20px;
}

.numericContainer option {
  font-size: 15px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
}

.validationText {
  color: red;
  font-family: "Manrope-Semibold";
}

.alertStyle {
  position: absolute;
  width: 78%;
  top: -2.3vh;
  align-items: center;
}

.existedUser {
  cursor: pointer;
  text-decoration: underline;
}

.failedDataDiv {
  text-align: center;
  padding: 35px;
}

.tickImage {
  width: 15vh;
}

.successData {
  padding: 15px 0px;
  font-size: 18px;
  font-family: "Segoe-Regular";
}

.failedList {
  margin-top: 5px;
  margin-bottom: 20px;
  background-color: #c6e2fa;
  padding: 0px 40px;
}

.failedListItem {
  padding: 2.5px 0px;
  color: #3B71CA;
  font-family: "Segoe-Regular";
}

@media only screen and (max-width: 389px) {
  .rightSide_border {
    border-right: 0;
    padding-bottom: 20px !important;
    margin-bottom: 20px !important;
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .underline2 {
    width: 100%;
    color: rgb(216, 210, 210);
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .button_box {
    width: 100%;
    margin-right: 10px;
    text-align: center;
  }

  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 390px) and (max-width: 599px) {
  .banner_section_referral {
    width: 100%;
    position: relative;
    overflow: hidden;
    top: 70px;
  }

  .rightSide_border {
    border-right: 0;
    padding-bottom: 20px !important;
    margin-bottom: 20px !important;
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .input_rows {
    margin-bottom: 25px;
  }

  .input_row_each {
    display: flex;
    flex-direction: column;
  }

  .labels {
    width: 27%;
    font-family: "Segoe-Regular";
    font-size: 16px;
    margin-bottom: 0px;
  }

  .invite_referral_label_wrapper {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 15px;
  }

  .underline2 {
    width: 100%;
    color: rgb(216, 210, 210);
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .button_box {
    width: 100%;
    margin-right: 10px;
    text-align: center;
  }

  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .banner_section_referral {
    width: 100%;
    position: relative;
    overflow: hidden;
    top: 70px;
  }

  .rightSide_border {
    border-right: 2px solid rgb(216, 210, 210);
  }

  .input_rows {
    margin-bottom: 25px;
  }

  .input_row_each {
    display: flex;
    flex-direction: column;
  }

  .labels {
    width: 27%;
    font-family: "Segoe-Regular";
    font-size: 16px;
    margin-bottom: 0px;
  }

  .invite_referral_label_wrapper {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 15px;
  }

  .underline2 {
    width: 100%;
    color: rgb(216, 210, 210);
    border-bottom: 2px solid rgb(216, 210, 210);
  }

  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .banner_section_referral {
    width: 100%;
    position: relative;
    overflow: hidden;
    top: 70px;
  }

  .rightSide_border {
    border-right: 2px solid rgb(216, 210, 210);
  }

  .input_rows {
    margin-bottom: 25px;
  }

  .input_row_each {
    display: flex;
    flex-direction: column;
  }

  .labels {
    width: 27%;
    font-family: "Segoe-Regular";
    font-size: 16px;
    margin-bottom: 0px;
  }

  .invite_referral_label_wrapper {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 15px;
  }

  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1024px) {
  .dfGrid {
    display: flex;
    flex-direction: column;
  }

  .flexInputs {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1111px) and (max-width: 1920px) {

  /* .phoneLabel {
    margin: -5px !important;
  } */
  .logos {
    margin-right: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}
.padding_80
{
    padding: 80px 0px;
}
.whiteAdust {
    height: 78vh
  }
  .circular-progress-center {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }
/* @media (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .Poweresume .text_area_cleartalent_support {
    width: 77%;
    padding: 0px 30px;
    font-size: 16px;
    font-family: "Segoe-Regular";
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
  }
  .Poweresume .container_rec {
    flex-direction: column;
    align-items: flex-start;
}
.Poweresume .responsive_Support {
    padding: 0px 18px !important ;
  }
  .Poweresume .support_textbackgroundone {
    background-color: #d1e8ff;
    width: 94%;
    padding: 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .Poweresume .support_textbackgroundtwo {
    background-color: #d1e8ff;
    width: 94%;
    padding: 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .Poweresume .support_section {
    display: block;
    justify-content: unset;
  }

  .Poweresume .MuiTypography-h6 {
    font-family: "Segoe-Light" !important;
    font-size: 30px !important;
    color: #01313c !important;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  .Poweresume .hamberger_icon {
    float: right;
    display: contents;
  }
  .Poweresume .nav_logo {
    margin-top: 22px;
    margin-bottom: 16px;

    width: 80%;
  }

  .Poweresume .nav_bg_righ {
      display: flex;
  }
  .Poweresume .nav_bg_rig{
    display: flex;
     justify-content: center;
  }
  .Poweresume .hamberger_nav {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 350px;
    z-index: 99;
    background-color: #04093f;
    top: 61px;
  }
  .Poweresume .fadeInHmbMenu {
    opacity: 1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    position: absolute;
    width: 100%;
    left: 0px;
    height: 350px;
    z-index: 99;
    background-color: #04093f;
    top: 61px;
  }
  .Poweresume .fadeOutHmbMenu {
    opacity: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    width: 0;
    height: 0;
  }
  .Poweresume .navbar_mobile {
    width: 100%;
    overflow: auto;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
  }
  .Poweresume .mobilemenuleft_adjust {
    margin-left: 24px;
  }

  .Poweresume .navbar_mobile li {
    list-style-type: none;
    width: 100%;
    border-bottom: 1px solid #192288;
    padding: 20px 0px !important;
  }
  .Poweresume .navbar_mobile li:last-child {
    list-style-type: none;
    width: 100%;
    border-bottom: 0px solid #192288;
    padding: 20px 0px !important;
  }

  .Poweresume .navbar_mobile li img {
    vertical-align: middle;
  }
  .Poweresume .nav_bg_home_sectio {
    justify-content: space-between;
  }
  .Poweresume .fle>.MuiSvgIcon-root{
    display: none;
  }
  .profileName_DesktopElement{
    display: none;
   }
   .profileName_PhoneElement{ 
     display: block !important;
    }
}
.profileName_PhoneElement{
  display: none;
}
/*--------------- Header CSS -------------*/
.profile_dropdown{
  position: relative;
}
.profile_headshadow{
  box-shadow: 0 0 10px -1px rgb(0 0 0 / 34%);
}
.profile_name{
  width: 80%;
}
.profile_drop{
  width: 1%;
}

.profile_drop_men{
position: absolute;
top: 73px;
right: 0px;
}
.profile_support
{
  margin-top: 5px;
}



.Poweresume .nav_b {
  width: 100%;
  background-color:  #FFFFFF;
}
.Poweresume .header_fixed {
  position: fixed;
  top: 0px;
  z-index: 1;
}
.Poweresume .nav_b_left {
  /* width: 42%; */
  background-color: #FFFFFF;
}
.Poweresume .nav_b_right {
  /* width: 58%; */
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Poweresume .hamberger_icon {
  display: none;
}
.Poweresume .nav_logo {
  margin-top: 22px;
  margin-bottom: 16px;
  width: 100%;
}
.Poweresume .navbar {
  width: 100%;
  overflow: auto;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}
.Poweresume .navbar li {
  list-style-type: none;
}
.Poweresume .navbar li img {
  vertical-align: middle;
}
 .Poweresume .cardAction{
  width:100%;
  padding:0 !important;
  margin: 0 !important;
  background-color: #1885e6;
  color: white;
  height: 160px;
  margin-top: -40px !important;
}
.Poweresume .card_text{
  font-size: 12px !important;
  font-family: 'Segoe UI'!important;
  width: 100%;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.Poweresume .menulist{
  height: 100%;
  width: 100%;
}
.Poweresume .dialog{
  position: absolute !important;
  left: 50px !important;
  top:50px !important;
  border: 5px solid black;
}
.Poweresume .all_profil{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #D1E8FF !important;
  padding: 0px 30px 50px 30px !important;
  height: 180px;
  width: 150px;

}
.Poweresume .LinkForHeader {
  float: left;
  padding: 7px 0px 0px 38px;
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-family: "Segoe-Regular";
}
.Poweresume .nav_menu_icon {
  padding-right: 5px;
}
.Poweresume .notify-badge {
  position: absolute;
  top: 5px;
  margin-left: 9px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  background: #2ad156;
  text-align: center;
  border-radius: 50%;
  color: black;
  font-size: 8px;
}
.Poweresume .MuiTypography-h6 {
  font-family: "Segoe-Light" !important;
  font-size: 35px !important;
  color: #01313c !important;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.Poweresume .MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 6px 24px;
}
.Poweresume .contact_details_heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Segoe-Semibold" !important;
  color: #01313c !important;
}
.Poweresume .nav_menu_adjust {
  display: flex;
  justify-content: space-between;
}
.Poweresume .mobilemenuleft_adjust {
  margin-left: 0px;
}
.Poweresume .fadeInHmbMenu {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
  position: absolute;
  width: 100%;
  left: 0px;
  height: 350px;
  z-index: 99;
  background-color: #04093f;
  top: 61px;
}
.Poweresume .fadeOutHmbMenu {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  width: 0;
  height: 0;
}
.Poweresume .support_textbackgroundone {
  background-color: #d1e8ff;
  width: 44.5%;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
}
.Poweresume .support_textbackgroundtwo {
  background-color: #d1e8ff;
  width: 42%;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 10px;
}
.Poweresume .support_section {
  display: flex;
  justify-content: space-between;
}
.Poweresume .text_area_cleartalent_support {
  width: 94%;
  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}
.Poweresume .add_email_box {
  width: 100%;
  position: relative;
  display: flex;
}
.Poweresume .add_email_box .search-input {
  width: 100%;
  padding: 10px;
  height: 45px;
  border: 1px solid #a5a4a4;
  border-radius: 4px 0 0 4px;
  border-right: none;
  outline: none;
  font-size: 16px;
  color: #000;
  background: none;
  font-family: "Segoe-Regular";
}

.Poweresume .add_email_box .search-input:focus {
  box-shadow: none;
  border: 1px solid #a5a4a4;
}

.Poweresume .add_email_box .search-input::placeholder {
  font-size: 16px;
  color: #01313c;

  font-family: "Segoe-Regular";
}

.Poweresume .text_area_cleartalent_support {
  width: 94%;

  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid #a5a4a4;
  border-radius: 4px;
}

.Poweresume .text_area_cleartalent_support::placeholder {
  font-size: 16px;
  color: #01313c;

  font-family: "Segoe-Regular";
}

.Poweresume .add_email_box .search-button {
  text-align: center;
  height: 45px;
  padding: 0px 20px;
  outline: none;
  cursor: pointer;
  border: 1px solid #2ad156;
  border-radius: 0 4px 4px 0;
  border-left: none;
  background: none;
  font-size: 16px;
  border-left: 1px solid #a5a4a4;
  font-family: "Segoe-Semibold";
  color: #fff;
  background-color: #2ad156;
}
.mail_bg {
  background-color: #d1e8ff;
  padding: 5px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline-block;
  margin-top: 7px;
  margin-bottom: 8px;
}
.mail_b {
  background-color: #F5F5F5;
  margin-left: 5px;
  border-radius: 50px;
  font-family: "Segoe-Regular";
  font-size: 17px;
  padding: 5px;
  display: inline-block;
  margin-top: 7px;
  margin-bottom: 8px;
}





.Poweresume .mail_text {
  font-size: 18px;
  margin-left: 8px;
  font-family: "Segoe-Regular";
  color: #01313c;
}

.mail_txt{
  font-family: "Segoe-Regular";
}

.mail_close_icon {
  margin-left: 8px;
  margin-right: 5px;
  cursor: pointer;
}
.Poweresume .support-mailbody-textarea {
  width: 100%;
  height: 100px !important;
}
.Poweresume .mail-validation-error {
  color: red;
}
.Poweresume .remove-snackbar-alert .MuiAlert-message {
  font-family: "Segoe-Regular";
}
.Poweresume .text_area_send_mail {
  width: 100%;
  padding-right: 0px;
}
.Poweresume .profile_Image{
  width: 38px;;
  height:38px;
  border-radius: 50%;
  margin-top: 8px;
}
.Poweresume .profileIma{
  width: 82.25px;
  height: 82.25px;
  border-radius: 50%;
}
.Poweresume .sdudent_name {
  font-family: "Arial" !important;
    font-size: 14px bold !important;
    text-align: center;
    /* color: #1885e6; */
    padding-top: 19px !important;

}

.Poweresume .teso{
  font-family: "Arial" !important;
  position: absolute;
}
.Poweresume .MuiSvgIcon-root {
  fill: currentColor;
  flex-shrink: 0;
  -webkit-user-select: none;
          user-select: none;
}
.Poweresume #support_Icon >.fle>.MuiSvgIcon-root {
  margin-top: 0px;
  padding-top: 0px;
}
.Poweresume .user_men{
  display: flex;
}
.Poweresume .container_rec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:50px 0px;
  
}

.Poweresume .fl{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
 .Poweresume .w-100
 {
  max-width: 100%;
 }
 .Poweresume .fles{
  display: flex;
  align-items: center;
 }
 .Poweresume .nav_bg_home_sectio {
  display: flex;
  justify-content: space-between;
}
.Poweresume .support_card{
  height: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Poweresume .support_content{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.Poweresume .support_img{
  margin-top: 65px;
}

.Poweresume .support_write{
  border-bottom: 1px solid #6398F1;
}


.Poweresume .support_action{
  height: 85px !important;
  border-top: 1px solid #6A91E9 ;
  opacity: 0.5;
  margin-right: 74px;
  margin-left: 74px;
  
}

.Poweresume .support_dialog_title{
  position: relative;
}
.Poweresume #para{
  font-size: 23px;
  font-family: 'Manrope-Regular';
  color: #666666;
}
.Poweresume .support_line{
  border-bottom: 1px solid #6398F1;
  width: 268px;
  font-family: 'Manrope-Medium';
  font-size: 36px;
  color: #1885E6;
  height: 58px;
}
.Poweresume .support_form_leb{
  font-family: 'Manrope-Medium';
  font-size: 16px;
  color:#666666;

}
.support_dialog_title .support_lin{
  border-bottom: 1px solid #6398F1;
  width: 368px;
  font-family: 'Manrope-Medium';
  font-size: 36px;
  color: #1885E6;
  height: 52px;
}
.Poweresume .support_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
}
.Poweresume .blue{
  position: absolute;
  top: 0;
  right: 4px;
}
.Poweresume .support_lebel{
  width: 330px;
}

.Poweresume .Support_textbox{
  height: 40px;
  width: 100%;
  padding-left: 0px !important;
}
.Poweresume #standard-basic{
  border-bottom: 2px solid #707070;
  border-radius: 0px;
  padding-left: 0px !important;
  font-family: 'Manrope-Regular';
}
.Poweresume #mes_lable{
  font-size: 16px;
  font-family: 'Manrope-Medium';
  width: 100%;
 height: 15px;
 color:#666666;
}




.Poweresume .support_message{
  height: 140px;
  width: 100%;
  border: 1.4px solid #707070;
  opacity: 0.6;
  border-radius: 3px;
}
.Poweresume .support_message_lebel{
  margin-top: 30px;
  margin-left: 20px;
  padding-bottom: 10px;
  font-family: 'Manrope';
  font-size: 20px;
}

.Poweresume .btn_send{
  width: 149px;
  height: 37px;
  background-color: #1885E6;
  color: #FFFFFF;
  border: none;
}

.Poweresume .btn_cancel{
  width: 149px;
  height: 37px;
  background-color: #FFFFFF;
  color: #1885E6;
  border: 1px solid #1885E6 !important;
}

.Poweresume .support_con{
  padding-top: 0px !important;
  padding-right: 28px !important;
}

.Poweresume .support_grid{
  padding-right: 20px;
}

.Poweresume .support_grid_message{
  margin-top: 20px !important;
  padding-bottom: 0px;
}




.MuiAutocomplete-option li{
  margin-left: -12px !important;
}


.support_con .MuiAutocomplete-input{
  margin-left: -12px !important;
}


.Poweresume element.style {
  margin-left: 0px !important;
}

.Poweresume .resume_head
{
  display: flex;
  align-items: center;
}
.Poweresume .user_menu{
  width: -webkit-max-content;
  width: max-content;
}
.pr_support{
  height: 24px;
  width: 24px;
}
.Pcom_pic{
    position: relative;
}
.proile-fwd-icon {
    vertical-align: super;
}

.profile_icon {
    vertical-align: middle;
}

.profile-text {
    display: inline-block;
    width: 82px;
    margin-left: 5px;
}

.profile_card {
    position: relative;
}

.profile_tops {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    align-items: center;
    display: flex;
}

.radialProgressBar {
    border-radius: 50%;
    /*-webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);*/
    width: 34px;
    height: 34px;
    display: flex;
    background: #ddd;
    margin-left: 14px;
    float: left;
}

.radialProgressBar .overlay {
    border-radius: 50%;
    width: 28px;
    height: 23px;
    margin: auto;
    background: #fff;
    text-align: center;
    padding-top: 12%;
    font-family: 'Segoe-Regular';
    color: #01313C;
    font-size: 12px;
    vertical-align: middle;
}

.progress-80 {
    background-image: linear-gradient(4deg, #2896FF 50%, transparent 50%), linear-gradient(-112deg, #2896FF 50%, #ddd 50%);
}

.profileleft_popup {
    width: 700px;
    margin: 30px auto;
}

.dropdown_width_profile {
    width: 31% !important;
}
.extra_padding {
    padding: 14px 8px 20px 8px;
    }
    
    .profile_completion_bottom_padding {
    padding: 14px 12px 0px 12px;
    }

.editprofilemodal {
    background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;	
	width: 500px ;
    position: relative;
    padding: 0px 20px 0px 20px;
}
.editprofilemodal_availabilty {
    background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;	
	width: 500px ;
    position: relative;
    padding: 0px 20px 0px 20px;
}
.editprofilemodal_completion {
    background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;	
	width: 700px ;
    position: relative;
    padding: 0px 20px 0px 20px;
    overflow-y: scroll;
    height: 600px;
}
.modal_popup_close {
	position: absolute;
	top: 24px;
	right: 24px;
	
}
.myskill-right {
	text-align: right;
}

.Dropdown-control{
    border:solid 2px #A3BCD5!important;
    font-size: 22px;
    font-family: 'Segoe-Regular';
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    padding: 15px 30px 15px 10px!important;
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
.Dropdown-arrow{
    top: 28px!important;
    border-color: #444 transparent transparent!important;
    
}
/* .input_dropdown{
    padding: 13px 10px;
    font-size: 22px;
    font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
} */

.Dropdown-menu {
    transition: all 200ms ease-out !important;
    top: 100% !important;
    left: 0 !important;
    min-width: 100% !important;
    margin-top: 0px !important;
    background: #fff !important;
    height: 240px !important;
    overflow-y: scroll !important;
    border: 2px solid #2896FF !important;
    border-radius: 3px !important;
    z-index: 999 !important;


}
.Dropdown-option {
    padding: 13px 10px!important;
    font-size: 22px !important;
    font-family: 'Segoe-Semibold'!important;
    font-style: normal!important;
    font-weight: 600!important;
    color: #01313C!important;

}
.profile_pop
{
    height: auto;
    overflow-y: scroll;
}
.br-word
{
    word-break: break-all;
}
.upload_button{
    margin-right: 20px;
    margin-top: -42px;
}
/* .MuiInput-underline:before{
    transition: none!important;
    border-bottom: none!important;
}

.MuiInput-underline:after {
    border-bottom: none!important;

}
.MuiInput-underline:after{
    border-bottom: none!important; 
}
.MuiGrid-container {
    border: 2px solid #A3BCD5;
    border-radius: 4px!important;
    padding: 6px 12px!important;
}
.MuiFormControl-marginNormal{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
} */
.progressBar
{
    width: 40px!important;
    height: 40px!important;
    margin-left: 7px!important;
}
.CircularProgressbar .CircularProgressbar-text
{
    font-size: 30px!important;
    font-weight: bold;
}
.powerProfileImage
{
    width:55px;
    height: 55px;
    border-radius: 50%;
}
.camera_icon
{
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 54px;
    top:0;
}

.all_padding {
    padding-left: 20px;
}
.editprofilemodal_image {
    background: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 600px;
    position: relative;
    padding: 0px 20px 0px 20px;
    
}
.position_unset {
    position: unset;
}
/* for croper */
.crop-container {
    position: relative;
    width: 100%;
    height: 250px;
    background: #333;
  }
  
  .controls {
    padding: 16px;
    display: flex;
    
    justify-content: space-between;
   
  }
  .sliderContainer {
    display: flex;
    flex: 1 1;
    align-items: center;
  }
  .slider {
    padding: 22px 0px;
    margin-left: 16px;
  }


.reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    /* border-radius: 50%;
    height: 250px !important;
    width: 250px !important; */
}
/* ------- Adding Circle Image Upload with id circle_profilePic ------*/
#circle_ProfilePic .reactEasyCrop_CropArea {
    border-radius: 50%;
    height: 250px !important;
    width: 250px !important;
  }
.mb_20 {
    margin-bottom: 20px;
}
.w-100 {
    width: 94%;
}
.MuiSlider-root {
    color: #3f51b5;
    width: 94% !important;
    cursor: pointer;
    height: 2px;
    display: inline-block;
    padding: 13px 0;
    position: relative;
    box-sizing: content-box;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    /* float: left; */
}
/* for croper */
.df_profile{
    display: flex;
}
.profiletopedit_icon{
    margin-left: 12px;
}
.phonenumber_align{
    margin-left: 6px;
}
.position_align{
    margin-left: 4px;
}
.exp_align{
    margin-left: 5px;
}
.zipgap{
    margin-top: 0px;
}
.icon_align{
    display: flex;
flex-direction: column;

justify-content: center;
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem!important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
            user-select: none;
}
.profilecamera_upload{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
}
.camera_section{
    text-align: center;
}
 /* .profilecompletion_edit_dailog.MuiDialog-paperWidthSm{
    width: 750px !important;
    max-width: none !important;
  } */

/* .WithStyles\(ForwardRef\(DialogContent\)\)-root-4  {
    padding: 42px 42px 0px 42px!important;
}
.WithStyles\(ForwardRef\(DialogActions\)\)-root-8 {
    margin: 0;
    padding: 33px 42px 42px 42px!important;
} */
.MuiDialog-root
.dailog_w80{
    width: 100%;
  }
  /* .email_hidden{
    display: inline-block;
    width: 90%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  } */
@media only screen and (max-width: 768px) {
    /* .email_hidden{
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      } */
    .MuiDialog-root

   .MuiDialogActions-root.responsive-dialog{
        
        padding: 24px 24px 24px 42px !important;
        
    }
    .responsive-dialog_body_profilepic{
        padding: 0px 24px 0px 24px!important;
    }

    .MuiDialog-root

    .responsive-body-for-profile-edit{
        padding: 0px 24px 0px 24px!important;
    margin-top: 70px
    }
    .MuiDialog-root

    .responsive-body-primaryskill{
        padding: 0px 24px 0px 24px;
    margin-top: 70px
    }

    .responsive-body-profilecompletion{
        padding: 0px 24px 0px 24px;
    margin-top: 70px
    }

    .MuiDialog-root
    .removemodal_responsive{
        padding: 30px 24px 0px 24px!important;
    }
    .MuiDialog-root
    .responsive-profilepic_title{
        padding: 16px 24px!important;
    }
    .MuiDialog-root
    .dailog_w80{
      width: 80%;
    }
    .MuiDialog-root
    .responsive-profilepic_body{
        padding: 0px 24px;
    }
    .MuiDialog-root
   .MuiDialogActions-root.responsiveRemove_Modal_button{
        padding: 22px!important;
    }
    
    .crop-container {
        position: relative;
        width: 100%;
        height: 250px;
        background: #333;
      }
    
    .profiletopedit_icon{
        margin-left: 142px;
    }
    .editprofilemodal_image {
    background: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 86%;
    position: relative;
    padding: 0px 20px 0px 20px;
    
}
.editprofilemodal {
    background: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 86%;
    position: relative;
    margin-left: 2px;
    }
    
    .editprofilemodal_availabilty {
        background: rgb(255, 255, 255) !important;
        border-radius: 4px !important;
        outline: none !important;	
        width: 86% ;
        position: relative;
        padding: 0px 20px 0px 20px;
    }
    .editprofilemodal_completion {
        background: rgb(255, 255, 255) !important;
        border-radius: 4px !important;
        outline: none !important;	
        width: 86% ;
        position: relative;
        padding: 0px 20px 0px 20px;
        margin-top: 0%;
        margin-left: 4px;
        height: 570px;
    }
    .profileleft_popup {
    width: 100%;
    margin: 30px auto;
    }
    .modal_popup_close {
        position: absolute;
        top: 5px!important;
        right: 24px;
    }
    .zipgap{
        margin-top: 12px;
    }
    
    
    .dropdown_width {
        width: 100%!important;
        }
        .dropdown_width_outer {
            display: block!important;
            justify-content: space-between;
        }
        .dropdown_width_profile {
            width: 100% !important;
        }
        .fs-35 {
            font-size: 27px;
        }
        .camera_icon {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 96px;
    top: 0;
}
}
.profile-completion__availability-edit-but {
    position: absolute;
    right: 35px;
    top: 20px;
    widows: 100%;
    /* margin-top: 10px; */
}
.profile-completion__availability-card {
    position: relative;
}
.profile_card .MuiSkeleton-circle {
    margin: 0 auto;
  }



  @media (min-width:769px) and (max-width:1366px) {
  .MuiDialog-root .responsive-body-primaryskill {
    padding: 0px 24px 0px 42px;
    margin-top: 70px;
}
.MuiDialog-root .responsive-body-profilecompletion {
    padding: 0px 42px 0px 42px;
    margin-top: 70px;
}
.MuiDialog-root
    .responsive-profilepic_body{
        padding: 0px 24px 0px 42px;
    }
  }

  @media (min-width:1367px) and (max-width:1920px) {
    .MuiDialog-root .responsive-body-primaryskill {
      padding: 0px 42px 0px 42px;
      margin-top: 70px;
  }
  .MuiDialog-root .responsive-body-profilecompletion {
      padding: 0px 42px 0px 42px;
      margin-top: 70px;
  }
  .MuiDialog-root
    .responsive-profilepic_body{
        padding: 0px 42px;
    }
    }
.phoneError_ml{
    margin-left: 10px;
}
.card_top {
    box-shadow: rgba(0, 0, 0, 0.24) -1px -5px 5px 0px;
    transition: all 0.2s ease-in-out;
    padding: 0px 10px 0px 10px;
    border-radius: 4px 4px 0px 0px;
    background-color: #ffffff;    
  }
@media (max-width:768px){
    .phoneError_ml{
        margin-left: 0;
    }
}
.pb_5{
    padding-bottom: 5px;
}
.resume_name{
    margin-top: 30px;
    margin-left: 10px;

}

.resume [type="radio"]:checked,
.resume [type="radio"]:not(:checked) {
	position: absolute;
	visibility: hidden;
}

.resume [type="radio"]:checked+label,
.resume [type="radio"]:not(:checked)+label {
	position: relative;
	padding-left: 32px;
	cursor: pointer;
	line-height: 25px;
	display: inline-block;
	font-family: 'Segoe-Semibold';
	font-style: normal;
	font-weight: 600;
	color: #01313C;
	font-size: 14px;
	margin-top: 20px;
}

.resume [type="radio"]:checked+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 25px;
	height: 25px;
	border: 1px solid #1A8FFF;
	border-radius: 100%;
	background: #fff;
}
.ml_26{
	margin-left: 26px;
}

.resume [type="radio"]:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 25px;
	height: 25px;
	border: 1px solid #A3BCD5;
	border-radius: 100%;
	background: #fff;
}

.resume [type="radio"]:checked+label:after,
.resume [type="radio"]:not(:checked)+label:after {
	content: '';
	width: 11px;
	height: 11px;
	background: #1A8FFF;
	position: absolute;
	border: 1px solid #1A8FFF;
	top: 7px;
	left: 7px;
	border-radius: 100%;
	transition: all 0.2s ease;
}

.resume [type="radio"]:not(:checked)+label:after {
	opacity: 0;
	transform: scale(0);
}

.resume [type="radio"]:checked+label:after {
	opacity: 1;
	transform: scale(1);
}
.upload_text {
	color: #1A8FFF;
	text-decoration: underline;
	padding-top: 10px;
	padding-left: 10px;
	cursor: pointer;
	}
	.remove_modal{
		width: 100%;
		display: flex;
		justify-content: center;
	}
/*.react-pdf__message
{
	display: none;
}*/
.remove-btn-design
{
	color: #01313C;
	text-decoration: underline;
	font-family: 'Segoe-Semibold';
	font-size: 14px;
}
.ResumeDelete 
{
	background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;
	width: 350px;
	position: relative;
}
.resumebg{
	padding: 24px 20px 20px 20px!important;
}
.linkedin-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.linkedin-wrapper img:first-child{
	position: relative;
	top: -8px;
}
.linkedin-wrapper img:last-child{
	position: relative;
	top: -8px;
}
.linkedin-modal-dialog .MuiDialog-paper {
	width: 80%;
}
.linkedin-modal-url-container {
	width: 100%;
}
.linkedin-modal-url-container .remove_modal {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.wordbreak {
	word-break: break-word;
}
.fs_manrope_12_btn{
	font-size: 12px;
	font-family: 'Manrope-Bold';
	display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 7px 24px 9px 23px;
  /* font-size: 16px; */
  border-radius: 4px;

}
.powres_box{
	height:170px;
	width: 238px;
}
.dialogueForTestimonial
{
    background: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 350px;
    position: relative;
}
.type_a
{
    cursor: pointer;
}
.testimonial_chkbox {
    display: block;
    margin:10px 0px 0px 0px;
    /*margin-top: 23px;*/
    width: 100%;
}

.testimonial_chkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.testimonial_chkbox label {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Segoe-Semibold';
    font-size: 22px;
    color: #01313C;
    display: flex;
    align-items: center;
}

.testimonial_chkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #01313C;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px !important;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    
    /* float: left; */
    margin-right: 11px;
    /* margin-top: 5px; */
}


/* .testimonial_chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 14px;
    width: 7px;
    height: 17px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */
.testimonial_chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 16px;
    /* left: -28px; */
    left: 6px;
    width: 4px;
    height: 11px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    }

.testimonial_chkbox :checked+label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #1A8FFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 12px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    
}
.date_icon {
    vertical-align: middle;
    padding-right: 0px;
}

.ManageInterviewModalHeader{
    display: flex;
    padding: 20px 0px 0px 40px;
    box-shadow: rgb(50 50 93 / 25%) 0px 0px 12px -2px, rgb(0 0 0 / 30%) 0px 2px 7px -3px;
}
.ManageInterviewModalHeader legend{
    font-size: 30px !important;
}

.InterviewTimeContainer{
    font-size: 22px !important;
}
#btmModalContainer{
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px -3px 7px -3px !important;
}

/*----------- Add Day & Time  -------------*/

.addDay_time {
    width: 750px;
}


/*------------- CHECKBOX ---------------*/
.adddaychk {
    float:left;
    margin-right: 0.2em;
    }
    
    .adddaychkheight {
    
    /*height: 50px;*/
    display: flex;
    align-items: center;
    }
    #InterviewTimeCheckbox .MuiSvgIcon-root{
        font-size: 2rem !important;
    }
    #InterviewTimeCheckbox .MuiCheckbox-colorPrimary.Mui-checked{
        color:#1A8FFF !important;
    }
    #btmModalLine hr{
        border-top: 2px solid #1a8fff !important;
    }
.addday_chkbox  .addday_chkboxs{
    display: block;
    margin-bottom: 20px;
    /*margin-top: 23px;*/
}

/* .addday_chkbox  */
.addday_chkboxs input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

/* .addday_chkbox  */
.addday_chkboxs label {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Segoe-Semibold';
    font-size: 19px;
    color: #01313C;
}

.addday_chkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #01313C;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 16px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 9px;
    float: left;
}

/* .addday_chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 14px;
    width: 7px;
    height: 17px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */
.addday_chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: -29px;
    width: 6px;
    height: 14px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    }

.addday_chkbox :checked+label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #1A8FFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 16px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    /* margin-right: 7px; */
}


/*------------- CHECKBOX ---------------*/


/*----------- Add Day & Time  -------------*/

.ReactModal__Overlay {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    overflow-y: scroll !important;
}

.interviewtime_modal {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 750px !important;
    margin: 30px auto !important;
    position: absolute !important;
}

.modal_popup_close {
    position: absolute;
    top: 24px;
    right: 24px;
    
}
/* .MuiDialog-paperWidthSm {
    max-width: 750px!important;
} */
.calender_adjust{
    height: auto;
     padding: 10px 4px 10px 14px;
    width: 94%
}
.text_box_calender{
    width: 94%;
height: 45px;
padding: 0px 0px;
font-size: 14px;
/* line-height: 1.42857143; */
padding: 0px 20px;
font-size: 16px;
font-family: 'Segoe-Regular';
font-style: normal;
font-weight: normal;
background-color: #fff;
background-image: none;
border: 1px solid#c6c0c0;
border-radius: 4px;
}
/* .WithStyles\(ForwardRef\(DialogContent\)\)-root-1 {
    padding: 42px 42px 0px 42px!important;
}
.WithStyles\(ForwardRef\(DialogActions\)\)-root-2 {
    margin: 0;
    padding: 33px 42px 42px 42px!important;
} */

@media only screen and (max-width: 768px) {
    /* .WithStyles\(ForwardRef\(DialogContent\)\)-root-1 {
        padding: 42px 18px 0px 18px!important;
    }
    .WithStyles\(ForwardRef\(DialogActions\)\)-root-2 {
        margin: 0;
        padding: 33px 18px 42px 18px!important;
    } */
    .text_box_calender{
            /* width: 94%; */
    height: 45px;
    padding: 0px 0px;
    font-size: 14px;
    /* line-height: 1.42857143; */
    padding: 0px 20px;
    font-size: 16px;
    font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
    }
    .calender_adjust{
        height: auto;
         padding: 10px 4px 10px 14px;
        width: 93%
    }
    .interviewtime_modal {
        background: rgb(255, 255, 255) !important;
        overflow: auto !important;
        border-radius: 4px !important;
        outline: none !important;
        width: 86% !important;
        margin: 30px auto !important;
        position: absolute !important;
        margin-top: 0%!important;
        padding: 0px 20px 0px 20px !important;
        height: 615px;
        
    }
    .interviewtime {
        width: 100%;
    }
    .addday_chkbox label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #01313C;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 16px!important;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 9px;
        float: left;
        margin-top:9px;
        }
        
        .addday_chkbox input:checked+label:after {
            content: '';
            display: block;
            position: absolute;
            top: 14px;
            left: -29px;
            width: 7px;
            height: 17px;
            border: solid #1A8FFF;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .modal_popup_close {
            position: absolute;
            top: 24px;
            /* right: -64px; */
            left: 305px;
        }
        
        .adddaychk {
        float:none;
        }
        
        .adddaychkheight {
        
        height: 50px;
        }
        .addday_chkbox label {
            position: relative;
            cursor: pointer;
            font-weight: 600;
            font-family: 'Segoe-Semibold';
            font-size: 18px;
            color: #01313C;
        }
    }



    @media (min-width:769px) and (max-width:1366px) {
    .addday_chkbox {
        /* display: block; */
        display: flex;
        margin-bottom: 20px;
        /*margin-top: 23px;*/
    }
    
    .addday_chkbox input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }
    
    .addday_chkbox label {
        position: relative;
        cursor: pointer;
        font-weight: 600;
        font-family: 'Segoe-Semibold';
        font-size: 18px;
        color: #01313C;
    }
    
    .addday_chkbox label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #01313C;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 12px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 9px;
        float: left;
    }
    
    .addday_chkbox input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 14px;
        width: 7px;
        height: 17px;
        border: solid #1A8FFF;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .addday_chkbox input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: -25px;
        width: 6px;
        height: 12px;
        border: solid #1A8FFF;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        }
    
    .addday_chkbox :checked+label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #1A8FFF;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 12px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        /* margin-right: 7px; */
    }
}

.resume [type="radio"]:checked+label.seeking-oportunity-radio-label, .resume [type="radio"]:not(:checked)+label.seeking-oportunity-radio-label {
    font-size: 18px;
}

@media only screen and (max-width: 600px) {
    .seeking-oportunity-radio-label{
        margin-right: 12px;
    }}

.powres_inter{
    display: flex;
    flex-direction: column;
    height: 954px;
    width: 225px;
    
}

.manageinterview_datetime{
    margin-bottom: 10px;
}

    
/*--------------- RATING CSS -------------*/

.rating {
	border: none;
	float: right;
}

.rating>input {
	display: none;
}

.rating>label:before {
	margin-right: 7px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
}

.rating>label:first-child:before {
	margin-right: 0px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
}

.rating>.half:before {
	content: "\f089";
	position: absolute;
}

.rating>label {
	color: #fff;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	float: right;
}


/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,

/* show gold star when clicked */

.rating:not(:checked)>label:hover,

/* hover current star */

.rating:not(:checked)>label:hover~label {
	
}
.rating_labelactive
{
    color: #1A8FFF !important;
    -webkit-text-stroke-width: 1px !important;
    -webkit-text-stroke-color: #D1E8FF !important;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,

/* hover current star when changing rating */

.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,

/* lighten current selection */

.rating>input:checked~label:hover~label {
	
}


/*--------------- RATING CSS -------------*/
.ReactModal__Overlay {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    overflow-y: scroll !important;
}

.adddaytime_modal {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 750px !important;
    margin: 30px auto !important;
    position: absolute !important;
}

.modal_popup_close {
    position: absolute;
    top: 24px;
    right: 24px;
}
/*---------------  -------------*/
.rating1 {
	border: none;
	float: right;
	}
	
	.rating1>input {
	display: none;
	}
	
	.rating1>label:before {
	margin-right: 7px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
	}
	
	.rating1>label:first-child:before {
	margin-right: 0px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
	}
	
	.rating1>.half:before {
	content: "\f089";
	position: absolute;
	}
	
	.rating1>label {
	color: #fff;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	float: right;
	}
	
	
	.rating1>input:checked~label {
	color: #1A8FFF;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	}


	.rating1 {
	border: none;
	float: right;
	}
	
	.rating1>input {
	display: none;
	}
	
	.rating1>label:before {
	margin-right: 7px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
	}
	
	.rating1>label:first-child:before {
	margin-right: 0px;
	font-size: 1.55em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
	}
	
	.rating1>.half:before {
	content: "\f089";
	position: absolute;
	}
	
	.rating1>label {
	color: #fff;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	float: right;
	}
	
	
	.rating1>input:checked~label {
	color: #1A8FFF;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #D1E8FF;
	}
	/*---------------  -------------*/
	.rating2 {
		border: none;
		float: right;
		}
		
		.rating2>input {
		display: none;
		}
		
		.rating2>label:before {
		margin-right: 7px;
		font-size: 1.55em;
		font-family: FontAwesome;
		display: inline-block;
		content: "\f005";
		}
		
		.rating2>label:first-child:before {
		margin-right: 0px;
		font-size: 1.55em;
		font-family: FontAwesome;
		display: inline-block;
		content: "\f005";
		}
		
		.rating2>.half:before {
		content: "\f089";
		position: absolute;
		}
		
		.rating2>label {
		color: #fff;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #D1E8FF;
		float: right;
		}
		
		
		.rating2>input:checked~label {
		color: #1A8FFF;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #D1E8FF;
		}
	
	
		.rating2 {
		border: none;
		float: right;
		}
		
		.rating2>input {
		display: none;
		}
		
		.rating2>label:before {
		margin-right: 7px;
		font-size: 1.55em;
		font-family: FontAwesome;
		display: inline-block;
		content: "\f005";
		}
		
		.rating2>label:first-child:before {
		margin-right: 0px;
		font-size: 1.55em;
		font-family: FontAwesome;
		display: inline-block;
		content: "\f005";
		}
		
		.rating2>.half:before {
		content: "\f089";
		position: absolute;
		}
		
		.rating2>label {
		color: #fff;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #D1E8FF;
		float: right;
		}
		
		
		.rating2>input:checked~label {
		color: #1A8FFF;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #D1E8FF;
		}
		
.Dropdown-control{
    border:solid 2px #A3BCD5!important;
    font-size: 22px;
    font-family: 'Segoe-Regular';
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    padding: 15px 30px 15px 10px!important;
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
.Dropdown-arrow{
    top: 28px!important;
    border-color: #444 transparent transparent!important;
    
}
/* .input_dropdown{
    padding: 13px 10px;
    font-size: 22px;
    font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
} */

.Dropdown-menu {
    transition: all 200ms ease-out !important;
    top: 100% !important;
    left: 0 !important;
    min-width: 100% !important;
    margin-top: 0px !important;
    background: #fff !important;
    height: 240px !important;
    overflow-y: scroll !important;
    border: 2px solid #2896FF !important;
    border-radius: 3px !important;
    z-index: 999 !important;


}

.Dropdown-option {
    padding: 13px 10px!important;
    font-size: 22px !important;
    font-family: 'Segoe-Semibold'!important;
    font-style: normal!important;
    font-weight: 600!important;
    color: #01313C!important;

}
.badge_skill {
	box-sizing: border-box;
	display: inline-block;
	background-color: #D1E8FF;
	color: #01313C;
	border-radius: 3rem;
	text-align: center;
	font-size: 1.6rem;
	font-weight: 400;
	padding: 0.05rem 0.8rem 0.1rem;
	line-height: inherit;
	font-family: 'Segoe-Regular';
	font-style: normal;
	margin-top: 5px;
	}
	
.badge_skill--info {
	background-color: #D1E8FF;
	}
	
.badge_skill--smaller {
	font-size: 13px;
	padding: 3px 10px ;
	}
.totalexp{
	width: 60%;
}
@media only screen and (max-width: 768px) {

.edit_left{
	margin-left: 12px!important;
}
.totalexp{
	width: 100%;
}
.badge_skill {
	
	margin-top: 5px;
	}
}
.rating_skill img {
	margin-right: 5px;
	cursor: pointer;
}

.subskill-badge {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #D1E8FF; 
	/* background-color: #e0e0e0; */
	transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-size: 13px;
	padding: 3px 0px;
	/* color: #01313C; */
	color: rgba(0, 0, 0, 0.87);
	border-radius: 3rem;
	text-align: center;
	font-weight: 400;
	line-height: inherit;
	font-family: 'Segoe-Regular';
	font-style: normal;
	margin-right: 10px;
	margin-bottom: 0.5rem;
}
.subskill-badge--list {
	display: flex;
	flex-wrap: wrap;
}
.subskill-badge > span {
	padding: 0px 12px;
	white-space: nowrap;
}
.subskill-badge > svg {
	cursor: pointer;
}

.subskill-badge > svg.MuiChip-deleteIcon:hover {
	color: rgba(0, 0, 0, 0.4) !important;
}

.subskill-badge > svg.MuiChip-deleteIcon.MuiChip-deleteIcon {
	color: rgba(0, 0, 0, 0.26);
	width: 22px;
	cursor: pointer;
	height: 22px;
	margin: 0 5px 0 -6px;
	-webkit-tap-highlight-color: transparent;
}

.add-new-btn {
	background: transparent;
	border: none;
	cursor: pointer,
}
.mn_normal{
	font-family: 'Manrope-Regular';
}
.skill_popup {
    width: 750px;
    height: 939px;
    margin: 30px auto;

}


.select {
     display: inline-block;
}
 .select .select-field input, .select .select-field label, .select .select-field input:checked ~ .select-placeholder {
     display: none;
}
 .select .select-field input:checked + label, .select .select-field .select-placeholder {
     display: block;
     font-size: 22px;
     font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
 .select .select-field label {
     border: 1px solid gray;
     padding: 5px;
     background: white;
}
 .select .select-options {
     animation: disable-options 0.5s;
     animation-fill-mode: both;
     opacity: 0;
     position: absolute;
     border: 1px solid gray;
     background: white;
}
 .select .select-options label {
     display: block;
}


 .select .select-options-focus {
     position: absolute;
     opacity: 0;
     pointer-events: none;
}
 .select .select-options-focus:focus + * {
     animation-name: enable-options;
     opacity: 1;
     

}
 @keyframes disable-options {
     0% {
         pointer-events: initial;
    }
     100% {
         pointer-events: none;
    }
}
 @keyframes enable-options {
     0% {
         pointer-events: none;
    }
     100% {
         pointer-events: initial;
    }
}
 
 form {
     width: 100%;
    
     
     align-items: center;
     justify-content: center;
     flex-direction: column;
     padding: 0px;
     box-sizing: border-box;
}
 legend {
     font-size:22px;
     margin-bottom: 17px;
      font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}

 .customized-select {
     font-family: sans-serif;
     font-size: 0.9em;
     position: relative;
     width: 100%;
}
 .customized-select label {
     cursor: pointer;
}
 .customized-select * {
     box-sizing: border-box;
}
 .customized-select .select-field label {
     background-color: #fff;
     border: 2px solid #A3BCD5;
     border-radius: 4px;
     overflow: hidden;
     padding: 15px 30px 15px 10px;
     background-image: url("https://image.flaticon.com/icons/svg/60/60995.svg");
     background-size: 10px;
     background-repeat: no-repeat;
     background-position: calc(100% - 10px) 50%;
     animation: bounce 300ms cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

 
 .customized-select .select-options {
     transition: all 200ms ease-out;
     top: 100%;
     left: 0;
     min-width: 100%;
     margin-top: 0px;
     background: #fff;
     height: 240px;
     overflow-y: scroll;
     border: 2px solid #2896FF;
     border-radius: 3px;
     z-index: 999
}
 .customized-select .select-options label {
     padding: 13px 10px;
     font-size: 22px;
      font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
 .customized-select .select-options label:hover {
     background: #DDEAF6;
     padding: 13px 10px;

}
.dropdown_width_outer {
    display: flex;
    justify-content: space-between;

}
.dropdown_width {
    width: 48.5%;
}

.text_box_cleartalent 
{
    width: 94%;
    height: 45px;
    padding: 0px 0px;
    font-size: 14px;
   /* line-height: 1.42857143;*/
  padding: 0px 20px;
    font-size: 16px;
  
  font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
}
.text_box_cleartalent:focus {
        outline-color:#2d2d2d
}
.text_box_cleartalent::placeholder {
  color: #01313C;
  opacity: 1; /* Firefox */

 padding: 15px 30px 15px 10px;
  margin-top: 1px;
  font-size: 16px;
  
  font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    white-space: normal;
word-wrap: break-word;

}


.rating_outer {
    height: 30px;
    
    width: 100%;
    display: flex;
}
.rating_skill {
    border: none;

}

.rating_skill>input {
    display: none;
}

.rating_skill>label:before {
    margin-right: 7px;
    font-size: 1.55em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating_skill>label:first-child:before {
    margin-right: 0px;
    font-size: 1.55em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating_skill>.half:before {
    content: "\f089";
    position: absolute;
}

.rating_skill>label {
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D1E8FF;
    float: right;
}


/***** CSS Magic to Highlight Stars on Hover *****/

.rating_skill>input:checked~label
{
    color: #1A8FFF;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D1E8FF;
}
.rating_skill_labelactive
{
    color: #1A8FFF !important;
    -webkit-text-stroke-width: 1px !important;
    -webkit-text-stroke-color: #D1E8FF !important;
}

/* show gold star when clicked */

.rating_skill:not(:checked)>label:hover,

/* hover current star */

.rating_skill:not(:checked)>label:hover~label {
    color: #1A8FFF;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D1E8FF;
}


/* hover previous stars in list */

.rating_skill>input:checked+label:hover,

/* hover current star when changing rating */

.rating_skill>input:checked~label:hover,
.rating_skill>label:hover~input:checked~label,

/* lighten current selection */

.rating_skill>input:checked~label:hover~label {
    color: #1A8FFF;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D1E8FF;
}

.ReactModal__Overlay {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    overflow-y: scroll !important;
}

.adddaytime_modal {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 750px !important;
    margin: 30px auto !important;
    position: absolute !important;
}

.modal_popup_close {
    position: absolute;
    top: 24px;
    right: 24px;
}
.dropdown_style
{
    width: 100%;
    padding: 20px;
    border: solid 2px #A3BCD5!important;
    font-size: 16px;
    font-family: 'Segoe-Regular';
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    padding: 15px 30px 15px 10px!important;
    font-style: normal;
    font-weight: 600;
    color: #01313C;
}
.btn-trash
{
    background-color: #ff3333;
    color:#fff;
    border:1px solid #ff3333;
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
.adddaytime_modal {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 100% !important;
    margin: 30px auto !important;
    position: absolute !important;
    /* margin-top: 200px!important; */
    
    
}
.addDay_time {
    width: 100%;
}
}

@media (min-width: 768px) and (max-width: 1435px){
    .dropdown_width{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

.subskill-badge--list {
    display: flex;
    flex-wrap: wrap;
}
.individual-additional-skill {
    border: 1px solid #A3BCD5;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
  }
.kHmnKJ {
  min-height: 484px;
}
.btn-blue {
  color: #01313C;
    background-color: #D1E8FF
}
.dloCfB {
  display: none;
}
#video-intro-element {
  height: 454px;
  width: 98%;
  transform: rotateY(180deg);
  object-fit: cover;
}
#video-intro-playback-element {
  height: 454px;
  width: 98%;
  object-fit: cover;
}
.skill_video_sec_left.skill-test-left {
  width: 100% !important;
}
.skill_video_sec_right.skill-test-right {
  width: 50% !important;
}
.preview-video-intro {
  width: 100%;
  object-fit: cover;
  height: 90px;
}
.tipsRecord_btn{
  margin-left: 10px;
  background-color:rgba(209,232,255,0.5);
  border-color: rgba(163,188,213,0.5);
}
@media only screen and (max-width: 768px) {
  #video-intro-element {
    height: 454px;
    width: 100%;
    transform: rotateY(180deg);
    object-fit: cover;
  }
  .tipsRecord_btn{
    margin-left: 0px;
    background-color:rgba(209,232,255,0.5);
  border-color: rgba(163,188,213,0.5);
  }
button:hover {
  cursor: pointer;
}
}

.record-intro-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
}
.record-intro-title span{
  padding-right: 4em; 
  font-family: 'Segoe-Semibold';
}
.record-video-timer-wrapper {
  display: flex;
}
.record-intro-title .react-timer {
  display: flex !important;
  margin-left: 1rem !important;
}

.skill_video_sec_right.available-video-intro-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 2rem;
  margin-top: 20px;
}
.available-video-tipsRecord_btn {
  background-color:rgba(209,232,255,0.5);
  border-color: rgba(163,188,213,0.5);
}

video.preview-video-intro::-webkit-media-controls{
  display: none;
  -webkit-appearance: none;
}
.recorded-video-intro {
  width: 100%;
}
/* .MediaStreamView-video-FilePicker {
  width: 100%;
} */

.circular-progress-record-video {
  position: absolute;
  z-index: 99999999;
  top: 50%;
  left: 45%;
}
.react-file-picker {
  display: flex;
}

@media (max-width: 700px) {
  .record-intro-title {
    flex-wrap: wrap;
  }
  .record-video-timer-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .record-intro-title span{
    padding-right: 0;
  }
  .record-video-intro-responsive button {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 768px) {
  .skill_video_sec_right.available-video-intro-right {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.video-intro-heading.MuiDialogTitle-root {
  padding: 16px 27px !important;
}
@media (max-width: 767px) {
  .record-video-intro-responsive .MuiDialog-paperFullWidth {
    width: 100% !important;
    margin: 15px !important;
  }
  .record-video-intro-responsive .crossFormodal {
    right: 22px !important;
  }
}


@media(min-width: 1367px) {
  .record-video-intro-responsive .video-intro-btn-container {
    padding: 24px 42px 24px 42px
  }
  .MediaStreamView-video-FilePicker{
    width:98%
  }
}
@media(max-width: 1366px) {
  .record-video-intro-responsive .video-intro-btn-container {
   padding: 24px 55px 24px 42px;
  }
  .MediaStreamView-video-FilePicker{
    width: 98%;
  }
}


.seperate_border {
  border: 1px solid #e4e4e4;
  padding: 10px;
  border-radius: 4px;
}




.text-center {
text-align: center;
}

.text-underline {
text-decoration: underline;
}
.video_section_arrow {
color: #007bff;
margin-right: 10px;
justify-content: center;
flex-direction: row;
}

.video_section_box {
color: #007bff;
margin-right: 8px;
font-size: 10px;
display: flex;
justify-content: center;
flex-direction: row;
align-items: center;
}
.ml_30 {
margin-left: 30px;
}

.video_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.videotimer_count_text {
  color: #ffffff;
  font-size: 110px;
  font-family: 'Segoe-Regular';   
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #001727a1;
  padding: 0px 50px;
  border-radius: 50%;  
  box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 33%);
}
.md400{
  max-width: 400px;
}
.mb2vh
{
  margin-bottom: 2vh;
}
.delbutton{
  background-color:red ;
  color: white;
}

/* Rectangle 7 */
.retake_button
{
  width: 100%;
  /* margin-left: 40px; */
  /* justify-content: center; */
/* position: absolute; */
width: 199px;
height: 35px;

border-radius: 4px;


background: #d1e8ff;
border: none;
}


.skill_video_sec_left {
  width: 37%;
}

.video-interview-chkbox {
  display: block;
  margin-bottom: 23px;
  margin-top: 10px;
}

.skill_video_sec_right {
  width: 63%;
}

.video-interview-chkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.video-interview-chkbox label {
  position: relative;
  cursor: pointer;
  font-weight: 600;
  font-family: "Segoe-Semibold";
  font-size: 16px;
  color: #01313c;
}

.video-interview-chkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #2896ff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 11px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 7px;
}

.video-interview-chkbox input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 5px;
  height: 12px;
  border: 2px solid #2896ff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.video-interview-chkbox :checked+label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #1a8fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 11px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

.skill_video_next_text {
  color: #a3bcd5;
}

.fs-30 {
  font-size: 30px;
}

.skill_video_next {
  display: flex;
  justify-content: flex-end;
}

.scrollHeight1 {
  overflow-y: scroll;
}

.interview-question-scroll::-webkit-scrollbar {
  width: 5px;
}

/* .interview-question-scroll::-webkit-scrollbar-track {
	background: #c6c6c6;
  width: 2px
} */

.interview-question-scroll::-webkit-scrollbar-thumb {
  background-color: #a5a4a4;
  border-radius: 30px;
}

.next-button {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.individual-skill {
  border: 1px solid #d1e8ff;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 14px 20px 14px 20px;
  margin-top: 10px;
}

.individual-skill-video {
  border: 1px solid #A3BCD5;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.myskill-right {
  text-align: right;
}

/*------------- CHECKBOX ---------------*/

.interview-chkbox {
  display: block;
  margin-bottom: 23px;
  margin-top: 23px;
}

.interview-chkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.interview-chkbox label {
  position: relative;
  cursor: pointer;
  font-family: "Segoe-Regular";
  font-size: 18px;
  color: #01313c;
}

.interview-chkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #01313c;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

.interview-chkbox input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #1a8fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.interview-chkbox :checked+label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #1a8fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

/*------------- CHECKBOX ---------------*/

/*------------- RADIO BUTTON ---------------*/

.clear [type="radio"]:checked, .clear [type="radio"]:not(:checked) {
  position: absolute;
  visibility: hidden;
}

.clear [type="radio"]:checked+label, .clear [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 43px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  color: #01313c;
  margin-top: 25px;
}

.clear [type="radio"]:checked+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #1a8fff;
  border-radius: 100%;
  background: #fff;
}

.clear [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #01313c;
  border-radius: 100%;
  background: #fff;
}

.clear [type="radio"]:checked+label:after, .clear [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: #1a8fff;
  position: absolute;
  border: 1px solid #1a8fff;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.clear [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.clear [type="radio"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

/*------------- RADIO BUTTON ---------------*/

/*------------- STEP CSS ---------------*/

.bs-stepper .step-trigger {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.bs-stepper .step-trigger:focus {
  outline: none;
}

.bs-stepper-label {
  display: inline-block;
  margin: 0.25rem;
}

.bs-stepper-header {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bs-stepper-line, .bs-stepper .line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: 9px;
  background-color: #a3bcd5;
}

.success_line {
  background-color: #2ad156;
}

.bs-stepper-circle {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  color: #adb5bd;
  border: 1px solid #a3bcd5;
  border-radius: 100%;
  line-height: 37px;
  font-size: 16px;
  background-color: #fff;
  font-weight: normal;
  font-family: "Segoe-Regular";
  outline: none;
}

.active .bs-stepper-circle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.success .bs-stepper-circle {
  color: #fff;
  background-color: #2ad156;
  border-color: #2ad156;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.bs-stepper-content {
  padding: 0 20px 20px;
}

.bs-stepper.vertical {
  display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane, .bs-stepper.vertical .content {
  display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade), .bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden;
}

.bs-stepper-pane:not(.fade), .bs-stepper .content:not(.fade) {
  display: none;
}

.bs-stepper .content.fade, .bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
}

.bs-stepper-pane.fade.active, .bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1;
}

.bs-stepper-pane.active:not(.fade), .bs-stepper .content.active:not(.fade) {
  display: block;
  visibility: visible;
}

.bs-stepper-pane.dstepper-block, .bs-stepper .content.dstepper-block {
  display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none, .bs-stepper:not(.vertical) .content.dstepper-none {
  display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none, .vertical .content.fade.dstepper-none {
  visibility: hidden;
}

.wb {
  word-break: break-word;
}

.link_color {
  color: #007bb5;
}

/*------------- STEP CSS ---------------*/

/*------------- VIDEO INTERVIEW QUESTION ---------------*/

.flex-video-bottom {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
}

.videosection {
  width: 100%;
}

.vl_video {
  border-left: 1px solid #a3bcd5;
  height: 267px;
  /* height: 328px; */
  margin-left: 12px;
  margin-top: 0px;
}

.report_question {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-right: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
}

/*------------- VIDEO INTERVIEW QUESTION ---------------*/

.cross_size {
  width: 16px;
}

/*------------- VIDEO INTERVIEW TOP ---------------*/

.playButton {
  width: 45px;
  height: 45px;
}

.playButtonother {
  width: 45px;
  height: 45px;
}

.flex-video-top {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

/*------------- VIDEO INTERVIEW TOP ---------------*/

.mainbg {
  background-color: #eeeeee;
}

.skill_video_popup {
  width: 800px;
}

.video_top_text {
  justify-content: space-between;
}

.ReactModal__Overlay {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  overflow-y: scroll;
}

.skillvideo_modal {
  /* background: rgb(255, 255, 255) !important;
  overflow: auto !important;
  border-radius: 4px !important;
  outline: none !important;
  width: 800px !important;

  position: absolute !important; */
  background: rgb(255, 255, 255) !important;
  border-radius: 4px !important;
  outline: none !important;
  width: 815px;
  position: relative;
  padding: 0px 20px 0px 20px;
  overflow-y: scroll;
  height: 650px;
}

.time_icon {
  vertical-align: middle;
  padding-right: 8px;
}

/*------------- IMAGE ---------------*/

.btn-icon {
  vertical-align: middle;
  padding-left: 6px;
}

.video_icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAARCAYAAAAsT9czAAAABHNCSVQICAgIfAhkiAAAASRJREFUOE/tlcFRAkEQRd9fPalVEgJmMNwUqRIjkAzECMQIdonAzUAyEauAK2sGmgEevPKtXSxBxAvLenKOUzX9pnu634gQanAYg7oSNbZYNjPwAN77ZNnstxAiNFOkNlZvC87yiJxiD8kmP+OE0zrsX4nG+QyrQzYaloKFsw5E92Tjk684BWQvltTN96RGyzaX5WGttsSjpyMtnuboFsizfIb5QIoedg+z70DxAkJSJBEWF9k9DL9iJ2STwbKcVcHyMq6vyjL7h5Wbs5XW/7M3s19AN9/mtrIGMX3kHtYU6Fc7Z0uD9CRiOxe0nwqD7NSNilKm4/qaGxNJ14UbC+ujCxSVs77naZ7BZus3A6j9Kc2DBKkrdLxNRxq/kZdr0/eyEvAD797TUbPgNR4AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  width: 27px;
  height: 17px;
}

.video_icon:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAARCAYAAAAsT9czAAAABHNCSVQICAgIfAhkiAAAAP5JREFUOE/tldFtAjEQRN9UEEpIOkgHhApCB4EKCBUEKsh1EDoBKkB0AB2QChYN2OiSXCQEvh/Efq1Ovn32eHesiOgAH8AAcH5J7IAZMJXkvDEUERXwArxfQqn94zoLSX/qRMQj8GqYd9KXtLgGFhF94FPSU66TIFk1DAugVwBmdeaSXNPXMUpqrZPEX8VhwDj1gCETHyIijhspfTJgmyBumEO0BrOMv+/+Djt7Gk5S3ayMwAYY1ue2tQaxP6ZhXiWvbG/Oag5ij7RVed6WwMFBSnpjJcmmm4fZ+QR484fs+t1Crr/8x/Wf/bJk0zTd79nD2b3+c+G35WoC1ZftARGb1rdzuWzUAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  width: 27px;
  height: 17px;
}

.arrow_icon {
  vertical-align: middle;
  margin-left: 10px;
}

.btnquestion {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 4px 10px 2px 10px;
  font-size: 16px;
  border-radius: 30px;
  font-family: "Segoe-Bold";
  width: 100%;
}

.btnquestion-questionselected {
  color: #fff;
  background-color: #1a8fff;
  outline: none;
}

.btnquestion-questionselected:hover {
  color: #fff;
  background-color: #1a8fff;
  outline: none;
}

.btnquestion-questionright {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #2ad156;
  outline: none;
}

.btnquestion-questionright:hover {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #2ad156;
  outline: none;
}

.btnquestion-questionwrong {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #ff4141;
  outline: none;
}

.btnquestion-questionwrong:hover {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #ff4141;
  outline: none;
}

.btnquestion-questiondisable {
  color: #a3bcd5;
  background-color: transparent;
  border: 1px solid #a3bcd5;
  outline: none;
}

.btnquestion-questiondisable:hover {
  color: #a3bcd5;
  background-color: transparent;
  border: 1px solid #a3bcd5;
  outline: none;
}

.modal_popup_close {
  position: absolute;
  top: 13px !important;
  right: 24px !important;
}

.alice-carousel__next-btn {
  position: absolute;
  top: -17px;
  right: 15px;
}

.alice-carousel__prev-btn {
  position: absolute;
  top: -17px;
  left: -5px;
}

.alice-carousel__next-btn-item {
  position: absolute;
  right: -16px;
  top: -2px;
}

.alice-carousel__prev-btn-item {
  position: absolute;
  left: -16px;
  top: -2px;
}

.alice-carousel__dots {
  display: none;
}

.alice-carousel__prev-btn [data-area]::after {
  position: relative;
  /* content: attr(data-area);  */
  content: "\f104" !important;
  font-size: 34px !important;
  text-transform: capitalize;
  font-family: FontAwesome !important;
}

.alice-carousel__next-btn [data-area]::after {
  position: relative;
  /* content: attr(data-area);  */
  content: "\f105" !important;
  font-size: 34px !important;
  text-transform: capitalize;
  font-family: FontAwesome !important;
}

.skillvideocodingtest {
  margin-top: 0px;
}

.videobuttomquestion {
  display: flex;
}

.playbuttonsection {
  display: flex;
}

.scorecardsection {
  display: flex;
}

.text-left_special {
  margin-left: 25px;
}

.res_left {
  margin-left: 41px;
}

.popup {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 40px 10px 40px;
}

.video-interview-test {
  object-fit: cover;
  width: 100% !important;
}

.skill_Pagination_adjust {
  height: 40px;
}

.skillvideo_btm_adjust {
  margin-bottom: 40px;
}

.add_email_box_skillvideo_test {
  width: 74%;
  position: relative;
  display: flex;
}

.add_email_box_skillvideo_test .search-input {
  width: 100%;
  padding: 10px;
  height: 45px;
  border: 1px solid #A3BCD5;
  border-radius: 4px 0 0 4px;
  border-right: none;
  outline: none;
  font-size: 16px;
  color: #000;
  background-color: rgba(209, 232, 255, 0.5);
  font-family: 'Segoe-Regular';
}

.add_email_box_skillvideo_test .search-input:focus {
  box-shadow: none;
  border: 1px solid #A5A4A4;
}

.add_email_box_skillvideo_test .search-input::placeholder {
  font-size: 16px;
  color: #01313c;
  font-family: 'Segoe-Regular';
}

.add_email_box_skillvideo_test .search-button {
  text-align: center;
  height: 45px;
  padding: 0px 20px;
  outline: none;
  cursor: pointer;
  border: 1px solid #2ad156;
  border-radius: 0 4px 4px 0;
  border-left: none;
  background: #2ad156;
  font-size: 16px;
  border-left: 1px solid #A5A4A4;
  font-family: 'Segoe-Semibold';
  color: #fff;
  white-space: nowrap;
}

.skill_video_test_btnsection {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .alice-carousel__next-btn {
    display: none;
  }
  .alice-carousel__prev-btn {
    display: none;
  }
  .add_email_box_skillvideo_test .search-button {
    text-align: center;
    height: 45px;
    padding: 0px 20px;
    outline: none;
    cursor: pointer;
    border: 1px solid #2ad156;
    border-radius: 0 4px 4px 0;
    border-left: none;
    background: #2ad156;
    font-size: 16px;
    border-left: 1px solid #A5A4A4;
    font-family: 'Segoe-Semibold';
    color: #fff;
    white-space: nowrap;
  }
  .skill_video_test_btnsection {
    display: block;
    justify-content: space-between;
  }
  .skill_video_test_end {
    display: block;
    justify-content: flex-end;
  }
  .add_email_box_skillvideo_test {
    width: 100%;
    position: relative;
    display: flex;
    margin-bottom: 10px;
  }
  .res_left {
    margin-left: 24px;
  }
  .text-left_special {
    margin-left: 0px;
  }
  .skill_video_width {
    width: 100%;
    flex-wrap: wrap;
  }
  .skill_video_sec_left {
    width: 100%;
  }
  .skill_video_sec_right {
    width: 100%;
  }
  .skillvideocodingtest {
    margin-top: 11px;
  }
  .skillvideo_modal {
    background: transparent !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 100% !important;
    position: absolute !important;
    margin-top: 0px !important;
    padding: 0px;
  }
  .skill_video_popup {
    width: 100%;
    /* margin-top: 212px; */
  }
  .mw {
    width: 100%;
  }
  .vl {
    border-left: 1px solid #a3bcd5;
    height: 327px;
    margin-left: 12px;
    margin-top: 0px;
    display: none;
  }
  .videobuttomquestion {
    display: flex;
    flex-wrap: unset;
  }
  .playbuttonsection {
    display: flex;
    flex-wrap: unset;
  }
  .scorecardsection {
    display: initial;
    flex-wrap: unset;
  }
  .ml_41 {
    margin-left: 0px;
  }
}

.skill_video_sec_right {
  width: 100%;
}

.skill_video_sec_right.ml_22.skillvideocodingtest {
  margin-left: 0 !important;
}

.questionoption.wrong-question-option [type="radio"]:checked+label {
  color: #ff4141;
}

.questionoption.wrong-question-option [type="radio"]:checked+label:after {
  background: #ff4141;
  border: 1px solid #ff4141;
}

.questionoption.wrong-question-option [type="radio"]:checked+label:before {
  border: 2px solid #ff4141;
}

.questionoption.right-question-option [type="radio"]:checked+label:after {
  background: #2ad156;
  border: 2px solid #2ad156;
  top: 7px;
  left: 7px;
}

.questionoption.right-question-option [type="radio"]:checked+label:before {
  border: 2px solid #2ad156;
}

.questionoption.right-question-option [type="radio"]:checked+label {
  color: #2ad156;
}

/* .skillvideo_modal {
  overflow: initial !important;
} */

.videosection.mt_17 {
  object-fit: cover;
}

.video-interview-test>video {
  object-fit: cover;
  /* width: 360px !important;
  height: 268px !important; */
  width: 100% !important;
  height: 100% !important;
}

.right-answer {
  color: #2ad156;
}

.wrong-answer {
  color: #ff4141;
}

.interview-chkbox>input.right-checkbox[type="checkbox"]:checked+label:after {
  /* background: #2ad156;
  border: 2px solid #2ad156; */
  border: solid #2ad156;
  content: "";
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.interview-chkbox>input.wrong-checkbox[type="checkbox"]:checked+label:after {
  /* background:#ff4141;
  border: 2px solid #ff4141; */
  border: solid #ff4141;
  content: "";
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.interview-chkbox>input.right-checkbox[type="checkbox"]:checked+label {
  color: #2ad156;
}

.interview-chkbox>input.wrong-checkbox[type="checkbox"]:checked+label {
  color: #ff4141;
}

.interview-chkbox>input.right-checkbox[type="checkbox"]:checked+label:before {
  border: 2px solid #2ad156;
}

.interview-chkbox>input.wrong-checkbox[type="checkbox"]:checked+label:before {
  border: 2px solid#ff4141;
}

.interview-chkbox input.wrong-checkbox:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #ff4141;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.interview-chkbox input.right-checkbox:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #2ad156;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.alice-carousel__prev-btn-item {
  top: 10px;
}

.alice-carousel__next-btn-item {
  top: 10px;
}

.fullscreen-width .MuiDialog-paperFullScreen {
  max-width: 100% !important;
  padding: 0px 35px;
}

.fullscreen-width .alice-carousel__next-btn-item {
  right: 14px;
}

/* .fullscreen-width .MuiDialogContent-dividers {
  overflow-x:hidden ;
} */

.fullscreen-width .alice-carousel__wrapper {
  width: 94%;
  margin: 0 auto;
}

.fullscreen-width .MuiDialogContent-root {
  overflow-x: hidden;
}

.fullscreen-width .alice-carousel__prev-btn-item {
  left: 12px;
}

.fullscreen-width .MuiDialogTitle-root {
  padding: 16px 0px im !important
}

.skill-video-crossFormodal {
  text-align: right;
  margin: 15px 0px;
  cursor: pointer;
}

.skill-video-crossFormodal .closeicon_set {
  width: 20px;
}

.questionoption.questionoption-alignment [type="radio"]:checked+label, .questionoption.questionoption-alignment [type="radio"]:not(:checked)+label {
  padding-left: 0;
  display: flex;
  align-items: center;
}

.questionoption.questionoption-alignment [type="radio"]:not(:checked)+label:before {
  position: initial;
  margin-right: 1rem;
  flex-shrink: 0;
}

.questionoption.questionoption-alignment.right-question-option [type="radio"]:checked+label:before {
  position: initial;
  margin-right: 1rem;
  flex-shrink: 0;
}

.questionoption.questionoption-alignment.right-question-option [type="radio"]:checked+label:after {
  top: initial;
  left: 7px;
}

.questionoption.questionoption-alignment.wrong-question-option [type="radio"]:checked+label:before {
  margin-right: 1rem;
  position: inherit;
  flex-shrink: 0;
}

.questionoption.questionoption-alignment.wrong-question-option [type="radio"]:checked+label:after {
  left: 8px;
  top: inherit;
}

.questionoption-alignment .interview-chkbox>input.right-checkbox[type="checkbox"]:checked+label {
  color: #2ad156;
  display: flex;
  align-items: center;
}

.questionoption-alignment .interview-chkbox input.right-checkbox:checked+label:after {
  top: inherit;
  height: 15px;
}

.questionoption-alignment .interview-chkbox>input.wrong-checkbox[type="checkbox"]:checked+label {
  display: flex;
  align-items: center;
}

.questionoption-alignment .interview-chkbox input.wrong-checkbox:checked+label:after {
  top: inherit;
  height: 15px;
}

.questionoption-alignment .interview-chkbox label {
  display: flex;
  align-items: center;
}

.skill_video_test_deletemodal {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  border-radius: 4 px;
  padding: 10 px;
  margin-top: 10 px;
}

.skill_delete_radio [type="radio"]:checked, .skill_delete_radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.skill_delete_radio [type="radio"]:checked+label, .skill_delete_radio [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 43px;
  cursor: pointer;
  line-height: 30px;
  /* display: inline-block; */
  color: #01313C;
  /* margin-top: 25px; */
}

.skill_delete_radio [type="radio"]:checked+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #1A8FFF;
  border-radius: 100%;
  background: #fff;
}

.skill_delete_radio [type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #01313C;
  border-radius: 100%;
  background: #fff;
}

.skill_delete_radio [type="radio"]:checked+label:after, .skill_delete_radio [type="radio"]:not(:checked)+label:after {
  content: '';
  width: 16px;
  height: 16px;
  background: #1A8FFF;
  position: absolute;
  border: 1px solid #1A8FFF;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.skill_delete_radio [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.skill_delete_radio [type="radio"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

.btn_red {
  color: #fff;
  background-color: #FF4141;
}

.newskilltest_block_note {
  margin-top: 20px;
  margin-bottom: 20px;
  /* text-align: center; */
  font-style: italic;
  color: #ff0000;
  padding-right: 20px;
}
.test-status-wrapper{
  display: flex;
  flex-direction: row-reverse;
}
.report_question_button_alignment_wrapper{
  justify-content: space-between;
}
.btn-blue {
  color: #fff;
  background-color: #1a8fff;
}

#testQuestionDescp h2 {
  -webkit-margin-before: 0;
          margin-block-start: 0; /* or margin-block-start: initial; */
}
#testQuestionDescp h1 {
  -webkit-margin-before: 0;
          margin-block-start: 0; /* or margin-block-start: initial; */
}
.responsiveSkillTestInfo{
  display: none;
}
.skillTestResponsiveScore{
  display: none;
}
@media only screen and (max-width: 600px) {
  .test-status-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 1vh;
  }
  .responsiveSkillTestInfo{
    display: block !important;
  }
  .skillTestResponsiveScore{
    display: block !important;
  }
  .skillScore .skillTestResponsive{
    margin-left: 0 ;
  }
}
.powres_togle{
  display: flex;
  justify-content: space-between;
}
.last-update-text {
  /* background-color:rgba(209, 232, 255, 0.4); */
  /* width: 430px; */
  /* padding: 10px 10px 0px 10px; */
  /* margin: 0px auto; */
  /* border-top-left-radius: 6px;
  border-top-right-radius: 6px; */
  font-size: 15px;
}
.pb_12 {
  padding-bottom: 12px;
}
.PowerResume_Copy{
  font-size: 26px;

}
.powerResume_section{
  display: flex;
  justify-content:end;
  /* margin-top: 13vh; */

}
.profile_footer{
margin-top: 20px;
}


.footerflex{
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.card_top_box {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease-in-out;
  padding: 14px 20px 20px 20px;
  /* border-radius: 4px; */
  background-color: #ffffff;
  
}

@media only screen and (max-width: 768px) {
  .last-update-text {
    /* background-color:rgba(209, 232, 255, 0.4); */
    /* width: 100%; */
    padding: 10px;
    margin: 0px auto;
    /* border-top-left-radius: 6px;
    border-top-right-radius: 6px; */
  }
  
}

.mn_medium{
  font-family: 'Manrope-Medium';

}
.mn_bold{
  font-family: 'Manrope-Bold';

}
.mn_semibold{
  font-family: 'Manrope-semibold';

}
.powres_contain{
  display: flex;
  justify-content: space-between;

}
.pl_55{
  padding-left: 55px;
}
.powres_maincon{
  background-color: #ffff;
}

.powres_main{
  display: flex;
  flex-direction: row-reverse;
  /* margin-top: 14vh; */
  justify-content: space-between;
}

.pt_20
{
  padding-top: 20px;
}

.powres_maintop{
  max-width: 1200px;
  margin-top: 3vh;
}

.powres_fs_color{
  color:#1885E6 ;
}
.powres_fs{
  font-size: 37px;
}
.powres_inter_box{
  margin-left:20px ;
  max-width: 280px;

}
.powres_other
{
  height: 190px;
}
.powres_prim
{
  height: 130px;
}



.admindailog_title{
    padding: 16px 0px !important;
}
.admin_textbox{
    height: 46px;
}
.admindailog_title_modal{
    padding: 16px 26px !important;
}

.pageHead {
    margin: 10px 0px;
}

.leftGrid {
    border: 1px solid green;
    padding: 10px 5px;
}

.searchBtnCntnr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.filters {
    padding: 10px 10px 20px 10px;
}

.heading{
    font-family: 'Segoe UI';
    font-size: 16px;
    color: #04093F;
}

.subHead {
    font-size: 14px;
    font-family: "Segoe UI";
    opacity: 0.7;
    color: #43425D;
}

.kendo_candidateSearch_admin {
    height: 67vh;
}

@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
.admin_skill_topsection{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.admin_skill_leftsection{
    width: 30%;
    margin-left: 10px;
}
.admin_skill_rightsection{
    margin-bottom: 10px;
    margin-right: 10px;
}
.btnGreen {
    color: '#fff'!important;
    background-color: '#2AD156'!important;
    border: 'none'!important;
    padding: '7px 24px 9px 23px'!important;
    float: 'right'!important;
    top:"20px"!important
}

.tabs{
    display: 'block'
}
.btnGreen {
    color: '#fff'!important;
    background-color: '#2AD156'!important;
    border: 'none'!important;
    padding: '7px 24px 9px 23px'!important;
    float: 'right'!important;
    top:"20px"!important
}
.admin_subskill_topsection{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.admin_subskill_leftsection{
    width: 30%;
    margin-left: 10px;
}
.admin_subskill_rightsection{
    margin-bottom: 10px;
    margin-right: 10px;
}
.kw
{
    width: 100%;
}
.marginHeading
{
    margin-bottom: 0px;
    padding-bottom: 0px;
}
@media only screen and (max-width: 768px){

    .btnGreen {
        color: '#fff'!important;
        background-color: '#2AD156'!important;
        border: 'none'!important;
        padding: '7px 24px 9px 23px'!important;
        float: 'right'!important;
        
    }
    .admin_subskill_topsection{
        width: 100%;
        display: block;
        justify-content: space-between;
    }
    .admin_subskill_leftsection{
        width: 95%;
        margin-left: 10px;
    }

}

.actionContainer {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1280px)
  {
    .alignment
    {
        max-width: none !important;
        margin-top: 20px;
    }
  }

.mt_110{
    margin-top: 110px;
}
/*--------------- Header CSS -------------*/
  
.nav_bg_left {
  width: 25%;
  background-color: #ffffff;
}
.marb{
  margin-bottom: 14px;
}
/* .nav_bg_right {
  width: 58%;
  background-color: #04093f;
} */
.mail_txt{
  font-size: 16px;
  margin-left: 5px;
  margin-right: 4px;
  font-family: "Segoe-Regular";
  color: #01313c;
}
.mail_text {
  font-size: 18px;
  margin-left: 8px;
  font-family: "Segoe-Regular";
  color: #01313c;
}
.mail_close_icon {
  cursor: pointer;
}


.nav_logo {
  margin-top: 22px;
  margin-bottom: 16px;
  width: 100%;
}
.navbar {
  width: 100%;
  overflow: auto;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.navbar li {
  list-style-type: none;
}

.navbar li img {
  vertical-align: middle;
}

.link {
  float: left;
  padding: 7px 19px 0px 19px;
  /* color: #d1e8ff; */
  text-decoration: none;
  font-size: 16px;
  font-family: "Segoe-Regular";
}
.share_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.support_dialog_title{
  position: relative;
}

.support_dialog_title #para{
  font-size: 23px;
  font-family: 'Manrope-Regular';
  color: #666666;
}
.support_lin{
  border-bottom: 1px solid #6398F1;
  width: 368px;
  font-family: 'Manrope-Medium';
  font-size: 36px;
  color: #1885E6;
  height: 52px;
}
.support_con{
  padding-top: 0px !important;
  padding-right: 28px !important;
}
.support_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
}
.support_grid{
  padding-right: 20px;
}

.support_form_leb{
  font-family: 'Manrope-Medium';
  font-size: 16px;
  color:#666666;
}

.profile_support {
  margin-top: 5px;
}
.support_grid_message{
  margin-top: 20px !important;
  padding-bottom: 0px;
}

.support_message{
  height: 140px;
  width: 100%;
  border: 1.4px solid #707070;
  opacity: 0.6;
  border-radius: 3px;
}

.nav_menu_icon {
  padding-right: 5px;
}

.notify-badge {
  position: absolute;
  top: 5px;
  margin-left: 9px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  background: #2ad156;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: 8px;
}
.contact_header{
  font-weight: normal!important;
  font-family: 'Segoe-Light'!important;
  font-size: 68px!important;
  color: #01313C!important;
}
.MuiTypography-h6 {
  font-family: 'Segoe-Light'!important;
  font-size: 28px!important;
  color: #01313C!important;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 6px 24px;
}
.contact_details_heading{
  font-size: 20px!important;
  font-weight: 600!important;
  font-family: 'Segoe-Semibold'!important;
  color: #01313C!important;
}
/* @media only screen and (max-width: 768px) {
  .nav_logo {
    margin-top: 22px;
    margin-bottom: 16px;
    
    width: 80%;
  }

  .nav_bg_left {
    width: 100% !important;
    background-color: #FFFFFF;
  }
ma
  .nav_bg_right {
    width: 100% !important;
    background-color: #04093f;
  }

  .link {
    float: left;
    padding: 7px 4px 0px 4px !important;
    color: #d1e8ff;
    text-decoration: none;
    font-size: 16px;
    font-family: "Segoe-Regular";
  }
} */
.navbar-wrapper {
  display: flex;
  justify-content: flex-end;
}
.header-skeleton .MuiSkeleton-text {
  transform-origin: 0 0%;
}
.header-skeleton {
  height: 0;
}
.ml_3
{
  margin-left: 3px;
}

.menuContainer_header{
  /* margin-top: 20px;
    margin-right: 4px; */
    flex-grow: 0 !important;
}

.sharecontainer_header{
  display: flex;
  flex-direction: row;
}

.shareIcon_header{
  margin-right: 15px;
  color: #1885E6;
  margin-top: 7px;
}

.shareText_header{
  font-family: 'Segoe UI';
    font-size: 20px;
    color: #1885E6;
}

.menu_header{
  margin-top: 40px !important;
  margin-left: -1% !important;
 
}

.profile_drop_menu_header{
  position: absolute;
    top: 54px !important;
   right: 115px;
  
}

@media screen and (max-width: 1100px) {
  .profile_drop_menu_header{
    right: 4vw;
  }
}

.account_nav_header{
  margin-top: 20px;
}

.trackCandidate_header{
  height: 25px;
}


.cdProfileHeading {
    color: #1885E6;
    font-family: "Segoe UI";
    font-size: 29px;
    font-weight: 300;
    font-style: normal;
}

.updateStyle {
    font-family: "Segoe UI";
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    color: #01313c;
}

.profileHeading {
    margin-bottom: 10px;
}

.firstBlueContainer {
    display: flex;
    flex-direction: row;
    background: #d5e7fd;
    padding: 20px 0px;
    /* height: 21vh; */
    border-radius: 8px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
}

.resumeContainer_noresume{
    height: 60px;
    width: 83px;
}

.imgAndCVContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #1885E6;
    padding: 10px 12px;
}
.imgAndCVContainer_skeleton{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    border-right: 1px solid #1885E6;
    padding: 10px 12px; 
}

.only_candidate_profileImage_admin {
    width: 69px;
    height: 69px;
    border-radius: 50%;
}

.imageContainer {
    position: relative;
}

.camera_icon_profile_powerResume_admin {
    position: absolute;
    width: 25px;
    bottom: 0;
    right: 0px;
    padding-left: 10px;
}

.resumeContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 65px;
    margin-top: 15px;
}
.resumeContainer_Skeleton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 65px;
}

.viewresume_container_profilecomplete{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap:5px;
    gap:5px;
    cursor: pointer;
    font-family: 'Segoe-Semibold';
    font-size: 14px;
}

.fileImg {
    width: 22px;
}
.fileImg_resume{
    width: 30px;
    height: 30px;
    margin-left: 4px;
}

.detailsContainer {
    margin: 0px 8px;
}

.candidateName {
    /* font-family: "Segoe UI";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    color: #04093f; */
    font-size: 15px;
    font-weight: 700;
    font-family: SegoeUI-Bold, "Segoe UI";
    color: #04093f;
    line-height: 1.5;
}

.candidateDetails {
    
    font-size: 15px;
    font-weight: 500;
    font-family:  "Segoe-regular";
    padding-bottom: 5px;
    color: #04093f;
}

.viewText{
    font-size: 14px;
    font-family: "Segoe-semibold";
    padding-bottom: 5px;
    color: #04093f;
}

.candidate_data_value {
    font-family: "Segoe-regular";
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.candidate_data_value_header {
    font-family: "Manrope-light";
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.partitionContainer {
    /* margin-bottom: 10px; */
}
.partitionContainer_skeleton{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.skillsCardHeading {
    font-family: "Manrope";
    font-size: 23px;
    font-weight: 300;
    font-style: normal;
    color: #01313c;
    margin-bottom: 15px;
}

.cardsContainer {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    background-color: white;
}

.cardContent {
    /* margin-bottom: 25px; */
}



.skillsCardSubHeading {
    /* font-family: "Segoe UI";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    color: #01313c; */
    font-family: SegoeUI-Semibold, "Segoe UI";
    color: #01313c;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.0;
}

.skillCardCaptions {
    /* font-family: "Segoe UI";
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    color: #01313c; */
    font-family: "Segoe-semibold";
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #01313c;
}

.chipContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.chipStyle {
    /* border-radius: 30px;
    background: #d1e8ff;
    font-family: "Segoe UI";
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    color: #01313c;
    width: 70px;
    height: 20px;
    text-align: center;
    margin-right: 8px; */
    border-radius: 10px;
    background-color: #d1e8ff;
    transform: translate(-0.26px, -0.265px);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-top: 6px;
    text-align: center;
}

.chipstyle_skill_font{
    font-family: SegoeUI-Semibold, "Segoe UI";
    color: #01313c;
    font-size: 10px;
    font-weight: 600;
}

.cardStyle {
    background: white;
    margin: 20px;
    padding: 5px 10px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    border-radius: 4px;
}

.cardStyle_primarySkill{
    background: white;
    margin: 20px;
    padding: 5px 10px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    border-radius: 4px;
}

/* ::-webkit-scrollbar {
    width: 16px;
}

::-webkit-scrollbar-thumb {
    background-color : #1885E6
}

::-webkit-scrollbar-track {
    background-color: #ece2e2;
}

::-webkit-scrollbar-track:hover {
    background-color: #d1c8c8;
} */

/* @media (max-width: 1872px) {
    .candidateDetails {
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        color: #04093f;
        word-break: break-word;
    }
} */
/* 
@media (max-width: 1532px) {
    .candidateDetails {
        font-family: "Segoe UI";
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        color: #04093f;
        word-break: break-word;
    }

    .updateStyle {
        font-family: "Segoe UI";
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        color: #01313c;
    }
} */

/* @media (max-width: 1158px) {
    .candidateDetails {
        font-family: "Segoe UI";
        font-size: 9px;
        font-weight: normal;
        font-style: normal;
        color: #04093f;
        word-break: break-word;
    }    

    .candidateName {
        font-family: "Segoe UI";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        color: #04093f;
    }
} */



/* Previous css */

.candidate_specific_extra_padding {
    /* padding: 14px 8px 20px 8px; */
    padding-top: 20px;
}

.candidate_specific_card {
  
    background-color: #D5E7FD;
}
.candidate_specific_card_wd{
    /* width:100% */
    width: 100%;
    height: 246px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.candiate_profile_first_container {
    display: flex;
    align-items: flex-start;
}

.candiate_profile_first_container .candidate_profile_image {
    margin-right: 10px;
    /* Adjust spacing between image and text */
}

.candiate_profile_first_container::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: black;
    /* Change the color of the vertical line */
    margin-right: 10px;
    /* Adjust spacing between line and text */
}

.candidate_specific_content {
    /* font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 300;
    font-size: 12px; */
   
    display: flex;
    flex-direction: column;
    word-break: break-word;
    margin-left: 4px;
}
.text_font{
   font-family: "Segoe-Semibold";
    color:#04093F
}
.candidate_specific_20_bold{
    font-size: 20px;
    font-weight: 700;
}
.candidate_specific_15_semibold{
    font-size: 15px;
    font-weight: 600;
}
.candidate_specific_14_semibold{
    font-size: 14px;
    font-weight: 600;
}

.candidate_specific_vr_line{
    width: 1px;
    height: 228px;
    background-color: #1885E6;
    margin: 0 2px; /* Adjust spacing around the line */
}
.candidate_specific_br_word{
    word-break: break-word;
}

.mt_25{
    margin-top: 25px;
}
.mt_10{
    margin-top: 10px;
}
.padding_icon_txt{
    padding: 13px 0px 0px 20px;
}
.icon_style{
    width: 24;
    height: 24;
    margin-left: 19px;
}
@media (max-width: 767px) {
 
}
.morelesscount{
    font-family: 'Segoe-semibold';
    font-size: 12px;
    color: #1885E6;
    cursor: pointer;
    margin-top: 10px;
}
.morelesscount:hover{
    color: red;
}

.pencilIcon_profilecompletion{
    cursor: pointer;
    margin-left: 25px;
    margin-top: 10px;
}

.closeIconProfileComplete{
    position: absolute;
    right: 11px;
    top: 14px;
    color: grey;
    cursor: pointer;
}

.saveButtonProfileComplete{
    height: 40px;
    display: flex;
    background-color: #1885e6 !important;
    color: #ffffff;
    padding: 5px 30px;
    width: 135px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
}

.saveButtonProfileComplete:hover{
    opacity: 0.9;
}

.saveButtonProfileComplete_disabled{
    height: 40px;
    display: flex;
    background-color: #1885e6 !important;
    color: #ffffff;
    padding: 5px 30px;
    width: 135px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    grid-gap: 10px;
    gap: 10px;
}

.cancelButton_profileComplete{ 
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* border: 1px solid transparent; */
    height: 40px;
    width: 135px;
    font-size: 18px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    cursor: pointer;
}

.cancelButton_profileComplete:hover {
    background-color: #f5f5f5;
}
.saveButtonProfileComplete_disabled .MuiCircularProgress-root {
    color: #FFFFFF !important;
    height: 18px !important;
    width: 18px !important;
    margin-top: 2px;
}

.candidateProfilecontainer_pencil{
    display: flex;
    flex-direction: row;
}

.dialogContainer{
    width: 100%;
}

.addressContainer{
    display: flex;
    flex-direction: row;
}

.inputLabel_right {
    font-size: 14px;
    font-family: 'Manrope-Regular';
}

.itemContainer{
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}

.compensationcontainer{
    width: 100%;
    display: flex;
}
.admin_textbox_currency_profileComplete{
    height: 46px;
    width: 160px;
}

.currency_CompensationContainer{
    display: flex;
    flex-direction: row;
}

.currncy_compensationstyle1{
    height: 46px;
    width: 160px !important;
}

.currncy_compensationstyle2{
    height: 46px;
    width: 160px !important;
    margin-left: 5px;
}

.locationitemcontainer_profilecomplete{
    width: 220px;
}

.locationItemsContainer{
    display: flex;
    flex-direction: column;
}

.locationContainer_profilecomplete{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dialogContainer_phone .PhoneInputInput {
    border: none; 
    outline: none;
}

.dialogContainer_name .input[type="text"]{
    padding: 0px !important;
}

.dialogContainer_email .input[type="text"]{
    padding: 0px !important;
}





.mt-15{
    margin-top: 15px;
}

.uploadcontainer_profile_complete{
    display: flex;
    flex-direction: column;
}

.uploadFuctionContainer_conditional_profileComplete{
    text-align: center;
    margin-top: 20px;
}

.candidate_filepicker_button_profileComplete{
    height: 37px;
    background-color: #05A6F4 !important;
    color: white !important;
}

.new_candidate_icon_profilecomplete{
    width: 40px;
    height: 40px;
}

.uploadFuctionCon_profileComplete{
    display: flex;
    justify-content: center;
}

.uploadResume_profileComplete{
    font-family: 'Segoe-semibold';
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
}

.candidate_filepicker_button_profileCompleted_noresume{
    background-color: #05A6F4 !important;
    color: white !important;
    padding: 5px 2px !important;
    font-size: 13px !important;
    width: 88px !important;
    margin-top: 26px !important;
}

.candidate_filepicker_button_profileComplete{
    background-color: #05A6F4 !important;
    color: white !important;
    padding: 10px 5px !important;
    font-size: 13px !important;
    width: 88px !important;
    height: 50px;
    margin-top: 14px !important;
}
.candidateHistoryDiv{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 15px;
}

.candidateHistoryDiv .MuiButton-containedPrimary{
    background-color: #05A6F4;
    width: 95%;
}

.candidateHistoryDiv .MuiButton-containedPrimary:hover{
    background-color: #0598e1;
}

.candidateHistoryDiv .MuiButton-label{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    font-size: 15px;
}
.uploadFuctionContainer {
    text-align: center;
    margin-top: 5vh;
}

.uploadFuctionContainer_conditional{
    text-align: center;
    margin-top: 100px;
}

.fileUploadText {
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    color: #4d4f5c;
}

.mb_10 {
    margin-bottom: 10px;
}

.cdProfileHeading_newProfile {
    color: #1885E6;
    font-family: "Segoe UI";
    font-size: 29px;
    font-weight: 300;
    font-style: normal;
}

.uploadBtn {
    width: 92px;
    height: 21px;
    border: 0px;
    background: #05a6f4;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    color: #ffffff;
    border-radius: 4px;
}

.newCandidateProfile_top_align {
    margin-top: 20vh;
    /* text-align: left; */
}

.drag_drop_margin {
    margin-top: 20px;
    margin-left: 30px; 
    /* margin-left: 62px */
}

.drag_drop_file {
    font-size: 11px;
    font-family: SegoeUI, "Segoe UI"
}

.color_4d4f5c {
    color: #a9aab3;
}

.image_in_center {
    display: block;
    /* margin-left: auto;
    margin-right: auto; */
}

.font_button_css {
    background-color: #1885E6;
    width: 150px;
    height: 45px;
    color: #FFFFFF;
    border: none;
    outline: none;
    font-size:9px;
    font-family:SegoeUI, "Segoe UI";
    border-radius: 4px;
}


.chooseFile{
    margin-top: 6px;
    margin-left: 50px;
}

.font_300_29_Semilight{
    font-size:29px;
    font-weight:300;
    font-family: SegoeUI-Semilight, "Segoe UI";
    color:#1885e6
}

@media only screen and (max-width: 768px) {
    .newCandidateProfile_top_align {
        margin-top: 11vh;
        /* text-align: left; */
    }
   
}

/* .text_in_center{
    display: block;
    margin-left: auto;
    margin-right: auto;   
} */

.new_candidate_icon{
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
}

.candidate_filepicker_button{
    background-color: #05A6F4 !important;
    color: white !important;
}

.candidate_filepicker_button_disable{
    background-color: #05A6F4 !important;
    color: white !important;
    opacity: 50%;
}

.uploadFuctionCon{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
}

.candidate_resume_logo{
    padding-left: 20px;
    padding-top: 11px;
}

.new_candidate_icon_download{
    width: 20px;
    height: 20px;
}

.close_icon_newCandidate{
    position: relative;
    top: -10px;
    left: 38px;
    cursor: pointer;
}

.candidateFileExtention{
    position: relative;
    top: 4px;
    left: -14px;
}
.fileUploadText{
    font-family: 'Segoe-semibold';
    font-size: 12px;
}

.container11 {
  /* position: relative; */
  width: 100%;
  max-width: 1095px;
  /* height: 647px; */

}

.navStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.threeCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.middleContainer {
  width: 36vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card1Styles {
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 32%;
}

.replaceFileBtn {
  font-size: 12px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
  border: 0px;
  width: 10vw;
  height: 4vh;
  background-color: #d1e8ff !important;
  color: #01313C !important;
}
.leftSide_poweResumeInfoCard{
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 5px;
}
.topContainerone_leftSide_poweResumeInfoCard .MuiButton-root.Mui-disabled{
  color: #01313C !important;
}

.testimonialBtn {
  font-size: 12px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
  border: 0px;
  width: 10vw;
  height: 4vh;
  background-color: white;
  border: 1px solid #A3BCD5;
}

.primarySkillContainer {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 25px;
  border-radius: 5px;
}

.primarySkillCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */

}

.pSkillHeading {
  margin-bottom: 25px;
}

.calender {
  margin-left: 20px;
}

.primarySkill_rightside {
  text-align: end;
}

.power-resume-text {
  float: right;
  top: 80px;
  margin-top: -22px;
  width: 100px;

}

.small-rectangle {
  /* position: relative;  */
  top: 5px;
  /* left: 9px; */
  /* width: 230px; */
  /* height: 87px; */
  background-color: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none;
  height: auto;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.4);
  /* shadow without bottom */
  padding: 1px 20px;
  /* box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2); */

}

.left_small_div_wd {
  width: 23%;
  /* height: 68px; */
}

.right_div_txt {
  /* text-align: right; */
  /* padding-top: 16px */
}

.right_small_div_wd {
  /* width: 77% */
}

.large-rectangle {
  /* margin-top: -3px; */
  /* position: relative;  */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* width: 50vw; */
  justify-content: space-between;
  top: -19px;
  left: 0;
  /* width: 100%;
    max-width: 1095px; */
  /* height: 647px; */
  background-color: #fff;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  /* padding-top: 18px; */
  padding: 20px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
}

.large-rectangle-padding-left {
  padding-left: 25px
}

.mg_lft_25 {
  margin-left: 25px;
}

.large-rectangle::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2); */
  ;
  z-index: -1;
}

.profileImage_powerResume {
  /* width: 58px; */
  height: 54px;
  border-radius: 50%;
}

.camera_icon_profile_powerResume {
  position: absolute;
  width: 13px;
  height: 13px;
  bottom: 0;
  right: 0px;
  top: 0;
  padding-left: 10px;
}

.powerresume_first_container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.powerresume_first_container .poweresume_profile_image {
  margin-right: 10px;
  padding-top: 6px;
  /* Adjust spacing between image and text */
}

.powerResume_specific_content {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  margin-left: 12px;
  /* padding-top: 15px; */

}

.font_12_bold_segoe {
  font-size: 12px;
  font-weight: 700;
  font-family: SegoeUI-Bold, "Segoe UI";
  color: #04093F;
  line-height: 2.0;
}

.font_12_semibold_segoe {
  font-size: 12px;
  font-weight: 600;
  font-family: "Segoe-Semibold";

}

.color_04093F {
  color: #04093F
}

.wrd_brk {
  word-break: break-word;
}

.threeDivContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.profile_boxes {
  padding-left: 13px;
  /* width: 238px;
    height: 138px; */
  /* background-color: #f1f1f1; */
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text_style_div {
  font-size: 14px;
  /* display: flex;
    text-align: center; */

  /* Adjust spacing between lines if needed */

}

.seekingText {
  font-size: 16px;
  font-weight: 600;
  font-family: SegoeUI-Semibold, "Segoe UI";
  margin-top: 20px;
}

.videoStyle {
  width: 85%;
  height: 80px;
}

.mt_bt_5 {
  margin-bottom: 5px;
}

.line_height_2 {
  line-height: 2.0;
}

.line_height_1 {
  line-height: 1.0;
}

.font_400 {
  font-size: 14px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 400;
  color: #01313c;
}

.font_600_12 {
  font-size: 12px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
}

.font_600_17 {
  font-size: 17px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
}

.font_600_10 {
  font-size: 10px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
}

.fs_regular_normal {
  font-weight: normal;
  font-family: "Segoe-Regular";
  font-size: 12px;
}

.responsive_Skill_Test_Info {
  display: none;
}

.font_600_14 {
  font-size: 14px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 600;
}

.font_12_400 {
  font-size: 12px;
  font-family: SegoeUI, Segoe UI;
  font-weight: 400;
}

.color_1a8fff {
  color: #1a8fff;
}

.color_01313C {
  color: #01313C;
}

.text-with-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-with-image img {
  margin-right: 5px;
  /* Adjust the spacing between the image and text */
}

.underline_text {
  text-decoration: underline;
}

.rectangle_199_35 {
  width: 199px;
  height: 35px;
  border-radius: 1px;

  transform: translate(-0.26px, -0.265px);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_color_d1e8ff {
  background-color: #d1e8ff;
}

.border_A3BCD5 {
  border: 1px solid #A3BCD5
}

.primary_skill_powerresume_wd {
  width: 100%;
  max-width: 780px;
  height: 202px;
  /* margin-bottom: 35px */
}

.primary_skill_powerresume_sub_card {
  display: flex;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease-in-out;
  /* padding: 14px 20px 20px 20px; */
  border-radius: 4px;
  margin-top: 30px
    /* background-color: #FFFFFF; */
}

.skill_videotest_powerresume_wd {
  width: 100%;
  max-width: 773px;
  /* height: 200px */
}

.skill_videotest_powerresume_sub_card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease-in-out;
  padding: 10px 25px;
  /* padding: 14px 20px 20px 20px; */
  border-radius: 4px;
  /* margin-top: 44px */
  /* background-color: #FFFFFF; */
}

.left-section,
.right-section {
  width: 50%;
  height: 100%;
  display: flex;



  flex-direction: column;
  /* align-items: center;
    justify-content: center; */
}

.font_25_300 {
  font-size: 25px;
  font-weight: 300;
  font-family: SegoeUI-Light, "Segoe UI";

}

.font_18_600 {
  font-size: 18px;
  font-weight: 600;
  font-family: SegoeUI-Light, "Segoe UI";

}

.color_01313c {
  color: #01313c;
}

.primary_skill_rectangle_container {
  margin-top: 16px;
  display: flex;
}

.btnpostion {
  display: "flex";
  padding: "0px!important";
  margin-top: "20px";
  background: "none!important";
  align-items: "center";
  flex-direction: "row";
  justify-content: "flex-end!important";
}

.btnstyle {
  padding: "0px!important";
  background: "none!important";
  max-width: "unset !important";
}

.left-section {
  width: 60%;
  padding-top: 6px;
  padding-left: 20px;
}

.font_14_rating {
  color: #1885e6;
  font-size: 14px;
  font-family: "SegoeUI, Segoe UI";
}

.font_37_300 {
  color: #1885e6;
  font-size: 37px;
  font-family: SegoeUI, "Segoe UI";
  font-weight: 300;
}

.font_15_600 {
  font-size: 15px;
  font-weight: 600;
  font-family: SegoeUI-Semibold, "Segoe UI";
}

.font_16_600 {
  font-size: 16px;
  font-weight: 600;
  font-family: SegoeUI-Semibold, "Segoe UI";
}

.right-section {
  width: 40%;
  text-align: right;
  padding-right: 14px;

}

.right-section-padding-top {
  padding-top: 59px;
}

.dltBtnContainer {
  margin-top: 4vh;
}

.buttonDeleteFont {
  color: #01313c;
  font-size: 18px;
  font-weight: 600;
  font-family: SegoeUI-Semibold, "Segoe UI";
  background-color: #ffff;
  width: 110px;
  height: 45px;
  border: 1px solid #A3BCD5;
}

.skill_publish_class {
  /* flex-wrap: wrap; */
  display: flex;
  /* padding-top: 10px;
    padding-left: 10px; */
}

.skill_class {
  display: flex;
  flex-grow: 1;
  /* width: 75% */
}

.publish_class {
  display: flex;
  text-align: right;
}

.video-interview-chkbox-publish {
  display: block;
  /* margin-bottom: 23px;
    margin-top: 10px; */
}

.testDesc {
  /* padding-left: 10px; */
  margin-top: 3px;
}

.marginTop7px {
  margin-top: 7px;
}

.newLine {
  border-top: 1px solid #a3bcd5;
  width: 73%;
  margin-left: 0px;
}

.skills_test_sore {
  /* padding-left: 10px; */
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.score_class {
  display: flex;
  /* width: 75%; */
  flex-direction: column;
}

.skillScoreClass {
  font-size: "12px";
  font-family: "SegoeUI, Segoe UI"
}

.view_skil_test_class {
  display: flex;
  text-align: right;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 12vw;
  height: 64vh;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);

  /* margin-right: 35px; */
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding-left: 10px; */
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  /* margin-right: 25px; */
}

.modifyDateBtn {
  margin-bottom: 20px;
}

.candidateDates {
  margin-top: 20px;
}

.rightDivMargin {
  margin-left: 35px;
  /* margin-top: 14px; */
}

.font_18_400 {
  font-size: 18px;
  font-weight: 400;
  font-family: SegoeUI-Bold, "Segoe UI";
}

.margin_top_left {
  margin-top: 21px;
  margin-left: 21px;
}

.font_14_400 {
  font-size: 14px;
  font-weight: 400;
  font-family: SegoeUI-Bold, "Segoe UI";
}

.avl_for_next_div {
  margin-top: 16px;
}

.retangle_175_24 {
  width: 175px;
  height: 24px;
  border-radius: 1px;
  transform: translate(-0.26px, -0.265px);
  /* margin-right: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modify_day_time {
  margin-top: 111%;
  margin-left: 11px;
}

.icon_button_dt_time {
  width: 10vw;
  height: 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #A3BCD5;
}

@media (max-width: 768px) {
  .mg_lft_25 {
    margin-left: 0px;
  }

  .rightDivMargin {
    margin-left: 0px;
  }

  .left_small_div_wd {
    width: 50%
  }

  .right_small_div_wd {
    width: 50%
  }
}

@media (max-width: 768px) {
  .threeDivContainer {

    justify-content: center;
  }

  .rightDivMargin {
    margin-left: 0px;
  }

  .left_small_div_wd {
    width: 50%
  }

  .right_small_div_wd {
    width: 50%
  }
}

@media screen and (max-width: 600px) {
  .large-rectangle-padding-left {
    padding-left: 0px
  }

  .right-section-padding-top {
    padding-top: 28px;
  }

  .rightDivMargin {
    margin-left: 0px;
  }

  .left_small_div_wd {
    width: 50%
  }

  .right_small_div_wd {
    width: 50%
  }

  .font_37_300 {
    color: #1885e6;
    font-size: 20px;
    font-family: SegoeUI, "Segoe UI";
    font-weight: 300;
  }

}

@media screen and (max-width: 1024px) {
  .font_37_300 {
    color: #1885e6;
    font-size: 20px;
    font-family: SegoeUI, "Segoe UI";
    font-weight: 300;
  }

  .left_small_div_wd {
    width: 50%
  }

  .right_small_div_wd {
    width: 50%
  }
}

@media screen and (max-width: 820px) {
  .right-section-padding-top {
    padding-top: 31px;
  }
}

/* .large-rectangle:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 5px;
      width: 80%;
      box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
    } */
/* @media (min-width: 768px) {
      .container11 {
        max-width: 768px;
      }
    }
    
    @media (min-width: 992px) {
      .container11 {
        max-width: 992px;
      }
    }
    
    @media (min-width: 1200px) {
      .container11 {
        max-width: 1200px;
      }
    } */

.headeings_poweResumeRight {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap-reverse;
}

.profileHeaderDiv {
  min-width: 220px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) -1px -5px 5px 0px;
  /* border-bottom: 4px solid #1885E6; */
  display: flex;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #FFFFFF;
}

.nameEmailDiv_poweResumeRight {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  gap: 2px;
  margin-left: 15px;
  font-family: 'Segoe-semibold';
  font-size: 14px;
  color: #04093F;
  margin-top: 5px;

}

.powerResume_email{
  font-family: 'Segoe-Regular';
  font-size: 14px;
  color: #04093F;
}

.camera_icon_profile_poweResumeRight {
  /* position: absolute; */
  width: 18px;
  height: 18px;
  margin-left: -16px;
}

.poweResumeInfoCard_poweResumeRight {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 5px;
  margin-left: 10px;
  border-top-left-radius: 0px;
  margin-top: -3px;
  margin-right: 3px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 20px;
}

.rightCorner_poweResumeRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
}

.poweResumeHeading_poweResumeRight {
  font-family: 'Segoe UI';
  font-size: 30px;
  color: #1885E6;
  /* margin-bottom: 10px; */
}

.poweResumeLastUpdated_poweResumeRight {
  font-family: 'Segoe-semibold';
  font-size: 15px;
  color: #04093F;
}

.rightSide_poweResumeInfoCard {
  width: 220px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  padding: 15px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-left: 15px;
}

.manrope-18_poweResumeRight {
  font-family: 'Manrope';
  font-size: 20px;
  color: #04093F;
  margin-bottom: 10px;
}

.availabilty_poweResumeRight {
  font-family: 'Manrope';
  font-size: 15px;
  color: #04093F;
}

.availabiltyTime_poweResumeRight {
  font-family: 'Manrope-semibold';
  font-size: 15px;
  color: #04093F;
  margin-left: 15px;
}

.weektitle_poweResumeRight {
  margin-top: 15px;
  font-family: 'Manrope-semibold';
  font-size: 16px;
  color: #04093F;
}

.weekTiming_poweResumeRight {
  font-family: 'Manrope';
  font-size: 13px;
  background-color: #D1E8FF;
  padding: 3px 7px;
  border-radius: 5px;
  width: 200px;
  margin-top: 7px;
}

.dateTimeButton_poweResumeRight .MuiButton-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.modifyDateTimeBtnText {
  font-family: 'Segoe-semibold';
  font-size: 13px;
  color: #04093F;
  margin-top: 1px;
}

.dateTimeButton_poweResumeRight {
  border: 1px solid #A3BCD5 !important;
  background-color: transparent !important;
  width: 215px;
  opacity: 0.7;
}

.dateTimeButton_poweResumeRight .MuiButton-root {
  margin-top: 10px;
}

.UpperSection_rightSide_poweResumeInfoCard {
  display: flex;
  flex-direction: column;
}

.dateTimeButtonContainer_poweResumeRight {
  padding-top: 20px !important;
  display: flex;
  flex-direction: column;
}

.trackHistory_button_poweResumeRight{
  margin-top: 20px !important;
  background-color: #05A6F4 !important;
  color: #ffffff !important;
}

.topContainerone_leftSide_poweResumeInfoCard {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.resumeCard_leftSide_poweResumeInfoCard {
  width: 200px;
  min-height: 125px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  justify-content: space-between;
  align-items: center;
}

.resumeHeading_resumeBox {
  font-family: 'Manrope';
  font-size: 15px;
  color: #04093F;
}

.resumeCard_leftSide_poweResumeInfoCard .ReplaceFileButton {
  background-color: #D1E8FF !important;
  width: 170px;
  border: 1px solid #A3BCD5 !important;
}

.videoIntroThumbnail_poweResumeRight {
  height: 80%;
  width: 196px;
}

.videoIndtroCard_leftSide_poweResumeInfoCard {
  width: 200px;
  min-height: 125px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
}

.primarySkillContainer_poweResumeRight {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
}
.primarySkillContainer_poweResumeRight_shared {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  width: 50vw;
}

.primarySkillTitle_poweResumeRight {
  font-family: 'Manrope-light';
  font-size: 25px;
  color: #04093F;
}

.primarySKillMainContainer_poweResumeRight {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.primarySKillMainContainer_poweResumeRight_shared{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 50vw;
}
.additionalsKillMainContainer_poweResumeRight{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
}
.additionalSkillRatingDiv{

}

.primarySKillItemOne_poweResumeRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
}

.primarySKillItemTwo_poweResumeRight {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.PrimarySkillName_poweResumeRight {
  font-family: 'Segoe-semibold';
  font-size: 18px;
  color: #04093F;
}
.noOtherSkill_poweResumeRight{
  font-family: 'Segoe UI';
  font-size: 18px;
  color: #04093F;
  margin-top: 10px;
}

.primarySkillExp_poweResumeRight {
  font-family: 'Segoe-semibold';
  font-size: 13px;
  color: #04093F;
}

.SelfAssesmentTitle_poweResumeRight {
  font-family: 'Manrope-semibold';
  font-size: 15px;
  color: #04093F
}
.SelfAssement_additionalSkill{
  font-family: 'Manrope-semibold';
  font-size: 15px;
  color: #04093F
}

.additionalSKillScroll_poweResumeRight {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
}

.arraowIconContainer_powerResumeRight {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.arraowIcon_powerResumeRight {
  height: 15px !important;
  width: 15px !important;
}

.skillItemCountContainer_poweResumeRight {
  font-family: 'Segoe-semibold';
  font-size: 12px;
  color: #04093F;
}

.skillItemCountContainer_poweResumeRight_opacity {
  opacity: 0.6;
}

.skillTestContainer_poweResumeRight {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
}
.skillTestContainer_poweResumeRight_shared {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  width: 50vw;
}


.skillTestName_poweResumeRight {
  font-family: 'Segoe-semibold';
  margin-top: 10px;
  font-size: 17px;
  color: #04093F;
}

.skillTestInfoContainer_poweResumeRight {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.skillTestTimeInfoContainer_poweResumeRight {
  display: flex;
  font-family: 'Segoe UI';
  font-size: 12px;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #A3BCD5;
}

.testScoreContainer_poweResumeRight {
  display: flex;
  font-family: 'Segoe UI';
  font-size: 12px;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-bottom: 10px;
}

.viewSkillTestBtn_poweResumeRight {
  width: 125px;
  background-color: #1885E6 !important;
  color: #FFFFFF !important;
}
.viewSkillTestBtn_poweResumeRight_copyLink{
  width: 125px;
  background-color: #1885E6 !important;
  color: #FFFFFF !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.viewSkillTestBtn_poweResumeRight:hover , .viewSkillTestBtn_poweResumeRight_copyLink:hover{
  opacity: 0.8;
}

.personalInfocardItems_poweResumeCard {
  display: flex;
  grid-gap:10px;
  gap:10px;
  justify-content: flex-start;
  font-family: 'Manrope';
  font-size: 12px;
  color: #04093F;
}

.personalInfoCard_title_poweResumeInfoCard{
  font-family: 'Manrope';
  font-size: 15px;
  color: #04093F;
}

.personalInfoCard_leftSide_poweResumeInfoCard {
  width: 200px;
  min-height: 125px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  grid-gap:10px;
  gap:10px;
  padding-right: 0px;
}
.icons_personalInfo_poweResumeRight {
  height: 20px !important;
  width: 14px !important;
}
.locationicons_personalInfo_poweResumeRight{
  height: 20px;
  width: 18px;
}
.linkedinicons_personalInfo_poweResumeRight{
  height: 15px;
  width: 15px;
}
.linkedinText_poweResumeRight{
  text-wrap: wrap;
  word-wrap: break-word;
  margin-top: 1px;
  width: 175px;
}
.primarySkillContainer_poweResumeRight .MuiRating-root{
  color: #1885E6 !important;
}
.starRatingContainer_poweResumeRight{
  display: flex;
  grid-gap: 2px;
  gap: 2px;
}
.starRatingHeight_poweResumeRight{
  height: 23px;
  width: 23px;
}

.skillVideoTestContentContainer_poweResumeRight{
  display: flex;
  flex-direction: column;
}
.SkillVideoTestTitleStatusContainer_poweResumeRight{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.videoSKillTestStatus_poweResumeRight_completed{
  font-family: 'Segoe-Regular';
  font-size: 18px;
  color: #FFFFFF;
  background-color: #1885E6 ;
  border-radius: 25px;
  padding: 5px 15px;
}
.videoSKillTestStatus_poweResumeRight_missing{
  font-family: 'Segoe-Regular';
  font-size: 18px;
  color: #FFFFFF;
  background-color: red ;
  border-radius: 25px;
  padding: 5px 15px;
}
.skillVideoTestestbtnContainer_poweResumeRight{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 5px;
}
.skillTestmainDiv_poweResumeRight{
  width: 100%;
}
.noVideoText_poweResume{
  font-family: 'Manrope';
  font-size: 12px;
  height: 50%;
}
.level_Skill_poweResumeRight{
  font-family: 'Segoe-semibold';
  font-size: 14px;
  color: #04093F;
}
.AdditionalSkillRatingContainer{
  display: flex;
  flex-direction: column;
}
.radioOptions_poweResumeRight{
  display: flex;
  align-items: center;
}
.radioOptionsContainer_poweResumeRight{
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-left: -6px;
}
.radioOptionsContainer_poweResumeRight_skeleton{
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-left: -6px;
  margin-top: 10px;
}
.radioLabel_poweResumeRight{
  font-family: 'Segoe-semibold';
  font-size: 12px;
  color: #04093F;
  margin-left: -3px;
}
.interViewTimeSkeleton{
  margin-top: 5px;
}
.additionalSkillContainerOne{
  display: flex;
  justify-content: space-between;
}
.noAdditionalSkill{
  margin-top: 15px;
}
@media only screen and (max-width: 1028px){
  .skillTestContainer_poweResumeRight_shared{
    width: 65vw;
  }
  .primarySkillContainer_poweResumeRight_shared{
    width: 65vw;
  }
  .rightSide_poweResumeInfoCard{
    margin-left: 5px;
    margin-top: 10px;
  }
}
.view_test{
  margin-left: 20px;
  margin-top: 24px ;
}
.ml-3px{
  margin-left:3px ;
}

/* .popup ~ .video-recorder__Wrapper-sc-7k20rv-0.kHmnKJ {
  min-height: 600px !important;
} */
.dloCfB {
  display: none;
}
.hvEHqT {
  display: none;
}
.gNmWLX {
  top: 0;
}
button {
  cursor: pointer;
}
.btn.btn-danger {
  color: #d4d4d4;
}
.video-skill-test {
  width: 258px !important;
  height: 158px !important;
}
.ml-25 {
  margin-left: 25px;
}
.mt-10 {
  margin-top: 10px;
}
.kHmnKJ {
  min-height: 450px!important;
}
.skillvideo_modal_videointro {
  background: rgb(255, 255, 255) !important;
  overflow: auto !important;
  border-radius: 4px !important;
  outline: none !important;
  width: 800px !important;
  /* margin: 1px auto !important; */
  position: relative!important;
}
.icaKGS {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 283px;
}

@media only screen and (max-width: 768px) {
  .ml-25 {
    margin-left: 0px;
  }
  .video-skill-test {
    width: 100% !important;
    height: 158px !important;
  }
  .kHmnKJ {
    min-height: 250px!important;
  }
  .skill_video_popup.pr {
    width: 100%;
    margin-top: 20px;
  }
  .ReactModal__Content.ReactModal__Content--after-open.skillvideo_modal {
    margin-top: 0px;
  }
  .skillvideo_modal_videointro {
    background: rgb(255, 255, 255) !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 94% !important;
    /* margin: 30px auto !important; */
    position: relative!important;
  }
  .popup {
    background-color: #fff;
    border-radius: 4px;
    padding: 15px 10px 25px 10px;
}
.icaKGS {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 164px;
}
}

.my-video-intro {
  width: auto;
  height: 180px;
  object-fit: cover;
}
.recorded-video-intro {
  width: 100%;
}
.closeicon_set {
  width: 15px;
}

.recorded-video-container .MuiDialogTitle-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 42px;
}

/* Recorded video intro responsiveness */
@media (max-width: 500px) {
  .my-video-intro {
    width: 100%;
  }
  .recorded-video-container .MuiDialogTitle-root {
    padding: 16px;
  }
  .recorded-video-container .MuiDialog-paper {
    margin: 14px;
  }
}
.video-intro-heading.MuiDialogTitle-root {
  padding: 16px 27px !important;
}
.profileContainer {
    display: flex;
    justify-content: center;
}

.candidate_specific_container {
    padding-right: 15px;
    padding-left: 15px;
    /* margin-left: 156px; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    /* margin-bottom: 20px; */
}

.candidate_specific_container_margin_bottom {
    margin-bottom: 20px;
}

.candidate_specific_w1004 {
    max-width: 1709px
}

.candidate_specific_full_body {
    display: flex;
    /* justify-content: space-between; */
    /* margin-top: 0px; */
    margin: 0 5vh;
}

.candidate_profile_section {
    /* font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 300;
    font-size: 29px; */
}

.candidate_profile_top_align {
    margin-top: 11vh;
    text-align: left;
}

.cs_lastUpdate_align {
    text-align: left;
}

.candidate_profile_text_color {
    color: #1885E6;
    font-size: 29px;
    font-weight: 300;
    font-family: SegoeUI-Semilight, "Segoe UI"
}

.main_candidate_specific_extra_padding {
    /* padding: 14px 8px 20px 8px; */
}

.main_candidate_specific_profile_card {
    position: relative;
}

.main_candidate_specific_card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease-in-out;
    /* padding: 14px 20px 20px 20px; */
    border-radius: 4px;
}

.main_candidate_specific_card_wd {
    /* width: 355px; */
    /* height: 647px; */
}

.main_powerresume_specific_card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease-in-out;
    /* padding: 14px 20px 20px 20px; */
    border-radius: 4px;
}

.main_powerresume_specific_card_wd {
    width: 1095px;
    /* height: 647px; */
}

.cs_lastUpdate_font {
    color: #01313c;
    font-size: 15px;
    font-family: SegoeUI-Semibold, "Segoe UI";
    font-weight: 600;
}

.candidate_specific_flex_to_footer {
    flex: 1 1;
    width: 100%;
}

.margin_main_right {
    margin-top: 12vh;
    margin-left: 20px;
}

.main-right_class {

    width: 73%;
}

.section-wrapper-candidateprofile {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


/* .section-wrapper-candidateprofile {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: auto !important;
    background-color: #ffffff;
} */

.bg_color_cadidate_profile {
    background-color: #ffffff;
}

.profile_footer_candidate {
    margin-top: auto
}

.candidate_footerflex {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #ffffff;
}

@media (max-width: 768px) {
    .candidate_specific_flex_to_footer {
        width: auto;
    }

    .candidate_specific_container {
        padding-right: 15px;
        padding-left: 15px;
        margin-left: auto;
        margin-right: auto;

    }

    .candidate_specific_container_margin_bottom {
        margin-bottom: 0px;
    }

    .candidate_profile_top_align {
        margin-top: 10vh;
        text-align: center;
    }

    .cs_lastUpdate_align {
        text-align: center;
    }

    .margin_main_right {
        margin-top: 3vh;
        margin-left: 2px;
    }

    .main-left_class {
        /* max-width: 25%; */
        width: 25%;
    }

}

@media only screen and (max-width: 768px) {
    .candidate_specific_full_body {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0px;
    }

    .candidate_specific_container_margin_bottom {
        margin-bottom: 0px;
    }

}

@media screen and (min-width: 820px) and (max-width: 912px) {
    .margin_main_right {
        margin-top: 8vh;
        margin-left: 1vh;
    }

    .candidate_profile_top_align {
        margin-top: 8vh;
        text-align: left;
    }

    .candidate_specific_container_margin_bottom {
        margin-bottom: 0px;
    }

}

@media (max-width: 767px) {

    .section-wrapper-candidateprofile {
        display: block;
        flex-direction: column;
        min-height: 100vh;
    }
}

/*new CSS*/

.mainContainer_candidateProfilePoweresume {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #ffffff;
}

.topContainer_candidateProfilePoweresume {}

.bottomContainer_candidateProfilePoweresume {
    margin-top: auto;
}

.mainDiv_candidateProfilePoweresume {
    margin: 0 5%;
    margin-top: 125px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
}
.mainDiv_candidateProfilePoweresume_shared{
    margin: 0 5%;
    margin-top: 125px;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.leftDiv_candidateProfilePoweresume {
    width: 360px;
    display: flex;
    flex-direction: column;
}

.rightDiv_candidateProfilePoweresume {
    min-width: calc(100% - 380px);
    min-height: 65vh;
}
.rightDiv_candidateProfilePoweresume{
    min-height: 65vh;
    width: calc(100% - 380px);
}

.leftDiv_candidateProfilePoweresumeDisplayNone{
    display: none;
}
.mainDiv_candidateProfileOnlyPoweResume {
    margin: 0 5%;
    margin-top: 125px;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

@media screen and (max-width: 1020px) {
    .mainDiv_candidateProfilePoweresume {
        display: flex;
        flex-direction: column;
    }
    .poweResumeInfoCard_poweResumeRight {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .leftDiv_candidateProfilePoweresume {
        width: 80vw;
    }
    .rightDiv_candidateProfilePoweresume {
        width: 85vw;
    }
}

.circularprogress_profile{
    height: 400px !important;
    width: 400px !important;
    color: #05A6F4 !important;
}
.skill_video_sec_left {
  width: 37%;
}

.video-interview-chkbox {
  display: block;
  margin-bottom: 23px;
  margin-top: 10px;
}

.skill_video_sec_right {
  width: 63%;
}

.video-interview-chkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.video-interview-chkbox label {
  position: relative;
  cursor: pointer;
  font-weight: 600;
  font-family: "Segoe-Semibold";
  font-size: 16px;
  color: #01313c;
}

.video-interview-chkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #2896ff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 11px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 4px; */
  margin-right: 7px;
}

.video-interview-chkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 5px;
  height: 12px;
  border: 2px solid #2896ff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.video-interview-chkbox :checked + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #1a8fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 11px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

.skill_video_next_text {
  color: #a3bcd5;
}
.fs-30{
  font-size: 30px;
}
.skill_video_next {
  display: flex;
  justify-content: flex-end;
}

.scrollHeight1 {
  overflow-y: scroll;
}

.interview-question-scroll::-webkit-scrollbar {
  width: 5px;
}

/* .interview-question-scroll::-webkit-scrollbar-track {
	background: #c6c6c6;
  width: 2px
} */

.interview-question-scroll::-webkit-scrollbar-thumb {
  background-color: #a5a4a4;
  border-radius: 30px;
}

.next-button {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.individual-skill {
  border: 1px solid #d1e8ff;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 14px 20px 14px 20px;
  margin-top: 10px;
}

.individual-skill-video {
  border: 1px solid #A3BCD5;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.myskill-right {
  text-align: right;
}

/*------------- CHECKBOX ---------------*/

.interview-chkbox {
  display: block;
  margin-bottom: 23px;
  margin-top: 23px;
}

.interview-chkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.interview-chkbox label {
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-family: "Segoe-regular";
  font-size: 18px;
  color: #01313c;
}

.interview-chkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #01313c;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

.interview-chkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #1a8fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.interview-chkbox :checked + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #1a8fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}







.interview-chkbox-testresult{
  display: block;
  margin-bottom: 23px;
  margin-top: 23px;
}

.interview-chkbox-testresult input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.interview-chkbox-testresult label {
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-family: "Segoe-regular";
  font-size: 18px;
  color: #01313c;
  display: flex;
}

.interview-chkbox-testresult label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #01313c;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  height: 0px;
  margin-top: 8px;
}

.interview-chkbox-testresult input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #1a8fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);

}

.interview-chkbox-testresult:checked + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #1a8fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
}

/*------------- CHECKBOX ---------------*/

/*------------- RADIO BUTTON ---------------*/

.clear [type="radio"]:checked,
.clear [type="radio"]:not(:checked) {
  position: absolute;
  visibility: hidden;
}

.clear [type="radio"]:checked + label,
.clear [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 43px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  color: #01313c;
  margin-top: 25px;
}

.clear [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #1a8fff;
  border-radius: 100%;
  background: #fff;
}

.clear [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #01313c;
  border-radius: 100%;
  background: #fff;
}

.clear [type="radio"]:checked + label:after,
.clear [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: #1a8fff;
  position: absolute;
  border: 1px solid #1a8fff;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.clear [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.clear [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

/*------------- RADIO BUTTON ---------------*/

/*------------- STEP CSS ---------------*/

.bs-stepper .step-trigger {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.bs-stepper .step-trigger:focus {
  outline: none;
}

.bs-stepper-label {
  display: inline-block;
  margin: 0.25rem;
}

.bs-stepper-header {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bs-stepper-line,
.bs-stepper .line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: 9px;
  background-color: #a3bcd5;
}

.success_line {
  background-color: #2ad156;
}

.bs-stepper-circle {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  color: #adb5bd;
  border: 1px solid #a3bcd5;
  border-radius: 100%;
  line-height: 37px;
  font-size: 16px;
  background-color: #fff;
  font-weight: normal;
  font-family: "Segoe-Regular";
  outline: none;
}

.active .bs-stepper-circle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.success .bs-stepper-circle {
  color: #fff;
  background-color: #2ad156;
  border-color: #2ad156;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.bs-stepper-content {
  padding: 0 20px 20px;
}

.bs-stepper.vertical {
  display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
  display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden;
}

.bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
  display: none;
}

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
  display: block;
  visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
  display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
  display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
  visibility: hidden;
}

.wb {
  word-break: break-word;
}

.link_color {
  color: #007bb5;
}

/*------------- STEP CSS ---------------*/

/*------------- VIDEO INTERVIEW QUESTION ---------------*/

.flex-video-bottom {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
}

.videosection {
  width: 100%;
}

.vl_video {
  border-left: 1px solid #a3bcd5;
  height: 267px;
  /* height: 328px; */
  margin-left: 12px;
  margin-top: 0px;
}

/*------------- VIDEO INTERVIEW QUESTION ---------------*/
.cross_size {
  width: 16px;
}
/*------------- VIDEO INTERVIEW TOP ---------------*/

.playButton {
  width: 45px;
  height: 45px;
}
.playButtonother {
  width: 45px;
  height: 45px;
}
.flex-video-top {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.cadidateinfo_clm_one{
  margin-right: 20px;
  text-align: left;
}
.cadidateinfo_clm_two{
  text-align: left;
}

/*------------- VIDEO INTERVIEW TOP ---------------*/

.mainbg {
  background-color: #eeeeee;
}

.skill_video_popup {
  width: 800px;
}

.video_top_text {
  justify-content: space-between;
}

.ReactModal__Overlay {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  overflow-y: scroll;
}

.skillvideo_modal {
  /* background: rgb(255, 255, 255) !important;
  overflow: auto !important;
  border-radius: 4px !important;
  outline: none !important;
  width: 800px !important;

  position: absolute !important; */
  background: rgb(255, 255, 255) !important;
	border-radius: 4px !important;
	outline: none !important;	
	width: 815px ;
    position: relative;
    padding: 0px 20px 0px 20px;
    overflow-y: scroll;
    height: 650px;
}

.time_icon {
  vertical-align: middle;
  padding-right: 8px;
}

/*------------- IMAGE ---------------*/

.btn-icon {
  vertical-align: baseline;
  padding-left: 6px;
}

.video_icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAARCAYAAAAsT9czAAAABHNCSVQICAgIfAhkiAAAASRJREFUOE/tlcFRAkEQRd9fPalVEgJmMNwUqRIjkAzECMQIdonAzUAyEauAK2sGmgEevPKtXSxBxAvLenKOUzX9pnu634gQanAYg7oSNbZYNjPwAN77ZNnstxAiNFOkNlZvC87yiJxiD8kmP+OE0zrsX4nG+QyrQzYaloKFsw5E92Tjk684BWQvltTN96RGyzaX5WGttsSjpyMtnuboFsizfIb5QIoedg+z70DxAkJSJBEWF9k9DL9iJ2STwbKcVcHyMq6vyjL7h5Wbs5XW/7M3s19AN9/mtrIGMX3kHtYU6Fc7Z0uD9CRiOxe0nwqD7NSNilKm4/qaGxNJ14UbC+ujCxSVs77naZ7BZus3A6j9Kc2DBKkrdLxNRxq/kZdr0/eyEvAD797TUbPgNR4AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  width: 27px;
  height: 17px;
}

.video_icon:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAARCAYAAAAsT9czAAAABHNCSVQICAgIfAhkiAAAAP5JREFUOE/tldFtAjEQRN9UEEpIOkgHhApCB4EKCBUEKsh1EDoBKkB0AB2QChYN2OiSXCQEvh/Efq1Ovn32eHesiOgAH8AAcH5J7IAZMJXkvDEUERXwArxfQqn94zoLSX/qRMQj8GqYd9KXtLgGFhF94FPSU66TIFk1DAugVwBmdeaSXNPXMUpqrZPEX8VhwDj1gCETHyIijhspfTJgmyBumEO0BrOMv+/+Djt7Gk5S3ayMwAYY1ue2tQaxP6ZhXiWvbG/Oag5ij7RVed6WwMFBSnpjJcmmm4fZ+QR484fs+t1Crr/8x/Wf/bJk0zTd79nD2b3+c+G35WoC1ZftARGb1rdzuWzUAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  width: 27px;
  height: 17px;
}

.arrow_icon {
  vertical-align: middle;
  margin-left: 10px;
}

.btnquestion {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 4px 10px 2px 10px;
  font-size: 22px;
  border-radius: 30px;
  font-family: "Segoe-Bold";
  width: 100%;
}

.btnquestion-questionselected {
  color: #fff;
  background-color: #1a8fff;
  outline: none;
}

.btnquestion-questionselected:hover {
  color: #fff;
  background-color: #1a8fff;
  outline: none;
}

.btnquestion-questionright {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #2ad156;
  outline: none;
}

.btnquestion-questionright:hover {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #2ad156;
  outline: none;
}

.btnquestion-questionwrong {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #ff4141;
  outline: none;
}

.btnquestion-questionwrong:hover {
  color: #01313c;
  background-color: transparent;
  border: 1px solid #ff4141;
  outline: none;
}

.btnquestion-questiondisable {
  color: #a3bcd5;
  background-color: transparent;
  border: 1px solid #a3bcd5;
  outline: none;
}

.btnquestion-questiondisable:hover {
  color: #a3bcd5;
  background-color: transparent;
  border: 1px solid #a3bcd5;
  outline: none;
}

.modal_popup_close {
  position: absolute;
  top: 13px !important;
  right: 24px !important;
}
/* .alice-carousel__next-btn {
  position: absolute;
  top: -17px;
  right: 10px;
}
.alice-carousel__prev-btn {
  position: absolute;
  top: -17px;
  left: -5px;
}
.alice-carousel__next-btn-item {
  position: absolute;
  right: -16px;
  top: -2px;
}
.alice-carousel__prev-btn-item {
  position: absolute;
  left: -16px;
  top: -2px;
}
.alice-carousel__dots {
  display: none;
}

.alice-carousel__prev-btn [data-area]::after {
  position: relative;
  
  content: "\f104" !important;
  font-size: 34px !important;
  text-transform: capitalize;
  font-family: FontAwesome !important;
}

.alice-carousel__next-btn [data-area]::after {
  position: relative;
  
  content: "\f105" !important;
  font-size: 34px !important;
  text-transform: capitalize;
  font-family: FontAwesome !important;
} */
.skillvideocodingtest {
  margin-top: 0px;
}
.videobuttomquestion {
  display: flex;
}
.playbuttonsection {
  display: flex;
}
.scorecardsection {
  display: flex;
}

.text-left_special {
  margin-left: 25px;
}
.res_left {
  margin-left: 41px;
}
.popup {
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 40px 10px 40px;
}
.video-interview-test {
  object-fit: cover;
  width: 100% !important;
}
.skill_Pagination_adjust{
  height: 40px;
}

@media only screen and (max-width: 768px) {
  /* .alice-carousel__next-btn {
    display: none;
   }
   .alice-carousel__prev-btn {
     display: none;
   } */
  .res_left {
    margin-left: 24px;
  }
  .text-left_special {
    margin-left: 0px;
  }
  .skill_video_width {
    width: 100%;
    flex-wrap: wrap;
  }

  .skill_video_sec_left {
    width: 100%;
  }

  .skill_video_sec_right {
    width: 100%;
  }
  .skillvideocodingtest {
    margin-top: 11px;
  }
  .skillvideo_modal {
    background: transparent !important;
    overflow: auto !important;
    border-radius: 4px !important;
    outline: none !important;
    width: 100% !important;
    position: absolute !important;
    margin-top: 0px !important;
    padding: 0px;
  }
  .skill_video_popup {
    width: 100%;
    /* margin-top: 212px; */
  }
  .mw {
    width: 100%;
  }
  .vl {
    border-left: 1px solid #a3bcd5;
    height: 327px;
    margin-left: 12px;
    margin-top: 0px;
    display: none;
  }
  .videobuttomquestion {
    display: flex;
    flex-wrap: unset;
  }
  .playbuttonsection {
    display: flex;
    flex-wrap: unset;
  }
  .scorecardsection {
    display: initial;
    flex-wrap: unset;
  }
  .ml_41 {
    margin-left: 0px;
  }
}
.skill_video_sec_right {
  width: 100%;
}
.skill_video_sec_right.ml_22.skillvideocodingtest {
  margin-left: 0 !important;
}

.questionoption.wrong-question-option [type="radio"]:checked + label {
  color: #ff4141;
}
.questionoption.wrong-question-option [type="radio"]:checked + label:after {
  background: #ff4141;
  border: 1px solid #ff4141;
}
.questionoption.wrong-question-option [type="radio"]:checked + label:before {
  border: 2px solid #ff4141;
}
.questionoption.right-question-option [type="radio"]:checked + label:after {
  background: #2ad156;
  border: 2px solid #2ad156;
  top: 7px;
  left: 7px;
}
.questionoption.right-question-option [type="radio"]:checked + label:before {
  border: 2px solid #2ad156;
}
.questionoption.right-question-option [type="radio"]:checked + label {
  color: #2ad156;
}
.btnquestion {
  width: 95%;
}
/* .skillvideo_modal {
  overflow: initial !important;
} */
.videosection.mt_17 {
  object-fit: cover;
}
.video-interview-test>video {
  object-fit: cover;
  /* width: 360px !important;
  height: 268px !important; */
  width: 100% !important;
  height: 100% !important;
}
.right-answer {
  color: #2ad156;
}
.wrong-answer {
  color: #ff4141;
}
.interview-chkbox > input.right-checkbox[type="checkbox"]:checked + label:after {
  /* background: #2ad156;
  border: 2px solid #2ad156; */
  border: solid #2ad156;
  content: "";
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.interview-chkbox > input.wrong-checkbox[type="checkbox"]:checked + label:after {
  /* background:#ff4141;
  border: 2px solid #ff4141; */
  border: solid #ff4141;
  content: "";
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.interview-chkbox > input.right-checkbox[type="checkbox"]:checked + label {
  color: #2ad156;
}
.interview-chkbox > input.wrong-checkbox[type="checkbox"]:checked + label {
  color: #ff4141;
}
.interview-chkbox > input.right-checkbox[type="checkbox"]:checked + label:before {
  border: 2px solid #2ad156;
}
.interview-chkbox > input.wrong-checkbox[type="checkbox"]:checked + label:before {
  border: 2px solid#ff4141;
}
.interview-chkbox input.wrong-checkbox:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #ff4141;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.interview-chkbox input.right-checkbox:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 11px;
  width: 7px;
  height: 12px;
  border: solid #2ad156;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* .alice-carousel__prev-btn-item {
  top: 10px;
}
.alice-carousel__next-btn-item {
  top: 10px;
} */



/* test result css started  */

.test-result-wrapper {
background: #fff;
height: 100vh;
}
.test-result-container {
  max-width: 92%;
  margin: 0 auto;
  padding: 2em 0em;
}
.alice-carousel {
  position: relative;
}
.alice-carousel__stage {
  /* display: flex; */
  padding: 0rem 2rem;
}
.alice-carousel__stage li {
  list-style: none;
}
.test-result-heading {
  font:  40px Segoe-Light;
  color: #01313C;
  margin: 0 0 20px 0;
}



.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2px;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  display:flex
}

.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
  width: 100% !important;
}

/* .alice-carousel__next-btn-item, .alice-carousel__prev-btn-item {
  display: none;
} */
@media (max-width: 981px) {
  .test-result-container {
    margin: 0rem 2rem;
  }
}
.test-result-container .playbuttonsection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.test-result-card {
  margin: 30px 30px 30px 30px;
  min-height: 70vh;
  width: 88vw !important;
  margin-top: 12vh;

}
.mainbgcenter {
  display: flex;
  justify-content: center;
}
.questionoption.questionoption-alignment [type="radio"]:checked + label, .questionoption.questionoption-alignment [type="radio"]:not(:checked) + label {
  padding-left: 0;
  display: flex;
  align-items: center;
  }
  .questionoption.questionoption-alignment [type="radio"]:not(:checked) + label:before {
  position: initial ;
  margin-right: 1rem;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment.right-question-option [type="radio"]:checked + label:before {
  position: initial;
  margin-right: 1rem;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment.right-question-option [type="radio"]:checked + label:after {
  top: initial;
  left: 7px;
  }
  .questionoption.questionoption-alignment.wrong-question-option [type="radio"]:checked + label:before {
  margin-right: 1rem;
  position: inherit;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment.wrong-question-option [type="radio"]:checked + label:after {
  left: 8px;
  top: inherit;
  }
  
  .questionoption-alignment .interview-chkbox-testresult input.right-checkbox:checked + label:after {
  top: inherit;
  height: 15px;
  border: solid #2AD156;
  border-width: 0 2px 2px 0;
  }
  
  .questionoption-alignment .interview-chkbox-testresult input.right-checkbox:checked + label:before {
  top: inherit;
  height: inherit;
  border: 2px solid #2AD156;
  
  }
  .questionoption-alignment .interview-chkbox-testresult > input.right-checkbox[type="checkbox"]:checked + label {
  color: #2AD156;
  display: flex;
  align-items: center;
  }
  .questionoption-alignment .interview-chkbox-testresult label:before{
  margin-top: inherit;
  }
  .questionoption-alignment .interview-chkbox-testresult input.right-checkbox:checked + label:after {
  top: inherit;
  height: 15px;
  }
  
  .questionoption-alignment .interview-chkbox-testresult > input.wrong-checkbox[type="checkbox"]:checked + label {
  color: #FF4141;
  display: flex;
  align-items: center;
  }
  .questionoption-alignment .interview-chkbox-testresult input.wrong-checkbox:checked + label:after {
  top: inherit;
  height: 15px;
  border: solid #FF4141;
  border-width: 0 2px 2px 0;
  }
  
  .questionoption-alignment .interview-chkbox-testresult input.wrong-checkbox:checked + label:before {
  top: inherit;
  height: inherit;
  border: 2px solid #FF4141;
  
  }
  
  .questionoption-alignment .interview-chkbox-testresult label {
  display: flex;
  align-items: center;
  }
  .test-result-card .alice-carousel__next-btn {
    right: 0px;
  }
  .string-whitespace-pre {
    white-space: pre;
  }
  .block-answer {
    white-space: pre;
  }
  @media (min-width: 768px) and (max-width: 1366px) {
    .scrollHeight2 {
      /* max-height: 68vh; */
      overflow-y: hidden;
    }
  }
  @media (min-width: 1367px) {
    .scrollHeight2 {
      /* max-height: 68vh; */
      overflow-y: hidden;
    }
  }
    

/* Portrait */
@media only screen and (max-width: 425px){
  .test-result-heading{
    font-size: 30px;
  }
  #testSkill_Name{
    font-size: 30px !important;
  }
  .playbuttonsection p{
    font-size: 15px !important;
  }
  .test-result-container .playbuttonsection {
    flex-direction: column;

  }
  #customWidthTest{
    width: 100% !important;
  }
  .test-result-card{
    width: 95vw !important;
    padding: 0 !important;
  }
  .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item{
    margin-right: -5px !important;
    /* margin-left: -5px; */
  }
  .viewtestresults-questionside{
    margin-left: 0px !important;
    margin-top: 20px !important;
    width: 100% !important;
  }
  #testQuestionDescp{
    width: 70vw !important;
  }
}

#testQuestionDescp h2 {
  -webkit-margin-before: 0;
          margin-block-start: 0; /* or margin-block-start: initial; */
  font-size: 1em
}
#testQuestionDescp h1 {
  -webkit-margin-before: 0;
          margin-block-start: 0; /* or margin-block-start: initial; */
  font-size: 1em
}
.termsofuse {
    text-align: center !important;
}

.Prohibited_ul li {
    list-style-type: none;
    font-size: 22px;
    font-family: "Segoe-Regular";
    padding: 5px 0px;
}

.goToclrTalent {
    color: #1A8FFF;
    text-decoration: underline;
}

.container.terms-of-use-container {
    padding-top: 6em;
}

.text-left {
    text-align: left;
}
.italic-blue {
   color: #1A8FFF;
}
.privacy-policy-last-section {
  display: flex;
  align-items: center;
}
.line-height-22 {
line-height: 22px;
}
.user_pop_button .MuiDialogActions-root {
    padding: 20px 24px 22px 24px;
    align-items: end;
}

.user_pop_button.content-scrollable .MuiDialogActions-root {
    padding: 20px 42px 22px 24px;
}

.user_table td {
    padding: 0px 16px;
}

.user_table th {
    padding: 0px 16px;

}

.MuiTable-root thead tr th {
    font-weight: bold;

}

.edit_user_table .MuiSvgIcon-root {
    width: .8em;
    height: .8em;
}

.table_outer_wrapper {
    padding-bottom: 20px;
}

.user_table_dropdown_section {
    padding: 10px 0px !important;
}

.userlist_dailog_content {
    padding: 0px;
}

.adduser-password-label {
    display: flex;
    flex-direction: column;
}

.adduser-password-label span:last-child {
    font-size: 12px;
}

@media(min-width: 1367px) {
    .user_pop_button.content-scrollable .MuiDialogActions-root {
        padding: 20px 24px 22px 24px;
    }
}

.admin_subskill_topsection.manage-user-table-wrapper {
    margin-bottom: 1.5rem;
}


.users_top_section_wrapper {
    display: flex;
    justify-content: space-between;
}

@media (min-width: 1280px) {
    .allignmentFix {
        max-width: none;
        margin-top: 20px;
    }
}

.mt-110 {
    margin-top: 110px;
}

.userlist_mainContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    height: 85vh;
}

.userlist_heading {
    font-size: 30px;
    color: #01313c;
    font-weight: 600;
    font-family: "Segoe-Semibold";
    font-size: 24px;
    padding: 5px 3px;
    color: #2896ff;
    border-bottom: 1px solid #2896ff;
    width: 72px;
    margin-bottom: 10px;
}

/* .userlist_content_container {
    display: flex;
    flex-direction: row;
} */

.userlist_content_container{
    display: flex;
    width: 100%;
}

.userlist_leftside_mainContainer {
    width: 400px;
    /* min-height: 1100px; */
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}

.userlist_rightside_mainContainer{
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    /* border-radius: 20px; */
    width: calc(100% - 410px);
    /* width: 1300px; */
    margin-left: 25px;
}

.userlist_topSection{
    padding: 15px;
    padding-left: 10px;
    background-color: #D1E8FF;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.userlist_showingRF {
    font-family: 'Segoe UI';
    font-size: 16px;
    color: #04093F;
}

.userlist_showMyCandidateContainer {
    margin-top: 10px;
}

.userlist_booleanText {
    font-size: 14px;
    font-family: "Segoe UI";
    opacity: 0.7;
    color: #43425D;
}

.userlist_bottomSection {
    padding: 15px;
    padding-top: 0px;
}

.inputItemsContainer {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}

.userlist_leftside_content {
    margin-bottom: 20px;
}

.userlist_title {

    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.userlist_kendostyle{
    height: 65vh;
    width: calc(100% - 20px);
}

.userlist_kendogridContainer {
    padding: 0px 10px 10px 10px;
    width: 100%
}
/* 
.phone_input_userlist {
    max-width: 94.5% !important;
    max-height: 45px !important;
} */

.phone_input_userlist .PhoneInputInput {
    padding: 10px;
    border: none;
    font-size: 16px;
}

.textbox_phone_input_userlist {
    height: 46px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}

.phone_input_userlist .PhoneInputInput {
    border: none;
    outline: none;

}

.leftSideMainContainer_user{
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 502px;
}

.rightSideMainContainer_user{
    width: calc(100% - 410px);
}
/*------------- VIDEO INTERVIEW QUESTION ---------------*/

.videosection {
  width: 100%;
}

.vl_question {
  border-left: 1px solid #a3bcd5;
  /* / height: 273px; / */
  margin-left: 20px;
  margin-top: 0px;
}

/*------------- VIDEO INTERVIEW QUESTION ---------------*/

/*------------- STEP CSS ---------------*/

.bs-stepper .step-trigger {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.bs-stepper .step-trigger:focus {
  outline: none;
}

.bs-stepper-label {
  display: inline-block;
  margin: 0.25rem;
}

.bs-stepper-header {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bs-stepper-line,
.bs-stepper .line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: 9px;
  background-color: #a3bcd5;
}

.success_line {
  background-color: #2ad156;
}

.bs-stepper-circle {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  color: #adb5bd;
  border: 1px solid #a3bcd5;
  border-radius: 100%;
  line-height: 37px;
  font-size: 16px;
  background-color: #fff;
  font-weight: normal;
  font-family: "Segoe-Regular";
  outline: none;
}

.active .bs-stepper-circle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.success .bs-stepper-circle {
  color: #fff;
  background-color: #2ad156;
  border-color: #2ad156;
  font-family: "Segoe-Bold";
  font-size: 16px;
}

.bs-stepper-content {
  padding: 0 20px 20px;
}

.bs-stepper.vertical {
  display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
  display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden;
}

/* .bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
  display: none;
} */

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
  display: block;
  visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
  display: block;
}

/* .bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
  display: none;
} */

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
  visibility: hidden;
}

/*------------- STEP CSS ---------------*/

/*------------- RADIO BUTTON ---------------*/

.questionoption [type="radio"]:checked,
.questionoption [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.questionoption [type="radio"]:checked + label,
.questionoption [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 43px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  color: #01313c;
  margin-top: 25px;
}

.questionoption [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #1a8fff;
  border-radius: 100%;
  background: #fff;
}

.questionoption [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #01313c;
  border-radius: 100%;
  background: #fff;
}

.questionoption [type="radio"]:checked + label:after,
.questionoption [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: #1a8fff;
  position: absolute;
  border: 1px solid #1a8fff;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.questionoption [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.questionoption [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

/*------------- RADIO BUTTON ---------------*/



.interview-question-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.interview-question-scroll::-webkit-scrollbar-thumb {
  background-color: #a5a4a4;
  border-radius: 30px;
}
/* .viewtestresults-questionside::-webkit-scrollbar {
  width: 5px;
  height: 5px;
} */
/* .viewtestresults-questionside::-webkit-scrollbar-thumb {
  background-color: #a5a4a4;
  border-radius: 30px;
} */
.flex-video-top {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.next-button {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.stepcontainer {
  display: flex;
  align-items: center;
  flex: 1 0 32px;
}

.text-success {
  color: #2ad156;
}
.interview-text {
  font-family: "Segoe UI";
  font-size: 14px;
}
#video-element {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); 
  -moz-transform: rotateY(180deg); 
  /* width: 462px; */
  /* max-height: 274px; */
  object-fit: cover;
}
.react-timer {
  padding: 0 !important;
  display: inline-block !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.react-timer > h3{
  font-size: 100% !important;
}
.react-timer button {
  display: none;
}
.timer-label-static {
  font-size: 100% !important;
  font-weight: 200;
  line-height: 1.5;
  margin: 0px;
  color: rgb(102, 102, 102);
}
.fs-regular.fs-14.f-color.mr_14.mt_23.text-success.interview-text {
  color:#2ad156 !important;
}

.question-card-container > p {
  text-align: left;
}
.start_test_text_top {
  text-align: left;
  margin-top: 10px;
}
.start_test_text_bottom  {
  text-align: center;
}

.ongoing-video-next-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 1rem;
  padding-top: 1.2rem;

}
.time-label-limit {
  display: flex;
}

.time-label-limit span {
  color: #01313C;
  font-weight: 400;
  font-family: 'Segoe-Semibold';
}
.react-timer h3 {
  color: #01313C !important;
}

.cardheight {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardheight.cardheight_endtest{
  height: auto;
  margin-top: 1em;
  padding: 2rem 0rem;
}
.start_test_text_top.after_endtest_submit{
  padding: 4rem 0rem;
}
@media(max-width: 767px) {
  .end-test-container {
  padding: 0rem 1rem;
  }


  .nav_logo_home {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 70%
}
  
}

.react-timer {
  display: none !important;
}
.end-test-comment-wrapper {
  width: 100%;
}
.end-test-comment-wrapper legend{
  text-align: left;
}
.end-test-comment-wrapper .text_area_cleartalent {
  width: 94%;
  padding: 10px 12px;
}
.end-test-comment-wrapper-btn {
  text-align: right;
  margin-top: 12px;
}
.end-test-star img {
  width: 35px;
  margin-right: 5px;
}

.questionoption.questionoption-alignment_card [type="radio"]:checked + label, .questionoption.questionoption-alignment_card [type="radio"]:not(:checked) + label {
  padding-left: 0px;
  display: flex;
  align-items: center;
  }
  .questionoption.questionoption-alignment_card [type="radio"]:not(:checked) + label:before {
  position: initial ;
  margin-right: 1rem;
  flex-shrink: 0;
  
  
  }
  
  .questionoption.questionoption-alignment_card [type="radio"]:checked + label:before {
  position: initial;
  margin-right: 1rem;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment_card [type="radio"]:checked + label:after {
  top: initial;
  left: 7px;
  }
  .questionoption.questionoption-alignment_card [type="radio"]:checked + label:before {
  margin-right: 1rem;
  position: inherit;
  flex-shrink: 0;
  }
  .questionoption.questionoption-alignment_card [type="radio"]:checked + label:after {
  left: 7px;
  top: inherit;
  }
  .questionoption-alignment_card .interview-chkbox input:checked + label:after {
  
  display: flex;
  align-items: center;
  top: inherit;
  }
  
  
  .questionoption-alignment_card .interview-chkbox label {
  
  display: flex;
  align-items: center;
  }
  
  .questionoption.questionoption-alignment_card  [type="radio"]:checked + label:after, .questionoption.questionoption-alignment_card  [type="radio"]:not(:checked) + label:after {
  top: inherit !important;
  }
  .string-whitespace-pre {
    white-space: pre;
  }
  .block-answer {
    white-space: pre;
  }

@media (min-width: 768px) and (max-width: 1366px) {
  .block-answer-wrapper {
    font-size: 16px;
  }
  .card.flex-video-bottom {
    padding: 14px 20px 23px 20px;
  }
  .register-test-container {
    max-width: 1200px;
  }
  .question-title {
    font-size: 16px;
  }
  .questionoption-alignment_card .interview-chkbox label {
    font-size: 16px;
  }
  .scrollHeight {
    /* max-height: 47vh; */
    overflow-y: scroll;
    height: 310px;
  }
  .vl_question {
    height: auto;
  }
  .bs-stepper-circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  /* .video-information-wrapper .mw {
    width: 50%;
  } */
  #video-element {
    width: 100%;
    max-height: 305px;
  }
  .skillname_fontsize{
    font-size: 23px;
  }
  .flex-video-bottom {
    height: 100%; 
    /* position: relative; */
  }
}
@media (min-width: 1367px) {
  .scrollHeight {
    /* max-height: 55vh; */
    overflow-y: scroll;
    height: 470px;
  }
  /* .video-information-wrapper {
    height: 60vh
  } */
  .vl_question {
    height: 52vh
  }
  .questioncardwidth{
    max-width: 1200px;
  }
  .register-test-container {
    max-width: 1200px;
  }
  
}
@media(min-width: 1367px) {
  .flex-video-bottom {
    /* height: calc(100vh - 132px); */
    position: relative;
  }
  #video-element {
    width: 100%;
    max-height: 100%;
    height: 82%;
  
   
  }
  .skillname_fontsize{
    font-size: 32px;
  }
  .question-title{
    font-size: 18px;
  }
  .block-answer-wrapper{
    font-size: 16px;
  }
  .card.flex-video-bottom {
    padding: 14px 20px 23px 20px;
  }
  .nav_bg_left {
    width: 21%;
}

}
@media(max-width: 1367px) and (min-width: 768px) {
  
}
 .right-pane-qa-video {
   width: 50%;
 }
 .interview-question-scroll {
   width: 96%;
 }
 .end_test_Loading_wraper{
  padding: 9em 0em;
 }
 .card.card-video-height {
  padding: 10px 20px 23px 20px;
 }

 /* p.string-whitespace-pre:empty {
   background: red;
 }
 p.without-line-break {
   background-color: red;
 } */
 p#index0 {
   display: none;
 }


 .registerstartTestRefferal{
  color: #1a8fff;
  font-size: 23px;
  font-family: "segoe-regular";
  margin-top: 63px;
  font-weight: 600;
 }

 .registerstartTestcolor{
  cursor: pointer;
  color: red;
 }

 .registerstartTestcolor:hover{
  color:blue;
 }

 #testQuestionDescp h2 {
  -webkit-margin-before: 0;
          margin-block-start: 0; /* or margin-block-start: initial; */
}
#testQuestionDescp h1 {
  -webkit-margin-before: 0;
          margin-block-start: 0; /* or margin-block-start: initial; */
}

.mainContainer_videoSkillTest {
    min-height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.testNameHeader {
    font-family: 'Manrope-Semibold';
    font-size: 35px;
    color: #04093F,
}

.mainDiv_videoSkillTest {
    margin: 0 11%;
    margin-top: 130px;
}

.testInfoContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.timeLeft {
    font-family: 'Manrope-Bold';
    font-size: 14px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.timeleftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressBarContainer {
    width: 260px;
}

.progressBarContainer .MuiLinearProgress-barColorPrimary {
    background-color: #1885E6 !important;
    border-radius: 10px;
}

.progressLabel_allignment_center {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progressLabel_allignment_start {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.progressLabel_allignment_end {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.questionContainer {
    display: flex;
    margin-left: 30px;
    margin-top: 30px;
}

.line_question {
    height: 76px;
    border-left: 5px solid #1885E6;
}

.questionNoContainer {
    height: 76px;
    border-left: 5px solid #1885E6;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.questionNo {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: #1885E6;
    color: #ffffff;
    font-family: 'Manrope-Semibold';
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionName {
    font-family: 'Manrope-Regular';
    font-size: 17px;
    color: #04093F;

}

.questionNameContainer {
    height: 100%;
    width: 100%;
    padding-right: 2px;
    padding-top: 10px;
}

@media (max-width: 600px) {
    .questionNameContainer {
        margin-left: 12px;
    }

    .testNameHeader {
        font-size: 18px;
    }

}

@media (max-width: 1100px) {
    .testInfoContainer {
        flex-direction: column;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
    }

    .testNameHeader {
        font-size: 25px;
    }

    .referral {
        font-size: 18px !important;
        cursor: pointer;
    }
}


.answerHeading {
    margin-top: 17px;
    height: 76px;
    display: flex;
    align-items: center;
    font-family: 'Manrope-bold';
    font-size: 17px;
    color: #04093F;
}

.video_Container_Grid {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 280px;
}

.video_camera {
    height: 160px;
    width: 220px;
    background-color: #707070;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    position: absolute;
    z-index: 10000;
    cursor: move;
    margin-top: -160px;
    margin-right: 20px;
}

.topDiv_video {
    z-index: 10000;
    cursor: move;
}

.bottomDiv_video {
    background-color: #D1E8FF;
    height: 33px;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.micIcon {
    margin-left: 5px;
}

.bottomContainer_quesCard {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.bottomContainer_quesCard:hover {
    opacity: 0.8;
}

.cardContainer_review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

.text_area_skillTest {
    font-size: 16px;
    font-family: "Segoe-Regular";
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
    width: 98%;
}

.SubmitButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;
}

#backProgress .MuiLinearProgress-barColorPrimary {
    background-color: #1885E6 !important;
    opacity: 0.25 !important;
    border-radius: 10px;
}

.tleft {
    margin-right: 2px;
}

.container_skillTest {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    padding-top: 4px;
    cursor: pointer;
    font-size: 17px;
    font-family: 'Source sans pro', sans-serif;
    -webkit-user-select: none;
    user-select: none;
}

.container_skillTest input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.container_skillTest .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #D7E7FF;
    border-radius: 5px;
    opacity: 0.8;
}

.container_skillTest:hover input~.checkmark {
    opacity: 1;
    border: 2px solid #1885E6;
}

.container_skillTest input:checked~.checkmark {
    background-color: #2196F3;
    border: none;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.container_skillTest input:checked~.checkmark:after {
    display: block;
}

.container_skillTest .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.container_radio_skillTest {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    padding-top: 4px;
    cursor: pointer;
    font-size: 17px;
    font-family: 'Source sans pro', sans-serif;
    -webkit-user-select: none;
    user-select: none;
}

.container_radio_skillTest input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.container_radio_skillTest .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #D7E7FF;
    border-radius: 5px;
    border-radius: 50%;
    opacity: 0.8;
}

.container_radio_skillTest:hover input~.checkmark {
    opacity: 1;
    border: 2px solid #1885E6;
}

.container_radio_skillTest input:checked~.checkmark {
    background-color: #2196F3;
    border: none;
}

.container_radio_skillTest:after {
    content: "";
    position: absolute;
    display: none;
}

.container_radio_skillTest input:checked~.checkmark:after {
    display: block;
}

.container_radio_skillTest .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.timeCount {
    font-family: 'Manrope-Semibold';
    font-size: 35px;
    margin-top: -7px;
}

.referral {
    margin-top: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    font-size: 22px;
    letter-spacing: 1px;
    animation-name: referal_link;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    margin: 40px 0px;
    text-align: center;
    text-shadow: 1px 1px 0 hsl(164deg 18% 20%);
    font-family: 'Manrope-Semibold';
    cursor: pointer;
}

@keyframes referal_link {
    from {
        color: orangered;
    }

    to {
        color: blue;
    }
}

.registered_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.successImagess {
    height: 100px;
    width: 100px;
    margin-bottom: 50px;
}

.containerfor_circularPrg {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
}

.containerfor_circul {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
}

.mainContainer_Test{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}
.testHeader_Test{
    margin: 0 11%;
    margin-top: 120px;
}
.testBody_test{
    margin: 0 11%;
    margin-top: 10px;
    height: 66vh;
    margin-bottom: 10px;
}
.mainTestContainer{
    height: 88%;
    overflow:auto;
}
.nextButtonContainer{
    height: 12%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    margin-left: 20px;
    border-top: 1px solid #DCF4FF;
}
.videoScreen_main{
    height: 16vh;
    cursor: move;
    position: fixed;
    background-color: #707070;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    left:58vw;
    top:0;
    z-index: 10000;
}
.bottomDiv_video {
    background-color: #D1E8FF;
    height: 3vh;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottomDiv_video .MuiSvgIcon-root{
    height: 3vh;
}
@media (max-width: 850px) {
    .videoScreen_main{
        height: 100px;
        width: 111px;
        left: 70vw;
    }
    .bottomDiv_video{
        height: 20px;
    }
}
.footer_bg {
  width: 100%;
  background-color: #04093F;
  margin-top: 20px;
  }
  
  .footer_bg_sec {
  width: 25%;
  }
  
  .footer_links {
  margin-bottom: 0px;
  padding-left: 0px;
  margin-top: 34px;
  
  }
  
  .footer_links li {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 40px;
  }
  
  .footer_links li:last-child {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px 0px 40px;
  }
  
  .footer_links li a {
  color: #D1E8FF;
  text-decoration: none;
  font-size: 16px;
  
  font-family: 'Segoe-Regular';
  }
  
  
  
  .footer_links li a:hover::after {
  opacity: 1;
  }
  
  .footer-text-color {
  color: #D1E8FF;
  }
  
  .footer-copyright {
  opacity: .4;
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
  }

.Colorcss {
  color: #d1e8ff;
  text-decoration: none;
  font-size: 16px;
  font-family: "Segoe-Regular";
}
.footerlogo_adjust{
  margin-left: 0px;
}
.footer_adjust {
  display: flex;
  justify-content: space-between;
  }
  
  
  .new4 {
  border-top: 1px solid #d1e8ff2b;
  }
  .footerlogo{
    width: 100%;
  }
  .footerlogo_section{
    width: 25%;
  }
  .poweresume-footer {
    font-size: 1.0625em;
    color: #D1E8FF;
    font-family:'Segoe-Regular';
    text-decoration: underline;
  }
  .poweresume-footer-copyright {
    font-size: 1.0625em;
    font-family:'Segoe-Regular';
    color: #D1E8FF;
  }
/*------------- COMMON CSS COMPONENT ---------------*/

@media only screen and (max-width: 768px) {
  .footerlogo_section{
    width: 100%;
    margin-left: 0rem;
  }
  .footer_bg_sec {
    width: 100%;
    padding-left: 20px;
  }

  .footer_bg {
    width: 100%;
    background-color: #04093f;
    margin-top: 20px;
    /* padding: 20px; */

  }
  .footer_links {
    margin-bottom: 0px;
    padding-left: 0px;
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
  }
  .footer-copyright {
    opacity: 0.4;
    padding-top: 15px;
    padding-bottom: 15px;
    
  }
  .footerlogo_adjust{
    margin-left: 20px;
  }
  .footer_links li {
    list-style-type: none;
    line-height: 40px;
    display: inline;
    padding: 0px;
    

    }
    
    .footer_links li:last-child {
    list-style-type: none;
    line-height: 40px;
    display: inline;
    padding: 0px;
    }
    .footer_adjust {
      display: contents;
      justify-content: unset;
  }
}

.email-configuration-wrapper {
  background-color: #fff;
  padding: 1.5rem;
}
.email-configuration-btn {
  text-align: right;
}

.email-configuration-wrapper .MuiTableCell-head {
  font-weight: bold;
}
.email-configuration-container .flex-to-footer {
  width: auto !important;
}
.distribution-email-count-wrapper {
  display: flex;
  align-items: center;
}
.distribution-email-count-wrapper input {
  width: 80%
}
.distribution-email-count-wrapper span {
  width: 20%;
  cursor: pointer;
  /* text-align: center; */
  margin-left: 2rem;
}
.email-configuration-wrapper tr td{
  width:50%;
}
.email-configuration-wrapper tr td:first-child {
  width: 30%;
}
/* .distrubution-list tr td:nth-of-type(3) {
  width: 26%;
} */
.distrubution-list tr td{
width: 25%;
}
.question-report-view-resolved.MuiTableCell-root{
   display: flex;
}
.answer-set-container-question-report{
   
      display: flex;
      flex-direction: column;
      padding: 1rem 0rem;
   
}
.radiobtn-questionreport .MuiRadio-colorSecondary.Mui-checked {
      color: #1a8fff;
}
.answer-set-list-questionreport{
   display: flex;
    align-items: center;
    justify-content: space-between;
}
.radiobtn-questionreport .MuiCheckbox-colorSecondary.Mui-checked{
   color: #1a8fff;
}
.link-colour{
   color: #3f51b5 !important;
}
.textareaComment{
   width: 90%;
   outline: none;
   height:45px;
   border: 1px solid rgba(0, 0, 0, 0.23);
   padding: 14px 20px;
   border-radius: 4px;
   font: inherit;
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;

}
.btnAction{
   padding-bottom: 0px;
}

@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}

.question-list-section-wrapper .MuiTableCell-head {
  font-weight: bold;
}
.answer-set-container {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
}
.answer-set-list {
  display: flex;
  align-items: center;
}
.question-list-section-wrapper {
  background: #fff;
}
.subkill-filter-question .MuiFormControl-root {
  width: 100%;
}
.question-list-section-heading h2 {
  margin-top: 0;
  margin-bottom: 15px;
}
.question-list-section-wrapper {
  padding: 1rem;
}
.subkill-filter-question {
  margin-bottom: 0.5rem;
}
.answer-set-list .MuiSvgIcon-root {
  font-size: 2rem !important;
}
.answer-set-list .MuiFormControlLabel-root {
  margin-right: 0;
}
.questions-list-table .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px;
}
.go-to-question a {
  color: #000;
}
.subkill-filter-question-addquestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subkill-filter-question-wrapper{
  width: 100%;
  margin-right: 1rem;
}
.circular-progress-center-question {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 100px;
}
@media(max-width: 767px) {
  .subkill-filter-question-addquestion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .subkill-filter-question-addquestion .subkill-filter-question-wrapper:first-child {
    margin-bottom: 1rem;
  }
}
.correct-answer-layout {
  background: #2AD156;
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 4px;
}
.searchBox {
  display: flex;
  /* justify-content: flex-start; */
  height: 100%;
  margin-bottom: 20px;
}

.searchBox .MuiInputBase-root {
  height: 110%;
  margin-right: 30px;
 
  width:100%
}
.textfield{
  width:"39%";
 margin-right:30px;
}
.searchLabel{
  margin-bottom: 20px;
}

.k-grid-header-locked > table, .k-grid-header-wrap > table {
  margin-bottom: -1px;
  
  width: 100% !important;
}

.k-grid-content table, .k-grid-content-locked table {
  table-layout: fixed;
  width: 100% !important;
}

.btn_question {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 11px 24px 11px 23px;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'Segoe-Semibold';
}
.cursorChange{
  cursor: pointer;
}

.mt_9 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 13.8px 10px !important; 
}
.edit-question-wrapper {
  background: #fff;
  padding: 30px;
}
.edit-question-answer-list .MuiOutlinedInput-root {
  margin-bottom: 1rem;
} 
.edit-question-answer-wrapper .MuiFormControl-fullWidth {
  width: 80%;
}
.edit-question-answer-wrapper  {
display: flex;
align-items: center;

}
.edit-question-choice-handle .MuiFormControlLabel-labelPlacementStart {
  /* width: 100%; */
  margin: 0;
}
.choice-tick-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-question-wrapper textarea {
  font-size: 16px;
  font-family: 'Segoe-Regular';
  padding: 0.5rem;
}
.question-list-section-wrapper .MuiTableCell-head.isactive_nowrap{
  white-space: nowrap;
}
.editQuestion_optiontextbox{
  margin-bottom: 10px;
}

.mySelect__value-container{
  height: 44px;
}



 /* PrismJS 1.25.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript+java+python */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

 code[class*="language-"],
 pre[class*="language-"] {
   color: black;
   background: none;
   text-shadow: 0 1px white;
   font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
   font-size: 1em;
   text-align: left;
   white-space: pre;
   word-spacing: normal;
   word-break: normal;
   word-wrap: normal;
   line-height: 1.5;
   tab-size: 4;
 
   -webkit-hyphens: none;
   hyphens: none;
 }
 
 pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
 code[class*="language-"]::selection, code[class*="language-"] ::selection {
   text-shadow: none;
   background: #b3d4fc;
 }
 
 @media print {
   code[class*="language-"],
   pre[class*="language-"] {
     text-shadow: none;
   }
 }
 
 /* Code blocks */
 pre[class*="language-"] {
   padding: 1em;
   margin: .5em 0;
   overflow: auto;
 }
 
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
   background: #f5f2f0;
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
   padding: .1em;
   border-radius: .3em;
   white-space: normal;
 }
 
 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
   color: slategray;
 }
 
 .token.punctuation {
   color: #999;
 }
 
 .token.namespace {
   opacity: .7;
 }
 
 .token.property,
 .token.tag,
 .token.boolean,
 .token.number,
 .token.constant,
 .token.symbol,
 .token.deleted {
   color: #905;
 }
 
 .token.selector,
 .token.attr-name,
 .token.string,
 .token.char,
 .token.builtin,
 .token.inserted {
   color: #690;
 }
 
 .token.operator,
 .token.entity,
 .token.url,
 .language-css .token.string,
 .style .token.string {
   color: #9a6e3a;
   /* This background color was intended by the author of this theme. */
   background: hsla(0, 0%, 100%, .5);
 }
 
 .token.atrule,
 .token.attr-value,
 .token.keyword {
   color: #07a;
 }
 
 .token.function,
 .token.class-name {
   color: #DD4A68;
 }
 
 .token.regex,
 .token.important,
 .token.variable {
   color: #e90;
 }
 
 .token.important,
 .token.bold {
   font-weight: bold;
 }
 .token.italic {
   font-style: italic;
 }
 
 .token.entity {
   cursor: help;
 }

 .edit_question_order .MuiFormControl-fullWidth {
  width: 100% !important;
}
.widthFix{
  width: 100%;
}

.mt_10 input[type="text"] {
  padding: 16px 0px 16px 14px !important;
}
.ql-editor{
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}
.demoDialog .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding-left: 25px !important;
}

.demoName {
    font-family: 'Manrope-Medium';
    font-size: 16px;
    color: #666666;
}

.demoNameTitle {
    font-family: 'Manrope-Medium';
    font-size: 16px;
    color: #666666;
    margin-bottom: 13px;
}
.bb{
    outline: none;
    border: 1px solid #c6c0c0;
    border-radius: 4px;
    padding: 10px;
}

.demoDialog .datePicker .MuiInputBase-root{
    padding-bottom: 10px;
}

.datePicker{
    width: 100%;
}

.MuiPickersToolbar-toolbar{
    background-color: #1885E6 !important;
}

.MuiPickersDay-daySelected{
    background-color: #1885E6 !important;
}

.demoTextBox{
    height: 47px;
    width: 100%;
}

.textbox_phone_input_demo .PhoneInputInput{
    border: none; 
    outline: none;
    opacity: 0.6;
    font-size: 16px;
}


.textbox_phone_input_demo .PhoneInputInput:focus{
    outline: none;
}

.textbox_phone_input_demo {
    height: 47px;
    width: 93%;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}



.admin-container {
  background: #fff;
  padding: 2em 0em;
  border-radius: 4px;
}
.admin-section-home-first,.admin-section-home-second {
  display: flex;
  /* justify-content: space-between; */
  padding: 0rem 0.5rem;
}
.page-box-element {
  border: 1px solid #1A8FFF;
  padding: 20px;
  width: 23%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-left: 14px;
  margin-right: 5px;
  text-align: center;
}

hr.admindivider {
  border-top: 1px dashed #b9b9b9;
    margin: 0px 22px;
}
.mt_12 {
  margin-top: 12px;
}

.page-box-element_last {
  padding: 20px;
  width: 23%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin-left: 14px;
  cursor: default;
  margin-right: 5px;
}
.admin-container h3 {
  padding: 0rem 0.5rem;
}
.page-box-element:hover {
  background:#1A8FFF;
}
.page-box-element:hover p{
  color:#fff;
}
.page-box-element:hover img{
  filter: brightness(0) invert(1);
}
.page-box-element img {
  width: 38px;
  height: 38px;
}
@media (max-width: 767px) {
  .admin-section-home-first,.admin-section-home-second {
    flex-direction: column;
    padding: 0rem 1.2rem;
  }
  .page-box-element {
    width: auto;
    margin-bottom: 1rem;
  }
  .admin-container .admin-section-home-first {
    margin-bottom: 0px;
  }
  .page-box-element_last{
    display: none;
  }
}
#reportedquestion {
  background: #eee;
  position: relative;
}
#reportedquestion:hover p {
  color: #01313c
}

#reportedquestion:hover img {
  filter: none;
}
#reportedquestion .coming-soon {
position: absolute;
bottom: 3px;
}
.candidate-signin-wrapper {
  /* position: relative; */
  /* height: calc(100vh - 80px); */
  /* padding-top: 80px; */
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* height: 100vh; */
  background-color: #fff;
}

.candidate-signin-container {
  width: 550px;
  margin: 0 auto;
  /* border-radius: 5px; */
  background: #fff;
  /* box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.54); */
  /* border: solid 1px; */
  /* padding: 10px; */
}

.candidate-signin-btn button:disabled {
  opacity: 0.5;
}

.candidate-signin-btn-fp button:disabled {
  opacity: 0.5;
}

.candidate-signin-btn, .candidate-signin-btn-fp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
}

.candidate-signin-btn .fplink {
  color: #04093F;
  text-decoration: underline;
}

.fp-email-wrapper {
  display: flex;
  /* justify-content: flex-start; */
  align-items: flex-start;
}

.fp-email-with-err {
  /* width: 78%; */
  width: 69%;
  position: relative;
  /* flex-shrink: 0;
  flex: 1; */
  margin-right: 10px;
}

.fp-email-with-err p {
  /* position: absolute; */
  bottom: -6px;
}

/* .fp-email-wrapper .otp-input {
  width: 70%;
} */

.btn-reset-pwd {
  padding: 12px 12px;
}

.btn-cancel-pwd {
  padding: 12px 24px;
}

.email-label-notice {
  font-size: 15px;
  margin-left: 5px;
  font-style: italic;
  color: #01313C;
  position: relative;
  bottom: 1px;
}

.full-width {
  width: 100%;
}

.signInBtnAfterReset{
  background-color: #009EFD;
  height: 50px;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 18px;
}

.candidate-signin-title {
  padding: 20px 0;
  background-color: #fff;
  color: #000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.fs-small {
  font-size: small;
}

.candidate-signin-main-content {
  padding: 25px;
}

.btn-candidate-signin {
  background-color: #1A8FFF;
  color: #fff;
}

.candidate-password-textbox {
  width: 100%;
  padding: 14px 20px!important;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c6c0c0;
}

.candidate-signin-btn-fp-screen {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 18px;
}

.btn.btn-fp-cancel {
  border: 1px solid #01313C;
  background: #fff;
}

.btn.btn-sendotp {
  padding: 12px 15px;
}

.btn.btn-timer {
  padding: 12px 0px;
  width: 100px;
}

.btn.btn-resendotp {
  padding: 12px 6px;
}

.btn-reset-pwd:disabled {
  opacity: 0.5;
}

/*Start: Sign in page css*/

.login_control input[type=text], input[type=password] {
  width: 100%;
  padding: 8px 20px !important;
  margin: 8px 0;
  display: inline-block;
  /* border: 1px solid #707070; */
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Manrope-SemiBold';
}

/* Set a style for all buttons */

.login_control button {
  background-color: #009EFD;
  border: 1px solid #009EFD;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Manrope-Regular';
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
}

.full-width {
  width: 100%;
}

.login_control button:hover {
  opacity: 0.8;
}

.login_control label {
  font-family: 'Manrope-SemiBold';
  font-size: 16px;
  color: #000;
}

.clear_login_remember_sec {
  display: flex;
  justify-content: space-between;
}

.mt_34 {
  margin-top: 34px;
}

.login_container {
  width: 450px;
  margin: 1rem 0;
}

.nav_bg_login {
  width: 100%;
  background-color: #fff;
  /* position: fixed; */
  z-index: 99;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.45);
}

/* .candidate-signin-wrapper {
  position: relative;
} */

.footer_links {
  margin-bottom: 0px;
  padding-left: 0px;
  margin-top: 34px;
}

.footer_bg_login {
  width: 100%;
  background-color: #C2C2C2;
  margin-bottom: 40px;
}

.footer_adjust {
  padding-bottom: 70px;
}

.form-group1 input[type=checkbox] {
  display: none;
  cursor: pointer;
}

.form-group1 input[type=checkbox]+label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #0076B5;
  border-radius: 0px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group1 input[type=checkbox]:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 4px;
  height: 10px;
  border: 1px solid #00a7ff;
  border-width: 0 2px 2px 0;
  transform: rotate( 45deg);
}

.chklabel {
  position: relative;
  color: #000000;
  font-family: 'Manrope-Medium';
  font-size: 12px;
}

.fgtext {
  color: #000000;
  font-family: 'Manrope-Semibold';
  font-size: 12px !important;
}
.resetPwdOTPText{
  font-family: "Manrope-Semibold";
  font-size: 16px;
}

/*End: Sign in page css*/

/* input[type=text] {
  padding: 8px 20px!important;
} */

.f_height {
  height: 20px;
}

.footer_links li {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px;
}

.footer_links li:last-child {
  list-style-type: none;
  line-height: 40px;
  display: inline;
  padding: 0px 0px 0px 0px;
}

.footer_links li a {
  color: #000;
  text-decoration: none;
  font-size: 1.0625em;
  font-family: 'Manrope-Medium';
}

.footer_links li a:hover::after {
  opacity: 1;
}

.footer-text-color {
  color: #D1E8FF;
}

.footer-copyright {
  opacity: .4;
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
}

.reset-password-container {
  /* width: 525px; */
  width: 430px;
  margin: 60px auto;
}

.reset-password-container button {
  background-color: #009EFD;
  border: 1px solid #009EFD;
  color: white;
  padding: 8px 20px;
  margin: 8px 0 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 108px;
  font-size: 14px;
  font-family: 'Manrope-Regular';
  box-shadow: 2px 3px 5px 0px rgb(0 0 0 / 45%);
}

#otp-input {
  padding: 0px;
  padding-left: 4px;
  letter-spacing: 24px;
  border: 0;
  background-image: linear-gradient(to left, #167ffc 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 33px 1px;
  background-repeat: repeat-x;
  background-position-x: 19px;
  width: 220px;
  min-width: 220px;
  margin-bottom: 0px;
}

#otp-content {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
}

#otp-holder {
  width: 190px;
  overflow: hidden
}

#otp-in {
  border: none;
    width: 12ch;
    background: repeating-linear-gradient(
      90deg , dimgrey 0, dimgrey 1.5ch, transparent 0, transparent 2.034ch) 0 100%/100% 2px no-repeat;
    color: dimgrey;
    font: 4.5ch consolas, monospace;
    letter-spacing: 1ch;
    color: #000;
}

#otp-in:focus {
  outline: none;
  color: dimgrey;
}

.otp_sec {
  display: flex;
  justify-content: space-between;
}

.w-70 {
  width: 70%;
}

.message-container {
  width: 350px;
  margin: 60px auto;
}
.login-box-border {
  border: 1px solid #d4d4d4;
  padding: 20px;
}
.login_wrapper_main {
  display: flex;
    width: 100%;
    justify-content: center;
}
.singin_box{
  padding-bottom: 12vh;
}
.error_message{
  margin-top: 5.5vh;
}

@media only screen and (max-width:1366px) and (min-width: 768px) {
  .login_container {
    width: 450px;
    /* margin-top: 1rem;
    margin-bottom: 2rem */
  }
/* .candidate-signin-wrapper {
  position: relative;
  height: unset;
} */
.login_wrapper_main{
  display: flex;
    width: 100%;
    justify-content: center;
    
}
 
}
.ht_47{
  height: 47px;
}

.emailStyle{
  font-size: 14px;
}
html {
    height: 100%;
}

body {
    margin: 0px;
    padding: 0px;
    /* background-color: #fff; */
    /* height: 100%; */
}

#root {
    /* height: 100%; */
}

.mainbg_form {
    background-color: #fff;
    min-height: 720px;
    /* height: auto; */
    height: 100%;
}

.mainbg_form_recruiter {
    background-color: #fff;
    /* min-height: 720px; */
    /* height: auto; */
    height: 100%;
}

.formlogo_adjust {
    width: 250px;
}

.PowerResume_Copy_form {
    vertical-align: super;
    font-size: 22px;
}

.leftSection {
    width: 50%;
    background-color: #04093F;
    position: relative;

}

.white_section_recruiter {
    background-color: white;
    height: 40px;
    width: 100%;
}

.rightSection {
    width: 50%;
    background-color: #fff;
    height: 100%;
}

.logo {
    width: 100%;
    text-align: center;
    margin-top: 100px;
}

.bgsec {
    width: 100%;
    text-align: center;

}

.dropdown_width_outer.formone-otpbtn {
    justify-content: initial;
}

.image_add {
    /* width: 225px; */
    margin-top: 10px;
    height: 100%;
    /* position: absolute;
    bottom: 0px;
    right: 19%;
    margin-left: -250px; */
}

.form_text_container {
    padding: 200px 50px 0px 200px;
}

.form_text_container_other {
    /* padding: 10px 100px 0px 100px; */
    padding: 0px;

}

.cp {
    cursor: pointer;
}

.profile_image {
    width: 93px;
    border-radius: 50%;
    height: 93px;
}

.image_addjust_inner {
    width: 93px;
    margin: 0px auto;
}

.text_box_cleartalent_form {
    width: 94%;
    /* height: 54px; */
    padding: 0px 0px;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 8px 12px;
    font-size: 16px;
    font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid #A3BCD5;
    border-radius: 4px;
}

input[type=text],
select {
    width: 100%;
    /* padding: 14px 20px !important; */

    display: inline-block;

    border-radius: 4px;
    box-sizing: border-box;
}





.MuiOutlinedInput-notchedOutline {
    border: 1px solid #292929;
}

.text_box_cleartalent_form1 {
    height: 48px;
    width: 94%;

    font-size: 14px;
    padding: 0 20px;
    font-size: 16px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;

}

input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.field_name {
    font-size: 16px;
    color: #04093F;
    font-family: 'Segoe-Regular';
    font-style: normal;

}

.text-right {
    float: right;
}

.progress_adjust {
    padding: 25px 0px 0px 0px;
    /* width: 597px; */
    margin: 0px auto;
    justify-content: center;
}

.margin-form-right {
    margin-top: 30px;
}

.hide-logo-formone-right {
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
    color: #000 !important;
    background-color: #EAEAEA !important;
    border-radius: 30px;
    width: 418px;
    height: 14px;
    margin-left: 20px;
    margin-top: 13px;
}

.w3-green,
.w3-hover-green:hover {
    color: #fff !important;
    background-color: #00CCBE !important;
    border-radius: 30px;
}


.w3-badge,
.w3-tag {
    background-color: #000;
    color: #04093F;
    display: inline-block;
    font-family: 'Segoe-Regular';
    padding: 8px 7px 8px 7px;
    text-align: center
}

.w3-green1,
.w3-hover-green1:hover {
    color: #04093F !important;
    background-color: #FFD9D9 !important;

}

.w3-badge {
    border-radius: 6px;
}

.camera_icon_form {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 0px;
    top: 0;
}

p {
    margin: 0px;
}

@font-face {
    font-family: 'Segoe-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('fonts/Segoe-Regular'), url(/static/media/SegoePro-Regular.80b70390.ttf) format('woff');
}

@font-face {
    font-family: 'Segoe-Semibold';
    font-style: normal;
    font-weight: 600;
    src: local('fonts/SegoePro-Bold'), url(/static/media/SegoePro-Semibold.0984fa39.ttf) format('woff');
}

@font-face {
    font-family: 'Segoe-Bold';
    font-style: normal;
    font-weight: 600;
    src: local('fonts/SegoePro-Bold'), url(/static/media/SegoePro-Bold.f0b557b5.ttf) format('woff');
}





/*------------- BUTTON CSS COMPONENT ---------------*/



.btn-green_form {
    color: #fff;
    background-color: #00D0AD;
}

.btn-green_form:hover {
    color: #fff;
    background-color: #00D0AD;
}

.btn-green_form:focus {
    outline: none;
}


.btn-form {
    color: #01313C;

    background-color: #D1E8FF;
}

.btn-form:hover {
    color: #01313C;
    background-color: #D1E8FF;
}

.btn-form:focus {
    outline: none;
}

hr.new1 {
    border-top: 1px solid #A3BCD5;
}

/*------------- BUTTON CSS COMPONENT---------------*/


/*-------------------------------------------------------- Hints------------------------------------------------------------
mr= margin-right, ml= margin-left, mt = margin-top, mb= margin-bottom, pt= padding-top, pb= padding-bottom, pl= padding-left
pr=padding-right, fs= font-size, mw=min-width, fsi=font-style, pr=position-relative
-------------------------------------------------------- Hints------------------------------------------------------------*/


/*------------- COMMON CSS COMPONENT ---------------*/

.row {
    width: 100%;
}

.fl {
    float: left;
}

/* .card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    transition: all .2s ease-in-out;
    padding: 24px 20px 23px 20px;
    border-radius: 4px;
    background-color: #FFFFFF;
} */

.text-uppercase {
    text-transform: uppercase;
}

/* .f-color {
    color: #04093F;
} */

.fs-semi-bold {
    font-weight: 600;
    font-family: 'Segoe-Semibold';
}

.fs-bold {
    font-weight: 600;
    font-family: 'Segoe-Bold';
}

.fs-regular {
    font-weight: normal;
    font-family: 'Segoe-Regular';
}

.fs-22 {
    font-size: 22px;
}

.fs-20 {
    font-size: 20px;
}

.fs-18 {
    font-size: 18px;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.fs-12 {
    font-size: 12px;
}

.fs-25 {
    font-size: 25px;
}

.fs-16 {
    font-size: 16px;
}

.fs-40 {
    font-size: 40px;
}

.fsi {
    font-style: italic;
}

.w100 {
    width: 100%;
}

.fs-12 {
    font-size: 12px;
}

.mr_14 {
    margin-right: 14px;
}

.pr {
    position: relative;
}

.mt_19 {
    margin-top: 19px;
}

.mt_40 {
    margin-top: 40px;
}

.mt_23 {
    margin-top: 23px;
}

.mt_22 {
    margin-top: 22px;
}

.mt_41 {
    margin-top: 41px;
}

.mt_9 {
    margin-top: 9px;
}

.mt_8 {
    margin-top: 8px;
}

.df {
    display: flex;
}

.ml_25 {
    margin-left: 25px;
}

.ml_10 {
    margin-left: 10px;
}

.ml_22 {
    margin-left: 22px;
}

.mw {
    width: 50%;
}

.mt_40 {
    margin-top: 40px;
}

.mw_36 {
    width: 36%;
}

.pt_6 {
    padding-top: 6px;
}

.pt_23 {
    padding-top: 23px;
}

.pl_8 {
    padding-left: 8px;
}

.pt-24 {
    padding-top: 24px;
}

.mb_3 {
    margin-bottom: 3px;
}

.mb_20 {
    margin-bottom: 20px;
}

.w1004 {
    max-width: 1004px;
}


/*------------- COMMON CSS COMPONENT ---------------*/


/*------------- VIDEO INTERVIEW TOP ---------------*/

.playButton {
    width: 65px;
    height: 65px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.flex-video-top {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
}

.containerfor_circularprogress {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}


/*------------- VIDEO INTERVIEW TOP ---------------*/


/*------------- VIDEO INTERVIEW QUESTION ---------------*/

.flex-video-bottom {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
}

.videosection {
    width: 100%;
}

.vl {
    border-left: 1px solid #A3BCD5;
    margin-left: 20px;
    margin-top: 0px;
}


/*------------- VIDEO INTERVIEW QUESTION ---------------*/


/*------------- STEP CSS ---------------*/

.bs-stepper .step-trigger {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    background-color: transparent;
    border: none;
    border-radius: .25rem;
    transition: background-color .15s ease-out, color .15s ease-out;
}

.bs-stepper .step-trigger:focus {
    outline: none;
}

.bs-stepper-label {
    display: inline-block;
    margin: .25rem;
}

.bs-stepper-header {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.bs-stepper-line,
.bs-stepper .line {
    flex: 1 0 32px;
    min-width: 1px;
    min-height: 1px;
    margin: 9px;
    background-color: #A3BCD5;
}

.success_line {
    background-color: #2AD156;
}

.bs-stepper-circle {
    display: inline-flex;
    align-content: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    color: #adb5bd;
    border: 1px solid #A3BCD5;
    border-radius: 100%;
    line-height: 37px;
    font-size: 16px;
    background-color: #fff;
    font-weight: normal;
    font-family: 'Segoe-Regular';
    outline: none;
}

.active .bs-stepper-circle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    font-family: 'Segoe-Bold';
    font-size: 16px;
}

.success .bs-stepper-circle {
    color: #fff;
    background-color: #2AD156;
    border-color: #2AD156;
    font-family: 'Segoe-Bold';
    font-size: 16px;
}

.bs-stepper-content {
    padding: 0 20px 20px;
}

.bs-stepper.vertical {
    display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
    flex-direction: column;
    align-items: stretch;
    margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
    display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
    display: block;
    visibility: hidden;
}

.bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
    display: none;
}

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
    visibility: hidden;
    transition-duration: .3s;
    transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
    visibility: visible;
    opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
    display: block;
    visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
    display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
    display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
    visibility: hidden;
}


/*------------- STEP CSS ---------------*/


/*------------- RADIO BUTTON ---------------*/

.clear [type="radio"]:checked,
.clear [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.clear [type="radio"]:checked+label,
.clear [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 43px;
    cursor: pointer;
    line-height: 30px;
    display: inline-block;
    color: #01313C;
    margin-top: 25px;
}

.clear [type="radio"]:checked+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #1A8FFF;
    border-radius: 100%;
    background: #fff;
}

.clear [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #01313C;
    border-radius: 100%;
    background: #fff;
}

.clear [type="radio"]:checked+label:after,
.clear [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 16px;
    height: 16px;
    background: #1A8FFF;
    position: absolute;
    border: 1px solid #1A8FFF;
    top: 8px;
    left: 8px;
    border-radius: 100%;
    transition: all 0.2s ease;
}

.clear [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}

.clear [type="radio"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
}


/*------------- RADIO BUTTON ---------------*/


/*------------- CHECKBOX ---------------*/

.interview-chkbox {
    display: block;
    margin-bottom: 23px;
    margin-top: 23px;
}

.interview-chkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}


.interview-chkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #01313C;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 13px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 7px;
}

.interview-chkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 11px;
    width: 7px;
    height: 12px;
    border: solid #1A8FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.interview-chkbox :checked+label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #1A8FFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 13px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 7px;
}

.link_wraper {
    display: flex;
    align-items: center;

}

.recruiter_signup_note {
    font-family: 'Segoe-Semibold';
    font-size: 11px;
    font-style: italic;
    color: #04093F;

    padding-bottom: 14px;
}


.recruiter_signup_form_adjust {
    padding-left: 0px !important;
}

.upload_text_recruiter {
    font-family: 'Segoe-Semibold';
    font-size: 14px;
    text-align: center;
    color: #01313C;

    padding-bottom: 14px;
}

.link_wraper_recruiter {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 17px;
}

.basicinfo_chkbox {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.formone_terms {
    text-decoration: underline;
    color: #2896ff;

}

/*------------- CHECKBOX ---------------*/


.interview-question-scroll::-webkit-scrollbar {
    width: 5px;
}

.interview-question-scroll::-webkit-scrollbar-track {
    /*background: #c6c6c6;
  width: 2px*/
}

.interview-question-scroll::-webkit-scrollbar-thumb {
    background-color: #A5A4A4;
    border-radius: 30px;
}




.interview-question-scroll1::-webkit-scrollbar {
    width: 5px;
}

.interview-question-scroll1::-webkit-scrollbar-track {
    /*background: #c6c6c6;
  width: 2px*/
}

.interview-question-scroll1::-webkit-scrollbar-thumb {
    background-color: #A5A4A4;
    border-radius: 30px;
}

/* 
.viewtestresults-questionside::-webkit-scrollbar {
    width: 5px;
}

.viewtestresults-questionside::-webkit-scrollbar-thumb {
    background-color: #A5A4A4;
    border-radius: 30px;
} */
.viewtestresults-questionside {
    width: 50%;
}

.next-button {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.individual-skill {
    /* border: 1px solid #D1E8FF; */
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 20px;
    margin-top: 10px;
}

.individual-skills {
    /* border: 1px solid #D1E8FF; */
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    /* padding: 10px; */
    margin-top: 10px;
}

.myskill-right {
    text-align: right;
}

.other_formpage {
    margin: 0px auto;
    width: 90%;

}

.notify-badge {
    position: absolute;
    /*right:-20px;*/
    top: 5px;
    margin-left: 9px;
    width: 12px;
    height: 12px;
    line-height: 12px;
    background: #2AD156;
    text-align: center;
    border-radius: 50%;
    color: white;
    font-size: 8px;
}

.mr_10 {
    margin-right: 10px;
}

.df_from1 {
    display: flex;

}

.pr_5 {
    padding-right: 5px;
}


@media only screen and (max-width: 768px) {

    .form_text_container {
        padding: 30px 50px 0px 20px;
    }

    /* .leftSection {
    width:100%;
    background-color: #04093F;
    position: relative;
    
}

.rightSection {
    width: 100%;
    background-color: #fff;
    height: 100%;
} */


    .df {
        display: flex;
        flex-wrap: wrap;
    }

    .progress_adjust {
        padding: 20px 20px 0px 20px;
        width: unset;
    }

    .w3-light-grey,
    .w3-hover-light-grey:hover,
    .w3-light-gray,
    .w3-hover-light-gray:hover {
        color: #000 !important;
        background-color: #EAEAEA !important;
        border-radius: 30px;
        width: 145px;
        height: 14px;
        margin-left: 10px;
        /* margin-top: 25px; */
    }

    .form_text_container_other>p {
        font-size: 25px;
    }

    .form_text_container_other {}

    .image_add {
        width: 251px;
        position: unset;
        bottom: 0px;
        right: 19%;
    }

    .logo {
        width: 100%;
        text-align: center;
        margin-top: 40px;
    }

    .camera_icon {
        position: absolute;
        width: 30px;
        height: 30px;
        bottom: 0;
        right: 108px;
        top: 0;
    }


    .bubble-1 {
        width: 89px;
        position: absolute;
        top: 50%;
        left: 0px;
        transition: top 2s;
        display: none;
    }

    .bubble-2 {
        width: 138px;
        position: absolute;
        top: 31%;
        right: -46px;
        transition: top 2s;
        display: none;
    }

    .bubble-3 {
        width: 89px;
        position: absolute;
        top: 400px;
        left: -38px;
        transition: top 2s;
        display: none;
    }

    .bubble-4 {
        width: 110px;
        position: absolute;
        top: 561px;
        right: -2rem;
        z-index: 99;
        transition: top 2s;
        display: none;
    }

    .bubble-5 {
        width: 138px;
        position: absolute;
        top: 45%;
        right: 207px;
        transition: left 2s;
        display: none;
    }

    .btn {
        display: inline-block;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;

        padding: 7px 10px 9px 10px;
        font-size: 16px;
        border-radius: 4px;
        font-family: 'Segoe-Semibold';
    }

    .ab {
        height: 923px;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding-top: 20px;
        margin: 10px;
        /* margin-left: 10px; */
    }

}

.ab {
    height: 881px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding-top: 20px;
    margin-bottom: 20px;
}

.bubble-1 {
    width: 89px;
    position: absolute;
    top: 50%;
    left: 0px;
    transition: left 2s;

}

.bubble-2 {
    width: 138px;
    position: absolute;
    top: 31%;
    right: -46px;
    transition: left 2s;
}

.bubble-3 {
    width: 89px;
    position: absolute;
    top: 400px;
    left: -38px;
    transition: top 2s;
}

.bubble-4 {
    width: 110px;
    position: absolute;
    top: 561px;
    /* right: 146px; */
    right: -2rem;
    z-index: 99;
    transition: top 2s;
}

.bubble-5 {
    width: 138px;
    position: absolute;
    top: 45%;
    right: 207px;
    transition: left 2s;
}

label#experience-label+div.MuiInputBase-root.MuiOutlinedInput-root.mt_8.MuiInputBase-formControl>div.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 12px 25px 12px 30px;
}

label#assignment-date+div.MuiFormControl-root.MuiTextField-root>div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl>input.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 12px 25px 12px 30px;
}


.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.flex-column {
    flex-direction: column;
}

.form-one-left {
    background: #000080;
}


.other_formpage_recruiter {
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.f-white {
    color: #fff;
}

.f-red {
    color: red;
}

.f-blue {
    color: #04093F;
}

.user_register_image_camera {
    position: absolute;
    top: 10px;
    right: 2px;
    width: 24px;
}


.user_register_image_setup {
    display: flex;
    justify-content: center;
}

.f_size_35 {
    font-size: 30px;
    font-family: 'Manrope-Bold';

}

.mt_35 {
    margin-top: 35px;
}

.signup_recruiter_dropdown .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.9);
}

button.verify-otp-btn_recruiter_signup:disabled {
    background: #1A8FFF;
    opacity: 0.5;
}

.text_box_cleartalent_form_recruiter {
    height: 48px;
    width: 100%;
    font-size: 14px;
    padding: 0 20px;
    font-size: 16px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
    border: 1px solid#292929;
    border-radius: 4px;
}



.term_text {
    font-family: 'Manrope-Bold' !important;
}

.vl_recruiter {
    border-right: 1px solid #A3BCD5;
}

.tick_mark_adjust {
    width: 30px;

    padding-top: 13px;
    padding-right: 10px;
}

.logo_start_color {
    color: #1A8FFF;
}

.btn_sign_up_recruiter {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 18px 112px 18px 110px;
    font-size: 18px;
    border-radius: 8px;
    font-family: 'Manrope-Bold';
}

.recruiter_signin .btn_sign_up_recruiter {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 18px 112px 18px 110px;
    font-size: 18px;
    border-radius: 8px;
    font-family: 'Manrope-Bold';
    box-shadow: none;
}

button:disabled,
button[disabled] {
    border: 1px solid #1a8fff6b;
    ;
    background-color: #1a8fff6b;
    opacity: 0.8;
    color: #fff;
}

.main-right button:disabled,
button[disabled] {
    border: 1px solid #1a8fff6b;
    border: none;
    background-color: #1a8fff6b;
    opacity: 0.8;
    color: #fff;
}

.btn_loader {
    display: flex;
}

.recruiter_signin .btn-blue_sign_up_recruiter {
    color: #fff;
    background-color: #1A8FFF;
}

.recruiter_profile_image_contaioner {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.recruiter_profile_image_contain {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
}

.recruiter_profile_wrapper {
    display: flex;
    justify-content: center;
}

.camera_icon_recruiter {
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 0;
    right: 0px;
    top: 0;
}

.camera_icon_profile {
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 0;
    right: 0px;
    top: 35px;
    padding-left: 10px;
}

.btn_sign_up_recruiter1 {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 11px 9px 13px 9px;
    font-size: 18px;
    border-radius: 4px;
    font-family: 'Manrope-Bold';
}

.btn-blue_sign_up_recruiter {
    color: #fff;
    background-color: #1A8FFF;
}

.user_register_image {
    position: relative;
}


@media (min-width: 768px) and (max-width: 1366px) {
    .recruiter_sign_thankyou_wrapper_adjust {
        display: flex;
        justify-content: center;
        padding: 180px 0px 140px 0px;

    }

    .field_name_reccruiter {
        font-size: 12px;
        color: #04093F;
        font-family: 'Manrope-Regular';
        font-style: normal;
    }

    .header_container_width {
        max-width: 94%;
    }

    .form-one-left_recruiter {
        background: #fff;
        padding: 0px 11%;
    }

    .footer_recruiter_width {
        max-width: 93%;
    }

    .recruiter_signup_header {
        font-family: 'Manrope-Bold';
        font-size: 34px;
        color: #04093F;
        text-align: center;
        padding-bottom: 38px;
        padding-top: 108px;
    }

    .form_text_container_other .form-one-left-text {
        padding: 0px 11%;
    }

    .btn_sign_up_recruiter {
        display: inline-block;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        border: 1px solid transparent;
        padding: 18px 86px 18px 86px;
        font-size: 18px;
        border-radius: 8px;
        font-family: 'Manrope-Bold';
    }


}

.btn_sign_up_verify {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 18px 94px 18px 95px;
    font-size: 18px;
    border-radius: 8px;
    font-family: 'Manrope-Bold';
}

.resend_otp_section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.btn_resendotp {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 10px 26px 10px 26px;
    font-size: 18px;
    border-radius: 8px;
    font-family: 'Manrope-Bold';
}


@media (min-width: 1921px) and (max-width: 2560px) {

    .form_text_container_other .form-one-left-text {
        padding: 0px 10%;
    }

    .form-one-left_recruiter {
        background: #fff;
        padding: 0px 10%;
    }

    .recruiter_sign_thankyou_wrapper_adjust {
        display: flex;
        justify-content: center;
        padding: 355px 0px 280px 0px;
    }

}

@media (min-width: 1367px) and (max-width: 1920px) {
    .recruiter_sign_thankyou_wrapper_adjust {
        display: flex;
        justify-content: center;
        padding: 344px 0px 280px 0px;
    }

    .field_name_reccruiter {
        font-size: 14px;
        color: #04093F;
        font-family: 'Manrope-Regular';
        font-style: normal;
    }

    .form_text_container_other .form-one-left-text {
        padding: 0px 10%;
    }

    .form-one-left_recruiter {
        background: #fff;
        padding: 0px 10%;
    }

    .header_container_width {
        max-width: 95%;
    }

    .recruiter_signup_header {
        font-family: 'Manrope-Bold';
        font-size: 34px;
        color: #04093F;
        text-align: center;
        padding-bottom: 38px;
        padding-top: 108px;
    }

    .footer_recruiter_width {
        max-width: 95%;
    }

}


@media (min-width: 1921px) and (max-width: 2560px) {

    .recruiter_signup_header {
        font-family: 'Manrope-Bold';
        font-size: 34px;
        color: #04093F;
        text-align: center;
        padding-bottom: 38px;
        padding-top: 132px;
    }



}

@media (min-width: 2561px) {
    .recruiter_signup_header {
        font-family: 'Manrope-Bold';
        font-size: 34px;
        color: #04093F;
        text-align: center;
        padding-bottom: 38px;
        padding-top: 190px;
    }
}

@media (min-width: 981px) and (max-width: 1150px) {

    .w3-light-grey,
    .w3-hover-light-grey:hover,
    .w3-light-gray,
    .w3-hover-light-gray:hover {
        width: 300px;
    }

    /* .form_text_container_other {
    padding: 10px 30px 0px 30px;
} */
    .progress_adjust {
        padding: 20px 0px 0px 0px;
    }
}

@media (min-width: 769px) and (max-width: 980px) {

    .w3-light-grey,
    .w3-hover-light-grey:hover,
    .w3-light-gray,
    .w3-hover-light-gray:hover {
        width: 220px;
    }

    .form_text_container_other {}

    .progress_adjust {
        padding: 20px 0px 0px 10px;
    }
}

.form-one-footnote {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-style: italic;
    color: #01313C;
}

.form-one-wrapper .MuiGrid-item {
    position: relative;
}

@media(max-width: 599px) {
    .form-one-left-lower {
        position: inherit;
    }

    .form-one-left-lower img {
        width: 100%;
    }

    .form-one-footnote {
        font-size: 14px;
    }

    .other_formpage .customized-select {
        margin-bottom: 20px;
    }

    .other_formpage .dropdown_width_outer {
        margin-bottom: 0px;
    }

    .w3-light-grey,
    .w3-hover-light-grey:hover,
    .w3-light-gray,
    .w3-hover-light-gray:hover {
        width: 100%;
        margin-left: 0;
    }
}

.form-one-left-punch>p {
    font-size: 40px;
}

.form-one-left-text>p {
    font-size: 28px;
    padding-right: 2rem;
}

/* .form-one-left-punch, .form-one-left-text {
    padding-left: 3rem;
} */
.form-one-left-logo {
    padding: 2rem 0rem 2rem 3rem;
}

@media(max-width: 767px) {

    .form-one-left-punch>p {
        font-size: 30px;
    }

    .form-one-left-text>p {
        font-size: 25px;
        padding-right: 1rem;
    }

    .other_formpage {
        padding-bottom: 2rem;
    }

    .form-one-left-logo {
        padding: 1rem;
        width: auto;
    }

    .form-one-btn {
        margin-top: 0px;
        padding: 12px 12px;
    }
}

.form-one-left-lower img {
    width: 100%;
}

@media (min-width: 767px) and (max-width: 1400px) {
    .form-one-left-punch>p {
        font-size: 30px;
    }

    .form-one-left-text>p {
        font-size: 22px;
        padding-right: 2rem;
    }

    .form-one-left-section {
        /* height: calc(100vh - 6rem); */
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        margin-top: 0px !important;
    }

    .margin-form-right {
        margin-top: 0px;
    }

    .form-one-footnote {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .form_text_container_other>p {
        font-size: 30px;
    }

    .progress_adjust {
        padding: 20px 0px 0px 0px;
    }
}

.form_text_container_other input {
    font-size: 16px;
}


.bubble-4-two {
    width: 110px;
    position: absolute;
    top: 561px;
    right: 0;
    z-index: 99;
    transition: top 2s;
}

.bubble-3-two {
    width: 89px;
    position: absolute;
    top: 400px;
    left: 0;
    transition: top 2s;
}

.form-two-wrapper-header {
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;
}

/* .form-two-main-seciton form {
    padding: 0rem 2rem;
} */

/* @media (max-width: 980px) and (min-width: 600px) {
    .form-one-left {
        height: 100vh;
    }
    .form-one-left-lower {
        position: absolute;
        bottom: 0;
    }
} */
@media (min-width: 600px) {

    .form-two-main-seciton .MuiGrid-grid-sm-6 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-two-side-border {
        border-right: 1px solid #A3BCD5;
    }
}

.form_container-two {
    width: 100%;
}

.form-two-testimonial-btn {
    display: flex;
}

.form-two-all-button {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 1rem 0rem;
}

@media(min-width: 900px) {
    .form-two-main-seciton .MuiGrid-grid-sm-6 {
        padding: 0rem 3rem;
    }
}

.form-two-main-seciton {
    background: #fff;
}

@media(max-width: 899px) {
    .form-two-main-seciton .MuiGrid-grid-sm-6 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        padding: 0rem 2rem;
    }

    .form-two-side-border {
        border-right: none;
    }

    .form-two-all-button {
        justify-content: flex-start;
        padding: 0rem 2rem;
    }
}

.form-two-resume-btn {
    display: flex;
}

.form-one-wrapper_recruiter {
    /* margin-top: 32px; */
    margin-bottom: 30px;
}

.footer_links_recruiter {
    margin-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px !important;
}

.footer_bg_candidates_recruters {
    width: 100%;
    background-color: #C2C2C2;
    margin-bottom: 40px;
    margin-top: 40px;

}

.recruiter_header_logo_adjustment {
    width: 227px;
    padding: 20px 0px;
}

.nav_bg_left_recruiter {
    width: 25%;
    background-color: #fff;
}

/* @media(min-width: 981px) {
    .form-one-wrapper .MuiGrid-item {
        height: auto;
    }
    .form-one-left {
        height: 100%;
    }
} */
@media (min-width: 600px) {
    .form-one-left {
        height: 100%;
        min-height: 100vh;
    }

    .form-one-left-lower {
        display: flex;
        align-items: flex-end;
    }
}

.form-one-left {
    display: flex;
    flex-direction: column;
}

.form-one-left-upper,
.form-one-left-lower {
    flex: 1 1
}

@media (min-width: 1500px) {

    .form-one-wrapper label,
    .form-two-main-seciton label {
        font-size: 20px;
    }
}

.form-two-testimonial-delete .MuiDialogActions-root,
.form-two-resume-delete .MuiDialogActions-root {
    justify-content: center;
}

.remove-snackbar-alert .MuiAlert-message {
    font-family: 'Segoe-Regular';
}

.form-one-left-section {
    /* height: calc(100vh - 6rem); */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-top: 30px;
}

@media (max-width: 599px) {
    .form-one-left-section {
        height: auto;
        padding-bottom: 2rem;
    }
}

button.verify-otp-btn:disabled {
    background: #00d0ad;
    opacity: 0.5;
}

.resendotp-timer {
    display: flex;
    align-items: center;
    color: #000080;
    white-space: nowrap;
}

.form-one-btn:disabled {
    opacity: 0.5;
    padding: 12px 12px;
}

.form-error {
    color: red;
    font-size: 13px;
}

button.verify-otp-btn {
    padding: 12px 12px !important;
}

.formone-otpbtnswitch {
    display: flex;
}

.otp-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.otp-wrapper input {
    flex-shrink: 0;
    margin: 0px 26px 0px 0px;
    width: 100%;
}

.formone-otpbtnswitch button {
    margin: 0;
}

.otp-wrapper .dropdown_width button {
    margin: 0;
}

@media (max-width: 767px) {
    .otp-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .formone-otpbtnswitch {
        margin-top: 1rem;
        margin-bottom: 1rem;

    }

    .form_text_container_other {
        margin-top: 0em !important;
        padding: 0px 15px;
    }

    .formone-button-wrapper button {
        margin-top: 1rem;
    }



}

@media(min-width:1367px) {
    .form_text_container_other {
        /* margin-top: 3.6em; */
    }
}

@media only screen and (max-width:1366px) and (min-width: 768px) {
    .form_text_container_other {
        /* margin-top: 1.5em; */
    }

    .imagelogo_formone {
        width: 250px;
    }

    .otp-wrapper input {
        flex-shrink: 0;
        margin: 0px 20px 0px 0px;
        width: 100%;
    }
}

.formone-button-wrapper button {
    padding: 12px 12px;
}

.formone-button-wrapper_recriter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: row-reverse; */
}

@media(min-width: 769px) and (max-width: 1200px) {
    .other_formpage {
        padding: 0px 25px;
    }
}

.text-cyan {
    color: #2896ff;
}

.text_box_cleartalent_form1.phone-number-form-one {
    width: 100%;
}

.form_text_container_other .block-device-color {
    color: red;
    font-size: 16px;
}

.mr_5 {
    margin-right: 5px;
}

.otp-text {
    white-space: nowrap;
    font-size: 16px;
    margin-left: 5px;
    font-style: italic;
    color: #01313C;
}

.email-label-form-one {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-family: 'Manrope-Semibold';
    font-size: 16px;
}

.formone-button-wrapper .form-one-next-btn {
    padding: 12px 24px;
}

.formone-button-wrapper .form-one-next-btn:disabled {
    opacity: 0.5;
}

.progress_adjust>p {
    white-space: nowrap;
}

.p-err {
    margin-top: 5px;
    font-style: italic;
    color: #01313C;
}


.recruiter_signup_otp_left_title {
    font-family: 'Manrope-Bold';
    font-size: 34px;
    color: #04093F;
    text-align: center;
    padding-bottom: 38px;
    padding-top: 18px;
}

.recruiter_signup_otp_left_title_drescription {
    font-family: 'Manrope-Semibold';
    font-size: 13px;
    color: #01313C;
    text-align: center;

    padding-top: 25px;
}

.mt_45 {
    margin-top: 45px;
}

.recruiter_otp_section_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.recruiter_resend_text {
    font-family: 'Manrope-Semibold';
    font-size: 13px;
    color: #1A8FFF;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;

}

.recruiter_otp_section #otp-content2 {
    display: flex;
    justify-content: inherit;
    padding-left: 8px;
    width: 11ch;
}

.recruiter_otp_section #otp-input {
    padding: 0px;
    padding-left: 4px;
    letter-spacing: 24px;
    border: 0;
    background-image: linear-gradient(to left, #167ffc 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 33px 1px;
    background-repeat: repeat-x;
    background-position-x: 19px;
    width: 220px;
    min-width: 220px;
    margin-bottom: 0px;
}

.recruiter_otp_section #otp-content {
    left: 0;
    position: -webkit-sticky;
    position: sticky;
}

.recruiter_otp_section #otp-holder {
    width: 190px;
    overflow: hidden
}

.recruiter_otp_section #otp-in {
    border: none;
    width: 12ch;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1.14ch, transparent 0, transparent 2.034ch) 0 100%/100% 2px no-repeat;
    color: dimgrey;
    font: 4.5ch consolas, monospace;
    letter-spacing: 20px;
    color: #000;

}

.recruiter_otp_section #otp-in:focus {
    outline: none;
    color: dimgrey;
}

.recruiter_sign_thankyou_wrapper {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recruiter_sign_thankyou_icon {}

.recruiter_sign_thankyou_icon_adjust {
    width: 108px;
}

.recruiter_sign_thankyou_text_head {

    font-family: 'Manrope-Semibold';
    font-size: 40px;
    color: #121212;
    padding-left: 64px;


}

.recruiter_sign_thankyou_text_head_dres {

    font-family: 'Manrope-Semibold';
    font-size: 20px;
    color: #04093F;
    padding-top: 16px;


}

.recruiter_sign_thankyou_text_head_other_color {
    color: #1A8FFF;
}


.recruiter_signin_header {
    font-family: 'Manrope-Bold';
    font-size: 34px;
    color: #04093F;


}

@media (min-width: 768px) and (max-width: 1435px) {
    .country_label {
        padding-top: 10px;
    }
}

.contactUsFont {
    font-family: 'Manrope-Regular';
    color: #000000;
    font-size: 1.0625em;
}
.mainDiv_reg {
    margin-top: 120px;
    background-color: #FFFFFF;
}
.mainContainer_reg{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    background-color: #FFFFFF;
}

.rightHeading {
    font-size: 35px;
    font-family: 'Manrope-semibold';
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 35px;
}

.leftContainer {
    padding: 0px 11%;
    padding-top: 30px;
}

.leftHeading {
    font-size: 32px;
    font-family: 'Manrope-Regular';
    margin-bottom: 30px;
}

.left_points {
    font-size: 17px;
    font-family: 'Manrope-Regular';
    margin-bottom: 20px;
}

.leftBullets {
    font-size: 18px;
    font-family: 'Manrope-Bold';
    color: #707070;
}

.blueTexts {
    font-size: 17px;
    font-family: 'Manrope-Semibold';
    color: #2699FB;
}

.rightContainer {
    border-left: 1px solid #A3BCD5;
    height: 100%;
    padding-left: 20px;
    padding-right: 11%;
    padding-top: 30px;
}
.inputLabel_right{
    font-size: 14px;
    font-family: 'Manrope-Regular';
    margin-bottom: 8px;
    margin-top: 5px;
}
.inputLabel_right_email{
    font-size: 14px;
    font-family: 'Manrope-Regular';
    margin-top: 5px;
}
.sendOtp{
    width: 130px;
    padding: 12px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
}
.sendOtp:hover{
    opacity: 0.8;
}
.sendOtp_disabled{
    width: 130px;
    padding: 12px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    opacity: 0.4;
    cursor:default;
}
.changeEmail{
    width: 120px;
    white-space: nowrap;
    padding: 12px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 15px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
    margin-right: 5px;
}
.changeEmail:hover{
    opacity: 0.8;
}
.resendOtp{
    width: 120px;
    white-space: nowrap;
    padding: 12px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 15px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
    margin-right: 5px;
}
.resendOtp:hover{
    opacity: 0.8;
}
.resendOtpDiv{
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
.note{
    font-size: 14px;
    font-family: 'Segoe UI';
    font-weight: 600;
    font-style: italic;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.alreadyAccount{
    font-size: 16px;
    font-family: 'Segoe UI';
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.loginLink{
    color: #2699FB;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: underline;
}
.continue_container{
    display: flex;
    justify-content: center;
}
.continue_button{
    width: 300px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
    padding: 15px;
    margin-top: 10px;
}
.continue_button:hover{
    opacity: 0.8;
}
.continue_button_disabled{
    width: 300px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    cursor:default;
    padding: 15px;
    opacity: 0.4;
    margin-top: 10px;
}
.termsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.termsLink{
    color: #2699FB;
    cursor: pointer;
    text-decoration: underline;
}
.rightHeading_inviteTest {
    font-size: 35px;
    font-family: 'Manrope-semibold';
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 35px;
    text-align: center;
}
@media (max-width: 600px){
    .rightContainer {
        padding-left: 11%;
    }
    .rightHeading_inviteTest{
        font-size: 25px;
    }
}
.topContainer{
    padding-bottom: 50px;
    background-color: #FFFFFF;
}

.headerListStyleNone{
    list-style: none;
}
.hamburgerIsClicked{
    display: block;
}
.hamburgerIsNotClicked{
    display: none;
}
.main_landing_container{
    margin-top: 50px;
    padding-bottom: 50px;
}
.contact_us{
    font-family: 'Manrope-Medium';
}
.addIntervalButton{
    margin-bottom: 20px;
    margin-top: -25px;
}

.deleteBtn{
    margin-left: 10px !important;
}

@media only screen and (min-width:640px) {
    .addIntervalButton{
        text-align: end;
        margin-bottom: 20px;
        margin-top: -25px;
    }
}


.mt_11 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 14.5px 8px !important;
}

.field_name {
    margin-bottom: 10px;
}
.recruiterSignUp .mainBody {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.recruiterSignUp .mainDiv {
    margin-top: 120px;
}

.recruiterSignUp .footerSection {
    margin-top: auto;
}

.recruiterSignUp .mainHeading {
    font-size: 35px;
    font-family: 'Manrope-semibold';
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 130px;
    margin-bottom: 35px;
}

.recruiterSignUp .leftContainer {
    padding: 0 11%;
    padding-top: 30px;
}

.recruiterSignUp .inputLabel {
    font-size: 13px;
    font-family: 'Manrope-Regular';
    margin-bottom: 8px;
    margin-top: 5px;
}

.recruiterSignUp .subLabel {
    font-size: 11px;
}

.recruiterSignUp .uploadPictureText {
    text-align: center;
    font-size: 14px;
    font-family: 'Manrope-Semibold';
    margin-bottom: 14px;
}

.recruiterSignUp .termsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.recruiterSignUp .termsLink {
    color: #2699FB;
    cursor: pointer;
    text-decoration: underline;
}

.recruiterSignUp .continue_container {
    display: flex;
    justify-content: center;
}

.recruiterSignUp .continue_button {
    width: 300px;
    background-color: #2699FB;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Manrope-Semibold';
    cursor: pointer;
    padding: 15px;
    margin-top: 10px;
}

.recruiterSignUp .continue_button:hover {
    opacity: 0.8;
}

.recruiterSignUp .note {
    font-size: 13px;
    font-family: 'Segoe UI';
    font-weight: 500;
    font-style: italic;
    display: flex;
    justify-content: center;
}

.recruiterSignUp .rightContent {
    border-left: 1px solid #A3BCD5;
    height: 100%;
}

.recruiterSignUp .topContainer {
    padding-bottom: 50px;
}

.recruiterSignUp .otpInput {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.recruiterSignUp .resendBtn {
    width: 140px;
    font-family: 'Manrope-Bold';
    font-size: 17px;
    color: #fff;
    background-color: #1a8fff;
    border: none;
    border-radius: 8px;
    padding: 10px 10px;
    cursor: pointer;
}

.recruiterSignUp .resendBtn:hover {
    opacity: 0.8;
}

.recruiterSignUp .resendCont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.recruiterSignUp .timer {
    font-family: 'Manrope-Bold';
    font-size: 17px;
    margin-left: 10px;
}

.recruiterSignUp .resendBtn_disabled {
    width: 140px;
    font-family: 'Manrope-Bold';
    font-size: 17px;
    color: #fff;
    background-color: #1a8fff;
    border: none;
    border-radius: 8px;
    padding: 10px 10px;
    cursor: default;
    opacity: 0.4;
}

.recruiterSignUp .verifyContainer {
    display: flex;
    justify-content: center;
}

.recruiterSignUp .verifyBtn {
    width: 260px;
    font-family: 'Manrope-Bold';
    font-size: 18px;
    color: #fff;
    background-color: #1a8fff;
    border: none;
    border-radius: 8px;
    padding: 20px 10px;
    cursor: pointer;
    margin-top: 70px;
    margin-bottom: 35px;
}

.recruiterSignUp .verifyBtn:hover {
    opacity: 0.8;
}

.recruiterSignUp .recievedEmail {
    font-family: 'Manrope-Semibold';
    font-size: 16px;
    text-align: center;
}

.recruiterSignUp .line1 {
    font-size: 40px;
    font-family: 'Manrope-Semibold';

}

.recruiterSignUp .line2 {
    font-size: 38px;
    font-family: 'Manrope-Semibold';
    color: #1a8fff;
}

.recruiterSignUp .line3 {
    font-family: 'Manrope-Semibold';
    font-size: 20px;
    color: #04093F;
    padding-top: 16px;
}

.recruiterSignUp .thanksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
    margin-left: 11%;
    margin-right: 11%;
}

.recruiterSignUp .successImage {
    width: 100px;
    height: 100px;
    margin-right: 30px;
}

.textbox_phone_input {
    height: 47px;
    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}

.textbox_phone_input:hover {
    border: 1px solid #000;
}

.textbox_phone_input:active {
    border: 1px solid #3f51b5;
}


@media (max-width: 768px) {
    .recruiterSignUp .thanksContainer {
        flex-direction: column;
        margin-top: 25%;
    }

    .recruiterSignUp .mainHeading {
        margin-top: 100px;
        margin-bottom: 0;
    }

    .recruiterSignUp .rightContent .f_size_35 {
        font-size: 20px;
    }

    .recruiterSignUp .rightContent .tick_mark_adjust {
        width: 22px;
    }

    .recruiterSignUp .rightContent {
        padding: 0 11%;
    }

    .recruiterSignUp .form_text_container_other {
        padding: 0;
    }

    .recruiterSignUp .mainHeading {
        font-size: 28px;
    }
}

.specific_screen {
    margin-bottom: 0px !important;
}

@media (max-width: 1279px) { 
    .specific_screen1 {
        display: none;
     }
}

@media (min-width: 1367px) and (max-width: 1920px) { 
    .specific_screen1 {
       display: none;
    }

}
.profileImage_recruiterSignUp{
    height: 93px;
    width: 93px;
    border-radius: 50%;
}

.hK {
  height: 75vh;
}

@media (min-width: 1280px) {
  .pageWidth {
    max-width: none;
    margin-top: 20px;
    padding: 0px !important;
  }
}

.revokeBtn {
  margin-left: 10px !important;
}

.options {
  margin-bottom: 15px;
}

.options:last-child {
  margin-bottom: 0px;
}

.mt_5 {
  margin-top: 5px;
}

.authorize {
  margin-top: 30px;
}

.tab_button {
  padding: 1rem 2rem;
  cursor: pointer;
  border: 1px solid #3f51b5;
  font-family: "Segoe-Semibold";
  transition: background ease 0.3s;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
}

.tab_button_active {
  background: #3f51b5;
  color: white;
  font-size: 18px;
  font-family: "Segoe-Semibold";
}

.updatedCard .k-grid {
  height: 64vh;
}

.titleContainer_approvalrecruiter {
  display: flex;
  justify-content: start;
  font-family: "Segoe-Semibold";
  /* background-color: #05A6F4;
  color: #FFFFFF; */
  font-size: 24px;
  padding: 5px 3px;
  color: #2896ff;
  border-bottom: 1px solid #2896ff;
  width: 220px;
  margin-bottom: 10px;
}

.tab_button_approval {
  margin-right: 3px;
  margin-bottom: 3px;
}



.tabs_requisitionRepor {
  font-family: 'Segoe-Semibold';
  width: 10%;
  font-size: 15px;
  text-align: center;
  padding: 10px 8px;
  cursor: pointer;
  background: #B0DAF18A;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}



.tabs_requisitionReports_select {
  background-color: #009EFD;
  width: 10%;
  font-size: 15px;
  color: #FFFFFF;
  font-family: 'Segoe-Semibold';
  text-align: center;
  padding: 10px 8px;
  opacity: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}



.approveRecruiterModal .admindailog_title_modal {
  padding: 13px 18px !important;
}

.approveRecruiterModal .admindailog_title_modal {
  display: flex;
  align-items: center;
  height: 4.2vh;
  padding: 10px;
  background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
  color: white;
  border-radius: 4px 4px 0px 0px;
  font-family: 'poppins', sans-serif;
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  align-items: end;
}



.approveRecruiterModal .dialog-title-text {
  color: #ffff;
  font-weight: 600;
  font-size: 20px;
  font-family: 'poppins', sans-serif;
}



.approveRecruiterModal .removemodal_responsive {
  padding: 20px 20px;
}

.approveRecruiterModal .iconStyle {
  width: 35px;
  height: unset;
  color: #FFFFFF;
}



.approveRecruiterModal .remove_modal {
  justify-content: end;
  margin-bottom: 5px;
  padding: 0px 10px;
}



.approveRecruiterModal .btn_primary {
  color: #FFFFFF;
  width: 120px;
  height: 35px;
  font-size: 15px;
  border-radius: 3px;
  background-color: #1885E6 !important;
  font-family: 'Segoe-regular';
  cursor: pointer;
  display: flex;
  justify-content: center;
}



.approveRecruiterModal .btn_primary:hover {
  background-color: #1885E6 !important;
  opacity: 0.8;
}



.approveRecruiterModal .btn_remove {
  font-size: 15px;
  border-radius: 3px;
  width: 120px;
  font-family: "Segoe-Semibold";
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 5px;

}

.unrevokedButton {
  background-color: #31B3F8 !important;
  color: #ffffff !important;
}

.tab_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dw {
  width: 100%;
}

.ExportButton_Container {
  padding-bottom: 5px;
}



.mainContainer_recruiter {
  display: flex;
  flex-direction: row;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  align-items: baseline;
}

.leftSideMainContainer_recruiter {
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.rightSideMainContainer_recruiter {
  width: calc(100% - 410px);
}

.leftSideTopSection {
  background-color: #D1E8FF;
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
}

.TopSectionLeftSide {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.button_search_recruiter {
  background-color: #31B3F8 !important;
  outline: none !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 10% !important;
  color: white !important;
  width: 100% !important;
  font-size: 1em !important;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.buttonContainer_search_recruiter {
  width: 31%;
}

.leftSide_buttomSection{
  padding: 10px 15px 10px 15px;
}

.leftside_contains_recruiter{
  margin-bottom: 10px;
}

.topography_front_recruiter{
  font-size: 16px;
    font-family: "Segoe UI";
    color: #43425D;
}

.outline_textbox_recruiter{
  height: 47px;
  background-color: #ffffff !important;
}
.heading {
    width: 100%;
    text-align: left !important;
    margin-bottom: 10px;
}

.header_name {
    font-family: 'Malayalam Sangam MN', sans-serif;
    font-size: 23px;
    font-weight: bold;
}

.flexContainer {
    display: flex;
    flex-direction: column;
}

.underline {
    border-bottom: 1px solid #6A91E9;
    opacity: 0.5;
}

.leftCard {
    text-align: center;
    border-radius: 10px;
    height: 100%;
}

.imgDiv {
    padding: 30px 0px;
    background-color: #D1E8FF
}

.menuDiv {
    background-color: #1885e6;
    padding: 20px 0px;
    height: 100%;
}

.rightCard {
    margin: 0px !important;
    padding: 0px !important;
    margin-left: 20px !important;
    padding: 0px 10px !important;
    height: 100% !important;
}

.firstRow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 150px;
    margin-top: 50px;
}

.secondRow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 18%;
}

.cells {
    width: 160px
}

.btnStyle {
    margin-top: 10px;
    text-align: right;
    margin-right: 55px;
}

.editBtn {
    text-align: center;
    margin-top: 10px;
}

.editContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.display_name {
    font-family: "Segoe UI";
    font-size: 20px;
    margin-top: 7px;
}

.updateButton {
    margin: 10px 0px !important;
    color: #fff !important;
    background-color: #1885E6 !important;
    margin-top: 9.5px !important;
    width: 139px;
}

.editButton {
    color: #fff;
    background-color: #1885E6;
    width: 50px;
}

.underline_btn {
    border-bottom: 1px solid #6A91E9;
    opacity: 0.5;
    margin-right: 55px;
}

.section-wrapper {
    min-height: auto !important;
}

.btn-error {
    background-color: red;
    color: #FFFFFF;
}


.edit_header_logo {
    height: 40px;
    width: 200px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.left_main_div {
    height: 100%;
}

.upper_left_div {
    height: 60%;
    background-color: #D5E7FD;
}

.lower_left_div {
    height: 40%;
    background-color: #4C94F7;
}

.card_left {
    height: 100%;
}

.profile_grid {
    margin-top: 20px !important;
}

.edit_grid {
    margin-top: 5px !important;
}

.rec_grid {
    margin-top: 10px !important;
}

.logo_grid {
    margin-top: 36px !important;
}

.mainContainer_acc {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.footerContainer {
    margin-top: auto;
}

.bodyContainer {
    margin-top: 120px;
    padding: 0 6%;
    padding-bottom: 20px;
}

.topcontainer {
    background-color: #FFFFFF;
}

.topHeading {
    font-family: 'Malayalam Sangam MN', sans-serif;
    font-size: 23px;
    font-weight: bold;
}

.underline_Heading {
    border-bottom: 1px solid #6A91E9;
    opacity: 0.5;
    padding-top: 10px;
    margin-bottom: 15px;
}


.rightsideDiv {
    height: 600px;
    padding-left: 12px;
}

@media (max-width: 600px) {
    .rightsideDiv {
        padding-left: 0;
        padding-top: 12px;
    }

    .rightBottomSection {
        border-top: none !important;
        justify-content: flex-start !important;
    }

    .user_profile {
        height: 25px;
        width: 25px;
        border: 3px solid #2896FF;
        border-radius: 50%;
        padding-top: 0px !important;
    }

    .nav_bg_right {
        top: 0;
        right: 0;
    }

    .responsive_profile {
        position: absolute;
        top: 8px;
        right: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .user_name {
        text-align: center;
    }
}

.rightBody {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}


.rightBottomSection {
    margin-top: auto;
    border-top: 1px solid hsl(222, 74%, 66%, 0.5);
    height: 75px;
    margin-left: 20px;
    margin-right: 80px;
    display: flex;
    justify-content: flex-end;
}

.updateButton_Right {
    height: 40px;
    color: #fff;
    background-color: #5E92F0;
    border: none;
    font-family: Arial;
    font-size: 18px;
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px 30px;
    cursor: pointer;
}

.updateButton_Right:hover {
    opacity: 0.9;
}

.rightTopSection {
    padding: 40px 100px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}

.cellBody {
    display: flex;
    flex-direction: column;
    width: 135px;
    margin-bottom: 100px;
}

.static_name {
    font-family: 'Segoe UI';
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    color: #5E92F0;
    width: 20px !important;
    cursor: pointer;
    margin-left: 5px;
}

.textbox {
    height: 46px;
    margin-top: 10px;
}

.cellBody_phn {}

@media (max-width: 960px) {

    .cellBody,
    .cellBody_phn {
        margin-bottom: 15px;
    }

    .rightTopSection {
        padding-left: 10px;
    }
}

.upperLeftSection {
    height: 360px;
    width: 100%;
}

.bottomLeftSection {
    background-color: #4C94F7;
    height: 240px;
}

.upper_left_img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.edit_profiles {
    width: 105px;
    height: 105px;
    border-radius: 50%;
}

.editContainer_left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 7px;
}

.Edit_name {
    font-size: 16px;
    font-family: "Segoe UI";
    color: #1885E6;
    cursor: pointer;
}

.icon1 {
    color: #5E92F0;
    width: 20px !important;
    cursor: pointer;
    margin-left: 5px;
}

.display_rec_name {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: "Segoe-Regular";
    font-size: 20px;
    margin-top: 8px;
}

.logo_main_div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.edit_logo_div {
    height: 54px;
    width: 205px;
    display: flex;
    justify-content: center;
}

.edit_logos {
    max-width: 100%;
    max-height: 100%;
}

.lower_left_acc {
    width: 100%;
    text-align: center;
}

.account_setting {
    margin-top: 40px;
    padding: 10px 0px;
    font-family: 'Segoe-Semibold';
    font-size: 22px;
    color: #05093C;
    background-color: #FFFFFF;
    width: 100%;
}

.menu-icon {
    height: 17px;
    width: 17px;
}

.account-text {
    margin-left: 4px;
}

.profile_dropdown {
    position: relative;
}

.account_profile_pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.account_nav {
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    width: -webkit-max-content;
    width: max-content;
}

.user_menu {
    width: 80%;
}

.profile_name {
    width: 80%;
}

.profileImag {
    width: 38px;
    ;
    height: 38px;
    border-radius: 50%;
    border: 3px solid #2896FF;
    margin-top: 22px;
}

.profile_drop {
    width: 1%;
}

.profile_drop_menu {
    position: absolute;
    top: 90px !important;
    right: 0px;
}

.nav_bg_home_sectio {
    justify-content: space-between;
}

.resume_head {
    display: flex;
    align-items: center;
}

.textbox_phone_input_accountSettings {
    height: 47px;
    width: 230px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 0px !important;
    padding: 0 10px;
    font-size: 20px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
    background-image: none;
}

.textbox_phone_input_accountSettings:hover {
    border: 1px solid #000;
}

.textbox_phone_input_accountSettings:active {
    border: 1px solid #3f51b5;
}

.userDetailFieldContainer_phoneNumber .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: 0px;
    height: 30px;
    font-size: 16px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    width: 80%;
}

.userDetailFieldContainer_phoneNumber .PhoneInputInput:focus {
    flex: 1 1;
    min-width: 0;
    border: 0px;
    outline: none;
    height: 30px;
    font-size: 16px;
    font-family: segoe-regular;
    font-style: normal;
    font-weight: 400;
    width: 80%;
}

@media only screen and (max-width: 600px) {
    .profile_name {
        width: 80%;
        font-size: 0.85rem !important;
    }
}

.userDetailFieldContainer {
    display: flex;
    flex-direction: column;
    width: 250px;
}

.userDetailFieldContainer_email {
    display: flex;
    flex-direction: column;
    width: 250px
}

.userDetailFieldContainer_phoneNumber {
    display: flex;
    flex-direction: column;
    width: 250px
}

.userDetails_headings {
    font-size: 17px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-bottom: 10px;
    color: #3B3B3B;
}

.userDetails_textbox_outline {
    height: 47px;
    background-color: #ffffff !important;
}

.updateButton_AccountSettings {
    height: 40px;
    display: flex;
    background-color: #1885e6 !important;
    color: #ffffff;
    padding: 5px 30px;
    margin-top: 10px;
    width: 135px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
}

.updateButton_AccountSettings:hover {
    opacity: 0.9;
}

.updateButton_AccountSettings_disabled {
    height: 40px;
    display: flex;
    background-color: #1885e6 !important;
    color: #ffffff;
    padding: 5px 30px;
    margin-top: 10px;
    width: 135px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    grid-gap: 10px;
    gap: 10px;
}

.profilePictureDialog .MuiCircularProgress-root {
    color: #FFFFFF !important;
    height: 18px !important;
    width: 18px !important;
    margin-top: 2px;
}

.rightBottomSection .MuiCircularProgress-root {
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
}

.removeButton_accountSettings_disabled .MuiCircularProgress-root {
    color: #01313c !important;
    height: 18px !important;
    width: 18px !important;
}

.removeButton_accountSettings_disabled{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    grid-gap: 5px !important;
    gap: 5px !important;
    margin-top: 2px !important;
    opacity: 0.7;
    cursor: default !important;
}


.cancelButton_AccountSettings {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* border: 1px solid transparent; */
    height: 50px;
    width: 135px;
    font-size: 18px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    cursor: pointer;
}

.cancelButton_AccountSettings:hover {
    background-color: #f5f5f5;
}

.profilePictureSave_accountSettings {
    height: 50px !important;
    margin-top: 0px !important;
    font-size: 18px !important;
    border-radius: 4px !important;
    font-family: "Segoe-Semibold" !important;
}
@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none !important;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
/* .margin_grid{
    margin: 10px ;
    margin-bottom: 20px;
    height: 70vh;
} */

.poppercolor{
    width: 100% !important;
    opacity: 1 !important;
    background-color: white !important;
    height: 50px !important;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: -5px;
    
}

.popperbutton{
    height: 30px !important;
    margin: 15px !important;
}


@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
.editCenter{
    display: flex;
    justify-content: center;
}
.logoButton_rp{
    display: flex;
    background-color: #3f51b5;
    color: white;
    font-size: 16px;
    font-family: "Segoe-Semibold";
    border: none;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    align-items: center !important;
    transition: box-shadow 1s;
}

.logoButton_rp:hover{

    /* opacity: 0.8; */
    background-color: #303f9f;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

.logoButton_rp_text{
    margin-left: 7px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.containerMain{
    position: relative;
    width: 100%;
}
.buttonAdd{
    position: absolute;
}
/* .style_grid{
    margin-bottom: 20px;
    height: 65vh;
} */

.dialogContainer input[type="text"] {
    padding: 14px 0px !important;
}
/* #rectCrop  div{
    border-radius: 0 !important;
} */
#rectCrop{
    height: 40vh;
    width: 100%;
    background-color: rgb(92, 92, 92);
    border: 1px solid black;}

   #rectCrop .ReactCrop{
    max-width: 85%;
   } 
#rectCrop .reactEasyCrop_CropArea{
    /* width: 95% !important;
height: 80% !important; */
border-radius: 0 !important;
background-color: auto !important;

}

#editRecruiterLogoContainer{
    height: 40px !important;
}

#rectCrop .reactEasyCrop_Container{
    background-color: white; 
    height: 40vh !important;
    width: 100% !important;
    /* background-color: grey;    */
}
#rectCrop {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}
#freeCropper .ReactCrop__image{
    max-height: 40vh;
    width: 100%;
    background-color: white;
  }
.ReactCrop__child-wrapper{
   height: 250px;
}

  
#mainContainer .MuiDialogContent-root{
    overflow-y: hidden !important;
}

#mainContainer .reactEasyCrop_CropAreaGrid::before{
    border: 1px solid rgb(193, 193, 193);
}
#mainContainer .reactEasyCrop_CropAreaGrid::after{
    border: 1px solid rgb(193, 193, 193);
}
#orgLogoUploadContainer{
    /* background-color: red; */
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 3px;
}


#orglogoFileName{
    padding: 0px 16px !important;
}

#OrgLogoUploadButton{
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    background-color: white;
    padding: 8px 10px 8px 10px;
    margin-left: 0;
    color: #303F9F;
    border: 1.5px solid #303F9F;
}

#OrgLogoUploadButton:hover{
    background-color: #303F9F;
    color: white;
}
/* .reactEasyCrop_Contain{ */
    /* transform: translateX(0%) !important */
/* } */
@media (min-width: 1280px)
  {
    .allignmentFix
    {
        max-width: none;
        margin-top: 20px;
    }
}
.mt-110 {
    margin-top: 110px;
}
.grid__container .MuiFormControl-root {
  width: 100%;
}

.grid__container .MuiFormControl-root input {
  padding: 20px 40px !important;
}

.recruiter_invite_heading {
  font-size: 18px;
  font-family: "Manrope-Semibold";
  color: #1a8fff;
}
.dropdown_width_skill_recruiter_invite_skill {
  display: flex;
}

.dropdown_width_skill_recruiter_invite_skill .MuiFormControl-fullWidth {
  width: 100%;
  border: 1px solid;
  border-radius: 4px;
  outline: none;
}

.recruiter_invite_divider {
  background-color: #a3bcd5;
  height: 1px;
  margin: 1rem 44rem 1rem 0rem;
}

.recruiter_invite_divider_two {
  margin: 1rem 20rem 1rem 0rem;

  background-color: #a3bcd5;

  height: 1px;
}
.bulk_upload_button{
  margin-left:20rem ;
}

.recruiter_invite_label {
  font-size: 20px;
  color: #04093f;
  font-family: "Segoe-Semibold";
}
.recruiter_invite_wrapper {
  padding: 6% 5.6% 1%;
  background-color: #fff;
}

@media (min-width: 1921px) and (max-width: 2560px) {
  .recruiter_invite_divider {
    background-color: #a3bcd5;
    height: 1px;
    margin: 1rem 66rem 1rem 0rem;
  }

  .recruiter_invite_divider_two {
    margin: 1rem 37rem 1rem 0rem;
    background-color: #a3bcd5;
    height: 1px;
  }
  .bulk_upload_button{
    margin-left:37rem ;
  }

  .recruiter_invite_divider_two {
    margin: 1rem 37rem 1rem 0rem;
    background-color: #a3bcd5;
    height: 1px;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .recruiter_invite_divider {
    background-color: #a3bcd5;
    height: 1px;
    margin: 1rem 58.5rem 1rem 0rem;
  }
  .recruiter_invite_divider_two {
    margin: 1rem 31rem 1rem 0rem;
    background-color: #a3bcd5;
    height: 1px;
  }
  .bulk_upload_button{
    margin-left:31rem ;
  }
  .recruiter_invite_wrapper {
    padding: 6% 5.6% 1%;
    background-color: #fff;
  }
}

.invite_candidate_buttom_wrapper {
  margin-top: 2rem;
}
.invite_candidate_add_icon_wrap .MuiSvgIcon-colorPrimary {
  color: #1a8fff;
  width: 1.4em;
  height: 1.4em;
}
.invite_candidate_form_wrapper {
  /* display: flex;
  justify-content: space-between; */
  width: 100%;
  /* padding: 0px 90px 0px 0px; */
  /* height: 450px;
    overflow: scroll; */
}

.scrollbar {
  height: 360px;
  padding: 0px 90px 0px 0px;
  width: 80%;
  overflow-y: auto;
  margin-bottom: 25px;
}

#style-2::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px; */
  background-color: #fff;
}

#style-2::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 0px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #1a8fff;
}

.recruiter_invite_label_form {
  font-size: 20px;
  color: #04093f;
  font-family: "Segoe-Semibold";
}

.dropdown_width_recruiter_invite {
  width: 32.5%;
}

.invite_candidate_form_wrapper_main {
  /* border-right: 2px solid #1A8FFF;
  display: flex; */
  width: 100%;
  margin-top: 10px;
}
.invite_candidate_form {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}
.invite_candidate_add_icon_wrap {
  float: right;
  margin-top: 20px;
  margin-right: -3px;
}

.btn-invite_submit {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  /* border: 1px solid transparent; */
  padding: 9px 35px 11px 35px;
  font-size: 18px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn-invite_submit_blue {
  color: #fff;
  background-color: #1a8fff;
  border: 1px solid #1a8fff;
  margin-top: 10px;
}

@media (min-width: 768px) and (max-width: 1366px) {
  .recruiter_invite_wrapper {
    padding: 6% 5.6% 1%;
    background-color: #fff;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .recruiter_invite_wrapper {
    padding: 6% 5.6% 1%;
    background-color: #fff;
  }
}

/* width */
.invite_candidates_scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.invite_candidates_scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.invite_candidates_scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.invite_candidates_scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.invite_candidate_skill_dropdown_wrapper_skilltype {
  width: 25%;
}
.invite_candidate_skill_dropdown_wrapper_skill {
  width: 25%;
  margin-left: 25px;
}
.closeIcon {
  position: absolute;
  right: -35px;
  top: 0px;
  /* bottom: -50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 69px;
}
.closeIcons {
  position: absolute;
  right: -80px;
  top: -3px;
}

.invite_action-icon {
  position: absolute;
  margin-top: 0px;
}
.invite_action-icon.check-circle {
  color: green;
  position: absolute;
  right: -35px;
  top: 0px;
  /* bottom: -50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 116px;
}
.invite_action-icon.circle-round {
  color: red;
  position: absolute;
  right: -35px;
  top: 0px;
  /* bottom: -50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 116px;
}
.invite__success {
  color: green;
  font-family: "Manrope-Semibold";
}
.invite__error {
  color: red;
  font-family: "Manrope-Semibold";
}

.goBack {
  font-size: 20px;
  color: #04093f;
  font-family: "Segoe-Semibold";
  text-decoration: none;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 21vw;
}
.invite_candidate_label_wrapper {
  width: 27%;
}

.client_info {
  padding-top: 10px;
  font-family: "Segoe-Regular";
  position: absolute;
}
.authorize
{
  margin-top: 30px;
}
.bulk_upload{
  font-size: 16px;
  font-family: "Segoe-Semibold";
}
.sample_file{
  margin-left: 2vh;
  align-items: center;
  font-size: 16px;
  color: #1a8fff;
  font-family: "Segoe-Semibold";
}
.bulk_drop_menu{
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.button_mgn_top{
  margin-top: 3vh;

}
.bulk_drop_mexdxd{
  position: 'fixed'; 
}
.bulkUploadTemplateUpload{
  font-size: 18px;
  
  font-family: "Segoe-Semibold";
}
.bulkUploadTemplateButton{
  height: 48px;
}

.linkButton{
  height: 48px;
}
.submit_btn_ivt{
  position: absolute;
  right: 23.9%;

}
.text_box_cleartalent_form_recruiter_highlighter {
  height: 48px;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  font-size: 16px;
  font-family: segoe-regular;
  font-style: normal;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 2px solid red;
  border-radius: 4px;
}
.btn_styl{
  width: 130px;
}
#btn_border{
  border: 2px solid red ;

}

#center_Aling{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
#downTemp{
  margin-top: 10px;
  margin-right: 3px;
}

.table_wrapper {
  width: 100%;
  height: 95vh;
  display: grid;
  place-items: center;
}

.table_container {
  width: 80%;
  height: 60vh;
  margin: 0 auto;
}

.button_container {
  display: flex;
  justify-content: space-between;
}

.actionContainer {
  display: flex;
  justify-content: center;
}

.alltest_table_bg {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  height: unset;
  width: unset;
    background: white;
    padding: 20px 20px;
    margin-bottom: 10px;
}

.alltest_width {
  width: 100%;
  height: unset;
}

.creatTest_title .MuiDialogTitle-root {
  padding: 16px 24px !important;
}
.background{
    background-color: white;
}
.skeleton_container {
    margin: 80px 0px;
    height: 70vh;
}

.skeleton_items_first {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.skeleton_items_second {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
}

.skeleton_items_1 {
    grid-gap: 5px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skeleton_items_2 {
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skeleton_width {
    width: 500px; 
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .skeleton_container {
        margin: 50px 0px 80px;
        height: 100vh;
    }

    .skeleton_width {
        width: 400px; 
    }

    .skeleton_items_second {
        margin-top: 30px; 
    }

    .skeleton_items_2 {
        margin-top: 20px;
    }
}

/* Medium devices (landscape tablets, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 900px) {
    .skeleton_container {
        margin: 100px 0px 300px;
        height: 70vh;
    }

    .skeleton_items_2 {   
        margin-top: 20px;  
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Large devices (laptops/desktops, 900px and up) */
@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .skeleton_container {
        margin: 100px 0px 80px;
        height: 70vh;
    }  

    .skeleton_items_second {
        margin-top: 50px;   
        flex-wrap: nowrap;
        grid-gap: 35px;
        gap: 35px;
    }

    .skeleton_items_2 {
        grid-gap: 20px;
        gap: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .skeleton_width {
        width: 400px; 
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .skeleton_container {
        margin: 120px 0px 80px; 
        height: 70vh;
    }
  
    .skeleton_items_second {
        margin-top: 80px;
        flex-wrap: nowrap;      
    }
    .skeleton_width{
        width: 600px;
    }
}
.pendingInvitemainContainer {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}

.pendingInviteMainGrid {
    padding: 0 5%;
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.kendoStyle {
    height: 100%;
    font-family: "Roboto", sans-serif;
}

.kendoGridDiv {
    width: 100%;
    height: 78vh;
}

.pendingInviteArrow {
    font-size: 20px;
    color: #04093f;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 21vw;
}

.pendingInviteTitle {
    font-size: 30px;
    color: #04093f;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 21vw;
    color: #2896ff;
    border-bottom: 1px solid #2896ff;
    padding-bottom: 5px;
}

.pendingInviteBottomContainer {
    margin-top: auto;
}


.pendingInvitemainContainer .k-table-tbody .k-table-row{
    height: 50px !important;
}

.pendingInviteMainGrid{
    padding-bottom: 10px;
}
.referralmainContainer {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}

.referralMainGrid {
    padding: 0 5%;
    padding-top: 110px;
    display: flex;
    flex-direction: column;
}

.referralContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 20px;
}

.referralTitle {
    font-size: 30px;
    color: #04093f;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 21vw;
    border-bottom: 1px solid #2896ff;
    padding-bottom: 5px;
    color: #2896ff;
}

.referralArrow {
    font-size: 20px;
    color: #04093f;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 21vw;
}

.referralContent_leftSide {
    width: 245px;
    box-shadow: 0 5px 10px 0 rgba(26, 143, 255, .24);
    border-bottom-left-radius: 15px;
}

.referralContent_topContainer {
    padding: 10px;
    background-color: #d1e8ff;
    display: flex;
    justify-content: space-between;
}

.referralContent_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.referralsearchButton {
    margin: 10px 0px !important;
    color: #fff !important;
    background-color: #1885e6 !important;
}

.referralContent_buttomContainer {
    padding: 10px;
}

.mytalent_sidebar .mytalentsidemutiselect .MuiChip-root {
    color: #2699fb;
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    white-space: nowrap;
    border-radius: 4px;
    vertical-align: middle;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;

    background-color: #fffdfd;
    border: 1px solid #bce0fd;
}

.mytalent_sidebar .mytalentsidemutiselect .MuiChip-deleteIcon {
    color: rgb(38 153 251);
    width: 22px;
    cursor: pointer;
    height: 22px;
    margin: 0 5px 0 -6px;
    -webkit-tap-highlight-color: transparent;
}

.mytalent_sidebar .mytalentsidemutiselect .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px;
}

.mytalent_sidebar .mytalentsidemutiselect .MuiAutocomplete-endAdornment {
    position: unset;
}

.mytalent_sidebar .MuiInput-underline:before {
    border-bottom: 1px solid rgba(38, 153, 251, 0.4);
}

.mytalent_sidebar .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #2699fb;
}

.mytalent_sidebar .MuiSlider-rail {
    width: 100%;
    height: 10px;
    display: block;
    opacity: 0.38;
    position: absolute;
    border-radius: 1px;
    background-color: #d5dde3;
    border-radius: 30px;
}

.mytalent_sidebar .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: -4px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -6px;
    border-radius: 50%;
    justify-content: center;
    background-color: #2699fb;
    border: 2px solid white;
}

.mytalent_sidebar .MuiSlider-root {
    width: 100% !important;
}

.mytalent_sidebar .MuiSvgIcon-root {
    fill: rgba(38, 153, 251, 0.9) !important;
}

.mytalent_sidebar .MuiSlider-track {
    height: 10px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: #2699fb;
    border-radius: 30px;
}

.skills input[type="text"],
select {
    padding: 14px 0px !important;
    font-size: 16px;
    font-family: "Segoe UI";
    font-weight: normal;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #2896ff !important;
}

.referralContent_rightSide {
    width: calc(100% - 265px);
    height: 72vh;
}

.moreButton {
    cursor: pointer;
}

.referralBottomContainer {
    margin-top: auto;
}

.header-text {
    font-size: 17px;
    color: #04093f;
    font-family: "Segoe UI";
    font-weight: normal;
}

.activityInviteBtnAllignment {
    display: flex;
    justify-content: space-evenly;
}

.referralCancelButton {
    margin-left: 5px !important;
    color: #fff !important;
    background-color: #1885e6 !important;
}

.referralInviteButton {
    color: #fff !important;
    background-color: #1885e6 !important;
}

.activityInviteBtnAllignment .MuiButton-containedPrimary {
    color: #fff;
    background-color: #1885e6;
}

.activityInviteBtnAllignment .MuiButton-containedPrimary:hover {
    color: #fff;
    background-color: #1885e6;
    opacity: 0.8;
}

.activityInviteBtnAllignment .k-button-solid-base{
    color: #fff;
    background-color: #1885e6;
}

.inviteActionBtnFront{
    font-family: 'Source sans pro', sans-serif;
    font-weight: "bold";
    font-size: 17px;
}
.error404-container {
    position: relative;
    height: calc(100vh - 80px);
    /* padding-top: 80px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
}

.error404_wrapper_main {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0px 0px 20px;

}

.errorLogoWrapper {
    margin-top: 10vh;
    margin-bottom: -7vh;
}

.error404Logo {
    height: 220px;
    width: auto;
}

.error4text {
    font-size: 200px;
    color: #A3BCD5;
    font-family: 'Manrope', sans-serif;
    font-weight: lighter;
}

.errorTextHeading {
    font-size: 33px;
    font-weight: semi-bold;
    font-family: 'Manrope', sans-serif;
}

.errorTextParagrapgh {
    font-family: 'Manrope', sans-serif;

}

.error404ActionBtn {
    background-color: #1885E6 !important;
    color: white !important;
    padding: 10px 60px !important;
    font-family: 'Manrope', sans-serif !important;
    font-weight: bold !important;
}

.errorTextHeading {
    font-family: 'Manrope', sans-serif;
}

.error404_wrapper_main #errorTextHeadingID {
    margin-top: 20px;

}



@media only screen and (max-width: 600px) {

    /*Big smartphones [426px -> 600px]*/
    .errorLogoWrapper {
        margin-top: unset;
    }

    .error4text {
        font-size: 70px;

    }

    .error404Logo {
        height: 90px;
    }

    .errorTextHeading {
        font-size: 29px;
        margin-top: 35px;
    }

    .errorTextParagrapgh {
        padding: 0px 20px;
        font-size: 11px;
    }

}
.addBtnDivStyle {
    width: 100%;
    text-align: end;
  }
  
  .addBtnStyle {
    color: "#fff" !important;
    background-color: "#2AD156" !important;
    border: "none" !important;
    padding: "7px 23px 7px 23px" !important;
  }

  .optionsStyle{
    margin-bottom: 10px;
  }

  .skillLocation{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .skilLocationContainer{
    padding: 0px 20px 0px 0px;
  }

  .locationCss{
    margin-left: 10px;
  }

  .addButton{
    text-align: end;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .closeBtnStyle{
    position: absolute;
    right: -27px;
    cursor: pointer;
  }

  .locationCloseBtnStyle{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
  }

  .locationField{
    width: 100%;
  }

  .addButton .MuiSvgIcon-root.addBtn{
    color: #1a8fff;
    width: 1.4em !important;
    height: 1.4em !important;
  }
.mainContainer_advanceSearch {
    display: flex;
    min-height: 100vh;
    /* width: 100vw; */
    flex-direction: column;
    background-color: #ffffff;
}

.footerContainer_advanceSearch {
    margin-top: auto;
}

.mainDiv_advanceSearch {
    margin-top: 115px;
    margin-left: 5%;
    margin-right: 5%;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titlesofCommunity {
    font-size: 30px;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin: 10px 0px 0px;
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
    color: #2896ff;
    border-bottom: 1px solid #2896ff;
    padding-bottom: 5px;
}

.goBackToRecruiterDashboard{
    font-size: 20px;
    color: #7F7F7F;
    font-family: "Segoe-Semibold";
    /* text-decoration: none; */
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 34vw;
    margin-bottom: 15px;
}
.goBackToRecruiterDashboard:hover{
    color:#04093f;
}

.searchTextfield {
    width: 450px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
}

.titleContainer input[type="text"],
select {
    padding-left: 0px !important;
}

.searchIcon_advSearch {
    opacity: 0.6;
}

.mainContentContainer_advanceSearch {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: flex-start;
}

.filterContainer_advanceSearch_poweresume {
    width: 400px;
    min-height: 760px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}

.filterContainer_advanceSearch_candidate {
    width: 400px;
    min-height: 1100px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px 0px rgba(26, 143, 255, 0.24);
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
}

.topSection_searchFilter {
    padding: 15px;
    padding-left: 10px;
    background-color: #D1E8FF;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.searchButton {
    margin: 0px 0px !important;
    color: #fff !important;
    background-color: #1885e6 !important;
    width: 80px;
}

.showMyCandidateContainer {
    margin-top: 10px;
}

.showMyCandidateText {
    font-size: 11px;
    font-family: "Segoe UI";
    font-weight: 500;
    color: #04093f;
    margin-left: -5px;
}

.showMyCandidateCheckboxContainer {
    margin-left: -10px;
}

.showingRF {
    font-family: 'Segoe UI';
    font-size: 16px;
    color: #04093F;
}

.filterSection_advanceSearch {
    padding: 15px;
    padding-top: 0px;
}

.skillTitleContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.booleanText {
    font-size: 14px;
    font-family: "Segoe UI";
    opacity: 0.7;
    color: #43425D;
}

.filterItemTitle {
    font-size: 16px;
    font-family: "Segoe UI";
    color: #43425D;
}

.inputFieldWidthFix {
    width: 100%;
}

.divider_input {
    margin-top: 15px;
    width: 100%;
    border-top: 1px solid #2699FB;
    opacity: 0.4;
}

.inputItemsContainer {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}
.CurrencyContainer {
   margin-top: 13px;
   display: flex;
   flex-direction: column;
}

.skillContainer_advanceSearch {
    margin-top: 13px;
}

.sliderInputFeild {
    padding-top: 10px;
    width: 94% !important;
}

.sliderContainer .MuiSlider-track {
    height: 10px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: #2699fb;
    border-radius: 30px;
}

.sliderContainer .MuiSlider-root {
    width: 100% !important;
}

.sliderContainer .MuiSlider-rail {
    width: 100%;
    height: 10px;
    display: block;
    opacity: 0.38;
    position: absolute;
    border-radius: 1px;
    background-color: #d5dde3;
    border-radius: 30px;
}

.sliderContainer .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: -4px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -6px;
    border-radius: 50%;
    justify-content: center;
    background-color: #2699fb;
    border: 2px solid white;
}

.mytalent_range_slider .MuiSlider-root {
    color: #2699fb;
    border: 2px solid white;
    width: 100% !important;
}

.sliderContainer .MuiSlider-track {
    height: 10px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: #2699fb;
    border-radius: 30px;
    /* left: -1% !important */
}

.sliderContainer .MuiSlider-mark {
    width: 5px;
    height: 5px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: 2px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -2px;
    border-radius: 50%;
    justify-content: center;
    background-color: #2699fb;
    /* border: 2px solid white; */
    opacity: 50%;
}

.sliderContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sliderValue {
    font-family: Arial;
    font-size: 13px;
    color: #2699FB;
    margin-top: 10px;
}

.locationContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.locationAutoComplete {
    width: 120px;
}

.locationItemTitle {
    font-size: 13px;
    font-family: 'Segoe UI';
    margin-bottom: 5px;
    color: #04093F;
}

.locationAutoComplete .MuiAutocomplete-input {
    font-size: 12px !important;
    font-family: 'Segoe UI' !important;

}

.employementAutocomplete .MuiAutocomplete-input {
    font-size: 13px !important;
    font-family: 'Segoe UI' !important;
}

.locationContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.othersContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.employmentItems .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.yearsOfExperienceAutoComplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.skillSelectAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.keywordSearchContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
{
    /* padding: 5px 0px !important; */
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 18px !important;
}

.CurrencyContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.othersContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.employmentItems .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.yearsOfExperienceAutoComplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.skillSelectAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.keywordSearchContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
{
    /* padding: 5px 0px !important; */
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 18px !important;
}
.skillSelectAutocomplete {
    margin-top: 10px;
}
.keywordSelectAutocomplete{
    margin-top: 10px;
}

.locationContainer .MuiOutlinedInput-root,
.othersContainer .MuiOutlinedInput-root,
.employmentItems .MuiOutlinedInput-root,
.skillContainer_advanceSearch .MuiOutlinedInput-root,
.yearsOfExperienceAutoComplete .MuiOutlinedInput-root,
.educationAutocomplete .MuiOutlinedInput-root,
.yoeMinMaxField .MuiOutlinedInput-root,
.additionalSkillSelectContainer .MuiOutlinedInput-root,
.keywordSearchContainer .MuiOutlinedInput-root

{
    border: 1px solid #2699FB;
}


.CurrencyContainer .MuiOutlinedInput-root,
.othersContainer .MuiOutlinedInput-root,
.employmentItems .MuiOutlinedInput-root,
.skillContainer_advanceSearch .MuiOutlinedInput-root,
.yearsOfExperienceAutoComplete .MuiOutlinedInput-root,
.educationAutocomplete .MuiOutlinedInput-root,
.yoeMinMaxField .MuiOutlinedInput-root,
.additionalSkillSelectContainer .MuiOutlinedInput-root,
.keywordSearchContainer .MuiOutlinedInput-root

{
    border: 1px solid #2699FB;
}

.employementContainer {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
}

.employmentItems {
    margin-top: 15px;
    width: 100%;
}

.educationCheckboxContainer {
    display: flex;
    justify-content: flex-start;
    grid-gap: 15px;
    gap: 15px;
    margin-left: -10px;
}

.educationCheckboxContainer .checkboxItems {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.educationCheckboxContainer .checkboxLabel {
    font-family: 'Segoe UI';
    color: #04093F;
    font-size: 12px;
    margin-left: -6px;
}

.educationCheckboxContainer .MuiCheckbox-root,
.mustHaveKeywordContainer .MuiCheckbox-root,
.showMyCandidateContainer .MuiCheckbox-root {
    color: #9bc1ff !important;
}

.educationCheckboxContainer .MuiCheckbox-colorSecondary.Mui-checked,
.mustHaveKeywordContainer .MuiCheckbox-colorSecondary.Mui-checked,
.showMyCandidateContainer .MuiCheckbox-colorSecondary.Mui-checked {
    color: #2896ff !important;
}

.KendoContainer_poweResumeSearch {
    width: calc(100% - 440px);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    margin-bottom: 17px;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 750px;
}
.KendoContainer_CandidateSearch {
    width: calc(100% - 440px);
    margin-bottom: 17px;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 1090px;
}
.KendoContainer_RecommendedCandidate {
    width: 100% ;
    margin-bottom: 17px;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 1090px;
}

.kendo_contains {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
}

.kendoTabsContainer {
    display: flex;
    align-items: center;
}

.kendoTab {
    background-color: #D1E8FF;
    width: 250px;
    font-family: 'Segoe-Semibold';
    font-size: 17px;
    text-align: center;
    padding: 10px 0px;
    opacity: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-right: 3px;
    cursor: pointer;
}

.kendoTabOpen {
    background-color: #1885e6;
    color: #FFFFFF;
}

.mainDiv_advanceSearch .k-grid-content {
    overflow-y: hidden;
}

.searchresultsText {
    font-family: 'Segoe UI';
    font-size: 18px;
    font-weight: 600;
    /* margin-bottom: 10px; */
}

.kendo_poweResumeSearch {
    min-height: 720px;
    margin-top: 10px;
}
.kendo_candidateSearch {
    min-height: 1195px;
    margin-top: 10px;
}

.mustHaveKeywordContainer {
    margin-left: -10px;
    display: flex;
    align-items: center;
}

.mustHaveText {
    font-family: 'Segoe UI';
    font-size: 13px;
    font-weight: 400;
    margin-left: -5px;
}

.skillContainer_advanceSearch .MuiChip-root,
.additionalSkillSelectContainer .MuiChip-root,
.educationAutocomplete .MuiChip-root, 
.keywordSearchContainer .MuiChip-root 
{
    background-color: #D1E8FF !important;
}

.skillContainer_advanceSearch .MuiChip-deleteIcon,
.additionalSkillSelectContainer .MuiChip-deleteIcon,
.educationAutocomplete .MuiChip-deleteIcon,
.keywordSearchContainer .MuiChip-deleteIcon

{
    color: dimgray !important;
}

.employementAutocomplete {
    width: 100%;
}

.otherCompanyNameContainer {
    margin-top: 10px;
    width: 100%;
}

.otherCompanyNameTextField {
    width: 100%;
}

.otherCompanyNameTextField input[type=text],
select {
    border: 2px solid #2698fb87;
    font-size: 13px;
    opacity: 0.9;
}

.yearsOfExperienceAutoComplete {
    margin-top: 10px;
}

.yearsOfExperienceAutoComplete .MuiAutocomplete-input,
.daysAutoComplete .MuiAutocomplete-input {
    font-size: 15px !important;
    font-family: 'Segoe UI';
}

.daysAutoComplete {
    width: 200px;
}

.educationAutocomplete {
    margin-top: 10px;
}

.educationAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.minMaxDiv {
    width: 80px;
}

.minMaxContainer {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 10px;
}

.searchtoggleContainer .MuiSwitch-colorPrimary.Mui-checked {
    color: #08A4F9 !important
}

.searchtoggleContainer .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
    background-color: #81C8EF !important;
}

.searchtoggleContainer .MuiSwitch-track {
    background-color: #b9dcff !important;
}

.searchtoggleContainer .MuiSwitch-switchBase {
    color: #81C8EF !important;
}

.titleContainer .MuiOutlinedInput-notchedOutline {
    border: none;
}

.titleContainer .MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
}

.additionalSkillContainer_primarySearch {
    margin-top: 15px;
}

.yearsOfExpMinMaxContainer {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.yoeMinMaxField .MuiOutlinedInput-input {
    padding: 10px;
    padding-left: 10px;
}

.yoeto {
    font-size: 15px;
    font-family: 'Segoe UI';
}

.additionalSkillContainer_primarySearch .MuiFormControl-root {
    width: 100%;
}

.selectedKeywords_container {
    display: flex;
    flex-wrap: wrap;
}

.keywords_items {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    background-color: #D1E8FF;
    padding: 0px 7px;
    border-radius: 16px;
    padding-left: 10px;
    height: 32px;
    margin: 3px;
    font-size: 14px;
    font-family: "Segoe UI";
    color: #43425D;
}

.cancelIcon_keywords {
    height: 22px !important;
    width: 22px !important;
    color: dimgray !important;
    cursor: pointer;
}

.keywordSearchContainer .MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
    padding: 10px 0px;
    border-bottom: 1px solid #2699FB;
}
.expandtext_kendo{
    color: blue;
    cursor: pointer;
    font-size: 13px;
    font-family: sans-serif;
}
.expandtext_kendo:hover{
    color:red
}
.keywordSearchTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.searchtoggleContainer {
    display: flex;
    align-items: center;
}

.btn_activity_cancel_community{
    width: 107px;
    height: 39px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #ffff;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  }
  .btn_activity_save_community{
    width: 107px;
    height: 39px;
    border: none;
    border-radius: 8px;  
    font-size: 16px;
    font-weight: 500;
    color: #ffff;
    background-color:#31B3F8;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

    }
    .topSection_advanceSearch{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
    }

    

.ats-dash-container {
    margin-top: 130px;
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.topBar-Ats-Feed {
    display: flex;
    height: 50px;
    margin-bottom: 20px;
}

.info-cards-topbar-ats-feed {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    grid-gap: 15px;
    gap: 15px;
}

.ats-feed-orgLogoArea {
    display: flex;
    justify-content: end;
    align-items: top;
    height: auto;
    width: 100%;
}

.Ats-DashFeed-OrgLogo {
    max-width: 200px;
    max-height: 45px;
}

.ats-dashFeed-card {
    padding: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    font-family: 'poppins', sans-serif;
    /* gap: 20px; */
    width: 240px;
    justify-content: space-between;
}

.ats-dashFeed-cardInfo {
    align-items: end;
    display: flex;
    flex-direction: column;
}

.communityStatsText {
    font-size: 18px;
    color: #2896fe;
    font-weight: 500;
}

.ats-dashFeed-MainGrid {
    display: grid;
    /* grid-template-columns: 3.5fr 2.5fr 1.5fr; */
    grid-template-columns: 4fr 1fr;
    grid-gap: 20px;
    gap: 20px;
}


.wasi-lineChart .main-svg {
    box-shadow:rgb(100 100 111 / 6%) 0px 0px 4px 2px;
}

.wasi-lineChart .js-plotly-plot .plotly .main-svg {
    height: 100% !important;
    width: 100%;
}

.ats-dashFeed-RightBar-Title {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 8px 8px 0px 0px;
    font-family: "Segoe-semibold";
    font-size: 19px;
}

.subtile_recLeadeFeed {
    font-family: 'poppins', sans-serif;
    font-size: 10px;
    margin-left: 2px;
    margin-top: 6px;
    color: #ffffff;
}

.ats-UploadResumeModal-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
}


.choose_file_dialog {
    margin-top: 20px;
}

.ats-dashFeed-RightBar {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 75vh;
    border-radius: 8px;

}

.ats-dash-rightBar-infoContainer {
    overflow-y: auto;
    height: 90%;
}

.ats-dash-rightBar-infoContainer::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.ats-dash-rightBar-infoContainer::-webkit-scrollbar-thumb {
    background-color: #1F729F;
    /* Set the color of the scrollbar thumb */
}

.ats-dash-rightBar-infoContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the color of the scrollbar track */
}


.ats-dashFeed-RightBar-InfoCard {
    border: 1px solid #31B3F8;
    display: flex;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7px;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    cursor: pointer;
}

.ats-dashFeed-RightBar-InfoCard:hover {
    opacity: 0.8;
    background-color: #f1f1f1;
}


.ats-dash-rightBar-loader {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.ats-dashFeedWrapper {
    display: grid;
    grid-template-columns: 2.35fr 3fr;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 3vh;
}

/* For 110% Screen Resolution */
@media only screen and (max-width: 1750px) {}

/* For 125% Screen Resolution */
@media only screen and (max-width: 1543px) {
    .ats-dashFeed-MainGrid {
        grid-template-columns: 1fr;
    }

    .ats-dashFeed-RightBar {
        width: 43%;
    }

    .ats-dash-body {
        margin-left: 20px !important;
    }
}


/* For 140% Screen Resolution */
@media only screen and (max-width: 1380px) {

}


/* For 150% Screen Resolution */
@media only screen and (max-width: 1380px) {}

.resume_source {
    font: 600 18px/24px Segoe UI;
    padding-left: 10px;
}

.source_div {
    margin-right: 20px;
    margin-bottom: 3px;
}
.recruiter_logo{
    max-width: 200px;
    max-height: 45px;
}
.font_29_300 {
    font-size: 29px;
    font-weight: 300;
    font-family: SegoeUI-Semilight, "Segoe UI";
}

.color_1885e6 {
    color: #1885e6;
}


.only_candidateProfileHeading_wd {
    width: 213px;
    height: 38px;
}

.cs_lastUpdate_font {
    color: #01313c;
    font-size: 15px;
    font-family: SegoeUI-Semibold, "Segoe UI";
    font-weight: 600;
}

.cs_lastUpdate_font_lastUpload{
    font-size: 15px;
    font-family: SegoeUI-Semibold, "Segoe UI";
}

.fiveDivContainer {
    display: flex;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    align-items: flex-start;
    margin-top: 10px;
}

.candidate_profile_boxes {
    padding-left: 13px;
    margin-bottom: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
    margin-right: 30px;

}

/* .icon_style{
    width: 15px;
    height: 15px;
} */

.only_candidate_profileImage {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.OnlyCandidateMainDiv_wd {
    /* width: 1069px; */
    width:100%;
    border-radius: 6px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.color_D5E7FD {
    background-color: #D5E7FD;
}

.color_04093f {
    color: #04093f;
}

.lh_1_pt_5 {
    line-height: 1.5;
}

.lh_2_pt_2 {
    line-height: 22px;
}

.lh_3_pt_5 {
    line-height: 3.5;
}

.font_20_700 {
    font-size: 20px;
    font-weight: 700;
    font-family: SegoeUI-Bold, "Segoe UI";
}

.font_15_600 {
    font-size: 15px;
    font-weight: 600;
    font-family: SegoeUI-Semibold, "Segoe UI";
}

.font_12_600 {
    font-size: 12px;
    font-weight: 600;
    font-family: SegoeUI-Semibold, "Segoe UI";
}

.font_17_600 {
    font-size: 17px;
    font-weight: 600;
    font-family: SegoeUI-Semibold, "Segoe UI";
}
.font_15_500{
    font-size: 15px;
    font-weight: 500;
    font-family: SegoeUI-Semibold, "Segoe UI";
}
.font_15_500_candidateName{
    font-size: 15px;
    font-weight: 500;
    font-family: SegoeUI-Semibold, "Segoe UI";
    padding-bottom: 5px;
}

.primary_secondary_skill_div {
    width: 96%;
    /* max-width: 1020px; */
    min-height: 430px;
    background-color: #fff;
    margin-top: 4vh;
    margin-left: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease-in-out;
    /* padding: 14px 20px 20px 20px; */
    /* border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; */
    padding-top: 22px;
    border-radius: 15px;
    padding-bottom: 20px;
    /* overflow-y: scroll; */
}

.only_candidate_specific_sub_card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.2s ease-in-out;
    /* padding: 14px 20px 20px 20px; */
    border-radius: 4px;
    background-color: #FFFFFF;
}

.only_candidate_specific_sub_wd {
    width: 100%;
    max-width: 780px;
    min-height: 202px;
    padding-bottom: 10px;
}
.only_candidate_specific_sub_wd_ps {
    width: 100%;
    max-width: 780px;
    /* min-height: 202px; */
    padding-bottom: 10px;
}

.only_candidate_specific_sub_card_margin {
    margin-left: 15px;
    min-height: 250px;
}
.only_candidate_specific_sub_card_margin_ps {
    margin-left: 15px;
    margin-bottom: 27px;
    /* min-height: 447px; */
}

.only_candidate_skill_type_exp_cmp_font {
    font-family: SegoeUI-Semibold, "Segoe UI";
    color: #01313c;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.0;
}

.only_candidate_rectangle-container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.only_candidate_rectangle_141 {
    border-radius: 10px;
    background-color: #d1e8ff;
    transform: translate(-0.26px, -0.265px);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-top: 6px;
    text-align: center;
}

.only_candidate_skill_font {
    font-family: SegoeUI-Semibold, "Segoe UI";
    color: #01313c;
    font-size: 10px;
    font-weight: 600;
}

/* .main-left_class {
    max-width: 25%;
    width: 25%;
}
.main-right_class {

    width: 73%;
} */
@media (max-width: 1200px) {
    .OnlyCandidateMainDiv_wd {
        width: 90%;
        /* Reduce the width to 90% for screens up to 1200px wide */
        padding-bottom: 10px;
        border-radius: 6px;
    }
}

@media (max-width: 768px) {
    .OnlyCandidateMainDiv_wd {
        width: 100%;
        /* Set the width to 100% for screens up to 768px wide */
        /* padding: 0 20px; */
        /* Add some padding to the container to avoid content overflowing */
        padding-bottom: 10px;
        border-radius: 6px;
    }
}

.primary_secondary_skill_div::-webkit-scrollbar {
    width: 13px;
}

.primary_secondary_skill_div::-webkit-scrollbar-thumb {
    background-color: #1885E6;
    border-radius: 4px;
}

.primary_secondary_skill_div::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.mainDiv_onlycandidateProfile {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFFFFF;
}

.maincontainer_onlycandidateprofile {
    display: flex;
    margin: 0 3%;
    margin-top: 120px;
    flex-direction: column;
}
.leftContainer_onlycandidateprofile{
    display: flex;
    flex-direction: column;
    width: 1000px;
}
.rightContainer_onlycandidateprofile{
    width: calc(100% - 1020px);
    padding-top: 60px;
    display: flex;
    justify-content: center;

}
.viewresume_box{
    display: flex;
    justify-content: flex-start;
    grid-gap: 5px;
    gap: 5px;
}
.viewresume_container{
    display: flex;
    align-items: center;
    grid-gap:5px;
    gap:5px;
    cursor: pointer;
    font-family: 'Segoe-Semibold';
    font-size: 14px;
}

.viewresume_container_value{
    display: flex;
    align-items: center;
    grid-gap:5px;
    gap:5px;
    cursor: pointer;
    font-family: 'Segoe';
    font-size: 14px;
}
.inviteCandidateIconStyle {
    height: 80px;
    width: 80px;
}
.invitecandidate_text1{
    font-family: 'Segoe-semibold';
    font-size: 12px;
    color: #4D4F5C;
    opacity: 0.5;
    margin-top: 5px;
}
.invitecandidate_invitebutton{
    height: 25px;
    width: 85px;
    background-color: #05A6F4;
    color: #FFFFFF;
    padding: 0px !important;
    font-size: 14px !important;
    font-family: 'Segoe-semibold' !important;
    margin-top: 10px;
}
.invitecandidate_invitebutton:hover{
    opacity: 0.8;
}
.invitecandidate_text2{
    font-size: 22px;
    font-weight: 400;
    color: #05A6F4;
    font-family: 'Segoe UI';
    margin-top: 10px;
}
.invitecandidate_text3{
    font-size: 22px;
    font-weight: 400;
    color: #05A6F4;
    font-family: 'Segoe UI';
}
.mainDivcontainer_onlycandidateprofile{
    display: flex;
    width: 100%;
}
@media screen and (min-width: 1290px) {
    .maincontainer_onlycandidateprofile{
        margin: 0 5%;
        margin-top: 120px;
    }
}
@media screen and (max-width: 1270px) {
    .mainDivcontainer_onlycandidateprofile {
        flex-direction: column-reverse;
        justify-content: flex-start;
    }

    .rightContainer_onlycandidateprofile {
        width: 100%;
        justify-content: flex-end;
    }

    .leftContainer_onlycandidateprofile {
        width: 100%;
    }

    .fiveDivContainer {
        flex-wrap: wrap;
    }
}

.yearsofExp_only_candidateProfile {
    font-family: "Segoe-semibold";
    /* font-weight: 400; */
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #01313c;
}

.candidate_resume_fontstyle {
    font-family: "Segoe";
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.textCapitalize {
    text-transform: capitalize;
}

@media screen and (max-width: 600px) {
    .only_candidate_specific_sub_wd {
        width: 90%;
    }
}

.primaryskillcard_div {
    padding-top: 10px;
}

.primaryskillcard_div_extra {
    padding-top: 10px;
    margin-top: 20px;
}

.primaryskilldivcontainer {
    padding-left: 15px;
}

.primaryskilldivcontainer_olderProfile {
    padding-left: 15px;
    padding-bottom: 5px;
}

.skillHeadings_candidateProfile {
    font-family: "Segoe UI";
    color: #01313c;
    font-size: 25px;
    font-weight: 300
}

.skillHeadings_candidateProfile_mt5 {
    font-family: "Segoe UI";
    color: #01313c;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 15px;
}

.skillHeadings_candidateProfile_secondarySkill {
    font-family: "Segoe UI";
    color: #01313c;
    font-size: 25px;
    font-weight: 300;
}

.skillHeadings_candidateProfile_secondarySkill_container {
    padding-left: 22px;
    padding-top: 10px;
}

.multiProfileBox {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.multiProfileBox_olderProfile {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding-bottom: 10px;
}

.skillHeadings_candidateProfile_olderProfile {
    font-family: "Segoe UI";
    color: #01313c;
    font-size: 25px;
    font-weight: 300;
    padding-bottom: 10px;

}

.candidate_resume_ph {
    font-size: 15px;
    /* font-weight: 500; */
    font-family: SegoeUI-Semibold, "Segoe UI"
}

.star_primaryskill {
    display: flex;
    flex-direction: row;
    grid-gap: 3px;
    gap: 3px;
}

.skillTestTimeInfoContainer_poweResumeRight_skeleton {
    display: flex;
    font-family: 'Segoe UI';
    font-size: 12px;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-bottom: 10px;
    /* border-bottom: 1px solid #A3BCD5; */
}

.invitetotaketestConrainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 48%;
    left: 51%;
}

.inviteCandidateIconStyle {
    height: 80px !important;
    width: 80px !important;
    color: #05A6F4;
    opacity: 0.8;
    text-shadow: 1px 4px 2px black !important;
    margin-left: -20px;
}

.invitecandidate_text1 {
    font-family: 'Segoe-semibold';
    font-size: 12px;
    opacity: 0.8;
    margin-top: 5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.041);
}

.invitecandidate_invitebutton {
    height: 25px;
    width: 85px;
    background-color: #05A6F4;
    color: #FFFFFF;
    padding: 0px !important;
    font-size: 14px !important;
    font-family: 'Segoe-semibold' !important;
    margin-top: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    letter-spacing: 1px;
    text-shadow: 0px 1px 0px black;
    padding-bottom: 2px !important;
}

.invitecandidate_invitebutton:hover {
    opacity: 0.8;
}

.invitecandidate_text2 {
    font-size: 22px;
    font-weight: 400;
    color: #05A6F4;
    font-family: 'Segoe UI';
    margin-top: 10px;
    text-shadow: 0px 1px 0px #013047b0;
}

.invitecandidate_text3 {
    font-size: 22px;
    font-weight: 400;
    color: #05A6F4;
    font-family: 'Segoe UI';
    /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.041); */
    text-shadow: 0px 1px 0px #013047b0;
}

.profileHeaderDiv_skeleton{
    width: 220px;
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) -1px -5px 5px 0px;
    /* border-bottom: 4px solid #1885E6; */
    display: flex;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    opacity: 0.5;
    background-color: rgba(128, 128, 128, 0.190);
}

.poweResumeInfoCard_poweResumeRight_skeleton {
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
    border-radius: 5px;
    margin-left: 10px;
    border-top-left-radius: 0px;
    margin-top: -3px;
    margin-right: 3px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-top: 20px;
    opacity: 0.5;
    background-color: rgba(128, 128, 128, 0.190);
  }

  .resumeContainer_skeleton{
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }

  .fileImg_resume_skeleton{
    width: 30px;
    height: 30px;
    margin-left: 4px;
    margin-bottom: 10px;
  }

  .viewText_skeleton{
    font-size: 15px;
    font-weight: 500;
    font-family: SegoeUI-Semibold, "Segoe UI";
    /* padding-bottom: 5px; */
    color: #04093f;
  }
  
  @media screen and (max-width: 1020px) {
    .invitetotaketestConrainer{
        top: 123%;
        left: 52%;
    }
  }

  .poweResumeHeading_poweResumeRight_header {
    font-weight: normal;
    font-family: 'Segoe-Regular';
    font-size: 30px;
    color: #1885E6;
  }
  .resumeContainer_skeleton{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 7px;
    gap: 7px;
  }
  .detailContainer_skeleton{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
  .primarySkillSkeletonContainer{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
  .secondarySkill_skeleton{
    margin-top: 7px;
  }
  
.reqDetailsContainer {
    margin-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
}

.reqDetailsGrid {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    height: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;

}


 .reqDetailsLeftCardGrid{
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    padding-left: 20px;
    padding-top: 20px;
    align-content: baseline;
}
.reqDetailsLeftFormContainer{
    width: calc(100% - 320px);
}
.reqDetailsRightCard{
    display: flex;
    flex-direction: column;
    width: 300px;
}

.reqDetailsBasicInfo{
    display: flex;
    flex-direction: column;
    width: 250px;
}
.reqDetailsBasicInfo_location{
    display: flex;
    flex-direction: column;
    width: 520px;
}
.reqDetailsBasicInfo_jobDesCription{
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 97%;
}

.reqDetails_headings{
    font-size: 17px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-bottom: 10px;
    color: #3B3B3B;
    
}

.textAreaJD{
    margin-left: 10px;

    }
.reqDetailsBasicInfo_ActionBtnsContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
    padding: 8px;
    margin-left: 10px;
    margin-bottom: 15px;
}
.reqDetails-referenceId{
    background-color: #fff;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    justify-content: space-between;
    cursor: pointer;
}
.reqDetailsBasicInfo .reqDetailsDatePicker{
   padding: 12.5px ;
   border: 1px solid lightgrey;
   font-family:"Roboto", "Helvetica", "Arial", sans-serif;
   font-size: 1rem;
   font-style: normal;
   font-weight: 400;


}

.reqDetailsBasicInfo .reqDetailsDatePicker::placeholder{
    color: #BABABA;
}

.reqDetailsBasicInfo .MuiSelect-selectMenu{
    padding-left: 10px;
}


.reqDetailsBasicInfo .react-datepicker__input-container .react-datepicker__calendar-icon {
    margin-top: 6px;
    margin-left: 85%;
}

.reqDetailsBasicInfo input:focus-visible{
    outline: none;
}

.reqDetailsBasicInfo .MuiSelect-select.MuiSelect-select em{
    font-family:"Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    color:#BABABA;
}

.reqDetailsBasicInfo .MuiInputBase-root{
    font-family:"Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.reqDetailsBasicInfo_ActionBtnsContainer .RequisitionDetails_CancelBtn{
    background-color: white;
    color: black;
}
.loader_requisitionDetails{
    height: 50vh;
    width: calc(100% - 320px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.viewDocumentButton_viewRequisition{
    padding: 10px !important;
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    width: 140px !important;
}

.viewButton_container_viewRequisition{
    position: absolute;
    right: 30%;
}



.cancelButton_viewRequisition{
    background-color: #ffffff !important ;
    color: black !important;
    width: 110px !important;
    padding: 10px !important;
    border-radius: 5px;
}
.cloneRequisitionButton_viewRequisition{
    background-color: #4DD164 !important ;
    color: #ffffff !important;
    padding: 10px ;
    cursor: pointer;
}

.cloneRequisitionButton_viewRequisition:hover{
   box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

.cloneRequisition_createRequisition_dialogBtn{
    width: 100%;
    padding: 10px;
    background-color: #31B3F8 !important;

}
.cloneRequisitionBtnContainer{
    display: flex;
    grid-gap:20px;
    gap:20px;
}
.maindivButton_requisitionBody{
    display: flex;
    flex-direction: row;
}

.button_AssignRecruiter{
    background-color: #2ECA75 !important;
    margin-bottom: 10px !important;
    color: #ffffff !important;
    padding: 10px !important;
}

.button_candidateSearch{
    background-color: #4A97C1 !important;
    margin-bottom: 10px !important;
    color: #ffffff !important;
    padding: 10px !important;
}

.button_uploadResume{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    padding: 10px !important;
}

.iconDivision_viewRequisition{
    padding-right: 5px !important;
}

.recentActivities_BtnGroup{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-right: 12px;
}

.dialogBox_assignRequisition .input-text-1 {
    width: 120px;
    height: 46px;
    border-radius: 8px;
    background-color: white;
}

.dialogBox_assignRequisition .input-text-2{
    width: 300px;
    height: 46px;
    border-radius: 8px;
    background-color: white;
    padding: 0px;
}

.dialogBox_assignRequisition .input-text-3{
    width: 350px;
    height: 46px !important;
    border-radius: 8px;
    background-color: white !important;
}
.header-text-popup{
    font-family: "Segoe-Semibold" !important;
    font-weight: 400px !important;
    font-size: 15px !important;
    line-height: 21.09px;
    color: #3B3B3B !important;
    padding: 10px 12px !important;
}

.header-text-popup-1 {
    font-family: "Segoe-Semibold" !important;
    font-weight: 400px !important;
    font-size: 15px !important;
    line-height: 21.09px;
    color: #3B3B3B;
    padding: 10px 12px ;
    max-width: 200px ;
}

.header-text-popup-2 {
    font-family: "Segoe-Semibold" !important;
    font-weight: 400px !important;
    font-size: 15px !important;
    line-height: 21.09px;
    color: #3B3B3B !important;
    padding: 10px 12px !important;
    width: 300px !important;
}

.alignment_ActionButton_recruitmentLeader{
    padding-right: 10px ;
    display: flex;
    flex-direction: row-reverse;
    grid-gap: 20px;
    gap: 20px;
    padding: 15px;
}
.alignment_ActionButton_recruiter{
    padding-right: 63px !important;
    padding-bottom: 30px !important;
}

.buttonContainer_viewRequisition_outside{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}



.dialogBox_assignRequisition .MuiDialogContent-root{
    padding: 15px;
    max-height: 440px;
    overflow: auto;
    padding-bottom: 0px !important;
}


.rd_UploadResumeActionsContainer .MuiDialogActions-root{
    justify-content: space-between !important;
}
.assignedRecruiterContainer{
    display: flex !important;
    justify-content: flex-end;
}
.assignedRecruiterContainer .alignment_ActionButton_recruiter{
    padding-right: 35px !important;
}
.close_icon_right{
    cursor: pointer;
}

.mainContainer_requisitionDetails{
   
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 100vh;
}

.topContainer_requisitionDetails{
    margin-top: -10px;
}

.saveButton_viewRequisition{
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff !important;
    width: 110px !important;
    padding: 10px !important;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center

}

.saveButton_viewRequisition_disable
{
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff !important;
    width: 110px !important;
    padding: 10px !important;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
    grid-gap:10px;
    gap:10px;

}


.saveButton_viewRequisition:hover{
  opacity: 0.9;
}

.buttonContainer_viewRequisition_outside .MuiCircularProgress-root{
  color: #FFFFFF !important;
  height: 13px !important;
  width: 13px !important;
}

.assignRequisitionContainer{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding-top:25px !important;
    padding-left: 30px !important;
    padding-bottom: 25px !important;
}
.reassignRecruiterContainer{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding-top:25px !important;
    padding-left: 30px !important;
    padding-bottom: 25px !important;
}
.assignRequistionRowItems{
    display: flex;
    flex-direction: row;
    grid-gap:15px;
    gap:15px;
}

.dialog-actionbutton-assignTeamLeader{
    padding: 15px 20px !important;
}
.updateButton-assignTeamLeader{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}
.updateButton-assignTeamLeader:hover{
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}
.updateButton-assignTeamLeader_disabled{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}
.cancel-button-assignTeamLeader{
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.cancel-button-assignTeamLeader:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.reassign-button-recruiter{
    font-size: 15px;
    border-radius: 3px;
    width: 160px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.reassign-button-recruiter:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.noOfPosition_teamLeaderAssignment .MuiOutlinedInput-input{
    height: 10px !important;
    width: 150px !important;
}
.addIcon_teamLeaderAssignment{
    float: right;
    cursor: pointer;
    color: #1885E6 !important;
    font-size: 35px !important;
}
.addIcon_teamLeaderAssignment:hover{
    opacity: 0.7;
}
.assignRequisitionContainer .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
    margin-right: 12px !important;
}
.dialog-actionbutton-assignTeamLeader .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
}
.addButtonGrid_addTeamLeader{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.openings_TeamLeaderRequisitionContainer{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    grid-gap: 5px !important;
    gap: 5px !important;
}
.openings_RecruiterRequisitionContainer{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    grid-gap: 5px !important;
    gap: 5px !important;
}
.cancelIcon_teamLeaderReq{
    cursor: pointer;
}
.cancelIcon_teamLeaderReq:hover{
    opacity: 0.7;
}
.assignRequisitionContainer .MuiTableCell-body {
    border:none !important;
}

.recruiterAssignment_teamLeader .MuiOutlinedInput-input{
    height: 10px !important;
    width: 150px !important;
    margin-right: 10px !important;
}
.recruiterName_teamLeader .MuiOutlinedInput-input{
    height: 46px !important;
    width: 250px !important;
}
.recruiterName_teamLeader .MuiInputBase-input.Mui-disabled{
    padding: 18.5px 14px !important;
} 
.positionsTag_assignRecruiter{
    font-family: 'Segoe-Semibold';
    font-size: 15px;
    padding-left: 15px;
}

.recruiterAssignmentButton{
    font-size: 15px;
    border-radius: 3px;
    width: 200px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.recruiterAssignmentButton:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.selectedTeamLeaderOnBehalfContainer{
    padding: 20px !important;
    padding-left: 32px !important;
    height: 100px !important;
}
.teamLeaderHeading_behalf{
    font-family: 'Segoe-Semibold';
    font-size: 15px;
    margin-bottom: 15px;
}

.dialogBox_reassignRecruiter .input-text-3{
    height: 46px !important;
    border-radius: 8px;
    background-color: white !important;
}

.label_recruiterReassignment{
    font-family: 'Segoe-Semibold' !important;
    font-size: 16px !important;
    margin-bottom: 0px !important;
}
.note_recruiterReassignment{
    font-family: 'Segoe UI' !important;
    font-size: 14px !important;
    margin-bottom: 0px !important;
    font-style: italic;
}

.loader_requisitionDetails{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContainer_createRequisition {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #ffffff;
}


/* .topContainer_createRequisition {} */

.bottomContainer_createRequisition {
    margin-top: auto;
}

.mainDiv_createRequisition {
    margin: 0 5%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.titlesofCommunity {
    font-size: 30px;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin: 10px 0px 20px;
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
    color: #31B3F8;
    border-bottom: 1px solid #31B3F8;
    padding-bottom: 5px;
}
.titlesofCommunity_blank{
    min-height: 57px;
}

.goBackToRecruiterDashboard_createRequisition{
    font-size: 20px;
    color: #7F7F7F;
    font-family: "Segoe-Semibold";
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 34vw;
    margin-bottom: 15px;
}
.goBackToRecruiterDashboard_createRequisition:hover{
    color:#04093f;
}

.mainContainer_div_createRequisition{
    box-shadow: 0px 3px 8px #0000003d;
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.rightside_maincontainer_div_createRequisition{
    width: 350px;
    padding: 10px;
}

.leftside_maincontainer_div_createRequisition{
    width: calc(100% - 360px);
    padding-top: 20px;
    justify-content: space-between;
}

.middleDiv_mainContainer{
    display: flex;
    flex-direction: column;
    background-color: #B0DAF130;
    /* flex-wrap: wrap; */
    /* height: 540px; */
    border-radius: 10px;
    padding: 20px;
}

.feildvalue_container {
    display: flex;
    flex-direction: column;
}

.valueFeild_Text {
    font-family: "Segoe-regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

}

.valueFeild_createRequisition{
    margin-top: 10px;
}

.createRequisition_textbox_assignto{
    height: 47px;
    background-color: #ffffff;
}

.feildvalue_container_jobDescription{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.valueFeild_Text {
    font-family: "Segoe-regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

}

.support_message_createRequisition{
    width: 100%;
    border: 1.4px solid #707070;
    opacity: 0.6;
    border-radius: 3px;
}

.container_button_fileattachment{
    margin-top: 30px;
    height: 90px;
    display: flex;
    align-items: flex-end;
}

.attachfileIcon{
    height: 20px !important;
    width: 20px !important;
    color: #ffffff !important;
}

.uploadFuctionContainer_conditional_createRequition{
    text-align: center;
    margin-top: 10px;
}

.uploadcontainer_profile_createRequition{
    display: flex;
    flex-direction: column;
}

.uploadFuctionCon_createRequition{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.close_icon_createRequisition{
    position: relative;
    top: -10px;
    right: -77px;
    cursor: pointer;
}

.candidateFileExtention_createRequisition{
    position: relative;
    top: 4px;
    right: -24px;
}

.form_container_createRequisition{
    width: 230px;
}
.form_container_createRequisition_location{
    width: 480px;
}

/* .createRequisition_textbox{

} */

.createRequisition_textbox_outline{
    height: 47px;
    background-color: #ffffff !important;
}

.createRequisition_textbox_datapicker{
    height: 47px;
    border: 1px solid rgb(192, 192, 192);
}

.form_container_createRequisition .react-datepicker__calendar-icon{
    right: 8px !important;
    margin-top: 7px;
}

.form_container_createRequisition .react-datepicker-wrapper {
    width: 100%;
}

.leftside_topContainer_createRequisition{
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    padding-left: 20px;
    align-content: baseline;
}

.ActionContainer_createRequisition{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-left: 20px;
    grid-gap:10px;
    gap:10px;
}

.ButtonSave_createRequisition{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    width: 85px;
}

.ButtonSave_createRequisition_dialog{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    margin-right: 10px !important;
    width: 85px;
}

.ButtonSave_createRequisition_dialog_opacity{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    margin-right: 10px !important;
    width: 85px;
    opacity: 0.5;
}


.ButtonCancel_createRequisition{
    background-color: #ffffff !important;
    color: #009EFD !important;
    width: 85px;
}

.ButtonCancel_cloneRequisition{
    background-color: #ffffff !important;
    color: #009EFD !important;
    width: 100%;
}

.container_button_fileattachment .MuiButton-label{
    flex-direction: row;
}

.new_candidate_icon_createRequisition{
    height: 35px;
    width: 35px;
}

.candidate_filepicker_button_createRequition{
    /* width: 150px !important; */
    width: 174px !important;
    background-color: #31B3F8 !important;
    color: white !important;
}

.bottomcontainer_container_attachment {
    /* margin-top: 15px; */
    width: 174px !important;
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    /* width: 150px !important; */
}

.createRequisition_error_outline{
    height: 46px;
    border: 2px solid red;
}

.createRequisition_error{
    height: 45px;
    border: 2px solid red;
}

.button_Container_CreateRequisition{
    margin-right: 5px !important;
    margin-top: 15px !important;
}
.mainContainer_notification {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #ffffff;
}

.topContainer_notification {}

.bottomContainer_notification {
    margin-top: auto;
}

.mainDiv_notification {
    margin: 0 5%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.titlesofCommunity_notification {
    font-size: 30px;
    font-family: "Segoe-Semibold";
    text-decoration: none;
    margin: 10px 0px 20px;
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
    color: #31B3F8;
    border-bottom: 1px solid #31B3F8;
    padding-bottom: 5px;
}

.newNotification_notification {
    color: #7F7F7F;
    font-family: "Segoe-regular";
    font-size: 16px;
}

.notification_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px 10px 7px 10px;
    border-bottom: 1px solid #636363;
}

.newAll_notification {
    display: flex;
    flex-direction: row;
}


.newtext_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.alltext_notification {
    color: #2896FF;
    font-family: "Segoe-regular";
    font-size: 18px;
    border-bottom: 3px solid #31B3F8;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.markAsReadButton {
    background-color: #2896FF !important;
    color: #ffffff !important;
    width: 160px;
}

.markAsReadButton_allReaded {
    background-color: #2896FF !important;
    color: #ffffff !important;
    width: 160px;
    opacity: 0.5;
}

.interviewedButoon_notification {
    background-color: #ffffff !important;
    color: #585858!important;
    width: 105px;
    border: 2px solid #31B3F8 !important;
}

.cardContainer_notification {
    height: 58vh;
    overflow: auto;
    padding: 0px 10px;
    margin-left: -10px;
    padding-top: 10px;
}

.cardContains_container_notification {
    background-color: #ffffff;
    box-shadow: 0px 3px 8px #0000003d;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.cardContains_container_notification_unread{
    box-shadow: 0px 3px 8px #0000003d;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #F3F9FF;
    border-left: 3px solid #2896FF;
    cursor: pointer;
}

.leftSideContainer_cardContainer_notification {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileImg_notification {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 15px;
}

.candidateDetail_notification {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.candidateDetail_informationContainer_notification {
    display: flex;
    flex-direction: row;
    margin: 5px;
    margin-top: 5px;
}

.candidateDetail_informationContainer_recruiter_notification {}

.candidateName_notification {
    padding-right: 15px;
    color: #000000;
    font-size: 20px;
    font-family: "Segoe-regular";
}

.interviewText_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 15px;
}

.activityTimeContainer_notification {
    display: flex;
    flex-direction: row;
    margin: 3px;
    /* justify-content: space-between; */
}

.activityTimingText_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 14px;
    margin-right: 20px;
}

.assignTo_notification {
    display: flex;
    flex-direction: row;
    margin: 3px;
}

.userIcon_notification {
    padding-right: 11px;
}

.recruiterName_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 15px;
}

.interviewedDataContainer_notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 65px;
}

.interviewedDate_notification {
    color: #636363;
    font-family: "Segoe-regular";
    font-size: 14px;
    margin-left: 5px;
}

.interviewedButtonContainer_notification {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.calanderIcon_notification {
    height: 20px !important;
}

.NoRecentText_notification{
    font-family: "Segoe-Semibold";
    font-size: 20px;
    color: #393939;
    text-align: center;
  }

.mainContainer_requisitionReports {
    min-height: 100vh;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.topContainer_requisitionReports {
    display: flex;
    flex-direction: column;
}

.contentcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 10px;
}

.sideContainer {
    /* border: 1px solid red; */
    padding: 1.5%;
    width: 27%;
    height: fit;
    background-color: white;
    padding-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
    margin-right: 2%;
    box-sizing: border-box;
    min-width: 360px;
    overflow: hidden;
}

.mainContainer {
    /* border: 1px solid red; */
    width: 72%;
    height: 100%;
    
}


.mainDiv_requisitionReports {
    margin-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
}

.titleContainer_requisitionReports {
    display: flex;
    justify-content: start;
    font-family: "Segoe-Semibold";
    /* background-color: #05A6F4;
    color: #FFFFFF; */
    font-size: 24px;
    padding: 5px 3px;
    color: #2896ff;
    border-bottom: 1px solid #2896ff;
    width: 140px;
}

.titleIcon_requisitionReports {
    height: 35px !important;
    width: 40px !important;
    margin-left: -10px;
}

.bottomContainer_requisitionReports {
    margin-top: auto;
}

.goBackToDashboard_requisitionReports {
    font-size: 20px;
    color: #7F7F7F;
    font-family: "Segoe-Semibold";
    /* text-decoration: none; */
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    margin-top: 15px;
}

.goBackToDashboard_requisitionReports:hover {
    color: #04093f;
}

.tabsContainer_requisitionReports {
    display: flex;
    /* margin-top: 10px; */
    grid-gap: 3px;
    gap: 3px;
    width: 100%;
}

.tabsMainContainer_requisitionReports {
    display: flex;
    /* margin-top: 10px; */
    grid-gap: 3px;
    gap: 3px;
    justify-content: space-between;
    align-items: flex-end;
}

.tabs_requisitionReports {
    font-family: 'Segoe-Semibold';
    width: 35%;
    font-size: 15px;
    text-align: center;
    padding: 10px 8px;
    cursor: pointer;
    background: #B0DAF18A;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tabs_requisitionReports:hover {
    opacity: 1;
    background: #a1cae4;
}

.tabs_requisitionReports_selected {

    background-color: #009EFD;
    width: 35%;
    font-size: 15px;
    color: #FFFFFF;
    font-family: 'Segoe-Semibold';
    text-align: center;
    padding: 10px 8px;
    opacity: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.kendoContainer_requisitionReports {
    margin-top: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
    
}
.export-button{
    width: 99%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1%;
}
.kendoContainer_requisitionReports .k-grid {
    height: 58vh !important;
}

.excelButton_requisitionReports {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009EFD;
    color: #ffffff;
    grid-gap: 6px;
    gap: 6px;
    padding: 10px 1px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: 'Segoe-semibold';
    font-size: 14px;
    width: 75px;
    margin-left: auto;
}

.excelButton_requisitionReports:hover {
    opacity: 0.9;
}

.excelImg_requisitionReports {
    height: 30px;
    padding-right: 15px;
}

.kendo_reports {
    min-height: 667px;
    margin-top: 10px;
}


.dialogContent_requisitionReport {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
}

.dialogTitleContainer_reports {
    width: 275px;
    /* padding-left: 5px; */
}

.dialogTitle_reports {
    font-family: 'Segoe-semibold';
    font-size: 20px;
    padding-left: 5px;
    padding-bottom: 7px;
    border-bottom: 1px solid #2896ff;
}

.dialogContent_Text_requisitionReport {
    font-family: 'Segoe-semibold';
    padding-bottom: 7px;
    font-size: 15px;
}

.createRequisition_textbox_datapicker_reports {
    height: 40px;
    width: 150px;
    /* border: 1px solid rgb(192, 192, 192); */
    border: 2px solid #177bd3ce !important;
    font-family: 'Segoe UI' !important;

}

.createRequisition_textbox_datapicker_reports_filter {
    width: 100px;
    border: 1px solid rgb(192, 192, 192);
    height: 50px;
}
 .react-datepicker-wrapper{
   width: 100%;
   font-family: 'Segoe UI' !important;
   display: block; 
}

  

.dialogContent_requisitionReport .react-datepicker__calendar-icon {
    right: 8px !important;
    margin-top: 7px;
}

.dialogContent_requisitionReport .react-datepicker-wrapper {
    width: 100%;
}

.dialogContent_requisitionReport .MuiDialogTitle-root {
    padding: 10px 24px !important;
}


.button_Container_reports {
    margin-right: 5px !important;
    margin-top: 180px !important;
    grid-gap: 5px;
    gap: 5px;
}

.excelButton_requisitionReports_dialog {
    cursor: pointer !important;
    background-color: #1e7145 !important;
    color: #ffffff !important;
    grid-gap: 6px;
    gap: 6px;
    font-family: 'Segoe-semibold' !important;
    font-size: 14px !important;
    /* float: right; */
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */

    /* padding: 10px 1px;
    border-radius: 5px;
    border: none;
    
    
    width: 200px; */
    background-color: #009EFD;
    margin-left: 200px;
    opacity: 0.9;
}


.excelButtonContainer_reports {
    display: flex;
    flex-direction: row;
    width: 120px;
}

.exportText_Dialog_reports {
    font-family: 'Segoe-semibold' !important;
    font-size: 16px !important;
}



.kendo_reports_recruiterActivity {
    min-height: 667px;
    margin-top: 10px;
}


.kendo_reports_recruiterActivity.k-grid .k-table-row {
    height: 53px !important;
}

.gobackContainer_requisitionReports {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dateRangeContainer_requisitionReports {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    ;
}

.dateRangeContainer_requisitionReports_text {
    font-family: 'Segoe-Semibold';
    font-size: 16px;

}

.datePicker_Container .react-datepicker__calendar-icon {
    right: 8px !important;
    top: 4px !important;
}

.dateRangeContainer_requisitionReport .requistionRepoets_date_border {
    border: 2px solid #177bd3ce !important;
    padding: 12px 10px !important;
    font-family: "Segoe UI" !important;
    font-size: 14px !important;
}

.search_main_Container{
    width:100%;
    height: 100%;
    /* background-color: black; */
    box-sizing: border-box;
}
.search_btn_box{
    width:113%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #D1E8FF ;
    padding:6%;
    box-sizing: border-box;
    margin: -6% 0 0 -6.5%;
}
.text_top{
    width: 50%;
    display: inline;
    /* border: 1px solid red; */
}
.search_btn_top{
    /* box-sizing: border-box; */
    width: 25%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -4% 0 0 0;
}

.search_btn{
    /* box-sizing: border-box; */
    background-color: #31B3F8; 
    outline: none;
    border: none;
    border-radius: 3px;
    padding: 10%;
    color:white;
    width: 100%;
    font-size: 1em;
    
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.current_tab_search_name{
    width:100%;
    height: 6%;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    /* background-color: #00A7FF; */
    /* color: black; */
    /* text-align: center!important; */
}
.access_search input[type="text"],
select {
  
    height: 47.5px;

}
.trackCandidateContainer {
    margin-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
}

.trackCand_UpperCard{
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 5px 0px;
    border:1px solid #1a8fff;
}

.trackCand_searchFieldsContainer{
    display: flex;
    padding: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    grid-gap: 20px;
    gap: 20px;

}

.trackCandidate_searchHeading{
    margin-bottom: 10px;
    font-size: 18px;
}

.trackCandidate_searchInput{
    width: 20vh;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    padding: 5px;
}

.trackCand_searchFieldsContainer p{
    color: #8d8d8d;
}

.trackCand_SearchBtn{
    display: flex;
    flex-direction: row;
    margin-right: 2%;
    height: 5.5vh;
}


.trackCand_LowerCard{
   
    height: 50vh;
    margin-bottom: 1% ; 
}



.trackCand_TrackFlowTitle{
    font-size: 20px;
    margin-top: 20px;
    background-color: rgb(40, 150, 255);
    padding: 15px;
    justify-content: center;
    display: flex;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: white;
    border-radius: 5px 5px 0px 0px;
    grid-gap: 10px;
    gap: 10px;
}

.trackCand_TrackFlowDiagram{
    height: 50vh;
    margin-bottom: 1%;
}
.trackCand_TrackFlowDiagram .bi.bi-diagram{
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 0px solid #d3d3d3;
    height: 200vh;
}

.mainContainer_trackCandidate{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.container_trackCandidate{
    margin-top: -10px;
}

/* .container {
    padding: 3rem;
} */
.card-container{
    padding:1rem;
    box-shadow:0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.header-client-manager {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    background-color: white;
}

.header-image-manage-client {
    display: flex;
    align-items: center;
    grid-gap: 14px;
    gap: 14px;
}
.image{
    width: 41px;
    height: 41px;
    top: 132px;
    left: 100px;
    padding: 0px 1.34px 0px 1.34px;

}

.manage-client-main {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    /* height: 75vh; */

}


.manage-client-main .manage-client-main-head {
    font-size: 30px;
    color: #31B3F8;
    font-weight: 600;
    font-family: "Segoe-Semibold";
    border-bottom: 1px solid #31B3F8;
}
.manage-client-main .add-button-manageclient {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
.manage-client-main .add-button-manageclient:hover {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
.header-client-manager .add-button-manageclient .MuiButton-label{
    display: flex;
    flex-direction: row;
}
.add-image {
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: row;
}

.table-head {
    background-color: #31B3F8;
}

.card-container .edit-button{
    background-color: #31B3F8;
    color: #ffff;
}
.card-container .edit-button .MuiButton-label{
    display: flex;
    flex-direction: row;
}

.edit-button {
    background-color: #31B3F8;
    color: #ffff;
}

.dialog-title-manage-client {
    background-color: #31B3F8;
    color: #ffff;
    display: flex;
    grid-gap:0.5rem;
    gap:0.5rem
}
.dialog-title-text{
    color: #ffff;
    font-weight: 600;
    font-size: 20px;
}
.dialog-image {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    vertical-align: middle;
}

.dialog-content {
    padding: 20px;
}
.dialog-button{
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.dialog-button .dialog-submit-button{
    background-color: #31B3F8;
    color: #ffff;
    border-radius: 6px;
    padding: 6px 20px;
}

.dialog-button .dialog-submit-button:hover{
    background-color: #31B3F8;
    color: #ffff;
    border-radius: 6px;
    padding: 6px 20px;
}
.dialog-button .dialog-cancel-button{
    background-color: white; 
    color: black;
    border-radius: 6px;
    padding: 0px 20px;
    border: 1px solid black;
    width: 116px;
    height: 40px;
    margin-top: 10px;
}

.dialog-button .dialog-cancel-button:hover{
    background-color: white; 
    color: black;
    border-radius: 6px;
    padding: 0px 20px;
    border: 1px solid black;
    width: 116px;
    height: 40px;
    margin-top: 10px;
    opacity: 0.9;
}

.iconStyle {
    width: 47px;
    height: 47px;
    margin-right: 8px;
    vertical-align: middle;
}


.manage-client-modal .MuiDialogTitle-root {
    padding: 8px 18px !important;
}


.manage-client-modal .MuiSelect-selectMenu {
    padding: 9.5px 14px;
}

.manage-client-modal .MuiDialogActions-root {

    padding: 8px 24px 15px;

}
.multi-select {
    width: 100%;
    height: 47px;
}

.dialog-content .MuiOutlinedInput-input {
    padding: 24.5px 14px;
}

.add-division .dialog-title {
    background-color: #31B3F8;
    padding: 16px 24px !important;
}


.add-division .iconStyle {
    width: 39px;
    height: unset;
}

.add-division .dialog-title .dialog-title-text {
    font-weight: 600;
    font-size: 25px;
    color: #ffff;
}

.add-division .dialog-button {
    padding: 16px 18px !important;

}

.active-field {
    height: 49px;
}

.MuiOutlinedInput-multiline {
    padding: 0px !important;
}

.manangeclientContainerDivisionLink{
    width: 100%;
    margin-bottom: 6px;
}

.manangeclientContainerDivisionLinks{
    width: 98%;
    margin-bottom: 6px;
}

.gridContainer_heading_manageClient{
    margin-left: 2px !important;
}

.gridContainerRecruitmentLeader{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.recruitmentLeader_contains_client{
    width: 100%;
}

.closeContainer_client{
    margin-top: 9px;
}

.updateButton_OrgAdmin{
    height: 40px;
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff ; 
    padding: 5px 30px ;
    margin-top: 10px ;
    width: 116px;    
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    justify-content: center;
}
.updateButton_OrgAdmin:hover{
    opacity: 0.9;
}
.updateButton_OrgAdmin_disabled{
    height: 40px;
    display: flex ;
    background-color: #1885e6 !important;  
    color: #ffffff ; 
    padding: 5px 30px ;
    margin-top: 10px ;
    width: 116px;     
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border: none;
    font-family: Arial;
    font-size: 15px;
    border-radius: 4px;
    grid-gap: 10px;
    gap: 10px;
}

.dialog-actionbutton-client .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
}

.client-dialog-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}
.client-dialog-content{
    padding-top: 20px !important;
}
.input-field-client-dialog input[type="text"]{
    height: 45px;
}
.field-title-client-dialog{
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}
.updateButton-client{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}
.updateButton-client:hover{
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}
.updateButton-client_disabled{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}
.cancel-button-client{
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.cancel-button-client:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.dialog-actionbutton-client{
    padding: 15px 20px !important;
}
.iconStyle_client{
    width: 35px;
    height:35px;
    margin-right: 8px;
    vertical-align: middle;
}
.client-dialog-content .MuiOutlinedInput-inputMultiline{
    padding: 18.5px 14px !important;
}
.actionbutton{
    display:flex;
    grid-gap:0.5rem;
    gap:0.5rem;
}
.tableGrid {
    grid-template-columns: 80% 20%;
    grid-gap: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;
}

.division-dialog-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}
.division-dialog-content{
    padding-top: 20px !important;
}
.input-field-division-dialog input[type="text"]{
    height: 45px;
}
.field-title-division-dialog{
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}
.updateButton-division{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}
.updateButton-division:hover{
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}
.updateButton-division_disabled{
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}
.cancel-button-division{
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}
.cancel-button-division:hover{
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}
.dialog-actionbutton-division{
    padding: 15px 20px !important;
}
.cancelIcon_leadersArr{
    cursor: pointer;
    margin-top: 45px;
}
.cancelIcon_leadersArr:hover{
    opacity: 0.7;
}

.addIcon_leadersArr{
    float: right;
    cursor: pointer;
    color: #1885E6 !important;
    font-size: 35px !important;
}
.addIcon_leadersArr:hover{
    opacity: 0.7;
}
.dialog-actionbutton-division .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
}
.kendoGrid_manageDivision .k-grid{
    min-height: 70vh !important;
}
.kendoGrid_manageDivision{
    padding: 15px !important;
}
.editBtn_manageDivision{
    background-color: #31B3F8 !important;
    color: #FFFFFF !important;
}
.editBtn_manageDivision:hover{
    background-color: #31B3F8 !important;
    opacity: 0.7;
}
.btn-text {
    text-align: center;
    justify-content: center;
}

.editBtn {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
    border-radius: 4px;
    border: none;
}
 
.editBtn:hover {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
.editBtn-client {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
 
.editBtn-client:hover {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}

.editIcon {
    width: 10px;
    height: 10px;
    top: 357px;
    left: 1476px;
    padding: 0.24px 0px 0.24px 0px;
}
.tableGrid {
    grid-template-columns: 80% 20%;
    grid-gap: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;
}
.spocGrid {
    grid-template-columns: 80% 20%;
    grid-gap: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;

}

.kendoGrid_manageSpoc{
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.kendoGrid_manageSpoc .k-grid{
    min-height: 70vh !important;
}


/* .container {
    padding: 3rem;
} */
 
.card-container{
    padding: 2rem 1rem;
}
 
.header-spoc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
 
.header-image {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
 
.header-spoc .add-button {
    background-color: #31B3F8;
    color: #ffff;
}
.header-spoc .add-button .MuiButton-label{
    display: flex;
    flex-direction: row;
}
.header-spoc .add-button:hover{
    background-color: #31B3F8;
}
 
.add-image {
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: row;
}
 
.table-head {
    background-color: #31B3F8;
}
 
.card-container .edit-button {
    background-color: #31B3F8;
    color: #ffff;
}
.card-container .edit-button .MuiButton-label{
    display: flex;
    flex-direction: row;
}
.card-container .edit-button:hover{
    background-color: #31B3F8;
}
 
.dialog-title {
    background-color: #31B3F8;
}
 
.dialog-title .dialog-title-text{
    font-weight: 600;
    font-size: 30px;
    color: #ffff;
}
 
.dialog-image {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    vertical-align: middle;
}
 
.dialog-content {
    padding: 20px;
}
 
.dialog-button{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
 
.dialog-button .dialog-submit-button {
    background-color: #31B3F8;
    color: #ffff;
}
 
.dialog-button .dialog-cancel-button {
    background-color: white;
    color: black;
    border: 1px solid black;
}
.manage-spoc-main {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    height: 75vh;

}


.manage-spoc-main .manage-spoc-main-head {
    font-size: 30px;
    color: #31B3F8;
    font-weight: 600;
    font-family: "Segoe-Semibold";
    border-bottom: 1px solid #31B3F8;
}
.manage-spoc-main .add-button-managespoc {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}
.manage-spoc-main .add-button-managespoc:hover {
    background-color: #31B3F8;
    color: #ffff;
    padding: 6px 20px;
}

.active-field {
    height: 49px;
}

.MuiOutlinedInput-multiline {
    padding: 0px !important;
}




.add-spoc .dialog-title {
    background-color: #31B3F8;
    padding: 16px 24px !important;
}

.add-spoc .iconStyle {
    width: 35px;
    height: unset;
}

.add-spoc .dialog-title .dialog-title-text {
    font-weight: 600;
    font-size: 25px;
    color: #ffff;
}

.add-spoc .dialog-button {
    padding: 16px 18px !important;

}

.spoc-dialog-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}

.spoc-dialog-content {
    padding-top: 20px !important;
}

.field-title-spoc-dialog {
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}

.input-field-spoc-dialog input[type="text"] {
    height: 45px;
}

.dialog-actionbutton-spoc {
    padding: 15px 20px !important;
}

.cancel-button-spoc {
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}

.cancel-button-spoc:hover {
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}

.updateButton-spoc {
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}

.updateButton-spoc:hover {
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}

.updateButton_spoc_disabled {
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    grid-gap: 10px;
    gap: 10px;

}

.dialog-actionbutton-spoc .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
  }

  .spoc-dialog-content .MuiOutlinedInput-inputMultiline{
    padding: 18.5px 14px !important;
}


.maincontainer_mis_operator_dashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #ffffff;
}

.maindiv_mis_operator_dashboard {
    margin: 0 6%;
    margin-top: 125px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    padding-bottom: 40px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.card_divisions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.iconMargin {
    margin-right: 5px;
}

.dateStyle {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.bottom_container_mis_operator_dashboard {
    margin-top: auto;
}
.thirdCard {
    width: 300px;
}

.cardBlock {
    padding: 20px 0px;
    border-radius: 10px;
    box-shadow: 0px 3px 8px #0000003d;
}

.t_card_heading {
    display: flex;
    justify-content: left;
    align-items: center;
}

.colorBox {
    background-color: #3ca4e5;
    width: 10px;
    height: 25px;
    margin-right: 10px;
}

.thirdCardHeading {
    /* font-family: 'Segoe-Semibold';
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #075482; */
    font-family: "Segoe-bold";
    font-weight: bold;
    color: #4D4F5C;
    font-size: 22px;
}

.t_card_content {
    padding: 20px 10px 10px 20px;
    width: 60%;
    font-weight: bold;
}

.third_col_styles {
    padding: 10px 0px;
    color: #393939;
}

.tableCell {
    padding: 5px 0px;
    font-family: 'Segoe-Regular';
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
}
.requisitionType{
    cursor: pointer;
}
.requisitionType:hover{
    text-decoration: underline;
    opacity: 0.9;
    color: #075482;
}

.cellQuantity {
    font-family: 'Segoe-Regular';
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    color: #393939;
}
.firstCard {
    width: 125px;
}

.cardBlock {
    /* border: 1px solid black; */
    padding: 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 3px 8px #0000003d;
}

.f_card_heading {
    display: flex;
    justify-content: left;
    align-items: center;
}

.colorBox {
    background-color: #3ca4e5;
    width: 10px;
    height: 25px;
    margin-right: 10px;
}

.actionsCardHeading {
    font-family: "Segoe-bold";
    font-weight: bold;
    color: #4D4F5C;
    font-size: 22px;
}

.f_card_content {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
}

.f_card_content_inner_div {
    text-align: center;
    width: 130px;
    cursor: pointer;
    margin: 25px 5px;
}

.f_card_content_inner_div:hover {
    opacity: 0.6;
}
.iconLegendStyle {
    font-family: 'Segoe-Semibold';
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #075482;
    text-align: center;
    margin-top: 10px;

}
.candidate-history-icon{
    font-size: 45px !important;
    color: #3ca4e5;
}

@media screen and (min-width: 1290px) {
    .firstCard {
        width: 300px
    }
}
.ats-dash-nav-container{
    background: rgb(24,90,153);
    background: linear-gradient(360deg, rgba(24,90,153,1) 0%, rgba(32,118,201,1) 25%, rgb(40, 150, 254) 100%);
    height:81% ;
    position:fixed;
    width: 12.5%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    overflow: auto;
}

.ats-dash-nav-container .MuiListItem-button:hover{
    background-color: transparent;
}

/* Custom scrollbar */
.ats-dash-nav-container::-webkit-scrollbar {
    width: 10px;
}

.ats-dash-nav-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2); /* Light track for visibility */
    border-radius: 10px;
}

.ats-dash-nav-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.8); /* Light thumb for contrast */
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.ats-dash-nav-container::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 1); /* Brighter on hover */
}

.ats-dash-nav-container::-webkit-scrollbar-corner {
    background: transparent;
}

.ats-dash-navTitle{
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.3;
}

.ats-dash-navItem{
    color: white;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.3;
    display: flex;
    align-items: center;
    cursor: pointer;
    grid-gap: 8px;
    gap: 8px;
    padding: 7px 10px;

}

.ats-dash-navItem-selected{
    background-color: white;
    color: black;
    border-radius: 5px;
}


@media only screen and (max-width: 1550px){
    .ats-dash-nav-container{
        height: 75%;
    }
    .ats-dash-navItem{
        font-size: 12px;
        padding: 2px 10px;
    }
    .ats-dash-navTitle{
        margin-bottom: 5px;
    }
}


@media only screen and (max-width: 1380px){
    .ats-dash-nav-container{
        height: 73%;
    }
    .ats-dash-navItem{
        font-size: 10px;
        padding: 2px 10px;
        margin-bottom: 12px;
    }
    .ats-dash-navTitle{
        margin-bottom: 5px;
        font-size: 17px;
    }
}


@media only screen and (max-width: 1280px){
    .ats-dash-nav-container{
        height: 72%;
    }
    .ats-dash-navItem{
        font-size: 9px;
        padding: 2px 10px;
        margin-bottom: 8px;
    }
    .ats-dash-navTitle{
        margin-bottom: 5px;
        font-size: 15px;
    }
}
.talentCommunityMainContainer {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}

.talentCommunityMainGrid {
    padding: 0 5%;
    padding-top: 117px;
}

.talentCommunitySearchBarContainer {
    border-bottom: 1px solid #E9E9F0;
    padding-bottom: 15px;
    margin-bottom: 15px !important;
}

.searchbar {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* justify-content: space-between; */
    align-items: center;
    grid-gap: 10px;
    gap: 10px
}

.searchbarcontainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.recruiterLogoContainerMain {
    max-width: 200px;
    max-height: 45px;
    display: flex;
    justify-content: flex-end;
}

.recruiterLogoContainer {
    max-height: 100%;
    max-width: 100%;
}

.searchbarfeild {
    width: 40vw;
    border-radius: 8px;
    box-shadow: 0px 3px 8px #0000003d;
}

.searchbar .MuiOutlinedInput-notchedOutline {
    border: none;
}

.talentCommunityAdvancedSearchButton {
    padding-left: 15px;
}

.searchButton {
    color: #fff !important;
    background-color: #31B3F8 !important;
    height: 35px;
    margin-left: 10px !important;
}

.talenCommunityActivityContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* align-items: center; */
    margin-top: 10px;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}

.activityCount {
    display: flex;
    grid-gap: 25px;
    gap: 25px;
}

.talentCommunitySizecontainer {
    width: 300px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 8px #0000003d;
    align-items: center;
    height: 40px;
}

.talentCommunitySizeleftside {
    display: flex;
}

.talentCommunitySizeInfoContainer {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.talentCommunitySizeCount {
    padding-bottom: 5px;
    margin-top: -6px;
    color: #03A2F9;
    font-family: "Segoe-semibold";
    font-size: 18px;
}

.talentCommunitySizeTile {
    font-family: "Segoe-semibold";
    color: #4D4F5C;
    font-size: 13px;
}

.talentCommunityaddedcontainer {
    width: 370px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 8px #0000003d;
    align-items: center;
    margin-left: 40px;
}

.talentCommunityGraphActionmainContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 25px;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: flex-start;
}

.talentCommunityLeftSideMainContainer {
    width: 340px;
    box-shadow: 0px 4px 12px #0000001a;
}

.talentCommunityRightSideMainContainer {
    /* box-shadow: 0px 4px 12px #0000001a; */
    width: calc(100% - 360px);
    display: flex;
    /* justify-content: center; */
    margin-top: -20px;
    flex-direction: column;

}
.tableContainer1_recruiterDashboard{
    display: flex;
}

.leftsidetopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 20px;

}

.talentCommunityLeftSideActionTitle {
    font-family: "Segoe-bold";
    font-weight: bold;
    color: #4D4F5C;
    font-size: 22px;
    margin-bottom: 25px;
    width: 100%;
    border-left: 10px solid #31B3F8;
    padding-left: 20px;
    margin-top: 15px;
}

.leftsidetopContainerTitle {
    font-family: "Segoe-semibold";
    font-size: 17px;
    text-align: center;
}


.leftsidebottomContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.leftsidetopContainercontent {
    width: 150px;
    height: 125px;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.leftsidetopContainercontent_admin {
    width: 150px;
    height: 125px;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    opacity: 0.6;
}

@media screen and (max-width: 900px) {

    .talentCommunityGraphActionmainContainer {
        flex-direction: column;
    }

    .talentCommunityaddedcontainer {
        margin-left: 0px;
        width: 600px;
    }

    /* .talentCommunitySizecontainer {
        width: 600px;
    } */

    /* .talentCommunityLeftSideMainContainer {
        width: 640px;
    } */

    /* .talentCommunityRightSideMainContainer {
        width: 640px;
        margin-top: 10px;
    } */
}

@media screen and (min-width:900px) and (max-width: 1280px) {
    /* .talentCommunitySizecontainer {
        width: 310px;
    }

    .talentCommunityaddedcontainer {
        width: 310px;
    } */

    /* .talentCommunityLeftSideMainContainer {
        width: 353px;
    }

    .talentCommunityRightSideMainContainer {
        width: calc(100% - 400px);
    } */
}

@media screen and (max-width: 680px) {
    /* .talentCommunityLeftSideMainContainer {
        width: 360px;
    } */

}

.plotyGraph {
    width: 90%;
    height: 100% !important;
}

.modebar {
    display: none;
}

.js-plotly-plot .plotly .main-svg {
    height: 100% !important;
}

.js-plotly-plot .plotly .user-select-none {
    height: 500px !important;
}

.advanceSearchButton {
    color: #fff !important;
    background-color: #31B3F8 !important;
    height: 40px;
    margin-left: 10px !important;
}

.MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
}

.actioncontainer_maindiv {
    display: flex;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    flex-wrap: wrap;
    align-items: center;
}

.action_imageheight {
    height: 60px;
}

.communityStatsImg {
    height: 38px;
}

.searchIcon_action {
    width: 45px !important;
    height: 45px !important;
    color: #31B3F8;
}

.searchIconContainer {
    border: 1px solid #31B3F8;
    border-radius: 50%;
    width: 50px;
    /* padding: 6px; */
    padding-left: 7px;
    padding-top: 8px;
    padding-bottom: 2px;
    opacity: 0.8;
    margin-bottom: 5px;
}

.item_actionContainer {
    width: 150px;
    height: 120px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
    cursor: pointer;
}

.item_actionContainer:hover,
.item_actionContainer_single:hover {
    opacity: 0.8;
    color: #31B3F8;
}

.item_actionContainer_single {
    width: 150px;
    height: 120px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
    cursor: pointer;
}

.item_actionContainer_admin {
    width: 150px;
    height: 120px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;
    opacity: 0.6;
}

.actioncontainer_title {
    font-family: "Segoe-semibold";
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width: 1400px) {
    .activityCount {
        grid-gap: 10px;
        gap: 10px;
    }

    .talentCommunitySizecontainer {
        width: 280px;
    }
}

@media screen and (max-width: 1270px) {
    .activityCount {
        flex-wrap: wrap;
    }

    .talenCommunityActivityContainer {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-end;
    }
}

.upload_resume_dialog_modal .dialog_modal_title .MuiTypography-root {
    /* position: ; */
    font: 600 28px/37px Segoe UI;
    letter-spacing: 0px;
    color: #01313C;
    opacity: 1;
}

.MuiTypography-root .modal_close_icon_button {
    position: absolute;
    right: 11px;
    top: 12px;
}

.talentCommunityMainContainer .MuiDialog-container .MuiDialog-paper {
    margin: 32px;
    position: relative;
    overflow-y: unset;
    max-width: 727px !important;
    min-width: 450px;
}

.choose_file_dialog_content {
    max-height: 80vh;
    max-width: 900px;
    overflow-y: unset !important;
    font-size: 14px;
    box-sizing: border-box;
}


.choose_file_dialog_content .MuiInputBase-root {
    border: 1px solid #1A8FFF;
    border-radius: 4px;
    /* width: 189px;
    height: 48px; */
    opacity: 1;
}

.caution_text {
    font: 600 11px/15px Segoe UI;
    letter-spacing: 0px;
    color: #AFAFAF;
    text-align: center;
    /* padding-bottom: 20px; */
}

.grid-container {
    /*margin-top: 10px; */
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
    max-height: 240px;
    padding: 20px 10px;
    overflow-y: auto;
}

.grid-item {
    position: relative;
    width: 19.5%;
    text-align: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    padding: 20px 10px;
}

.file-info {
    margin-right: 8px;
}


.file-desc {
    font: 600 10px/14px Segoe UI;
    letter-spacing: 0px;
    color: #01313C;
    word-break: break-word;
}

.close-icon {
    position: absolute !important;
    top: -23px;
    right: -23px;
}

.close-icon .MuiSvgIcon-root {}

.custom-icon {
    width: 48px;
    /* Set the desired width */
    height: 48px;
    /* Set the desired height */
}

.choose_file_dialog {
    /* position: relative; */
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
    /* display: inline-block; */
    flex-direction: column;
    align-items: center;
}

.choose_file_btn {
    background-color: white;
    padding: 8px 20px;
    text-align: center;
    font: 600 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #3F3F3F;
    width: 189px;
    height: 48px;
    border: 1px solid #1A8FFF;
    border-radius: 4px;
    cursor: pointer;
}

.choose_file_dialog input[type=file] {
    position: relative;
    top: -48px;
    height: 45px;
    width: 189px;
    height: 48px;
    opacity: 0;
    cursor: pointer;
}


.action_buttons {
    justify-content: center !important;
}

.action_buttons .upload_button {
    width: 140px;
    height: 47px;
    background: #05A6F4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.action_buttons .upload_button .MuiButton-label {
    width: 58px;
    height: 24px;
    text-align: center;
    font: 600 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.action_buttons .cancel_button {
    width: 126px;
    height: 47px;
    border: 1px solid #A3BCD5;
    border-radius: 4px;
    opacity: 1
}

.action_buttons .cancel_button:hover {
    /* background-color: #01313C;
    color: #FFFFFF; */
}

.action_buttons .cancel_button .MuiButton-label {
    width: 56px;
    height: 26px;
    text-align: center;
    font: 600 19px/26px Segoe UI;
    letter-spacing: 0px;
    color: #01313C;
    opacity: 1;
}

.circularProgressContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    height: 200px;
    font-family: "Segoe-semibold";
    font-size: 16px;
}

.plsWaitText {
    padding-left: 12px;
}

.mytalentBottomContainer {
    margin-top: auto;
}

.Unauthorized_container {
    background-color: #ffffff;
}

.UnAuthorized_contain {
    min-height: 60vh;
}

.excelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e7145;
    color: #ffffff;
    grid-gap: 6px;
    gap: 6px;
    padding: 4px 8px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: 'Segoe-semibold';
    font-size: 14px;
}

.excelButton:hover {
    opacity: 0.9;
}

.excelImg_advanceSearch {
    height: 30px;
}

.searchresultsText_div {
    font-family: 'Segoe UI';
    font-size: 18px;
    font-weight: 600;
    /* margin-bottom: 10px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.circularprogress_mytalent {
    height: 400px !important;
    width: 400px !important;
    color: #05A6F4 !important;
}

.upload_resume_mod {
    text-align: center;
}

.requisitionCountTable_recruiterDashboard {
    padding: 0px 20px;
    width: 50%;
    margin-bottom: 20px;
    padding-left: 0px;
}

.candidateInterviewTable_recuiterDashboard {
    width: 50%;
}
.upcomingStartsTable_recuiterDashboard{
    width: 49.5%;
}

@media only screen and (max-width: 1540px) {
    .talentCommunityRightSideMainContainer{
        flex-direction: column;
    }
    .tableContainer1_recruiterDashboard{
        flex-direction: column;
    }
    .upcomingStartsTable_recuiterDashboard{
        width: 100%;
    }
    .requisitionCountTable_recruiterDashboard {
        width: 100%;
    }

    .candidateInterviewTable_recuiterDashboard {
        width: 100%;
    }
}
@media only screen and (max-width: 900px) {
    .talentCommunityRightSideMainContainer{
        width: 100%;
    }
}

.title-bar {
    width: 1376px;
    height: 50px !important;
    top: 297px;
    left: 121px;
    border-radius: 8px, 8px, 0px, 0px;
    background-color: #31B3F8 !important;
    font-family: Roboto !important;
    font-size: 24px !important;
    font-weight: 600;
    line-height: 28px !important;
    letter-spacing: 0em;
    text-align: left;
    color: white !important;
}

.action-btn {
    width: 81px;
    height: 29px;
    top: 400px;
    left: 158px;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #31B3F8 !important;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}

.tableRow {
    top: 297px;
    /* left: 121px; */
    font-family: Roboto !important;
    font-size: 24px !important;
    font-weight: 600;
    line-height: 28px !important;
    letter-spacing: 0em;
    text-align: left;
    border: none;
}

.rec_interview_today {
    background: linear-gradient(90deg, rgba(242, 235, 189, 1) 0%, rgba(254, 191, 189, 1) 100%);
    cursor: pointer;
}

.rec_interview_today:hover {
    opacity: 0.8;
}

.rec_upcomingDate{
    cursor: pointer;
}
.rec_upcomingDate:hover{
    opacity: 0.8;
    background-color: #f1f1f1;
}


.rec_interview_coming {
    background-color: #fffd8d;
}

.candidateInterviewTable .MuiTableContainer-root {
    margin: 0px !important;
}

.candidateInterviewTable .MuiTableContainer-root::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.candidateInterviewTable .MuiTableContainer-root::-webkit-scrollbar-thumb {
    background-color: #1F729F;
    /* Set the color of the scrollbar thumb */
}

.candidateInterviewTable .MuiTableContainer-root::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the color of the scrollbar track */
}



.candidateInterviewTable .MuiTableContainer-root th {
    font-family: 'Segoe-semibold' !important;
}
.btn-container {
    margin-top: 20px;
  }
  
  .view-btn {
    width: 199px;
    height: 39px;
    top: 667px;
    left: 584px;
    border-radius: 8px;
    background-color: #31B3F8;
    color: white;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border-style: none;
  }
  
  .middle-card-gap {
    padding: 0px 20px;
    width: 38%;
    margin-bottom: 20px;
  }
  
  
  .table-header-recruitment-leader {
    display: flex;
    background: rgb(49,179,248);
  background: linear-gradient(90deg, rgba(49,179,248,1) 0%, rgba(29,105,146,1) 100%);
    color: white;
    align-items: center;
    padding: 15px 15px;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .recruitment-leader-left {
    display: flex;
    align-items: center;
  }
  
  .datePickerContainer_recruitment-leader-left {
    display: flex;
    align-items: center;
  
  }
  
  @media only screen and (max-width: 480px) {
    .datePickerContainer_recruitment-leader-left {
      flex-direction: column;
      align-items: flex-start;
    }
  
  }
  
  .recruitment-leader-left p,
  .datePickerContainer_recruitment-leader-left p {
    font-family: "Segoe-semibold";
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    padding-left: 10px;
  }
  
  .recruitment-leader-left .date-inner-text,
  .datePickerContainer_recruitment-leader-left .date-inner-text {
    font-family: "Segoe-semibold";
    font-weight: bold;
    color: #fff;
    font-size: 14px;
    padding: 0px 8px;
  }
  
  
  .datePickerContainer_recruitment-leader-left input[type="text"],
  select {
  
    background: white;
    border: none;
    height: 30px;
    /* padding: 0px 10px; */
    padding-left: 10px !important;
    padding-right: 10px;
    width: 120px;
  }
  
  .table-header-recruitment-leader .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0;
    opacity: 0.7;
  }
  
  .requision-table .MuiTableCell-root {
    padding: 8px 18px;
    font-family: "Segoe-semibold";
  }
  
  .box-shadow {
    box-shadow: 0px 3px 8px #0000003d;
  }
  
  .border-none {
    border-bottom: 1px solid transparent !important;
    /* width: 200px; */
  }
  
  .left {
    width: 25%;
  }
  
  .left img {
    height: 56px;
  }
  
  .left .talentCommunityLeftSideMainContainer {
    width: unset;
    border-radius: 8px;
    box-shadow: 0px 3px 8px #0000003d;
  }
  
  .right {
    width: 38%
  }
  
  .table-header-recruitment-leader img {
    width: 32px;
  }
  
  .view-btn-requisitions {
    width: 199px;
    height: 39px;
    top: 667px;
    left: 584px;
    border-radius: 8px;
    background-color: #31B3F8;
    color: white;
    font-family: "Segoe-semibold";
    font-weight: bold;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border-style: none;
  }
  
  .recruitment-leader-top .talentCommunitySizeTile {
    font-family: "Segoe-semibold";
    color: #4D4F5C;
    font-size: 13px;
  }
  
  
  .recruitment-leader-top .talentCommunitySizecontainer {
    width: 300px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 8px #0000003d;
    align-items: center;
    height: 40px;
    border-radius: 8px;
  }
  
  .recruitment-leader-top .talentCommunitySizeleftside {
    display: flex;
    align-items: center;
  }
  
  /* .recruitment-leader-top .talentCommunitySizeCount {
      padding-bottom: 5px;
      margin-top: -6px;
      color: #03A2F9;
      font-family: "Segoe-semibold";
      font-size: 24px;
    } */
  .requision-row-blue {
    width: 140px !important;
    border-bottom: 1px solid transparent !important;
    font-size: 20px !important;
    background-color: rgba(0, 158, 253, 0.17);
    font-size: 18px !important;
    font-family: "Segoe-semibold" !important;
  }
  
  .requision-number-blue {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 18px !important;
    background-color: rgba(0, 158, 253, 0.17);
    font-family: "Segoe-semibold" !important;
  }
  
  
  .requision-row-white {
    width: 140px !important;
    border-bottom: 1px solid transparent !important;
    font-size: 20px !important;
    background-color: #fff;
    font-size: 18px !important;
    font-family: "Segoe-semibold" !important;
  }
  
  .requision-number-white {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 18px !important;
    background-color: #fff;
    font-family: "Segoe-semibold" !important;
  }
  
  .requision-row {
    width: 140px !important;
    border-bottom: 1px solid transparent !important;
    font-size: 20px !important;
    background-color: rgba(255, 4, 4, 0.26);
    font-size: 18px !important;
    font-family: "Segoe-semibold" !important;
  }
  
  .requision-number {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 18px !important;
    background-color: rgba(255, 4, 4, 0.26);
    font-family: "Segoe-semibold" !important;
  }
  
  
  .requision-number-inner-1-blue {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: rgba(0, 158, 253, 0.17);
    padding: 0px !important;
  }
  
  .requision-number-inner-2-blue {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: rgba(0, 158, 253, 0.17);
    padding: 0px !important;
  }
  
  .requision-number-inner-2-blue_covered {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: aliceblue;
    padding: 0px !important;
  }
  
  .requision-number-inner-1-white {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: #fff;
    padding: 0px !important;
  }
  
  .requision-number-inner-2-white {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: #fff;
    padding: 0px !important;
  }
  
  .requision-number-inner-1 {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: rgba(255, 4, 4, 0.16);
    padding: 0px !important;
  }
  
  .requision-number-inner-2 {
    border-bottom: 1px solid transparent !important;
    color: #393939 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    background-color: rgba(255, 4, 4, 0.16);
    padding: 0px !important;
  }
  
  
  /* @media screen and (max-width: 1270px){
  .talenCommunityActivityContainer {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
  }
  .middle-card-gap {
    padding: 0px 20px;
    width: 32%;
  }
  .right {
  width: 33%;
  }
    } */
  
  /* @media screen and (max-width: 1370px){
      .middle-card-gap {
        padding: 0px 20px;
        width: 32%;
      }
      .right {
      width: 33%;
      }
    } */
  @media only screen and (min-width: 1260px) and (max-width: 1799px) {
  
    .middle-card-gap {
      padding: 0px 20px;
      width: 36%;
    }
  
    .right {
      width: 36%;
    }
  }
  
  @media only screen and (min-width: 1260px) and (max-width: 1400px) {
  
    .table-header-recruitment-leader input[type="text"],
    select {
  
      font-size: 11px;
    }
  
    .middle-card-gap {
      padding: 0px 20px;
      width: 33%;
    }
  
    .right {
      width: 33%;
    }
  }
  
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  
    .middle-card-gap {
      padding: 0px 20px;
      width: 33%;
    }
  
    .right {
      width: 33%;
    }
  }
  
  @media only screen and (min-width: 1501px) and (max-width: 1600px) {
  
    .table-header-recruitment-leader input[type="text"],
    select {
  
      font-size: 16px;
    }
  
    .middle-card-gap {
      padding: 0px 20px;
      width: 35%;
    }
  
    .right {
      width: 35%;
    }
  }
  
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  
  /* .uncoveredTableData .MuiTableCell-stickyHeader {
    background-color: rgba(255, 4, 4, 0.13) !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 4px 10px -4px !important;
  }
  
  .coveredTableData .MuiTableCell-stickyHeader {
    background-color: rgba(0, 158, 253, 0.10) !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 4px 10px -4px !important;
  }
  
  .openTableData .MuiTableCell-stickyHeader {
    background-color: #fff !important;
    border: 1px solid rgba(128, 128, 128, 0.4) !important;
  } */
  
  .circularProgress_requisitionCount {
    width: 19px !important;
    height: 19px !important;
  }
  
  .collapseMainContainer {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }
  
  .collapseMainContainer_header {
    display: flex;
  }
  
  .collapse_headerItems {
    padding: 10px;
    font-family: 'Segoe-semibold';
    width: 100%;
    border: 1px solid #000;
    align-items: flex-end;
  }
  
  .requsitionStatusDataContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .requsitionStatusInfoContainer_header {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  }
  .requsitionStatusInfoContainer_header_dialog {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  }
  
  .collapseDataContainer {
    display: flex;
  }
  
  .collapse_dataItems {
    padding: 10px;
    font-family: 'Segoe-semibold';
    font-size: 14px;
    width: 100%;
  }
  
  .requsitionStatusInfo_title {
    width: 120px;
  }
  
  .requsitionStatusInfoContainer_header_covered {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    background-color: #d4efff;
    border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  }
  .requsitionStatusInfoContainer_header_covered_dialog {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    background-color: #d4efff;
    border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  }
  
  .requsitionStatusInfoContainer_header_uncovered {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    background: rgb(242,235,189);
    background: linear-gradient(90deg, rgba(242,235,189,1) 0%, rgba(254,191,189,1) 100%);
      border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  
  }
  .requsitionStatusInfoContainer_header_uncovered_dialog {
    font-family: 'Segoe-semibold';
    font-size: 16px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    height: 30px;
    cursor: pointer;
    background: rgb(242,235,189);
    background: linear-gradient(90deg, rgba(242,235,189,1) 0%, rgba(254,191,189,1) 100%);
      border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  
  }
  
  .coveredTableData .MuiTableCell-stickyHeader {
    background-color: #d4efff !important;
  }
  
  .uncoveredTableData .MuiTableCell-stickyHeader {
    background: rgb(242,235,189) !important;
  }
  
  
  
  /* For 150% Screen Resolution */
  @media only screen and (max-width: 1380px){
  
    .recruitment-leader-left p{
        font-size: 16px;
    }
  }
  
  .requsitionDialog .MuiTableContainer-root {
    margin: 0px 0px !important;
  }
  
  .requsitionDialog .datePickerContainer_recruitment-leader-left input[type="text"], select{
    width: 200px;
  }
  
  .ButtonSave_requisitionCount{
    background-color: #31B3F8 !important;
    color: #ffffff !important;
    width: 85px;
  }
  
  .ButtonCancel_requisitionCount{
    background-color: #ffffff !important;
    color: #009EFD !important;
    width: 85px;
  }
  


  .gridCollapseContainer  .MuiGrid-container {
    overflow-y: auto;
  }

  .gridCollapseContainer  .MuiGrid-container::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
    z-index: 999;
}

.gridCollapseContainer  .MuiGrid-container::-webkit-scrollbar-thumb {
    background-color: #1F729F;
    /* Set the color of the scrollbar thumb */
    z-index: 999;
}

.gridCollapseContainer  .MuiGrid-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the color of the scrollbar track */
    z-index: 999;
}

.name_date_column {
    margin-bottom: 12px;
}

.report_details_container {
    width: 70%;
    height: 100%;
}

.report_details_tab {
    width: 19%
}

/* details summary::before {
    content: "▶"; 
    color:  rgb(0, 158, 253);
    padding-right: 8px;
    font-size: 1rem;
}

details[open] summary::before {
    content: "▼"; 
} */

/* .requisiton_report_details[open]>summary,
details[open]>p {
    border-bottom: 2px solid #009efd;
} */

details[open] .show_all_date_name {
    display: none;
}

details summary::after,.requisition_report_details_close {
    content: "...show more ";
    font-style: italic;
    font-weight: 600;
    text-align: end;
    width: 100%;
    float: right;
    font-size: 0.8rem;
    color: rgb(0, 158, 253);
    margin-top: -2%;
    /* background-color: rgb(0, 158, 253);
    color:white; */
    cursor: pointer;
}

details[open] summary::after {
    content: "";
}

.requisiton_report_details>summary {
    list-style: none;
}

.req_details_evenRow{

    background: #F6F6F6 !important
}
.req_details_oddRow{
    background: #ffffff !important
}
.add-team .dialog-title {
    background-color: #31B3F8;
    padding: 16px 24px !important;
}

.add-team .iconStyle {
    width: 35px;
    height: unset;
}

.add-team .dialog-title .dialog-title-text {
    font-weight: 600;
    font-size: 25px;
    color: #ffff;
}

.add-team .dialog-button {
    padding: 16px 18px !important;

}

.team-dialog-TitleContainer {
    display: flex;
    align-items: center;
    height: 4.2vh;
    padding: 10px;
    background-image: linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);
    color: white;
    border-radius: 4px 4px 0px 0px;
    font-family: 'poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}

.team-dialog-content {
    padding-top: 20px !important;
}

.field-title-team-dialog {
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}

.input-field-team-dialog input[type="text"] {
    height: 45px;
}

.dialog-actionbutton-team {
    padding: 15px 20px !important;
}

.cancel-button-team {
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
}

.cancel-button-team:hover {
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}

.close_button_team{
    font-size: 15px;
    border-radius: 3px;
    width: 120px;
    font-family: "Segoe-Semibold";
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    height: 35px;
    cursor: pointer;
    margin-right: 14px;
}

.close_button_team:hover {
    background-color: #fff;
    color: #01313c;
    opacity: 0.7;
}

.updateButton-team {
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: pointer;
}

.updateButton-team:hover {
    background-color: #1885E6;
    color: #FFFFFF;
    opacity: 0.8;
}

.updateButton_team_disabled {
    color: #FFFFFF;
    width: 120px;
    border: 1px solid #1885E6;
    height: 35px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #1885E6;
    font-family: 'Segoe-regular';
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    grid-gap: 10px;
    gap: 10px;

}

.dialog-actionbutton-team .MuiCircularProgress-root{
    color: #FFFFFF !important;
    height: 13px !important;
    width: 13px !important;
    margin-top: 2px !important;
}

.team_dialog .MuiDialogContent-root{
    margin-top: 31px !important;
}

.tableGrid_team{
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tableGrid_team .k-grid{
    min-height: 70vh !important;
}
.field-title-recruiter-dialog {
    font-size: 26px;
    color: #000000;
    margin-bottom: 10px !important;
    font-family: 'Segoe-semibold' !important;
}
.dialog-actionbutton-Recruiter{
    padding: 10px 10px !important;
    /* margin-top: 10vh; */
}


.field-title-recruiter-reassign {
    font-size:16px;
    font-family: 'Segoe-regular';
    text-align: center;
}


/* LoaderDialog.css */

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is on top of all other content */
  }
  
  .dialog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .loader {
    position: relative;
  }
  
  .spinner {
    border: 4px solid transparent;
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

 
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*----------- Scroll -----------*/

/* ::-webkit-scrollbar {
	width: 8px;
  } */

/* Track */
/* ::-webkit-scrollbar-track {
   
	border-radius: 30px;
  }
    */
/* Handle */
/* ::-webkit-scrollbar-thumb {
	background: #B0D0EE; 
	border-radius: 0px;
  }
   */
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
	background: #2896FF; 
  }
   */
/*----------- Scroll -----------*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Segoe-Light";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Segoe-Regular"),
    url(/static/media/SegoePro-Light.bcf121f0.ttf) format("woff");
}

@font-face {
  font-family: "Segoe-semi-Light";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Segoe-Regular"),
    url(/static/media/SegoePro-Semilight.2e7597df.ttf) format("woff");
}

@font-face {
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("fonts/Segoe-Regular"),
    url(/static/media/SegoePro-Regular.80b70390.ttf) format("woff");
}

@font-face {
  font-family: "Segoe-Semibold";
  font-style: normal;
  font-weight: 600;
  src: local("fonts/SegoePro-Bold"),
    url(/static/media/SegoePro-Semibold.0984fa39.ttf) format("woff");
}

@font-face {
  font-family: "Segoe-Bold";
  font-style: normal;
  font-weight: 600;
  src: local("fonts/SegoePro-Bold"),
    url(/static/media/SegoePro-Bold.f0b557b5.ttf) format("woff");
}

@font-face {
  font-family: "Segoe-Bold-Italic";
  font-style: normal;
  font-weight: 600;
  src: local("fonts/SegoePro-Bold-Italic"),
    url(/static/media/SegoePro-Bold-Italic.1dc286ee.ttf) format("woff");
}

@font-face {
  font-family: "Noway-Regular";
  src: local("fonts/Noway-Regular"),
    url(/static/media/Noway-Regular.b139fd30.otf) format("woff");
}

@font-face {
  font-family: "Noway_Regular_Italic";
  src: local("fonts/Noway_Regular_Italic"),
    url(/static/media/Noway_Regular_Italic.4e124782.otf) format("woff");
}


/*    #################################    COMMON CSS    ##################################      */

body {
  margin: 0px;
  padding: 0px;
  /* background-color: #eeeeee; */
}

p {
  margin: 0px;
}

/*------------- BUTTON CSS COMPONENT ---------------*/
.btn-big {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 9px 35px 11px 35px;
  font-size: 22px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn-small {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 5px 20px 7px 20px;
  font-size: 18px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn_remove {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 7px 30px 9px 30px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn2 {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 7px 30px 9px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
  border: none;
}

.btn3 {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 7px 30px 9px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
  width: 95px;
  border: none;
}

.btn4 {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 7px 30px 9px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
  width: 85px;
  border: none;
}

.btn5 {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  /* border: 1px solid transparent; */
  border: none;
  padding: 9px 35px 11px;
  font-size: 20px;
  border-radius: 4px;
  width: 135px;
  font-family: "Segoe-Semibold";
}

.btn_remove-green {
  color: #fff;
  padding: 7px 30px 9px 30px;
  background-color: #2ad156;
}

.btn_remove-green:hover {
  color: #fff;
  background-color: #2ad156;
}

.btn_remove-green:focus {
  outline: none;
}

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 7px 24px 9px 23px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Semibold";
}

.btn-green {
  color: #fff;
  background-color: #2ad156;
}

.btn-green:hover {
  color: #fff;
  background-color: #2ad156;
}

.btn_primary {
  background-color: #31B3F8 !important;
  color: #ffffff !important;
}

.btn_primary:hover {
  background-color: #31B3F8 !important;
  opacity: 0.8;
  color: #ffffff !important;
}

.btn-green:focus {
  outline: none;
}

.btn-resume {
  color: #01313c;
  width: 100%;
  background-color: #d1e8ff;
}

.btn-resume:hover {
  color: #01313c;
  background-color: #d1e8ff;
}

.btn-resume:focus {
  outline: none;
}

.btn-resume-testimonial {
  color: #01313c;
  width: 100%;
  background-color: #d1e8ff;
  padding: 7px 9px 9px 9px;
}

.btn-resume-testimonial:hover {
  color: #01313c;
  background-color: #d1e8ff;
}

.btn-resume-testimonial:focus {
  outline: none;
}

.change_profile_btn {
  color: #01313c;
  background-color: #d1e8ff;
  padding: 7px 24px 9px 23px;
}

.change_profile_btn:hover {
  color: #01313c;
  background-color: #d1e8ff;
}

.change_profile_btn:focus {
  outline: none;
}

.btn-skill {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
}

.btn-skill:hover {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
}

.btn-skill:focus {
  outline: none;
}

.btn-addanothertestimonial {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
  padding: 7px 10px 9px 9px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Segoe-Semibold";
  width: 100%;
}

.btn-addanothertestimonial:hover {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
}

.btn-addanothertestimonial:focus {
  outline: none;
}

.btn-date {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
  padding: 7px 8px 9px 8px;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  font-family: "Segoe-Semibold";
  white-space: break-spaces;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn-date:hover {
  color: #01313c;
  background-color: #fff;
  border: 1px solid #a3bcd5;
}

.btn-date:focus {
  outline: none;
}

.btn-record {
  color: #01313c;
  background-color: #d1e8ff;
  border: 1px solid #a3bcd5;
  font-weight: 600;
  padding: 7px 24px 9px 23px;
  font-size: 16px;
  font-family: "Segoe-Semibold";
}

.btn-record:hover {
  color: #01313c;
  background-color: #d1e8ff;
  border: 1px solid #a3bcd5;
}

.btn-record:focus {
  outline: none;
}

.preference-btn {
  color: #01313c;
  background-color: #d1e8ff;
  width: 100%;
  font-weight: 600;
  padding: 7px 9px 9px 9px;
  font-size: 12px;
  text-align: center;
  font-family: "Segoe-Semibold";
}

.preference-btn:focus {
  outline: none;
}

.add_another_btn_adjust {
  text-align: right;
  margin-top: 20px;
}

/*------------- BUTTON CSS COMPONENT---------------*/

/*-------------------------------------------------------- Hints------------------------------------------------------------
mr= margin-right, ml= margin-left, mt = margin-top, mb= margin-bottom, pt= padding-top, pb= padding-bottom, pl= padding-left
pr=padding-right, fs= font-size, mw=min-width, fsi=font-style, pr=position-relative
-------------------------------------------------------- Hints------------------------------------------------------------*/

.row {
  width: 100%;
}

.fl {
  float: left;
}

.card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.2s ease-in-out;
  padding: 14px 20px 20px 20px;
  border-radius: 4px;
  background-color: #ffffff;
}

.closeicon_set {
  width: 24px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.text-uppercase {
  text-transform: uppercase;
}

.f-color {
  color: #01313c;
}

.fs-semi-bold {
  font-weight: 600;
  font-family: "Segoe-Semibold";
}

.fs-regular {
  font-weight: normal;
  font-family: "Segoe-Regular";
}

.fs-light {
  font-weight: normal;
  font-family: "Segoe-Light";
}

.fs-semi-light {
  font-weight: normal;
  font-family: "Segoe-semi-Light";
}

.fs-Bold-Italic {
  font-weight: normal;
  font-family: "Segoe-Bold-Italic";
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-28 {
  font-size: 28px;
}

.fs-18 {
  font-size: 18px;
}

.pt_13 {
  padding-top: 13px;
}

.fs-16 {
  font-size: 16px;
}

.fs-24 {
  font-size: 24px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-25 {
  font-size: 25px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-35 {
  font-size: 35px;
}

.fs-44 {
  font-size: 44px;
}

.fsi {
  font-style: italic;
}

.w100 {
  width: 100%;
}

.fs-12 {
  font-size: 12px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_14 {
  margin-right: 14px;
}

.mr_31 {
  margin-right: 31px;
}

.mr_26 {
  margin-right: 26px;
}

.mr_18 {
  margin-right: 18px;
}

.mr_23 {
  margin-right: 23px;
}

.mr_20 {
  margin-right: 20px;
}

.pr {
  position: relative;
}

.pr_31 {
  padding-right: 31px;
}

.mt_19 {
  margin-top: 19px;
}

.mt_24 {
  margin-top: 24px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_0 {
  margin-top: 0px;
}

.mt_100 {

  margin-top: 100px;
}

.mt_110 {
  margin-top: 110px;
}

.mt_34 {
  margin-top: 34px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_23 {
  margin-top: 23px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_22 {
  margin-top: 22px;
}

.m_0 {
  margin: 0px;
}

.text-center {
  text-align: center;
}

.mt_9 {
  margin-top: 9px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_12 {
  margin-top: 12px;
}

.mb_100 {
  margin-bottom: 100px;
}

.mb_12 {
  margin-bottom: 12px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_14 {
  margin-bottom: 14px;
}

.mb_16 {
  margin-bottom: 16px;
}

.mb_30 {
  margin-bottom: 30px;
}

.green_text {
  color: #2ad156;
}

.mt_15 {
  margin-top: 15px;
}

.mt_17 {
  margin-top: 17px;
}

.mt_18 {
  margin-top: 18px;
}

.ml_6 {
  margin-left: 5px;
}

.mt_41 {
  margin-top: 41px;
}

.mt_6 {
  margin-top: 6px;
}

hr.new1 {
  border-top: 1px solid #a3bcd5;
}

.df {
  display: flex;
}

.ml_25 {
  margin-left: 25px;
}

.ml_20 {
  margin-left: 20px;
}

.mt_16 {
  margin-top: 16px;
}

.mt_29 {
  margin-top: 29px;
}

.mt_60 {
  margin-top: 60px;
}

.ml_57 {
  margin-left: 57px;
}

.ml_41 {
  margin-left: 41px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_21 {
  margin-left: 21px;
}

.pr_23 {
  padding-right: 23px;
}

.pr_18 {
  padding-right: 18px;
}

.mt_70 {
  margin-top: 70px;
}

.pr_26 {
  padding-right: 26px;
}

.fs-26 {
  font-size: 26px;
}

.powerresumetop_adjust {
  margin-top: 80px;
}

.ml_12 {
  margin-left: 12px;
}

.ml_22 {
  margin-left: 22px;
}

.ml_36 {
  margin-left: 36px;
}

.pb_22 {
  padding-bottom: 22px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pb_40 {
  padding-bottom: 40px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_17 {
  margin-left: 17px;
}

.ml_15 {
  margin-left: 15px;
}

.mw {
  width: 50%;
}

.mt_40 {
  margin-top: 40px;
}

.mw_36 {
  width: 36%;
}

.mw_50 {
  width: 50%;
}

.primary_background {
  background-color: #f5f9fc;
}

.profile_icon {
  vertical-align: middle;
}

.pt_6 {
  padding-top: 6px;
}

.pt_23 {
  padding-top: 23px;
}

.pt_3 {
  padding-top: 3px;
}

.pt_4 {
  padding-top: 4px;
}

.pt_2 {
  padding-top: 2px;
}

.pt_1 {
  padding-top: 1px;
}

.pl_8 {
  padding-left: 8px;
}

.pl_10 {
  padding-left: 10px;
}

.pl_3 {
  padding-left: 3px;
}

.pt-24 {
  padding-top: 24px;
}

.mb_3 {
  margin-bottom: 3px;
}

.mb_32 {
  margin-bottom: 32px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_0 {
  margin-bottom: 0px;
}

.w1004 {
  max-width: 1004px;
}

.w95 {
  max-width: 95%;
}

.nowrap-white {
  white-space: nowrap;
}

.main-left {
  max-width: 25%;
  width: 25%;
}

.main-right {
  width: 73%;
  margin-left: 2%;
}

.full_body {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 0px;
}

.pull-right {
  float: right;
}

.popup {
  background-color: #fff;
  border-radius: 4px;
  padding: 0px !important;
}

.text-right {
  text-align: right;
}

.pt_4 {
  padding-top: 4px;
}

.pl_6 {
  padding-left: 6px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_32 {
  margin-top: 32px;
}

.mt_47 {
  margin-top: 47px;
}

hr.new2 {
  border-top: 2px solid #1a8fff;
}

hr.new3 {
  border-top: 1px solid #707070;
}

.cp {
  cursor: pointer;
}

.text-upper {
  text-transform: uppercase;
}

.custom-file-container__custom-file {
  display: flex !important;
  flex-wrap: wrap;
  margin-top: 32px !important;
}

.custom-file-container__image-preview {
  background-color: transparent !important;
  background-image: none !important;
  height: auto !important;
}

.custom-file-container__custom-file__custom-file-control {
  width: 20%;
}

.custom-file-container__image-multi-preview {
  width: 21% !important;
  height: 190px !important;
}

.custom-file-container__custom-file__custom-file-input {
  position: relative !important;
}

.custom-file-container__image-clear {
  border: 1px solid;
  width: 25px;
  height: 25px;
  margin-top: 5px;
  text-align: center;
  border-radius: 50%;
}

.signOut-btn {
  background-color: #2ad156;
  padding: 12px 22px;
  color: #fff;
  font-size: 18px;
  font-family: "Segoe UI";
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  top: 2%;
  right: 10%;
  position: absolute;
}

.pl-0 {
  padding-left: 0px !important;
}

.br_word {
  word-break: break-word;
}

/* .flex-wrap-container {
			display: flex;
			flex-wrap: wrap;
		} */
.mw_60 {
  width: 60%;
}

.divider_adjust {
  padding: 0px 42px;
}

.MuiDialogActions-spacing> :not(:first-child) {
  margin-left: 10px !important;
}

.pt_0 {
  padding-top: 0px !important;
}

.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 16px 42px;
}

.float_none {
  float: unset !important;
}

.text_area_cleartalent {
  width: 94%;
  /* height: 45px; */
  /* padding: 0px 0px; */
  /* font-size: 14px; */
  /* line-height: 1.42857143; */
  padding: 0px 20px;
  font-size: 16px;
  font-family: "Segoe-Regular";
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  background-image: none;
  border: 1px solid#c6c0c0;
  border-radius: 4px;
}

.__react_component_tooltip {
  display: inline-block;
  font-size: 13px;
  left: -999em;
  opacity: 0;
  padding: 6px 13px !important;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  top: -999em;
  visibility: hidden;
  z-index: 999;
  border-radius: 6px !important;
  left: 45px !important;
}

.crossFormodal {
  float: right;
  position: absolute;
  right: 41px;
  top: 30px;
}

.record-intro-title .crossFormodal {
  top: 13px;
}

.email_hidden {
  display: inline-block;
  width: 90%;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

/*    #################################    COMMON CSS    ##################################      */
/* responsive    */

@media only screen and (max-width: 768px) {
  .email_hidden {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .add_another_btn_adjust {
    text-align: left;
    margin-top: 20px;
  }

  .closeicon_set {
    width: 15px;
  }

  legend {
    font-size: 18px !important;
    margin-bottom: 17px;
    font-family: "Segoe-Semibold";
    font-style: normal;
    font-weight: 600;
    color: #01313c;
  }

  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 18px !important;
  }

  .text_area_cleartalent {
    width: 79%;
    /* height: 45px; */
    /* padding: 0px 0px; */
    /* font-size: 14px; */
    /* line-height: 1.42857143; */
    padding: 0px 30px;
    font-size: 16px;
    font-family: "Segoe-Regular";
    font-style: normal;
    font-weight: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid#c6c0c0;
    border-radius: 4px;
  }

  .crossFormodal {
    float: right;
    position: absolute;
    right: 41px;
    top: 30px;
  }

  .record-intro-title .crossFormodal {
    top: 13px;
  }

  .btn-big {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;

    padding: 9px 9px 11px 9px !important;
    font-size: 22px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
  }

  /* .MuiDialogContent-root {
		flex: 1 1 auto;
		padding: 8px 24px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding: 12px 18px 0px 18px!important;
		margin-top: 70px;
} */
  /* .MuiDialogActions-root {
		flex: 0 0 auto;
		display: flex;
		padding: 8px;
		align-items: center;
		justify-content: flex-end;
		padding: 20px 18px 20px 18px!important;
} */

  .full_body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
  }

  .main-left {
    min-width: 100%;
  }

  .main-right {
    width: 100%;
    margin-left: 0%;
  }

  .mw_50 {
    width: 100%;
  }

  .ml_12 {
    margin-left: 0px;
  }

  .df {
    display: flex;
    flex-wrap: wrap;
  }

  .myskill-right {
    text-align: left !important;
    margin-top: 10px;
  }

  .btn-addanothertestimonial {
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
    padding: 7px 10px 9px 9px;
    font-weight: 600;
    font-size: 16px;
    font-family: "Segoe-Semibold";
    width: 100%;
  }

  .btn-addanothertestimonial:hover {
    color: #01313c;
    background-color: #fff;
    border: 1px solid #a3bcd5;
  }

  .btn-addanothertestimonial:focus {
    outline: none;
  }

  .btn-resume-testimonial {
    color: #01313c;
    width: 100%;
    background-color: #d1e8ff;
    padding: 13px 10px 16px 9px !important;
  }

  .btn-resume-testimonial:hover {
    color: #01313c;
    background-color: #d1e8ff;
  }

  .btn-resume-testimonial:focus {
    outline: none;
  }

  .divider_adjust {
    padding: 0px 24px;
  }

  .btn2 {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 7px 30px 9px;
    font-size: 16px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    margin-bottom: 17px;
    border: none;
  }

  .btn3 {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 7px 30px 9px;
    font-size: 16px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    margin-bottom: 17px;
    width: 89px;
    border: none;
  }

  .btn_remove {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding: 7px 30px 9px 30px;
    font-size: 16px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
    margin-bottom: 17px;
  }
}

.signoutbtn {
  position: absolute;
  right: 26%;
  top: 14px;
}

.signoutbtn {
  position: absolute;
  right: 7% !important;
  top: 11px;
}

@media screen and (max-width: 1366px) {
  .closeicon_set {
    width: 15px;
  }

  .btn-big {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* border: 1px solid transparent; */
    padding: 9px 35px 11px 35px;
    font-size: 18px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
  }

  legend {
    font-size: 18px !important;
    margin-bottom: 17px;
    font-family: "Segoe-Semibold";
    font-style: normal;
    font-weight: 600;
    color: #01313c;
  }
}

/* @media screen and (max-width: 1920px) { */

@media (min-width: 1367px) and (max-width: 1920px) {
  .closeicon_set {
    width: 24px;
  }

  .btn-big {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* border: 1px solid transparent; */
    padding: 9px 35px 11px 35px;
    font-size: 22px;
    border-radius: 4px;
    font-family: "Segoe-Semibold";
  }

  legend {
    font-size: 22px;
    margin-bottom: 17px;
    font-family: "Segoe-Semibold";
    font-style: normal;
    font-weight: 600;
    color: #01313c;
  }
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-to-footer {
  flex: 1 1;
  width: 100%;
}

@media (max-width: 767px) {
  .flex-to-footer {
    width: auto;
  }

  .section-wrapper {
    display: block;
    flex-direction: column;
    min-height: 100vh;
  }
}

.homepage-carousel .thumbs-wrapper {
  display: none;
}

.banner-container {
  position: relative;
}

.carousel-text-wrapper {
  position: absolute;
  top: 0rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.homepage-carousel {
  height: 100vh;
  overflow: hidden;
}

.banner-text {
  font-size: 2rem;
  /* padding: 0em 2em; */
  padding-right: 2em;
  font-family: "Noway-Regular";
  color: #fff;
  font-weight: bold;
  width: 40%;
  text-align: left;
}

.homepage-carousel .carousel-status {
  visibility: hidden;
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.homepage-carousel .carousel-slider {
  height: 100vh;
}

.homepage-carousel .carousel .control-dots .dot.selected {
  background: #2ad156;
}

.homepage-carousel .carousel .control-dots .dot {
  width: 12px;
  height: 12px;
  box-shadow: none;
  border: 1px solid #2ad156;
}

.homepage-carousel .carousel .control-next.control-arrow:before {
  transform: rotate(135deg);
  border-right: 8px solid transparent;
  margin-right: 20px;
}

.homepage-carousel .carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.homepage-carousel .carousel .control-prev.control-arrow:before {
  transform: rotate(-45deg);
  border-right: 8px solid transparent;
  margin-left: 20px;
}

.homepage-carousel .carousel .control-arrow:before,
.homepage-carousel .carousel.carousel-slider .control-arrow:before {
  width: 25px;
  height: 25px;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
}

.homepage-carousel .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

@media (min-width: 767px) and (max-width: 1366px) {

  .homepage-carousel .carousel .control-arrow:before,
  .homepage-carousel .carousel.carousel-slider .control-arrow:before {
    width: 20px;
    height: 20px;
    border-top: 4px solid #fff;
    border-left: 4px solid #fff;
  }
}

@media (max-width: 767px) {
  .homepage-carousel .carousel-slider {
    height: auto;
  }

  .carousel-text-wrapper {
    height: auto;
    bottom: 3rem;
  }

  .carousel.carousel-slider .control-arrow {
    height: auto;
  }

  .homepage-carousel {
    height: auto;
  }

  .level_sec {
    display: flex;
    flex-direction: column;
  }

  .level_sec_single {
    width: auto !important;
    margin: 1rem !important;
  }

  .banner-text {
    font-size: 14px;
  }
}

.formone-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.form-one-complete-later {
  order: 1;
}

.formone-button-wrapper .MuiCircularProgress-root {
  margin-right: 3rem;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 14.5px 12px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-left: 0px !important;
}

input[type="text"],
select {
  padding-left: 14px !important;
}

.MuiButton-root {
  text-transform: capitalize !important;
}

.MuiTableContainer-root {
  margin: 0px 12px;
}

.question-list-section-wrapper .MuiTableContainer-root {
  margin: 0;
}

.email-configuration-container .MuiTableContainer-root {
  margin: 0;
}

.configaration_input {
  width: 100%;
}

.recruiter_private_table th {
  padding: 12px 16px;
  text-align: left;
}

.Recruiter_Private_Skill_Modal .admindailog_title_modal.MuiDialogTitle-root {
  padding: 16px 24px !important;
}

.Recruiter_Private_Skill {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 2px 16px 2px 16px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Regular";
}

.btn-Recruiter_Private_Skill {
  color: #fff;
  background-color: #ff4141;
}

.btn-Recruiter_Private_Skill:hover {
  color: #fff;
  background-color: #ff4141 !important;
}

.Recruiter_Private_Skill {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 2px 16px 2px 16px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Segoe-Regular";
}

.talent_community_inner .MuiTableContainer-root {
  margin: 0px 0px;
}

.btn-blue_adjust {
  height: 43px;
}

.invite_taketest .form-one-left-upper {
  padding: 0px 31px;
}

.invite_taketest .form-one-left-upper .form-one-left-logo {
  padding: 2rem 0rem 2rem 0rem;
}

.invite_taketest_right .form_text_container_other {

  margin-top: 1.2em;

}


@media (min-width: 1367px) and (max-width: 1920px) {
  .invite_taketest .form-one-left-upper {
    padding: 14px 46px;
  }

}

@media (min-width: 1921px) and (max-width: 2560px) {
  .invite_taketest .form-one-left-upper {
    padding: 14px 54px;
  }

}


.k-grid th,
.k-grid td {

  font-family: Roboto, sans-serif;
}

.k-column-title {
  font-weight: bold;
}

.k-pager-numbers .k-link.k-state-selected {
  color: #ffffff;
  background-color: rgb(63 81 181 / 68%);
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
  color: #ffffff;
  background-color: rgb(63 81 181 / 68%);
}

.k-pager-input,
.k-pager-sizes,
.k-pager-info {
  font-family: Roboto, sans-serif;
}

.recruiter_badge {
  z-index: 0;
}


.Skill_serch_right_wrapper {
  position: unset !important;
  width: 100%;
  display: flex !important;
  justify-content: right;
  flex-direction: row;
}

.Skill_serch_right {

  width: 259px !important;

}

/* input:focus, textarea:focus, select:focus{
  outline: none !important;
  background-color: yellow;
}
.Mui-focused {
   outline: none !important; 
  
} */
/* .bar{
  height: 100%;
  border: 1px solid #009EFD;
  width: 10px;
  margin-left: 10px;
}
.pid{
  width: 10px;
  height: calc(10% - 10px);;
  
  margin: 5px;
} */
.powres_card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px;
  transition: all 0.2s ease-in-out;
  padding: 14px 20px 20px 20px;
  border-radius: 4px;
  background-color: #ffffff;
}

.btn_powres {
  color: #01313c;
  background-color: #d1e8ff;
}

.btn_powres:hover {
  /* color: #0f8aa6; */
  opacity: 0.7;
  background-color: #d1e8ff;
}

.btn_powres:focus {
  outline: none;
}

.fs-semi-ui-class {
  font-family: "Segoe UI"
}
.footerContainer_inc{
    margin-top: auto;
}
.browserLinks{
    font-size: 15px;
    font-family: 'Manrope-SemiBold';
    color: #4998EF;
    text-decoration: none;
    cursor: pointer;
    margin-top: 3px;
}
.browserLinks:hover{
    opacity: 0.8;
    text-decoration: underline;
}
.MainContainer_INC{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #ffffff;
}
.containerTop_INC{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.mainDiv_top{
    margin: 0 11%;
    margin-top: 20vh;
    margin-bottom: 70px;
}
.topHeading_inc{
    font-size: 2rem;
    font-family: 'Manrope-Semibold'
}
.browserContainer_INC{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-top: 20px;
}
@media (max-width:600px){
    .topHeading_inc{
        font-size: 24px;
    }
}
