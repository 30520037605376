.mainContainer_requisitionReports {
    min-height: 100vh;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.topContainer_requisitionReports {
    display: flex;
    flex-direction: column;
}

.contentcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 10px;
}

.sideContainer {
    /* border: 1px solid red; */
    padding: 1.5%;
    width: 27%;
    height: fit;
    background-color: white;
    padding-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
    margin-right: 2%;
    box-sizing: border-box;
    min-width: 360px;
    overflow: hidden;
}

.mainContainer {
    /* border: 1px solid red; */
    width: 72%;
    height: 100%;
    
}


.mainDiv_requisitionReports {
    margin-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
}

.titleContainer_requisitionReports {
    display: flex;
    justify-content: start;
    font-family: "Segoe-Semibold";
    /* background-color: #05A6F4;
    color: #FFFFFF; */
    font-size: 24px;
    padding: 5px 3px;
    color: #2896ff;
    border-bottom: 1px solid #2896ff;
    width: 140px;
}

.titleIcon_requisitionReports {
    height: 35px !important;
    width: 40px !important;
    margin-left: -10px;
}

.bottomContainer_requisitionReports {
    margin-top: auto;
}

.goBackToDashboard_requisitionReports {
    font-size: 20px;
    color: #7F7F7F;
    font-family: "Segoe-Semibold";
    /* text-decoration: none; */
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    margin-top: 15px;
}

.goBackToDashboard_requisitionReports:hover {
    color: #04093f;
}

.tabsContainer_requisitionReports {
    display: flex;
    /* margin-top: 10px; */
    gap: 3px;
    width: 100%;
}

.tabsMainContainer_requisitionReports {
    display: flex;
    /* margin-top: 10px; */
    gap: 3px;
    justify-content: space-between;
    align-items: flex-end;
}

.tabs_requisitionReports {
    font-family: 'Segoe-Semibold';
    width: 35%;
    font-size: 15px;
    text-align: center;
    padding: 10px 8px;
    cursor: pointer;
    background: #B0DAF18A;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tabs_requisitionReports:hover {
    opacity: 1;
    background: #a1cae4;
}

.tabs_requisitionReports_selected {

    background-color: #009EFD;
    width: 35%;
    font-size: 15px;
    color: #FFFFFF;
    font-family: 'Segoe-Semibold';
    text-align: center;
    padding: 10px 8px;
    opacity: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.kendoContainer_requisitionReports {
    margin-top: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
    
}
.export-button{
    width: 99%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1%;
}
.kendoContainer_requisitionReports .k-grid {
    height: 58vh !important;
}

.excelButton_requisitionReports {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009EFD;
    color: #ffffff;
    gap: 6px;
    padding: 10px 1px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: 'Segoe-semibold';
    font-size: 14px;
    width: 75px;
    margin-left: auto;
}

.excelButton_requisitionReports:hover {
    opacity: 0.9;
}

.excelImg_requisitionReports {
    height: 30px;
    padding-right: 15px;
}

.kendo_reports {
    min-height: 667px;
    margin-top: 10px;
}


.dialogContent_requisitionReport {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
}

.dialogTitleContainer_reports {
    width: 275px;
    /* padding-left: 5px; */
}

.dialogTitle_reports {
    font-family: 'Segoe-semibold';
    font-size: 20px;
    padding-left: 5px;
    padding-bottom: 7px;
    border-bottom: 1px solid #2896ff;
}

.dialogContent_Text_requisitionReport {
    font-family: 'Segoe-semibold';
    padding-bottom: 7px;
    font-size: 15px;
}

.createRequisition_textbox_datapicker_reports {
    height: 40px;
    width: 150px;
    /* border: 1px solid rgb(192, 192, 192); */
    border: 2px solid #177bd3ce !important;
    font-family: 'Segoe UI' !important;

}

.createRequisition_textbox_datapicker_reports_filter {
    width: 100px;
    border: 1px solid rgb(192, 192, 192);
    height: 50px;
}
 .react-datepicker-wrapper{
   width: 100%;
   font-family: 'Segoe UI' !important;
   display: block; 
}

  

.dialogContent_requisitionReport .react-datepicker__calendar-icon {
    right: 8px !important;
    margin-top: 7px;
}

.dialogContent_requisitionReport .react-datepicker-wrapper {
    width: 100%;
}

.dialogContent_requisitionReport .MuiDialogTitle-root {
    padding: 10px 24px !important;
}


.button_Container_reports {
    margin-right: 5px !important;
    margin-top: 180px !important;
    gap: 5px;
}

.excelButton_requisitionReports_dialog {
    cursor: pointer !important;
    background-color: #1e7145 !important;
    color: #ffffff !important;
    gap: 6px;
    font-family: 'Segoe-semibold' !important;
    font-size: 14px !important;
    /* float: right; */
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */

    /* padding: 10px 1px;
    border-radius: 5px;
    border: none;
    
    
    width: 200px; */
    background-color: #009EFD;
    margin-left: 200px;
    opacity: 0.9;
}


.excelButtonContainer_reports {
    display: flex;
    flex-direction: row;
    width: 120px;
}

.exportText_Dialog_reports {
    font-family: 'Segoe-semibold' !important;
    font-size: 16px !important;
}



.kendo_reports_recruiterActivity {
    min-height: 667px;
    margin-top: 10px;
}


.kendo_reports_recruiterActivity.k-grid .k-table-row {
    height: 53px !important;
}

.gobackContainer_requisitionReports {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dateRangeContainer_requisitionReports {
    display: flex;
    align-items: center;
    gap: 10px;
    ;
}

.dateRangeContainer_requisitionReports_text {
    font-family: 'Segoe-Semibold';
    font-size: 16px;

}

.datePicker_Container .react-datepicker__calendar-icon {
    right: 8px !important;
    top: 4px !important;
}

.dateRangeContainer_requisitionReport .requistionRepoets_date_border {
    border: 2px solid #177bd3ce !important;
    padding: 12px 10px !important;
    font-family: "Segoe UI" !important;
    font-size: 14px !important;
}

.search_main_Container{
    width:100%;
    height: 100%;
    /* background-color: black; */
    box-sizing: border-box;
}
.search_btn_box{
    width:113%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #D1E8FF ;
    padding:6%;
    box-sizing: border-box;
    margin: -6% 0 0 -6.5%;
}
.text_top{
    width: 50%;
    display: inline;
    /* border: 1px solid red; */
}
.search_btn_top{
    /* box-sizing: border-box; */
    width: 25%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -4% 0 0 0;
}

.search_btn{
    /* box-sizing: border-box; */
    background-color: #31B3F8; 
    outline: none;
    border: none;
    border-radius: 3px;
    padding: 10%;
    color:white;
    width: 100%;
    font-size: 1em;
    
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.current_tab_search_name{
    width:100%;
    height: 6%;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    /* background-color: #00A7FF; */
    /* color: black; */
    /* text-align: center!important; */
}
.access_search input[type="text"],
select {
  
    height: 47.5px;

}